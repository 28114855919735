import React, { FC, ReactNode } from 'react';

import { Box, Flex, ListItem, Stack, UnorderedList } from '@chakra-ui/react';
import backgroundMissing from 'images/Background-Missing.jpg';
import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { PrimaryButton } from 'components/DataEntry';

interface Props {
  header: string;
  headerMessage: ReactNode | string;
  steps: string[];
  footer: string;
  footerLink: string;
}

export const NoSetup: FC<Props> = props => {
  const { header, headerMessage, steps, footer, footerLink } = props;
  const navigate = useNavigate();

  return (
    <Box
      pl={24}
      pt={3}
      backgroundImage={backgroundMissing}
      w="full"
      h="full"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      borderRadius={6}
      //maxH="1000px"
      minH="700px"
    >
      <Stack color="white" pt={6} pl={1} pr={32} spacing={16} w="85%">
        <Stack
          spacing={8}
          pl={12}
          border="1px solid"
          borderRadius={6}
          borderColor={customTheme.colors.gray['100']}
          pt={5}
          pb={10}
          bg="rgba(255, 255, 255, 0.25)"
        >
          <Box fontSize="2xl">{header}</Box>
          <Box fontSize="lg" w={'1000px'}>
            {headerMessage}
          </Box>
        </Stack>
        <Stack pl={12} spacing={3}>
          <Box fontSize="2xl">Next Steps</Box>
          <UnorderedList fontSize="lg" spacing={2}>
            {map(steps, o => (
              <ListItem pl={4}>{o}</ListItem>
            ))}
          </UnorderedList>
        </Stack>
        <Flex>
          <Box pt={40} pl={12}>
            <PrimaryButton size="lg" onClick={() => navigate(footerLink)}>
              {footer}
            </PrimaryButton>
          </Box>
        </Flex>
      </Stack>
    </Box>
  );
};
