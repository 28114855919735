import React from 'react';

import { VirtualMachineIcon } from 'components/Icons';
import { CDashboard } from 'components/Visualization';
import { infrastructureDashboardConfig } from 'containers/Dashboard/CloudPostureOverview/config';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';

export const InfrastructurePosture: React.FC = () => {
  return (
    <DashboardOverviewWrapperCard
      label="Infrastructure Security"
      content={<CDashboard dashboardWidgets={infrastructureDashboardConfig} />}
      icon={<VirtualMachineIcon />}
      redirectLink="/findings?tab=Cloud Posture"
    />
  );
};
