import React, { FC } from 'react';

import { Box, Stack, Image, HStack } from '@chakra-ui/react';
import map from 'lodash/map';

import { HelpCount } from 'components/DataDisplay/Utils/HelpCount';

import { steps } from './steps';

export const CreateOAuthToken: FC = () => {
  return (
    <Stack px={4} spacing={6} pt={4}>
      {map(steps, (each, index) => (
        <Stack spacing={1} key={`${index}-slack`}>
          <HStack align={'start'}>
            <HelpCount count={index + 1}></HelpCount>
            <Box>{each.description}</Box>
          </HStack>
          <Box pt={each?.pt ?? 4} pb={4} pl={8}>
            <Image src={each.image} alt={each.alt} />
            {each.image2 && <Image src={each.image2} alt={each.alt2} pt={4} />}
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};
