import React, { FC } from 'react';

import { Box, Center, Stack, Image } from '@chakra-ui/react';
import channelDetails from 'images/slack/Slack-channel-ID.svg';
import channelOptions from 'images/slack/Slack-get-channel-ID.svg';

export const GetChannelID: FC = () => {
  return (
    <Stack px={4} spacing={6} pt={4} w="full">
      <Box>
        Log in to your workspace in Slack via a web browser or app. Right click
        on the channel name and click on "View channel details". Copy channel ID
        at bottom.
      </Box>
      <Box w="full" pl={32}>
        <Center w={48}>
          <Image src={channelOptions} alt="Channel options" />
        </Center>
      </Box>
      <Image px={28} src={channelDetails} alt="Channel details" />
    </Stack>
  );
};
