import React, { useEffect, useMemo, useState } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import round from 'lodash/round';
import { useSelector } from 'react-redux';

import { BarChart } from 'components/Visualization';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import {
  customizedXAxisTick,
  customizedYAxisTick,
} from 'components/Visualization/Charts/utils';
import {
  renderChange,
  renderLegendsAtTop,
} from 'containers/Dashboard/utils/utils';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { formatDate } from 'utils/date';

export const ExploitableEntitiesToPatch: React.FC = () => {
  const { exploitableEntitiesToPatch } = useSelector(
    selectVulnerabilityOverview,
  );
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const [change, setChange] = useState(0);
  const color = '#eeb2b4';

  useEffect(() => {
    const items = map(
      exploitableEntitiesToPatch.data?.exploitable_entities_to_patch,
      (count, date) => ({
        Date: formatDate(date, 'D-MMM'),
        Entities: count,
      }),
    );
    setChartData(items);
    const first = items?.[0]?.Entities ?? 0;
    const last = items?.[items?.length - 1]?.Entities ?? 0;
    setChange(
      first === 0 && last > 0
        ? 100
        : first === 0
        ? 0
        : round(((last - first) / first) * 100, 0),
    );
  }, [exploitableEntitiesToPatch.data]);

  const legends = useMemo(
    () => [
      {
        label: 'Entities',
        color,
      },
    ],
    [],
  );

  return (
    <DashboardWidget
      label="Exploitable Entities To Patch"
      styles={{ contentWrapper: { pb: 0 } }}
      subHeader="Last 30 days - Daily"
      content={
        <Stack w="full" h="full" spacing={0}>
          <HStack justify="space-between" w="full">
            <Box pl={10}>{renderLegendsAtTop(legends)}</Box>
            <Box>{renderChange(change)}</Box>
          </HStack>
          <Box flex={1}>
            <BarChart
              xField={'Date'}
              yField={['Entities']}
              data={chartData}
              legend={false}
              colorMap={{ Entities: color }}
              styles={{
                yAxis: {
                  axisLine: false,
                  tickLine: false,
                  tick: props =>
                    customizedYAxisTick({
                      ...props,
                      style: { fontSize: 14, x: -6 },
                    }),
                },
                xAxis: {
                  tickLine: false,
                  interval: 'preserveStartEnd',
                  ticks: [
                    chartData?.[0]?.Date,
                    chartData?.[chartData?.length - 1]?.Date,
                  ],
                  tick: props =>
                    customizedXAxisTick({
                      ...props,
                      style: { fontSize: 14, fontWeight: 600, y: 6 },
                    }),
                },
              }}
            />
          </Box>
        </Stack>
      }
      isLoading={exploitableEntitiesToPatch.isLoading}
    />
  );
};
