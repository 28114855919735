import React, { useCallback } from 'react';

import {
  CloudEnvironmentType,
  EnvironmentRead,
} from '@ariksa/compliance-policies/api';
import { CloudInsightCategory } from '@ariksa/inventory-core/api';
import { map } from 'lodash';

import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';

import { limitedString } from '../../../utils/string';

interface EnvironmentNameProps {
  environmentId: string;
  limitedStringNumber?: number;
}

export const EnvironmentName = (props: EnvironmentNameProps) => {
  const { environmentMapping } = useEnvironmentOptions();
  const { environmentId, limitedStringNumber } = props;

  return (
    <>
      {limitedStringNumber
        ? limitedString(
            environmentMapping?.[environmentId]?.name ?? environmentId,
            limitedStringNumber,
          )
        : environmentMapping?.[environmentId]?.name ?? environmentId}
    </>
  );
};

export const useShowEnvironment = () => {
  // filtering environments based on the current path
  const showEnvironment = useCallback(
    (environment: EnvironmentRead, pathname) => {
      if (pathname.includes('/inventory')) {
        return true;
      }

      if (
        pathname.includes('/visibility/access') ||
        pathname.includes('/visibility/posture') ||
        pathname.includes('/dashboard/data')
      ) {
        return (
          environment.environment_type === CloudEnvironmentType.DataSaas ||
          environment.environment_type === CloudEnvironmentType.CloudProvider
        );
      } else if (pathname.includes('/dashboard')) {
        return (
          environment.environment_type === CloudEnvironmentType.Iac ||
          environment.environment_type === CloudEnvironmentType.CloudProvider
        );
      }

      return (
        environment.environment_type === CloudEnvironmentType.CloudProvider
      );
    },
    [],
  );

  return {
    showEnvironment,
  };
};

export const runtimeInsightsCategoryOptions = map(CloudInsightCategory, o => ({
  label: o,
  value: o,
}));
