import React, { ReactNode } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map, sum, toLower, values } from 'lodash';
import round from 'lodash/round';
import { customTheme, colorOpacity } from 'theme';

import { getIcon, getIconColor, getProviderName } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { FootprintBox } from 'components/DataDisplay/Utils/FootprintBox';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CustomerJourneyBulbIcon,
  GraphDecreaseIcon,
  GraphIncreaseIcon,
} from 'components/Icons';
import {
  CountFontSize,
  ResourceIconAndCountProps,
} from 'containers/Dashboard/types';
import { LegendProps } from 'containers/Dashboard/types';
import { dashboardWidgetLineStyles } from 'containers/Dashboard/utils/styles';
import { limitedString } from 'utils/string';

export const renderResourceCount = ({
  icon,
  count,
  label,
  isLoading = false,
  onClick = () => {},
  numberFormatting = true,
}) => (
  <Stack
    w="full"
    spacing={4}
    onClick={onClick}
    cursor={!!onClick ? 'pointer' : 'default'}
    fontSize={16}
    key={label + '-key-resource-count'}
  >
    <Center w="full">{label}</Center>
    <Center w="full" color="primary">
      <Box boxSize={7}>{icon}</Box>
    </Center>
    <Center w="full" fontWeight={600} fontSize={CountFontSize}>
      {isLoading ? (
        <CustomSpinner />
      ) : numberFormatting ? (
        formatNumber(count, 1)
      ) : (
        count
      )}
    </Center>
  </Stack>
);

export const renderSummaryCount = (
  icon,
  count,
  label,
  isLoading = false,
  onClick = () => {},
  numberFormatting = true,
) => (
  <Stack
    w="full"
    spacing={4}
    onClick={onClick}
    cursor={!!onClick ? 'pointer' : 'default'}
    key={label + '-key-summary-count'}
  >
    <Center w="full" color="primary">
      <Box boxSize={7}>{icon}</Box>
    </Center>
    <Stack w="full" spacing={0}>
      <Center w="full" fontWeight={600}>
        {isLoading ? (
          <CustomSpinner />
        ) : numberFormatting ? (
          formatNumber(count, 1)
        ) : (
          count
        )}
      </Center>
      <Center w="full">{label}</Center>
    </Stack>
  </Stack>
);

export const renderCloudIcons = providers => (
  <HStack>
    {map(providers, (o, index) => (
      <CustomTooltip
        label={getProviderName(o)}
        key={index + 'cloud-provider-tooltip'}
      >
        <Box boxSize={4}>
          <Center pt={toLower(o) === 'aws' ? 1 : 0}>{getIcon(o)}</Center>
        </Box>
      </CustomTooltip>
    ))}
  </HStack>
);

export const renderLegendBox = (
  legends: Record<string, any>[],
  label: string,
) => {
  return (
    <FootprintBox
      content={
        <HStack w="full" h="full" justify="space-between" spacing={0}>
          {map(legends, o =>
            renderSummaryCount(
              o.icon,
              o.count,
              o.label,
              o.isLoading,
              o.onClick,
            ),
          )}
        </HStack>
      }
      label={label}
    />
  );
};
export const renderLegend = (legends: LegendProps[], py = 2) => (
  <Box h="full" py={py}>
    <Stack
      h="full"
      pl={1}
      pr={4}
      borderRight="1px solid"
      borderColor={customTheme.colors.gray['200']}
    >
      {map(legends, (o, index) => (
        <Center h="full" key={index + 'key-legends'}>
          <HStack justifyContent="space-between" w="full">
            <HStack>
              <Box boxSize={6} color="primary" p={o?.iconPadding ?? 0.5}>
                <Center>{o?.icon}</Center>
              </Box>
              <Box
                color={customTheme.colors.gray['300']}
                cursor={!!o?.onClick ? 'pointer' : 'default'}
                onClick={o?.onClick}
              >
                {o.label}
              </Box>
            </HStack>
            <Box fontWeight={600}>
              {o.isLoading ? <CustomSpinner /> : formatNumber(o.count, 1)}
            </Box>
          </HStack>
        </Center>
      ))}
    </Stack>
  </Box>
);

export const getSeveritySum = severity =>
  sum([severity?.CRITICAL, severity?.MEDIUM]);

export const renderAccount = (
  name: string,
  total: number,
  cloud?: string,
  stringLength = 20,
  onClick?: () => void,
) => (
  <HStack
    border="1px solid"
    borderRadius={4}
    borderColor={customTheme.colors.gray['100']}
    color={cloud ? 'black' : 'white'}
    onClick={onClick}
    cursor={!!onClick ? 'pointer' : 'default'}
    bg={
      cloud
        ? colorOpacity(getIconColor(cloud), 0.2)
        : customTheme.colors.primary
    }
    px={1}
    key={name + '-' + cloud + '-account'}
  >
    {cloud && (
      <Center boxSize={4} pt={cloud === 'aws' || 'AWS' ? 1 : 0}>
        <Center>{getIcon(cloud)}</Center>
      </Center>
    )}
    <Box>
      {limitedString(name, total === 1 ? stringLength * 2 : stringLength)}
    </Box>
  </HStack>
);

export const renderEmptyRows = dataLength => (
  <>
    {dataLength < 3 && <Box h="full" />}
    {dataLength < 2 && <Box h="full" />}
  </>
);

export const renderPercentChange = (change, color?: string) =>
  change === 0 ? (
    <Box color={customTheme.colors.gray['300']}>0%</Box>
  ) : (
    <HStack
      color={
        color ?? (change < 0 ? customTheme.colors.green['300'] : 'critical')
      }
      spacing={1}
    >
      <Box>
        {change > 0 ? '+' : ''}
        {round(change, 2)}%
      </Box>
      <Box boxSize={5}>
        <Center>{change > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />}</Center>
      </Box>
    </HStack>
  );

export const renderChange = change => (
  <HStack w="full" justify="flex-end">
    <Box color={customTheme.colors.gray['300']}>Change:</Box>
    {renderPercentChange(change)}
  </HStack>
);

export const renderChartLegend = (props: LegendProps) => {
  const { label, count, color, onClick, spacing = 3, labelSpacing = 1 } = props;
  return (
    <HStack spacing={labelSpacing}>
      <Box boxSize={3} bg={color} borderRadius={3} />
      <HStack spacing={spacing}>
        <Box onClick={onClick} cursor={onClick ? 'pointer' : 'default'}>
          {label}
        </Box>
        {!!count ? <Box fontWeight={600}>{formatNumber(count, 1)}</Box> : ''}
      </HStack>
    </HStack>
  );
};

export const renderLegendsAtTop = (legends: LegendProps[], spacing = 6) => {
  return (
    <HStack spacing={spacing}>{map(legends, o => renderChartLegend(o))}</HStack>
  );
};

export const renderSmallFindingCard = (categories, c) => {
  return (
    <Center
      w="full"
      h="full"
      key={c + '-key-critical-and-high-severity-findings-by-category'}
    >
      <Box
        h="full"
        w={56}
        border="1px solid"
        borderColor={categories[c]?.borderColor}
        borderRadius={6}
        bg={categories[c]?.color}
        fontSize={16}
        onClick={categories[c]?.onClick}
        cursor="pointer"
      >
        <Stack spacing={0} h="full">
          <Box w="full" h="full">
            <Center h="full">{c}</Center>
          </Box>
          <Box
            fontWeight={600}
            h="full"
            fontSize={CountFontSize}
            textAlign="center"
          >
            {categories[c]?.isLoading ? (
              <CustomSpinner />
            ) : (
              formatNumber(categories[c]?.count)
            )}
          </Box>
        </Stack>
      </Box>
    </Center>
  );
};

export const getSumOfValues = item => sum(values(item)) ?? 0;

const renderResourceWithIconAndCount = (props: ResourceIconAndCountProps) => {
  const {
    iconColor = 'primary',
    icon,
    isLoading,
    iconType,
    onClick,
    count = 0,
    label,
    iconPadding = 0,
    iconTooltip,
  } = props;

  const renderItem = () => (
    <HStack spacing={6}>
      <Box boxSize={6} color={iconColor} p={iconPadding}>
        {iconType ? getIcon(iconType) : icon}
      </Box>
      <Box>{label}</Box>
    </HStack>
  );
  return (
    <HStack
      {...dashboardWidgetLineStyles}
      justify="space-between"
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'inherit'}
      maxH={14}
      h={12}
    >
      {iconTooltip ? (
        <CustomTooltip label={iconTooltip}>{renderItem()}</CustomTooltip>
      ) : (
        renderItem()
      )}
      <Box>{isLoading ? <CustomSpinner /> : formatNumber(count, 1)}</Box>
    </HStack>
  );
};

export const renderResourcesWithIconAndCount = (
  items: ResourceIconAndCountProps[],
) => (
  <Stack spacing={0} w="full" h="full">
    {map(items, o => renderResourceWithIconAndCount(o))}
  </Stack>
);

export const DashboardWidgetRightIcon = (props: {
  icon: ReactNode;
  onClick?();
  iconColor?: string;
  iconTooltip?: string | ReactNode;
}) => {
  const { icon, onClick, iconColor, iconTooltip } = props;
  const renderBox = () => {
    return (
      <Box
        p={1}
        border="1px solid"
        borderColor={customTheme.colors.gray['200']}
        boxSize={8}
        color={iconColor}
        borderRadius={6}
        cursor={onClick ? 'pointer' : 'default'}
        onClick={onClick}
      >
        {icon}
      </Box>
    );
  };

  return iconTooltip ? (
    <CustomTooltip label={iconTooltip}>{renderBox()}</CustomTooltip>
  ) : (
    renderBox()
  );
};

export const DashboardWidgetTooltipIcon = () => (
  <DashboardWidgetRightIcon
    icon={<CustomerJourneyBulbIcon />}
    iconColor="orange"
  />
);
