import React, { ReactNode } from 'react';

import { Box, HStack, useRadio, UseRadioProps, Center } from '@chakra-ui/react';
import { colorMap, customTheme } from 'theme';

interface Props extends UseRadioProps {
  option: { label: ReactNode; icon: ReactNode; value: any };
  iconStyles?: any;
}

export const RadioCard = (props: Props) => {
  const { option, iconStyles, ...rest } = props;
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        borderColor="primary"
        color={customTheme.colors.gray['300']}
        _checked={{
          bg: colorMap.primary(300),
          color: 'white',
        }}
        _focus={{
          boxShadow: 'none',
          border: 'none',
          /* bg: 'primary',
          color: 'white',*/
        }}
        pl={3}
        pr={4}
        py={3}
      >
        <HStack spacing={5} w="full">
          <Box h="full" w={10} {...iconStyles}>
            <Center>{option.icon}</Center>
          </Box>
          <Box>{option.label}</Box>
        </HStack>
      </Box>
    </Box>
  );
};
