import React from 'react';

import { Resources } from '@ariksa/inventory-core/api';
import { Box, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { BlockStorageIcon } from 'components/Icons';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const Disks: React.FC = () => {
  const { dataSecurity } = useSelector(selectDataSecurityOverview);
  const { analyzedDataSourcesCount, summaryOfAllDataSources } = useSelector(
    selectDashboard,
  );
  const { othersCount } = dataSecurity;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Disks"
      icon={<BlockStorageIcon />}
      onClick={() =>
        navigate('/inventory/summary?tab=Data_' + Resources.BlockStorage)
      }
      change={{
        isLoading: othersCount.isLoading,
        first: othersCount.data?.previous_count,
        last: othersCount.data?.current_count,
        percentChange: othersCount.data?.percentage_change,
      }}
      cloudProviders={
        summaryOfAllDataSources.data?.resource_type_providers?.Disks ?? []
      }
      count={summaryOfAllDataSources.data.resource_type_counts?.Disks ?? 0}
      isLoading={summaryOfAllDataSources.isLoading}
      info={{
        isLoading: analyzedDataSourcesCount.isLoading,
        message: !!analyzedDataSourcesCount.data?.Others ? (
          <HStack>
            <Box>Analyzing</Box>
            <Box>
              {analyzedDataSourcesCount.isLoading ? (
                <CustomSpinner />
              ) : (
                analyzedDataSourcesCount.data?.Others
              )}
            </Box>
          </HStack>
        ) : (
          'No Resources Analyzed'
        ),
        enabled: !!analyzedDataSourcesCount.data?.Others,
      }}
      labelTooltip={formatTooltip({
        header: 'Disks',
        content: 'Virtual disks or block storage',
      })}
    />
  );
};
