/**
 *
 * OnboardGitLabIAC
 *
 */

import React, { FC, useCallback, useEffect, useReducer } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { CloudEnvironmentType } from '@ariksa/compliance-policies/api';
import {
  HStack,
  Box,
  Stack,
  Input,
  Flex,
  InputGroup,
  InputRightElement,
  Center,
} from '@chakra-ui/react';
import { filter, find, forEach, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { PrimaryButton, PrimaryIconButton } from 'components/DataEntry';
import { Form } from 'components/DataEntry/Form';
import { defaultStyles as formDefaultStyles } from 'components/DataEntry/Form/styles';
import {
  AddIcon,
  CheckmarkIcon,
  CrossIcon,
  MinusIcon,
  RepoBranchIcon,
} from 'components/Icons';
import {
  selectApp,
  selectOnboardedCloudAccounts,
} from 'containers/App/selectors';
import { selectCloudAccountWizard } from 'containers/Setup/CloudAccounts/CloudAccountWizard/selectors';
import { actions } from 'containers/Setup/CloudAccounts/CloudAccountWizard/slice';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';
import { actions as setupActions } from 'containers/Setup/slice';

import { customTheme } from '../../../../../../../theme';

interface Props {
  isEdit?: boolean;
  provider: CloudProviders;
}

export const OnboardIACRepository: FC<Props> = props => {
  const { isEdit = false, provider } = props;
  const {
    repositories,
    branches,
    onboardRepository,
    validateS3Arn,
  } = useSelector(selectCloudAccountWizard);
  const { accountDetails } = useSelector(selectCloudAccounts);
  const accounts = useSelector(selectOnboardedCloudAccounts);
  const { environments } = useSelector(selectApp);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      name: '',
      saasOptions: [],
      environmentOptions: [],
      selectedSaasAccount: {},
      selectedEnvironment: {},
      repositoryOptions: [],
      selectedRepository: {},
      branchOptions: [],
      selectedBranch: {},
      bucketARNs: {},
      bucketARN: '',
      validatedArn: '',
      enableValidate: false,
    },
  );

  const getProvider = useCallback(() => {
    switch (provider) {
      case CloudProviders.GitlabIac:
        return CloudProviders.Gitlab;
      case CloudProviders.GithubIac:
        return CloudProviders.Github;
      case CloudProviders.BitbucketIac:
        return CloudProviders.Bitbucket;
    }
  }, [provider]);

  //set accounts
  useEffect(() => {
    const options = map(
      filter(accounts.data, o => o.cloud_type === getProvider()),
      o => ({ label: o.name, value: o.uuid }),
    );
    updateState({ saasOptions: options, selectedSaasAccount: options[0] });
  }, [accounts.data, getProvider]);

  //set saas account in edit mode
  useEffect(() => {
    if (isEdit)
      updateState({
        selectedSaasAccount: find(
          state.saasOptions,
          o => o.value === accountDetails.data?.parent,
        ),
      });
  }, [isEdit, state.saasOptions, accountDetails.data]);

  //get repositories
  useEffect(() => {
    !!state.selectedSaasAccount?.value &&
      dispatch(
        actions.getRepositories({
          q: { uuid: state.selectedSaasAccount?.value },
          onSuccess: res => {
            const options = map(res, o => ({
              label: o.id,
              value: o.id,
              default_branch: o.default_branch,
            }));
            let branch: Record<string, any> = {};
            if (!isEmpty(options))
              branch = {
                label: options[0].default_branch,
                value: options[0].default_branch,
                icon: <RepoBranchIcon />,
              };

            updateState({
              repositoryOptions: options,
              selectedRepository: options[0],
              selectedBranch: branch,
            });
          },
        }),
      );
  }, [state.selectedSaasAccount, dispatch]);

  //set repository in edit mode
  useEffect(() => {
    if (isEdit)
      updateState({
        selectedRepository: find(
          state.repositoryOptions,
          o =>
            o?.value ===
            accountDetails.data?.[getProvider()!]?.path_with_namespace,
        ),
        selectedBranch: {
          label: accountDetails.data?.[getProvider()!]?.default_branch,
          value: accountDetails.data?.[getProvider()!]?.default_branch,
        },
      });
  }, [state.repositoryOptions, accountDetails.data, isEdit, getProvider]);

  //get branches
  useEffect(() => {
    !!state.selectedRepository?.value &&
      !!state.selectedSaasAccount?.value &&
      dispatch(
        actions.getBranches({
          q: {
            repoId: state.selectedRepository?.value,
            uuid: state.selectedSaasAccount?.value,
          },
          onSuccess: res => {
            //set branch options
            updateState({
              branchOptions: map(res, o => ({
                label: o,
                value: o,
                icon: <RepoBranchIcon />,
              })),
            });
          },
        }),
      );
  }, [state.selectedRepository, state.selectedSaasAccount, dispatch]);

  //set environments
  useEffect(() => {
    const options = map(
      filter(
        environments.data,
        o => o.environment_type === CloudEnvironmentType.Iac,
      ),
      o => ({ label: o.name, value: o.id }),
    );
    updateState({
      environmentOptions: options,
      selectedEnvironment: options[0],
    });
  }, [environments, environments.data]);

  //set tf_state_files
  useEffect(() => {
    const arns: Record<string, any> = {};
    forEach(
      accountDetails.data?.[getProvider()!]?.tf_state_files,
      o => (arns[o] = true),
    );
    updateState({ bucketARNs: arns });
  }, [accountDetails.data, getProvider]);

  //is edit
  useEffect(() => {
    if (isEdit) updateState({ name: accountDetails.data.name });
  }, [accountDetails.data, isEdit]);

  useEffect(() => {
    return () => {
      updateState({ selectedSaasAccount: {}, selectedRepository: {} });
    };
  }, []);

  const handleReset = () => {
    navigate('/setup/accounts');
  };

  const handleSubmit = data => {
    let bucketARNs: string[] = [];
    forEach(
      state.bucketARNs,
      (valid, key) => valid && (bucketARNs = [...bucketARNs, key]),
    );
    if (
      !!state.bucketARN &&
      state.bucketARN === state.validatedArn &&
      validateS3Arn.data
    )
      bucketARNs = [...bucketARNs, state.bucketARN];

    if (isEdit)
      dispatch(
        setupActions.updateCloudAccount({
          q: {
            uuid: accountDetails.data.uuid,
            cloudAccountUpdate: {
              cloud_type: provider,
              name: data.name,
              [getProvider()!]: {
                tf_state_files: bucketARNs,
              },
            },
          },
          onSuccess: handleReset,
        }),
      );
    else
      dispatch(
        actions.onboardRepositories({
          q: {
            parentUuid: state.selectedSaasAccount?.value,
            iACOnboardingRequest: {
              accounts: [
                {
                  default_branch: state.selectedBranch?.value,
                  repo_id: state.selectedRepository?.value,
                  tf_state_files: bucketARNs,
                  name: data.name,
                },
              ],
            },
          },
          onSuccess: handleReset,
        }),
      );
  };

  const handleValidate = useCallback(() => {
    updateState({ validatedArn: state.bucketARN });
    dispatch(
      actions.validateS3Arn({
        q: {
          fileArn: encodeURI(state.bucketARN),
        },
        onSuccess: res => {
          updateState({ enableValidate: res });
        },
      }),
    );
  }, [dispatch, state.bucketARN]);

  const renderCheckmark = value => (
    <Center
      borderRadius="full"
      bg={value ? customTheme.colors.green['300'] : 'red'}
      color="white"
      p={0.5}
    >
      {value ? <CheckmarkIcon /> : <CrossIcon />}
    </Center>
  );

  const renderBucketArns = (arn, valid) => {
    return (
      <HStack>
        <Box
          w="full"
          border="1px solid"
          borderColor={customTheme.colors.gray['100']}
          px={3}
          py={1}
          borderRadius={4}
        >
          <HStack justify="space-between">
            <Box>{arn}</Box>
            {renderCheckmark(valid)}
          </HStack>
        </Box>
        <PrimaryIconButton
          size="xxs"
          p={0.5}
          borderRadius={'50%'}
          icon={<MinusIcon />}
          aria-label={'remove s3 url'}
          color="white"
          bg="red"
          _hover={{ bg: 'red' }}
          onClick={() => {
            updateState({
              bucketARNs: {
                ...state.bucketARNs,
                [arn]: false,
              },
              bucketARN: '',
            });
          }}
        />
      </HStack>
    );
  };

  const renderS3TfFileInput = () => {
    return (
      <Stack>
        <HStack>
          <InputGroup>
            <Input
              {...formDefaultStyles.textField?.input}
              placeholder="Please enter the ARN for the state file in S3 bucket"
              isDisabled={validateS3Arn.isLoading}
              value={state.bucketARN}
              onChange={e =>
                updateState({
                  bucketARN: e.target.value,
                  enableValidate: !!e.target.value,
                })
              }
            />
            {(validateS3Arn.isLoading ||
              validateS3Arn.isSuccess ||
              validateS3Arn.isError) &&
              !!state.bucketARN &&
              state.validatedArn === state.bucketARN && (
                <InputRightElement w={8} h={8} p={2}>
                  <WithSpinner
                    loadStatus={{
                      loading: validateS3Arn.isLoading,
                    }}
                  >
                    {renderCheckmark(validateS3Arn.data)}
                  </WithSpinner>
                </InputRightElement>
              )}
          </InputGroup>
          {
            <PrimaryIconButton
              size="xxs"
              p={0.5}
              borderRadius={'50%'}
              icon={<AddIcon />}
              aria-label={'add s3 url'}
              color="white"
              bg="primary"
              isDisabled={!state.bucketARN || !validateS3Arn.data}
              onClick={() => {
                const arn = state.bucketARN;
                updateState({
                  bucketARNs: {
                    ...state.bucketARNs,
                    [arn]: true,
                  },
                  bucketARN: '',
                });
              }}
            />
          }
        </HStack>
        {
          <Flex justify="flex-end" w="full">
            <PrimaryButton
              onClick={e => handleValidate()}
              isDisabled={
                !state.bucketARN ||
                validateS3Arn.isLoading ||
                !state.enableValidate
              }
            >
              Validate
            </PrimaryButton>
          </Flex>
        }
      </Stack>
    );
  };

  const getHeader = () => {
    switch (provider) {
      case CloudProviders.GithubIac:
        return 'GitHub';
      case CloudProviders.GitlabIac:
        return 'GitLab';
      case CloudProviders.BitbucketIac:
        return 'BitBucket';
    }
  };

  return (
    <Form
      title={
        <PageHeaderWithIcon
          label={(isEdit ? 'Update' : 'Add') + ' ' + getHeader()}
          iconType={getProvider()}
        />
      }
      schema={{
        name: {
          type: 'text',
          label: 'Account name',
          placeholder: 'Enter name to be used by Ariksa',
          isRequired: true,
          value: state.name,
          onChange: value => updateState({ name: value }),
        },
        saasName: {
          type: 'react-select',
          label: 'SaaS name',
          options: state.saasOptions,
          value: state.selectedSaasAccount,
          onChange: s => {
            updateState({ selectedSaasAccount: s });
          },
          isRequired: true,
          isDisabled: isEdit,
        },
        environment: {
          type: 'react-select',
          label: 'Environment',
          options: state.environmentOptions,
          value: state.selectedEnvironment,
          onChange: s => {
            updateState({ selectedEnvironment: s });
          },
          isRequired: true,
          isDisabled: isEdit,
        },
        projects: {
          type: 'object',
          label: 'Select Repository and Branch to Scan',
          isRequired: true,
          properties: {
            repository: {
              type: 'react-select',
              label: 'Select repository',
              options: state.repositoryOptions,
              value: state.selectedRepository,
              onChange: s => updateState({ selectedRepository: s }),
              isLoading: repositories.isLoading,
              isDisabled: repositories.isLoading || isEdit,
            },
            branches: {
              type: 'react-select',
              label: 'Select branches',
              options: state.branchOptions,
              value: state.selectedBranch,
              onChange: s => updateState({ selectedBranch: s }),
              isLoading: branches.isLoading,
              isDisabled: branches.isLoading || isEdit,
            },
            tfStateFiles: {
              type: 'custom-with-form-control',
              label: 'S3 bucket ARN for state file',
              component: (
                <Stack>
                  {map(
                    state.bucketARNs,
                    (value, key) => value && renderBucketArns(key, value),
                  )}
                  {renderS3TfFileInput()}
                </Stack>
              ),
            },
          },
        },
      }}
      buttonOptions={{
        submit: {
          name: 'Okay',
          isLoading: onboardRepository.isLoading,
          isDisabled:
            onboardRepository.isLoading ||
            validateS3Arn.isLoading ||
            repositories.isLoading ||
            branches.isLoading ||
            !state.name,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: handleReset,
        },
      }}
      styles={{
        objectField: {
          objectContainer: {
            bg: 'white',
          },
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
