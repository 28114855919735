import React from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { ComplianceStandards } from '@ariksa/compliance-policies';
import { CloudEnvironmentType } from '@ariksa/compliance-policies/api';
import { SupportedServices } from '@ariksa/data-scanning/api';
import { ContextType } from '@ariksa/inventory-core';
import {
  Categories,
  CloudProvider,
  NativeResources,
  Resources,
} from '@ariksa/inventory-core/api';
import { Clients } from '@ariksa/notification';
import { ReportTypes } from '@ariksa/reporting';
import {
  ApplicationEnum,
  ScanTypes,
  VulnerabilityType,
} from '@ariksa/scan-analysis';
import { LibraryType } from '@ariksa/scan-analysis/api';
import { Image } from '@chakra-ui/react';
import { BsQuestionCircle } from 'react-icons/all';

import {
  AccessIcon,
  AccountIcon,
  ActionLogsIcon,
  ActivityIcon,
  AMIIcon,
  AnalyticsIcon,
  ApacheCassandraIcon,
  ApacheHttpIcon,
  ApacheKafkaIcon,
  APIGatewayApiIcon,
  APIGatewayIcon,
  AppIcon,
  ApplicationOrchestrationElasticBeanstalkIcon,
  AutoScalingIcon,
  AwsIcon,
  AzureADDomainIcon,
  AzureADRoleIcon,
  AzureIcon,
  BackupIcon,
  BellIcon,
  BlockStorageIcon,
  CacheIcon,
  CertificateIcon,
  ChecklistIcon,
  CheckmarkCircleOutlineIcon,
  ClockIcon,
  CloudFormationIcon,
  CloudServiceIcon,
  CloudtrailIcon,
  ClusterIcon,
  ComputeIcon,
  ConfigurationIcon,
  ConsoleIcon,
  ContextIcon,
  CouchDBIcon,
  DatabaseIcon,
  DataScanIcon,
  DistributionCloudfrontIcon,
  DistributionIcon,
  DNSIcon,
  DocumentDBIcon,
  ECSInstanceIcon,
  EcsTaskIcon,
  EgressIcon,
  ElasticacheIcon,
  EcsClusterIcon,
  ElasticIPIcon,
  ElasticMapReduceIcon,
  ElasticsearchIcon,
  EnvironmentIcon,
  EventLogIcon,
  ExceptionIcon,
  ExclamationTriangleIcon,
  FileIcon,
  GoogleCloudIcon,
  GroupIcon,
  IngressIcon,
  InterfaceIcon,
  InternetGatewayIcon,
  JiraIcon,
  KeyIcon,
  KubernetesIcon,
  AmazonLinuxIcon,
  LoadBalancerIcon,
  MachineLearningIcon,
  MariaDBIcon,
  MemCachedIcon,
  MetadataIcon,
  MoneyDataIcon,
  MongoDBIcon,
  MonitoringIcon,
  MysqlIcon,
  NatGatewayIcon,
  Neo4jIcon,
  NeptuneClusterIcon,
  NeptuneInstanceIcon,
  NetworkACLIcon,
  NetworkInterfaceIcon,
  NginxIcon,
  NoSqlIcon,
  NotificationIcon,
  ObjectStorageIcon,
  OktaIcon,
  OperatingSystemIcon,
  OrganizationIcon,
  OrganizationUnitIcon,
  P2PIcon,
  PackagesIcon,
  PermissionsActionsIcon,
  PolicyIcon,
  PostgreSQLIcon,
  PythonIcon,
  QueueIcon,
  RabbitMQIcon,
  RdsClusterIcon,
  RdsInstanceIcon,
  RedisIcon,
  RegionIcon,
  RoleIcon,
  Route53HealthCheckIcon,
  Route53HostedZoneIcon,
  RouteIcon,
  RouteTableIcon,
  SagemakerMachineLearningIcon,
  SecretsPasswordIcon,
  SecurityGroupIcon,
  ServerlessIcon,
  ServicesIcon,
  SlackIcon,
  SnapshotIcon,
  SnsNotificationIcon,
  SSHKeyPairIcon,
  SubnetIcon,
  SuperNetIcon,
  SuppressAlarmIcon,
  TechnologyIcon,
  TransitGatewayAttachmentIcon,
  TrustedEntitiesIcon,
  UbuntuIcon,
  UserIcon,
  VirtualMachineIcon,
  VmwareIcon,
  VpcGatewayIcon,
  VulnerabilityIcon,
  WAFIcon,
  RedshiftIcon,
  BarChartIcon,
  ServiceNowIcon,
  MailIcon,
  MicrosoftTeamsIcon,
  SMSIcon,
  TicketIcon,
  SSOIcon,
  AzureActiveDirectoryIcon,
  SnowflakeIcon,
  SearchIcon,
  RemediateIcon,
  ShowIcon,
  TimelineIcon,
  DisableIcon,
  ContainerVolumeIcon,
  DataClassificationIcon,
  DhcpOptionsIcon,
  BlueprintExceptionIcon,
  CommandLineIcon,
  NISTIcon,
  PciIcon,
  CISIcon,
  Soc2Icon,
  CompanyLogoIcon,
  CloudChartIcon,
  RegexDataClassifierIcon,
  ComplianceResourceIcon,
  ComplianceEnforcedChecksIcon,
  ComplianceCheckExceptionIcon,
  ComplianceCheckFailedIcon,
  ComplianceCheckPassedIcon,
  HistoricalTimelineIcon,
  HomeIcon,
  AccountRootIcon,
  ListIcon,
  AutomationIcon,
  OverviewIcon,
  SummaryIcon,
  NetworkMapIcon,
  UserCanAccessIcon,
  InternetAccessIcon,
  BruteForceIcon,
  LineChartIcon,
  BurgerMenuIcon,
  HAProxyIcon,
  UnencryptedDiskIcon,
  AccessKeyIcon,
  SSMDocumentIcon,
  Ec2AutoScalingGroupIcon,
  CloudWatchMonitoringIcon,
  RdsCertificateIcon,
  ApiIcon,
  PolicyResourceIcon,
  PolicyAccountIcon,
  PolicyOrganizationIcon,
  DocumentDbClusterIcon,
  DynamoDbIcon,
  Ec2LaunchTemplateIcon,
  EcrImageIcon,
  EcsServiceIcon,
  ElastiCacheReplicationGroupIcon,
  AgentIcon,
  IamPolicyIcon,
  VpcFlowLogIcon,
  VpnGatewayIcon,
  TableIcon,
  DataCloudIcon,
  IdentityCloudIcon,
  SnoozeIcon,
  RubyIcon,
  GolangIcon,
  JavaIcon,
  NodeJSIcon,
  CrossAccountIcon,
  MaliciousIngressIcon,
  VpnIcon,
  RdsIcon,
  CriticalDataIcon,
  HighPrivilegesIcon,
  SIEMIcon,
  RulesIcon,
  AttachIcon,
  DetachIcon,
  GitLabIcon,
  GithubIcon,
  BitbucketIcon,
  InventoryIcon,
  RepoBranchIcon,
  CopyIcon,
  CloudIcon,
  PolicyExceptionResourceIcon,
  PolicyExceptionIcon,
  DashboardAllIcon,
  LockOpenIcon,
  GlobeIcon,
  NetworkMisconfigurationIcon,
  ThumbsDownIcon,
  SyncPauseIcon,
  OutpostForVulnerabilityIcon,
  CommentIcon,
  ZigzagIcon,
  ThirdPartyIcon,
  NewFindingIcon,
  AnomalousBehaviourIcon,
  HealthIcon,
  GraphIncreaseIcon,
  GraphDecreaseIcon,
  CloseCircleOutlineIcon,
  ActionsIcon,
  BlueprintViewIcon,
  CalendarIcon,
  ChatIcon,
  CloudSlashIcon,
  DashboardIcon,
  DatabaseSecurityIcon,
  DependenciesIcon,
  DoubleTapIcon,
  ExcludeIcon,
  DebianIcon,
  VMImageIcon,
  ShareIcon,
  ThirdPartyIntegrationIcon,
  SqlDatabaseIcon,
  ListOfInstancesIcon,
  SuppressIcon,
  EOLIcon,
  RedHatIcon,
  CentosIcon,
  ContainerRepositoryIcon,
  LearnMoreIcon,
  GoogleDriveIcon,
  MicrosoftOneDriveIcon,
  BoxIcon,
  DropboxIcon,
  ServiceTicketIcon,
  DocumentsIcon,
  LibraryIcon,
  LeakedCredentialIcon,
} from 'components/Icons';
import { AriksaLogoOnlyIcon } from 'components/Icons/ReactCustomIcons/AriksaLogoOnlyIcon';
import { BucketDenyIcon } from 'components/Icons/ReactCustomIcons/BucketDenyIcon';
import { ContextLabelIcon } from 'components/Icons/ReactCustomIcons/ContextLabelIcon';
import { DownloadIcon } from 'components/Icons/ReactCustomIcons/DownloadIcon';
import { EvidenceIcon } from 'components/Icons/ReactCustomIcons/EvidenceIcon';
import { HackerIcon } from 'components/Icons/ReactCustomIcons/HackerIcon';
import { IndividualAlertsIcon } from 'components/Icons/ReactCustomIcons/IndividualAlertsIcon';
import { LateralMovementIcon } from 'components/Icons/ReactCustomIcons/LateralMovementIcon';
import { LoginIcon } from 'components/Icons/ReactCustomIcons/LoginIcon';
import { PrefixListIcon } from 'components/Icons/ReactCustomIcons/PrefixListIcon';
import { PrincipalAccessIcon } from 'components/Icons/ReactCustomIcons/PrincipalAccessIcon';
import { SyncRestartIcon } from 'components/Icons/ReactCustomIcons/SyncRestartIcon';
import { TagIcon } from 'components/Icons/ReactCustomIcons/TagIcon';
import { TrustedEntityIcon } from 'components/Icons/ReactCustomIcons/TrustedEntityIcon';
import { ViewAllIcon } from 'components/Icons/ReactCustomIcons/ViewAllIcon';
import { ZoomInIcon } from 'components/Icons/ReactCustomIcons/ZoomInIcon';
import { ZoomOutIcon } from 'components/Icons/ReactCustomIcons/ZoomOutIcon';
import { ExploitableIcon } from 'components/Icons/ReactResourceIcons/ExploitableIcon';
import { RemoveIcon } from 'components/Icons/ReactResourceIcons/RemoveIcon';
import { Route53RecordSetIcon } from 'components/Icons/ReactResourceIcons/Route53RecordSetIcon';
import { CustomIconTypes, IconType, IconTypes } from 'components/Icons/types';

import internet from '../SvgResourcesIcon/svg/internet.jpeg';

const imageProps = { bg: 'cardBg', p: 0, w: 'full' };

export const getIcon = (type: IconType, bgColor?: string) => {
  switch (type) {
    case IconTypes.Exploitable:
      return <ExploitableIcon />;
    case NativeResources.DhcpOptions:
    case Resources.Dhcp:
      return <DhcpOptionsIcon />;

    case CustomIconTypes.Remove:
      return <RemoveIcon />;

    case CustomIconTypes.CommandLine:
      return <CommandLineIcon />;

    case CustomIconTypes.BlueprintException:
      return <BlueprintExceptionIcon />;

    case CustomIconTypes.Evidence:
      return <EvidenceIcon />;

    case CustomIconTypes.AllFindings:
      return <DashboardAllIcon />;

    case CustomIconTypes.Dashboard:
      return <DashboardIcon />;

    case CustomIconTypes.ZoomIn:
      return <ZoomInIcon />;

    case CustomIconTypes.ZoomOut:
      return <ZoomOutIcon />;

    case CustomIconTypes.Download:
      return <DownloadIcon />;

    case CustomIconTypes.LateralMovement:
      return <LateralMovementIcon />;

    case CustomIconTypes.Login:
      return <LoginIcon />;

    case CustomIconTypes.PrefixList:
      return <PrefixListIcon />;

    case CustomIconTypes.PrincipalAccess:
      return <PrincipalAccessIcon />;

    case CustomIconTypes.TrustedEntity:
      return <TrustedEntityIcon />;

    case CustomIconTypes.ViewAll:
      return <ViewAllIcon />;

    case CustomIconTypes.Pii:
      return <MoneyDataIcon />;

    case CustomIconTypes.Calendar:
      return <CalendarIcon />;

    case Resources.ContainerRegistry:
    case IconTypes.Registry:
    case NativeResources.ElasticContainerRegistry:
    case NativeResources.AmazonMachineImage:
    case NativeResources.GcpImage:
    case Resources.Image:
    case CustomIconTypes.Ami:
    case CustomIconTypes.Images:
      return <AMIIcon />;

    case IconTypes.EcrImage:
      return <EcrImageIcon />;

    case NativeResources.EcsService:
    case NativeResources.ElasticContainerService:
      return <EcsServiceIcon />;

    case NativeResources.EcsCluster:
    case Resources.ContainerCluster:
      return <EcsClusterIcon p={0.5} />;

    case NativeResources.VpcFlowLogs:
      return <VpcFlowLogIcon />;

    case CustomIconTypes.ECSTask:
      return <EcsTaskIcon />;

    case IconTypes.Table:
    case NativeResources.SnowflakeTables:
      return <TableIcon />;

    case NativeResources.EcsContainerInstance:
    case IconTypes.Containers:
    case Resources.ContainerApp:
    case IconTypes.ContainersOnVirtualMachine:
    case IconTypes.ContainerService:
    case NativeResources.Container:
    case NativeResources.GcpContainer:
    case NativeResources.AzureContainerApp:
    case NativeResources.AzureContainer:
      return <ECSInstanceIcon />;

    /*case Categories.Cluster:
      return <ClusterIcon />;*/

    case CustomIconTypes.EventLog:
      return <EventLogIcon />;

    case IconTypes.CloudSlash:
      return <CloudSlashIcon />;

    case CustomIconTypes.CloudService:
    case IconTypes.CloudPosture:
      return <CloudServiceIcon />;

    case IconTypes.Actions:
      return <ActionsIcon />;

    case CustomIconTypes.Permission:
    case ContextType.ExcessPermissions:
    case CustomIconTypes.EXCESS_PERMISSIONS:
      return <PermissionsActionsIcon />;

    case NativeResources.SecurityGroup:
    case NativeResources.DatabaseSecurityGroup:
    case NativeResources.GcpCloudFirewall:
    case NativeResources.AzureSecurityGroups:
    case 'Access':
    case Resources.ComputeFirewall:
    case Resources.DatabaseFirewall:
    case 'DatabaseAccess':
      return <SecurityGroupIcon />;

    case CustomIconTypes.DatabaseSecurity:
      return <DatabaseSecurityIcon />;

    case Resources.Account:
    case NativeResources.AwsAccount:
    case NativeResources.GcpProject:
    case NativeResources.GcpServiceAccount:
    case 'AccountSummary':
      //case NativeResources.AccountRoot:
      return <AccountIcon />;

    case NativeResources.AccountRoot:
      return <AccountRootIcon />;

    case NativeResources.GcpsqlInstances:
      return <SqlDatabaseIcon />;

    case NativeResources.CloudTrail:
    case NativeResources.GcpCloudLogMetrics:
      return <CloudtrailIcon />;

    case Resources.ActionLog:
    case Resources.CloudServiceLog:
    case 'ActionLogs':
    case 'NetworkLogs':
    case Categories.Logging:
      return <ActionLogsIcon />;

    case NativeResources.CloudWatchLogGroup:
    case NativeResources.CloudWatchLogs:
    case NativeResources.CloudWatchAlarms:
    case NativeResources.CloudWatchMetrics:
    case NativeResources.CloudWatchMetricAlarms:
      return <CloudWatchMonitoringIcon />;

    case NativeResources.ElasticSearch:
    case Clients.Elasticsearch:
      return <ElasticsearchIcon />;

    case NativeResources.ElasticMapReduce:
    case NativeResources.GcpDataprocCluster:
      return <ElasticMapReduceIcon />;

    case Resources.Analytics:
    case NativeResources.GcpPubSub:
      return <AnalyticsIcon />;

    case CustomIconTypes.Internet:
      return <Image src={internet} w="full" h="full" />;

    case NativeResources.NatGateway:
    case NativeResources.GcpCloudNat:
    case NativeResources.AzureNatGateway:
      return <NatGatewayIcon />;

    case 'Gateway':
    case NativeResources.ApiGatewayDomain:
    case Resources.ApiGateway:
    case NativeResources.ApiGatewayRestApi:
    case NativeResources.GcpapiGateway:
      return <APIGatewayApiIcon />;

    case Resources.NetworkGateway:
    case NativeResources.AzureNetworkGateway:
      return <APIGatewayIcon />;

    case NativeResources.VpnGateway:
    case NativeResources.GcpvpnGateway:
      return <VpnGatewayIcon />;

    case NativeResources.InternetGateway:
      return <InternetGatewayIcon />;

    case NativeResources.AutoscalingGroup:
    case Resources.AutoScaling:
      return <AutoScalingIcon />;

    case NativeResources.Ec2AutoScalingGroup:
    case NativeResources.GcpComputeEngineAutoscaler:
    case NativeResources.AzureVirtualMachineScaleSets:
      return <Ec2AutoScalingGroupIcon />;

    case CustomIconTypes.Chat:
      return <ChatIcon />;

    case Categories.Backup:
    case NativeResources.RdsSnapshot:
    case NativeResources.BackupPlan:
    case NativeResources.GcpFilestoreBackup:
    case NativeResources.AzureSnapshot:
    case CustomIconTypes.DATA_RECOVERY:
    case 'VMBackup':
    case 'RDSClusterSnapshot':
      return <BackupIcon />;

    case NativeResources.ElasticBlockStorage:
    case NativeResources.GcpPersistentDisk:
    case NativeResources.AzureManagedDisk:
    case Resources.BlockStorage:
      return <BlockStorageIcon />;

    case NativeResources.AcmCertificate:
    case NativeResources.GcpsqlsslCerts:
    case NativeResources.AzureCertificate:
    case Resources.Certificate:
      return <CertificateIcon />;

    case NativeResources.RdsCertificate:
      return <RdsCertificateIcon />;

    case NativeResources.AwsConfigRule:
    case Resources.Configuration:
      return <ConfigurationIcon />;

    case NativeResources.IamUserAccessKey:
    case NativeResources.AzureKeyVault:
    case 'AccessKey':
      return <AccessKeyIcon />;

    case Categories.Credential:
    case NativeResources.KeyManagementService:
    case NativeResources.GcpCloudKms:
    case NativeResources.AzureKeyVaultKeys:
    case Resources.Key:
    case ContextType.CredentialFound:
    case CustomIconTypes.INSECURE_CREDENTIALS:
      return <KeyIcon />;

    case Resources.Dns:
      return <DNSIcon />;

    case 'Route53':
    case NativeResources.Route53HealthCheck:
    case CustomIconTypes.Route53TrafficPolicy:
      return <Route53HealthCheckIcon />;

    case CustomIconTypes.Route53RecordSet:
      return <Route53RecordSetIcon />;

    case NativeResources.Route53HostedZone:
    case NativeResources.GcpCloudDnsZone:
    case NativeResources.GcpCloudDomainsRegistration:
    case NativeResources.AzureDnsZones:
    case CustomIconTypes.Route53SubDomain:
      return <Route53HostedZoneIcon />;

    case NativeResources.RdsCluster:
      return <RdsClusterIcon />;

    case CustomIconTypes.RdsInstance:
    case 'RDS':
      return <RdsInstanceIcon />;

    case NativeResources.NeptuneCluster:
      return <NeptuneClusterIcon />;

    case 'DatabaseCluster':
    case 'Cluster':
    case NativeResources.DocumentDbCluster:
      return <DocumentDbClusterIcon />;

    case Resources.DataCluster:
      return <ClusterIcon />;

    case Categories.Compute:
      return <ComputeIcon />;

    case Categories.App:
    case NativeResources.AzureAdApplication:
    case NativeResources.OktaApplication:
    case NativeResources.AzureApplication:
    case Resources.Application:
    case Resources.AppManagement:
      return <AppIcon />;

    case CustomIconTypes.Technology:
    case VulnerabilityType.Application:
      return <TechnologyIcon />;

    case CustomIconTypes.Packages:
    case VulnerabilityType.Library:
      return <PackagesIcon />;

    case NativeResources.Neptune:
      return <NeptuneInstanceIcon />;

    case NativeResources.RelationalDatabaseService:
    case NativeResources.GcpCloudSql:
    case NativeResources.AzureSqlServer:
    case IconTypes['Relational Database Service (RDS)']:
      return <RdsIcon />;

    case 'DatabaseInstance':
    case Categories.Data:
    case Resources.Database:
    case IconTypes.DataRecovery:
    case IconTypes.DataSecurity:
    case NativeResources.SnowflakeDatabases:
    case NativeResources.GcpBigtableInstances:
    case NativeResources.AzureMysqlFlexibleServerDatabase:
    case NativeResources.AzureSqldbServersDatabase:
    case NativeResources.AzurePostgresSqlFlexibleServersDatabase:
      return <DatabaseIcon />;

    case NativeResources.DocumentDb:
    case NativeResources.GcpFirestore:
      return <DocumentDBIcon />;

    case NativeResources.DynamoDb:
      return <DynamoDbIcon />;

    case CustomIconTypes.Distribution:
      return <DistributionIcon />;

    case CustomIconTypes.Environment:
      return <EnvironmentIcon />;

    case 'File':
    case Resources.FileStorage:
    case NativeResources.ElasticFileSystem:
    case NativeResources.GcpFilestore:
    case NativeResources.AzureFileShare:
      return <FileIcon />;

    case 'Firewall':
    case NativeResources.WafRegional:
    case NativeResources.Wafv1:
    case NativeResources.Wafv2:
    case NativeResources.AzureWafRuleSets:
    case Resources.AppFirewall:
      return <WAFIcon />;

    case NativeResources.IamGroup:
    case Resources.Group:
    case IconTypes.IdentityAndAccess:
    case NativeResources.OktaGroup:
    case NativeResources.AzureAdGroup:
    case NativeResources.GitHubGroup:
    case NativeResources.GitLabGroup:
    case NativeResources.GcpGroup:
    case NativeResources.AzureGroup:
    case NativeResources.AzureManagementGroups:
      return <GroupIcon />;

    case NativeResources.ElasticNetworkInterface:
    case Resources.NetworkInterface:
    case NativeResources.VirtualInterface:
    case NativeResources.GcpNetworkInterface:
    case NativeResources.AzureNetworkInterface:
      return <NetworkInterfaceIcon />;

    case CustomIconTypes.Interface:
      return <InterfaceIcon />;

    case CustomIconTypes.Attach:
      return <AttachIcon />;

    case CustomIconTypes.Detach:
      return <DetachIcon />;

    case 'Addon':
    case 'NodeGroup':
    case NativeResources.ElasticKubernetesService:
    case NativeResources.Gcpgke:
    case NativeResources.Aks:
    case ComplianceStandards.CisEks15:
    case IconTypes.CISEKS15:
    case IconTypes.Kubernetes:
    case 'CIS_EKS_1_5':
      return <KubernetesIcon />;

    case 'ELB':
    case 'ELBConfiguration':
    case 'RDSDBParameterGroup':
    case NativeResources.ElasticLoadBalancer:
    case NativeResources.ElasticLoadBalancerv2:
    case NativeResources.GcpurlMaps:
    case NativeResources.AzureLoadBalancer:
    case Resources.LoadBalancer:
      return <LoadBalancerIcon />;

    case CustomIconTypes.Sagemaker:
    case NativeResources.SagemakerNotebookInstances:
    case NativeResources.SagemakerEndpoints:
      return <SagemakerMachineLearningIcon />;

    case Categories.Monitoring:
    case Resources.CloudMonitoring:
    case Resources.DnsMonitoring:
    case CustomIconTypes.DATA_AT_RISK:
    case CustomIconTypes.Informational:
      return <MonitoringIcon />;

    case Categories.Network:
    case IconTypes.Networking:
    case NativeResources.NetworkAcl:
    case Resources.AccessControl:
    case ReportTypes.NetworkAssessment:
      return <NetworkACLIcon />;

    case NativeResources.AwsVirtualPrivateNetwork:
      return <VpnIcon />;

    case NativeResources.SystemsManagerDocument:
    case Resources.AgentConfiguration:
      return <SSMDocumentIcon />;

    case IconTypes.NetworkMap:
      return <NetworkMapIcon />;

    case CustomIconTypes.Ubuntu:
      return <UbuntuIcon />;

    case NativeResources.SimpleNotificationService:
    case NativeResources.GcpPubSubLite:
    case NativeResources.AzureServiceBusNamespace:
    case CustomIconTypes.SNSSubscription:
    case CustomIconTypes.SNSTopic:
      return <SnsNotificationIcon />;

    case 'SNS':
    case NativeResources.RdsEventSubscription:
    case CustomIconTypes.Notification:
      return <NotificationIcon />;

    case 'S3':
    case 'S3Object':
    case NativeResources.SimpleStorageService:
    case NativeResources.GcpBucket:
    case NativeResources.AzureBlobStorage:
    case Resources.ObjectStorage:
    case IconTypes['SimpleStorageService(S3)']:
      return <ObjectStorageIcon />;

    case 'OrgUnit':
    case NativeResources.OrganizationUnit:
    case NativeResources.GcpFolder:
    case Resources.OrganizationUnits:
      return <OrganizationUnitIcon />;

    case NativeResources.AwsOrganization:
    case Categories.Organization:
    case Resources.Organizations:
    case NativeResources.OktaOrganization:
    case NativeResources.AzureAdOrganization:
    case NativeResources.GcpOrganization:
    case NativeResources.AzureOrganization:
      return <OrganizationIcon />;

    case NativeResources.TransitGatewayConnect:
    case NativeResources.VpcPeeringConnection:
    case Resources.P2P:
      return <P2PIcon />;

    case Categories.Policy:
    case Resources.PermissionsPolicy:
    case NativeResources.OktaPolicy:
    case CustomIconTypes.Blueprint:
      return <PolicyIcon />;

    case NativeResources.ResourcePolicy:
    case NativeResources.GcpResourcePolicy:
    case NativeResources.AsgResourcePolicy:
      return <PolicyResourceIcon />;

    case NativeResources.IamPolicy:
    case NativeResources.GcpiamPolicy:
    case NativeResources.IamInlinePolicy:
    case NativeResources.AzureFrontDoorPolicies:
    case NativeResources.AzurePolicy:
    case NativeResources.AzureGovernanceRule:
      return <IamPolicyIcon />;

    case NativeResources.AccountPasswordIdentityPolicy:
      return <PolicyAccountIcon />;

    case NativeResources.OrganizationPolicy:
    case NativeResources.GcpOrgPolicyService:
      return <PolicyOrganizationIcon />;

    case NativeResources.SimpleQueueService:
    case NativeResources.GcpCloudTasksQueue:
    case NativeResources.AzureStorageQueue:
    case Resources.AppMessaging:
    case 'Queue':
      return <QueueIcon />;

    case CustomIconTypes.Region:
      return <RegionIcon />;

    case CustomIconTypes.Rules:
      return <RulesIcon />;

    case Resources.Role:
    case Resources.SamlRole:
    case Resources.DirectoryRole:
    case NativeResources.IamRole:
    case NativeResources.SnowflakeRoles:
    case NativeResources.GcpRole:
    case NativeResources.AzureManagedRole:
    case NativeResources.AzureDirectoryRole:
    case NativeResources.AzureSamlRole:
    case NativeResources.AzureFrontDoorManagedRules:
    case ReportTypes.RoleAccessReview:
      return <RoleIcon />;

    case NativeResources.SubnetRouteTable:
    case NativeResources.TransitGatewayRouteTable:
    case NativeResources.AzureRouteTable:
    case Resources.RouteTable:
      return <RouteTableIcon />;

    case CustomIconTypes.VpcGateway:
      return <VpcGatewayIcon />;

    case CustomIconTypes.Route:
    case NativeResources.GcpRoute:
      return <RouteIcon />;

    case 'Serverless':
    case NativeResources.LambdaFunction:
    case NativeResources.GcpCloudFunctions:
    case NativeResources.AzureFunctions:
    case Resources.ServerlessCompute:
      return <ServerlessIcon />;

    case Categories.Code:
    case 'AutoscalingLaunchConfiguration':
    case NativeResources.CloudFormation:
    case NativeResources.GcpCloudDeploymentManager:
    case NativeResources.GitLabProject:
    case NativeResources.GitHubRepository:
    case NativeResources.BitBucketRepository:
    case SupportedServices.GitLabBranch:
    case SupportedServices.GitHubBranch:
    case SupportedServices.BitBucketBranch:
    case Resources.InfrastructureasCode:
    case CloudEnvironmentType.Iac:
      return <CloudFormationIcon />;

    case NativeResources.Ec2LaunchTemplate:
      return <Ec2LaunchTemplateIcon />;

    case Resources.Subnets:
    case NativeResources.Subnet:
    case NativeResources.GcpSubnetwork:
    case NativeResources.AzureVirtualNetworkSubnets:
      return <SubnetIcon />;

    case NativeResources.VirtualPrivateCloud:
    case NativeResources.GcpVirtualPrivateCloud:
    case NativeResources.AzureVirtualNetworkTaps:
    case NativeResources.AzureVirtualNetwork:
    case Resources.Vpc:
      return <SuperNetIcon />;

    case Categories.Identity:
    case NativeResources.IamUser:
    case Resources.User:
    case CustomIconTypes.IdentityAndAccessManagement:
    case NativeResources.OktaUser:
    case NativeResources.SnowflakeUsers:
    case NativeResources.AzureAdUser:
    case NativeResources.GitHubUser:
    case NativeResources.GitLabUser:
    case NativeResources.GcpUser:
    case NativeResources.IdentityCentreInstance:
    case NativeResources.AzureUser:
    case ReportTypes.UserAccessReview:
      return <UserIcon />;

    case CustomIconTypes.IdentityAuthorization:
      return <UserIcon />;
    //return <IdentityAuthorizationIcon bg={bgColor ?? '#fff'} />;

    case ReportTypes.Inventory:
      return <InventoryIcon />;

    case NativeResources.Ec2Instance:
    case Resources.VirtualMachine:
    case IconTypes.VirtualMachines:
    case 'VirtualMachines':
    case 'Virtual Machine':
    case NativeResources.GcpComputeEngine:
    case NativeResources.GcpCloudTpus:
    case NativeResources.AzureVirtualMachine:
      return <VirtualMachineIcon />;

    case 'Warehouse':
    case CustomIconTypes.AriksaOutpost:
    case NativeResources.RedshiftCluster:
    case CustomIconTypes.RedshiftInstance:
    case NativeResources.GcpBigQuery:
      return <RedshiftIcon />;

    case NativeResources.EbsSnapshot:
    case Resources.Snapshot:
    case IconTypes.DataSources:
    case IconTypes.Saas:
      return <SnapshotIcon />;

    case 'NetworkAttachment':
    case 'TransitGatewayVPCAttachment':
    case Resources.VpcTransitRoute:
    case NativeResources.TransitGateway:
    case NativeResources.TransitGatewayAttachment:
      return <TransitGatewayAttachmentIcon />;

    case CloudProviders.Okta:
    case CloudProvider.Okta:
    case CustomIconTypes.Okta:
    case NativeResources.OktaDomain:
    case NativeResources.OktaRoleSubscription:
    case NativeResources.OktaIdentityProvider:
      return <OktaIcon />;

    case CloudProviders.Aws:
    case CloudProvider.Aws:
    case ComplianceStandards.CisAws14:
    case ComplianceStandards.CisAws15:
    case IconTypes.CISAWS14:
    case IconTypes.CISAWS15:
    case CustomIconTypes.AWS:
      return <AwsIcon />;

    case CustomIconTypes.AWSWhite:
      return <AwsIcon color="inherit" />;

    case CloudProviders.Azure:
    case CloudProvider.Azure:
      return <AzureIcon />;

    case CustomIconTypes.Vmware:
      return <VmwareIcon />;

    case CloudProviders.Gcp:
    case CloudProvider.Gcp:
    case CloudProvider.GoogleWorkspace:
      return <GoogleCloudIcon />;

    case CustomIconTypes.NoSql:
      return <NoSqlIcon />;

    case ApplicationEnum.MySql:
      return <MysqlIcon />;

    case ApplicationEnum.Nginx:
      return <NginxIcon />;

    case ApplicationEnum.HaProxy:
      return <HAProxyIcon />;

    case ApplicationEnum.Neo4j:
      return <Neo4jIcon />;

    case ApplicationEnum.ApacheHttp:
      return <ApacheHttpIcon />;

    case ApplicationEnum.Cassandra:
      return <ApacheCassandraIcon />;

    case ApplicationEnum.Kafka:
      return <ApacheKafkaIcon />;

    case ApplicationEnum.Redis:
      return <RedisIcon />;

    case ApplicationEnum.RabbitMq:
      return <RabbitMQIcon />;

    case NativeResources.AzurePostgreSqlFlexibleServer:
    case ApplicationEnum.PostgreSql:
      return <PostgreSQLIcon />;

    case ApplicationEnum.Memcached:
      return <MemCachedIcon />;

    case ApplicationEnum.MariaDb:
      return <MariaDBIcon />;

    case ApplicationEnum.MongoDb:
      return <MongoDBIcon />;

    case ApplicationEnum.CouchDb:
      return <CouchDBIcon />;

    case ScanTypes.NpmPackages:
    case VulnerabilityType.Os:
      return <OperatingSystemIcon />;

    /*case VulnerabilityType.RpmPackage:
      return <RpmIcon />;
     */
    case CustomIconTypes.AmazonLinux:
      return <AmazonLinuxIcon />;

    case CustomIconTypes.DebianLinux:
      return <DebianIcon />;

    case CustomIconTypes.Library:
      return <LibraryIcon />;

    case CustomIconTypes.Python:
    case LibraryType.Python:
      return <PythonIcon />;

    case LibraryType.Ruby:
      return <RubyIcon />;

    case LibraryType.Golang:
      return <GolangIcon />;

    case LibraryType.Nodejs:
      return <NodeJSIcon />;

    case LibraryType.Java:
      return <JavaIcon />;

    case NativeResources.ElastiCache:
    case NativeResources.GcpMemorystoreRedis:
    case NativeResources.AzureRedis:
      return <ElasticacheIcon />;

    case Resources.Cache:
      return <CacheIcon />;

    case NativeResources.ElastiCacheReplicationGroup:
      return <ElastiCacheReplicationGroupIcon />;

    case NativeResources.CloudFront:
    case NativeResources.AzureFrontDoor:
    case NativeResources.AzureFrontDoorEndpoints:
    case Resources.ContentDeliveryNetwork:
      return <DistributionCloudfrontIcon />;

    case CustomIconTypes.ExclamationTriangle:
    case IconTypes.Cve:
      return <ExclamationTriangleIcon />;

    case CustomIconTypes.SuppressAlarm:
      return <SuppressAlarmIcon />;

    case CustomIconTypes.Suppress:
      return <SuppressIcon />;

    case CustomIconTypes.Exclude:
      return <ExcludeIcon />;

    case CustomIconTypes.BlueprintView:
      return <BlueprintViewIcon />;

    case CustomIconTypes.Egress:
      return <EgressIcon />;

    case CustomIconTypes.Ingress:
      return <IngressIcon />;

    case NativeResources.SystemsManager:
    case Resources.Agent:
      return <AgentIcon />;

    case NativeResources.AzureAdDomain:
      return <AzureADDomainIcon />;

    case NativeResources.AzureAdsamlRole:
    case NativeResources.AzureAdDirectoryRoles:
      return <AzureADRoleIcon />;

    case NativeResources.ElasticIp:
    case NativeResources.AzurePublicIpAddresses:
    case Resources.Ip:
      return <ElasticIPIcon />;

    case NativeResources.ElasticBeanstalk:
      return <ApplicationOrchestrationElasticBeanstalkIcon />;

    case NativeResources.Secret:
    case NativeResources.AzureSecrets:
    case NativeResources.GcpSecretManager:
    case Resources.Secrets:
      return <SecretsPasswordIcon />;

    case NativeResources.SshKeyPair:
      return <SSHKeyPairIcon />;

    case CustomIconTypes.Alert:
    case NativeResources.AzureSecurityAlert:
      return <BellIcon />;

    case CustomIconTypes.DataScan:
      return <DataScanIcon />;

    case CustomIconTypes.Vulnerability:
    case CustomIconTypes.VULNERABILITIES:
    case CustomIconTypes.Vulnerabilities:
    case ContextType.CriticalVulnerability:
    case ContextType.HighVulnerability:
    case ReportTypes.Vulnerabilities:
      return <VulnerabilityIcon />;

    case CustomIconTypes.TrustedEntities:
      return <TrustedEntitiesIcon />;

    case IconTypes.Slack:
    case Clients.Slack:
      return <SlackIcon />;

    case IconTypes.Jira:
    case Clients.Jira:
      return <JiraIcon />;

    case IconTypes.Servicenow:
      return <ServiceNowIcon />;

    case IconTypes.Email:
      return <MailIcon />;

    case IconTypes.Teams:
      return <MicrosoftTeamsIcon />;

    case IconTypes.Clock:
    case IconTypes.Jit:
      return <ClockIcon />;

    case IconTypes.IndividualAlerts:
      return <IndividualAlertsIcon />;

    case IconTypes.Label:
    case IconTypes.Tag:
      return <TagIcon />;

    case IconTypes.SIEM:
      return <SIEMIcon />;

    case IconTypes.Access:
      return <AccessIcon />;

    case IconTypes.CloudActivity:
      return <ActivityIcon />;

    case IconTypes.Hacker:
    case IconTypes['Malicious Access']:
    case IconTypes['Malicious Behavior']:
      return <HackerIcon />;

    case IconTypes.QuestionMark:
      return <BsQuestionCircle />;

    case IconTypes.Context:
      return <ContextIcon />;

    case IconTypes.ContextLabel:
      return <ContextLabelIcon />;

    case IconTypes.Console:
      return <ConsoleIcon />;

    case IconTypes.Whitelist:
    case IconTypes.CheckmarkCircle:
      return <CheckmarkCircleOutlineIcon />;

    case IconTypes.CloseCircle:
      return <CloseCircleOutlineIcon />;

    case IconTypes.Metadata:
      return <MetadataIcon />;

    case IconTypes.Exception:
      return <ExceptionIcon />;

    case IconTypes.PolicyResourceException:
      return <PolicyExceptionResourceIcon />;

    case IconTypes.Checklist:
      return <ChecklistIcon />;

    case IconTypes.Dependencies:
      return <DependenciesIcon />;

    case IconTypes.BarChart:
      return <BarChartIcon />;

    case IconTypes.Sms:
      return <SMSIcon />;

    case IconTypes.Ticket:
      return <TicketIcon />;

    case IconTypes.Sso:
      return <SSOIcon />;

    case IconTypes.AzureActiveDirectory:
    case IconTypes.azuread:
    case CloudProviders.AzureAd:
    case CloudProvider.AzureAd:
      return <AzureActiveDirectoryIcon />;

    case CloudProviders.Snowflake:
    case CloudProvider.Snowflake:
    case CustomIconTypes.Snowflake:
    case NativeResources.SnowflakeSchemas:
    case NativeResources.SnowflakePrivileges:
      return <SnowflakeIcon />;

    case IconTypes.Search:
      return <SearchIcon />;

    case IconTypes.Remediate:
      return <RemediateIcon />;

    case IconTypes.Details:
      return <ShowIcon />;

    case IconTypes.Timeline:
      return <TimelineIcon />;

    case IconTypes.Disable:
      return <DisableIcon />;

    case IconTypes.ContainerVolume:
      return <ContainerVolumeIcon />;

    case IconTypes.DataClassification:
      return <DataClassificationIcon />;

    case ComplianceStandards.Nist80053:
    case 'NIST-800-53 Rev 5':
      return <NISTIcon />;

    case ComplianceStandards.PciDssV321:
    case 'PCI-DSS v3.2.1':
    case 'PCI DSS 4.0.1':
      return <PciIcon />;

    case ComplianceStandards.CisV8:
    case IconTypes.CISv8:
      return <CISIcon />;

    case ComplianceStandards.Soc2:
    case IconTypes.SOC2:
      return <Soc2Icon />;

    case CustomIconTypes.AriksaLogo:
      return <CompanyLogoIcon />;

    case CustomIconTypes.AriksaLogoOnly:
    case CustomIconTypes.AriksaBestPractices:
      return <AriksaLogoOnlyIcon bg={bgColor ?? '#fff'} />;

    case CustomIconTypes.CloudChart:
      return <CloudChartIcon />;

    case CustomIconTypes.RegexDataClassifier:
      return <RegexDataClassifierIcon />;

    case CustomIconTypes.ComplianceResource:
      return <ComplianceResourceIcon />;

    case CustomIconTypes.ComplianceEnforcedChecks:
    case CustomIconTypes.EnforcedPolicies:
      return <ComplianceEnforcedChecksIcon />;

    case CustomIconTypes.ComplianceCheckExceptions:
      return <ComplianceCheckExceptionIcon />;

    case CustomIconTypes.ComplianceFailedChecks:
      return <ComplianceCheckFailedIcon />;

    case CustomIconTypes.CompliancePassedChecks:
      return <ComplianceCheckPassedIcon />;

    case CustomIconTypes.HistoricalTimeline:
      return <HistoricalTimelineIcon />;

    case CustomIconTypes.Home:
      return <HomeIcon />;

    case CustomIconTypes.Automation:
      return <AutomationIcon />;

    case CustomIconTypes.List:
      return <ListIcon />;

    case CustomIconTypes.Overview:
      return <OverviewIcon />;

    case CustomIconTypes.Summary:
      return <SummaryIcon />;

    case CustomIconTypes.UserCanAccess:
      return <UserCanAccessIcon />;

    case ContextType.InternetAccess:
      return <InternetAccessIcon />;

    case ContextType.BruteForceAttack:
      return <BruteForceIcon />;

    case ContextType.UnencryptedDisk:
    case ContextType.UnencryptedData:
      return <UnencryptedDiskIcon />;

    case ContextType.CrossAccount:
    case 'CrossAccount':
      return <CrossAccountIcon />;

    case ContextType.MaliciousIngress:
      return <MaliciousIngressIcon />;

    case ContextType.SensitiveData:
    case CustomIconTypes.DataTypes:
      return <CriticalDataIcon />;

    case ContextType.Privileged:
    case CustomIconTypes.PRIVILEGE_ESCALATION:
      return <HighPrivilegesIcon />;

    /*case ContextType.MaliciousEgress:
      return <MaliciousEgressIcon />;
     */
    case CustomIconTypes.LineChart:
      return <LineChartIcon />;

    case CustomIconTypes.BurgerMenu:
      return <BurgerMenuIcon />;

    case CustomIconTypes.DataCloud:
    case CloudEnvironmentType.DataSaas:
      return <DataCloudIcon />;

    case CustomIconTypes.IdentityCloud:
    case CloudEnvironmentType.IdentityProvider:
      return <IdentityCloudIcon />;

    case CustomIconTypes.Snooze:
      return <SnoozeIcon />;

    case CloudProviders.Gitlab:
    case CloudProvider.GitLab:
    case CustomIconTypes.GitLab:
    case CloudProviders.GitlabIac:
      return <GitLabIcon />;

    case CloudProviders.Github:
    case CloudProvider.GitHub:
    case CustomIconTypes.GitHub:
    case CloudProviders.GithubIac:
      return <GithubIcon />;

    case CloudProviders.Bitbucket:
    case CloudProvider.Bitbucket:
    case CustomIconTypes.BitBucket:
    case CloudProviders.BitbucketIac:
      return <BitbucketIcon />;

    case IconTypes.RepoBranch:
      return <RepoBranchIcon />;

    case IconTypes.BucketDeny:
      return <BucketDenyIcon />;

    case IconTypes.Copy:
      return <CopyIcon />;

    case IconTypes.Cloud:
    case CloudEnvironmentType.CloudProvider:
      return <CloudIcon />;

    case CustomIconTypes.PolicyException:
      return <PolicyExceptionIcon />;

    case CustomIconTypes.DATA_ENCRYPTION:
    case CustomIconTypes.LockOpen:
      return <LockOpenIcon />;

    case CustomIconTypes.INTERNET_EXPOSURE:
    case CustomIconTypes.Globe:
    case CustomIconTypes['Public VMs with SSH open']:
      return <GlobeIcon />;

    case CustomIconTypes.NETWORK_MISCONFIGURATION:
      return <NetworkMisconfigurationIcon />;

    case CustomIconTypes.POOR_GOVERNANCE:
      return <ThumbsDownIcon />;

    case CustomIconTypes.DoubleTap:
      return <DoubleTapIcon />;

    case CustomIconTypes.POOR_AUDITABILITY:
      return <ActivityIcon />;

    case CustomIconTypes.UNUSED_ENTITIES:
      return <SyncPauseIcon />;

    case CustomIconTypes.ANOMALOUS_ACTIVITY:
      return <ZigzagIcon />;

    case CustomIconTypes.OutpostForVulnerability:
      return <OutpostForVulnerabilityIcon bg={bgColor ?? '#fff'} />;

    case CustomIconTypes.Comment:
      return <CommentIcon />;

    case CustomIconTypes.ThirdParty:
      return <ThirdPartyIcon />;

    case CustomIconTypes.NewFinding:
      return <NewFindingIcon />;

    case CustomIconTypes.AnomalousBehaviour:
    case CustomIconTypes['Abnormal Behavior']:
      return <AnomalousBehaviourIcon />;

    case CustomIconTypes.Health:
      return <HealthIcon />;

    case CustomIconTypes.Port:
      return <ApiIcon />;

    case CustomIconTypes.GraphIncrease:
    case CustomIconTypes['Elevated Risk']:
      return <GraphIncreaseIcon />;

    case CustomIconTypes.GraphDecrease:
      return <GraphDecreaseIcon />;

    case CustomIconTypes.VMImages:
    case CustomIconTypes.ContainerImages:
      return <VMImageIcon />;

    case CustomIconTypes.Share:
      return <ShareIcon />;

    case CustomIconTypes.ThirdPartyIntegration:
      return <ThirdPartyIntegrationIcon />;

    case CustomIconTypes.ListOfInstances:
      return <ListOfInstancesIcon />;

    case CustomIconTypes.SyncRestart:
      return <SyncRestartIcon />;

    case CustomIconTypes.SyncPause:
      return <SyncPauseIcon />;

    case CustomIconTypes.EOL:
      return <EOLIcon />;

    case CustomIconTypes.RedHatEnterpriseLinux:
    case CustomIconTypes.RedHat:
      return <RedHatIcon />;

    case CustomIconTypes.Centos:
      return <CentosIcon />;

    case CustomIconTypes.ContainerRepository:
      return <ContainerRepositoryIcon />;

    case CustomIconTypes.LearnMore:
      return <LearnMoreIcon />;

    case CustomIconTypes.GoogleDrive:
      return <GoogleDriveIcon />;

    case CustomIconTypes.MicrosoftOneDrive:
      return <MicrosoftOneDriveIcon />;

    case CustomIconTypes.Box:
      return <BoxIcon />;

    case CustomIconTypes.Dropbox:
      return <DropboxIcon />;

    case CustomIconTypes.ServiceTicket:
      return <ServiceTicketIcon />;

    case CustomIconTypes.Documents:
      return <DocumentsIcon />;

    case CustomIconTypes['Public IPs']:
      return <ElasticIPIcon />;

    case CustomIconTypes['Public subnets']:
      return <GlobeIcon />;

    case CustomIconTypes['Anomalous traffic']:
      return <HackerIcon />;

    case CustomIconTypes.ExposedCredentials:
      return <LeakedCredentialIcon />;

    case CustomIconTypes['VMs with SSH open']:
      return <LoginIcon p={0.5} />;

    default:
      return <ServicesIcon />;
  }
};
