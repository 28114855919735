import React from 'react';

import { CloudIcon } from 'components/Icons';
import { overviewDashboardConfig } from 'containers/Dashboard/Overview/Components/config';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';

interface Props {
  isDataClassificationEnabled: boolean;
  isActivityLogEnabled: boolean;
}

export const StateOfYourCloud: React.FC<Props> = props => {
  return (
    <DashboardOverviewWrapperCard
      label="State of Cloud"
      dashboardWidgets={overviewDashboardConfig}
      icon={<CloudIcon />}
      redirectLink="/findings?tab=Overview"
    />
  );
};
