import React, { useEffect, useState } from 'react';

import { AggregatedAlertsSummary } from '@ariksa/notification/api';
import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import { each, isEmpty, map, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { DashboardOverviewTooltipProps } from 'containers/Dashboard/utils/OverviewDashboardTooltip';
import { dashboardWidgetLineStyles } from 'containers/Dashboard/utils/styles';
import { renderEmptyRows } from 'containers/Dashboard/utils/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { toTitleCase } from 'utils/string';

interface Props {
  categoryClass?: string;
  tooltip?: DashboardOverviewTooltipProps;
  accounts: AggregatedAlertsSummary[];
}

export const TopFindings: React.FC<Props> = props => {
  const { categoryClass, accounts } = props;
  const { openFindings } = useSelector(selectDashboard);
  const navigate = useNavigate();
  const [allCategories, setAllCategories] = useState<Record<string, any>[]>();
  const [categoryAccountsMapping, setCategoryAccountsMapping] = useState<
    Record<string, any>[]
  >();
  const { accountMapping } = useCloudAccountId();

  useEffect(() => {
    let items: Record<string, any> = {};
    if (!!categoryClass) {
      each(
        openFindings.data?.[categoryClass],
        c =>
          (items[c.category] = {
            CRITICAL: c.severity?.CRITICAL ?? 0,
            HIGH: c.severity?.HIGH ?? 0,
          }),
      );
    } else {
      each(openFindings.data, o => {
        each(
          o,
          c =>
            (items[c.category] = {
              CRITICAL:
                c.severity?.CRITICAL + (items[c.category]?.CRITICAL ?? 0),
              HIGH: c.severity?.HIGH + (items[c.category]?.HIGH ?? 0),
            }),
        );
      });
    }
    let filteredItems: Record<string, any> = {};
    each(items, (o, category) => {
      if (!!o?.CRITICAL)
        filteredItems[category] = { count: o?.CRITICAL, severity: 'critical' };
      else if (!!o?.HIGH)
        filteredItems[category] = { count: o?.HIGH, severity: 'high' };
    });

    setAllCategories(
      orderBy(
        map(filteredItems, (s, c) => ({ category: c, ...s })),
        ['severity', 'count'],
        ['asc', 'desc'],
      ),
    );
  }, [openFindings.data, categoryClass]);

  useEffect(() => {
    let mapping: Record<string, any>[] = [];
    each(accounts, o => {
      mapping[o.category] = o;
    });
    setCategoryAccountsMapping(mapping);
  }, [accounts, accountMapping]);

  const renderLegend = (category, count, severity) => {
    const accounts =
      categoryAccountsMapping?.[category]?.account_ids?.length ?? 0;
    return (
      <HStack
        onClick={() => {
          navigate(
            '/findings?tab=' +
              (!!categoryClass ? categoryClass : 'AllFindings') +
              '&alertCategory=' +
              category +
              '&severity=' +
              severity?.toUpperCase() +
              '&redirect=true',
          );
        }}
        cursor="pointer"
        spacing={6}
        {...dashboardWidgetLineStyles}
      >
        <Box h="full" w={1} bg={severity} borderRadius={2} />
        <Stack spacing={0} w="full" py={1}>
          <HStack justify="space-between" w="full">
            <Box>{toTitleCase(category)}</Box>
            <Box>{count}</Box>
          </HStack>
          <HStack
            justify="space-between"
            color={customTheme.colors.gray['250']}
            w="full"
          >
            <Box>{toTitleCase(severity)} severity</Box>
            <Box>
              In {accounts} account{accounts === 1 ? '' : 's'}
            </Box>
          </HStack>
        </Stack>
      </HStack>
    );
  };

  return (
    <DashboardWidget
      label="Findings to Prioritize"
      noData={
        categoryClass
          ? isEmpty(openFindings.data?.[categoryClass])
          : isEmpty(allCategories)
      }
      isLoading={openFindings.isLoading}
      content={
        <Flex w="full" h="full">
          {
            <Stack h="full" w="full" justify="space-between" spacing={0}>
              {map(allCategories, o =>
                renderLegend(o.category, o.count, o.severity),
              )}
              {renderEmptyRows(allCategories?.length)}
            </Stack>
          }
        </Flex>
      }
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
    />
  );
};
