import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { forEach, includes, isEmpty, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { usePageContentContext } from 'components/Layout';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectApp } from 'containers/App/selectors';
import { selectSharedState } from 'containers/SharedState/selectors';
import { VulnerabilityContextProvider } from 'containers/Visibility/Vulnerabilities/Components/hooks/context';
import { useVulnerabilities } from 'containers/Visibility/Vulnerabilities/Components/hooks/useVulnerabilities';
import { NoVulnerabilityScannerDeployed } from 'containers/Dashboard/utils/NoVulnerabilityScannerDeployed';
import { VulnerabilitySideNav } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilitiesSideNav';
import { useSearchParams } from 'hooks/useSearchParams';
import { useInjector } from 'utils/inject';

import { vulnerabilitySaga } from './saga';
import { reducer, sliceKey, actions } from './slice';

export const Vulnerabilities: React.FC = () => {
  useInjector(sliceKey, reducer, vulnerabilitySaga);
  const dispatch = useDispatch();
  const { environments } = useSelector(selectApp);
  const { vulnerabilityScannerInfo } = useSelector(selectSharedState);
  const { environmentId, accountId } = useAccessBoundary();
  const [environmentsObject, setEnvironmentsObject] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [showDashboard, setShowDashboard] = useState(true);
  const { contentHeight } = usePageContentContext();
  const params = useSearchParams<{
    tab: string;
    search_term: string;
  }>();

  useEffect(() => {
    if (!!params.tab) dispatch(actions.updateSelectedTab(params.tab));
  }, [params.tab, dispatch]);

  useEffect(() => {
    if (!isEmpty(environments.data)) {
      let items = {};
      forEach(environments.data, o => (items[o.id] = o));
      setEnvironmentsObject(items);
    }
  }, [environments.data]);

  useEffect(() => {
    !!environmentId &&
      setAccounts(environmentsObject[environmentId]?.account_ids);
  }, [environmentId, environmentsObject]);

  useEffect(() => {
    const scanners = Object.keys(vulnerabilityScannerInfo.data);

    if (!!accountId) setShowDashboard(includes(scanners, accountId));
    else setShowDashboard(some(accounts, o => includes(scanners, o)));
  }, [accounts, accountId, vulnerabilityScannerInfo.data]);

  return (
    <VulnerabilityContextProvider value={useVulnerabilities()}>
      <Box h={contentHeight + 'px'}>
        <WithSpinner
          loadStatus={{
            loading:
              vulnerabilityScannerInfo.isLoading || environments.isLoading,
            loaded: vulnerabilityScannerInfo.isSuccess,
          }}
          spinnerSize="lg"
        >
          {showDashboard ? (
            <VulnerabilitySideNav />
          ) : (
            <NoVulnerabilityScannerDeployed />
          )}
        </WithSpinner>
      </Box>
    </VulnerabilityContextProvider>
  );
};
