import React from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from 'components/DataEntry';
import { CustomerJourneyBulbIcon } from 'components/Icons';

export const DeployOutpostForDataMessage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <HStack
      spacing={4}
      align="flex-start"
      fontSize="md"
      justify="center"
      pb={8}
    >
      <Box boxSize={10} color="orange">
        <Center>
          <CustomerJourneyBulbIcon />
        </Center>
      </Box>
      <Stack spacing={4} flex={1}>
        <Stack color="primary" cursor="pointer" spacing={0}>
          <Box>Deploy Outpost for Data</Box>
          <Box>for this insight</Box>
        </Stack>
        <Box>
          <PrimaryButton onClick={() => navigate('/setup/outpost/add/data')}>
            DEPLOY
          </PrimaryButton>
        </Box>
      </Stack>
    </HStack>
  );
};
