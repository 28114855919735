import {
  SensitiveDataByRegion,
  SensitiveDataByType,
  VisibilityDataApiGetSensitiveDataByRegionRequest,
} from '@ariksa/data-scanning';
import {
  DataFootprintAnalyzed,
  DataSourcesWithData,
  PageDocumentClassificationAggregateInfo,
  PageDocumentClassificationInfo,
  PageResourcesRead,
  PageSensitiveDataInfo,
  PageSourceBySensitiveData,
  ResourceDistributionCount,
  ResourceGroup,
  ResourcesApiGetDataTypeSensitiveSourcesRequest,
  ResourcesApiGetResourceGroupsRequest,
  ResourcesApiGetResourcesByGroupRequest,
  ResourcesApiGetResourcesCategoriesCountRequest,
  ResourcesApiGetSensitiveDataSourcesCountRequest,
  ResourcesApiGetSourcesDistributionCountRequest,
  SensitiveDataApiGetFilesBySensitiveDataRequest,
  SensitiveDataApiGetSensitiveDataLabelsCountRequest,
  SensitiveDataByAccount,
  SensitiveDataBySource,
  TrendRegionsInfo,
  TrendSourcesInfo,
  TrendTotalSizeInfo,
  TrendTypesInfo,
  VisibilityDataApiGetDataSourcesWithSensitiveDataRequest,
  VisibilityDataApiGetDocumentClassificationBySubtypeAndSourceRequest,
  VisibilityDataApiGetDocumentClassificationBySubtypesRequest,
  VisibilityDataApiGetDocumentClassificationByTypeAndSourceRequest,
  VisibilityDataApiGetDocumentClassificationByTypesRequest,
  VisibilityDataApiGetSensitiveDataByAccountRequest,
  VisibilityDataApiGetSensitiveDataBySourcesRequest,
  VisibilityDataApiGetSensitiveDataInfoTableRequest,
  VisibilityDataApiGetTrendDataAnalyzedRequest,
  VisibilityDataApiGetTrendDataInfoGraphsRequest,
  VisibilityDataApiGetTrendRegionsInfoGraphRequest,
  VisibilityDataApiGetTrendSourcesInfoRequest,
  VisibilityDataApiGetTrendTypesInfoGraphRequest,
} from '@ariksa/data-scanning/api';
import {
  TrendDataInfo,
  VisibilityDataApiGetSensitiveDataByTypeRequest,
} from '@ariksa/data-scanning/dist/api';
import {
  CloudInsightApiGetRuntimeInsightsForDataSourcesRequest,
  ContextApiGetRiskContextResDataScanRequest,
  DataSourcesSummary,
  PublicResourceSummary,
  ResourceApiGetDataSourcesSummaryRequest,
  ResourceSummaryApiGetPublicExposedResSummaryRequest,
  RiskContextResponse,
  RuntimeInsightBySources,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummaryResponse,
  AlertHistoryResponse,
  AlertsApiGetAggregatedAlertsSummaryRequest,
  AlertsApiGetAlertHistoryRequest,
  AlertsApiGetAlertingResourcesCountRequest,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo, SideNavKey } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Tag container
export const initialState: ContainerState = {
  selectedTab: 'overview',
  dataSources: PagedQueryState.init([]),
  allDataResourceTypes: PagedQueryState.init([]),
  dataTypeSensitiveSources: PagedQueryState.init([]),
  filesByDataType: PagedQueryState.init([]),
  dataSourcesPieChart: QueryState.init({
    service_sources: [],
    total: 0,
  }),
  historicalTrend: QueryState.init({
    data_sources_trend: [],
    sensitive_data_trend: [],
    regions_trend: [],
  }),
  dataTypesRiskContext: QueryState.init({}),
  highRiskDataSources: QueryState.init([]),
  resourceCount: QueryState.init({}),
  sensitiveDataBySources: QueryState.init({ service_sources: [], total: 0 }),
  sensitiveDataByType: QueryState.init({ category_types: [], total: 0 }),
  sensitiveDataByRegion: QueryState.init({ regions: [], total: 0 }),
  dataSourcesByRegion: QueryState.init({ regions: [], total: 0 }),
  sensitiveDataByRegionTrend: QueryState.init({}),
  sensitiveDataByTypeTrend: QueryState.init({}),
  sourcesByAccount: QueryState.init({} as SensitiveDataByAccount),
  dataSourcesAnalyzed: QueryState.init({}),
  dataAnalyzed: QueryState.init({} as TrendTotalSizeInfo),
  dataFindings: QueryState.init({}),
  totalDataFindings: QueryState.init({} as AggregatedAlertsSummaryResponse),
  sensitiveDataByAgnosticSources: QueryState.init(
    {} as ResourceDistributionCount,
  ),

  analyzedSources: QueryState.init({}),
  sensitiveDataSourceCount: QueryState.init(0),
  labelCount: QueryState.init({}),
  publiclyExposedDataSources: QueryState.init([]),
  summaryOfAllDataSources: QueryState.init({} as DataSourcesSummary),
  alertingCount: QueryState.init(0),
  documentSubTypes: PagedQueryState.init([]),
  documentSubTypeDataSources: PagedQueryState.init([]),
  dataSourcesByType: PagedQueryState.init([]),
  documentTypeDetails: QueryState.init({}),
  runtimeInsights: QueryState.init([]),
};

const dataDashboardSlice = createSlice({
  name: 'dataDashboard',
  initialState,
  reducers: {
    getDataSources(
      state,
      action: QueryAction<
        PageResourcesRead,
        ResourcesApiGetDataTypeSensitiveSourcesRequest
      >,
    ) {
      state.dataSources = PagedQueryState.next(state.dataSources, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total ?? 0,
      });
    },

    getAllDataResourceTypes(
      state,
      action: QueryAction<
        ResourceGroup[],
        ResourcesApiGetResourceGroupsRequest
      >,
    ) {
      state.allDataResourceTypes = QueryState.next(
        state.allDataResourceTypes,
        action,
      );
    },

    getDataTypes(
      state,
      action: QueryAction<
        PageSensitiveDataInfo,
        VisibilityDataApiGetSensitiveDataInfoTableRequest
      >,
    ) {
      state.dataSources = PagedQueryState.next(state.dataSources, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total ?? 0,
      });
    },

    getDocumentTypes(
      state,
      action: QueryAction<
        PageDocumentClassificationAggregateInfo,
        VisibilityDataApiGetDocumentClassificationByTypesRequest
      >,
    ) {
      state.dataSources = PagedQueryState.next(state.dataSources, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total ?? 0,
      });
    },

    getDocumentSubTypes(
      state,
      action: QueryAction<
        PageDocumentClassificationAggregateInfo,
        VisibilityDataApiGetDocumentClassificationBySubtypesRequest
      >,
    ) {
      state.documentSubTypes = PagedQueryState.next(
        state.documentSubTypes,
        action,
        {
          mapData: r => r.items,
          mapTotalCount: r => r.total ?? 0,
        },
      );
    },

    getDataSourcesByDocumentSubType(
      state,
      action: QueryAction<
        PageDocumentClassificationInfo,
        VisibilityDataApiGetDocumentClassificationBySubtypeAndSourceRequest
      >,
    ) {
      state.dataSourcesByType = PagedQueryState.next(
        state.dataSourcesByType,
        action,
        {
          mapData: r => r.items,
          mapTotalCount: r => r.total ?? 0,
        },
      );
    },
    getDataSourcesByDocumentType(
      state,
      action: QueryAction<
        PageDocumentClassificationInfo,
        VisibilityDataApiGetDocumentClassificationByTypeAndSourceRequest
      >,
    ) {
      state.dataSourcesByType = PagedQueryState.next(
        state.dataSourcesByType,
        action,
        {
          mapData: r => r.items,
          mapTotalCount: r => r.total ?? 0,
        },
      );
    },
    getDataSourcesByResourceType(
      state,
      action: QueryAction<
        PageResourcesRead,
        ResourcesApiGetResourcesByGroupRequest
      >,
    ) {
      state.dataSourcesByType = PagedQueryState.next(
        state.dataSourcesByType,
        action,
        {
          mapData: r => r.items,
          mapTotalCount: r => r.total ?? 0,
        },
      );
    },

    updateDataTablePageInfo(state, action: PayloadAction<PageInfo>) {
      state.dataSources.page.info = action.payload;
    },
    updateDataTypeSourcesPageInfo(state, action: PayloadAction<PageInfo>) {
      state.dataTypeSensitiveSources.page.info = action.payload;
    },
    updateDataSourcesByTypePageInfo(state, action: PayloadAction<PageInfo>) {
      state.dataSourcesByType.page.info = action.payload;
    },
    updateFilesByDataTypePageInfo(state, action: PayloadAction<PageInfo>) {
      state.filesByDataType.page.info = action.payload;
    },

    getHistoricalTrend(
      state,
      action: QueryAction<
        TrendDataInfo,
        VisibilityDataApiGetTrendDataInfoGraphsRequest
      >,
    ) {
      state.historicalTrend = QueryState.next(state.historicalTrend, action);
    },

    getDataSourcesPieChartData(
      state,
      action: QueryAction<
        DataSourcesWithData,
        VisibilityDataApiGetDataSourcesWithSensitiveDataRequest
      >,
    ) {
      state.dataSourcesPieChart = QueryState.next(
        state.dataSourcesPieChart,
        action,
      );
    },

    getRiskContextForDataTypes(
      state,
      action: QueryAction<
        Record<string, RiskContextResponse[]>,
        ContextApiGetRiskContextResDataScanRequest
      >,
    ) {
      state.dataTypesRiskContext = QueryState.next(
        state.dataTypesRiskContext,
        action,
      );
    },

    getResourceCount(state, action: QueryAction<any, any>) {
      state.resourceCount = QueryState.next(state.resourceCount, action);
    },

    getSensitiveDataBySources(
      state,
      action: QueryAction<
        SensitiveDataBySource,
        VisibilityDataApiGetSensitiveDataBySourcesRequest
      >,
    ) {
      state.sensitiveDataBySources = QueryState.next(
        state.sensitiveDataBySources,
        action,
      );
    },

    getSensitiveDataByType(
      state,
      action: QueryAction<
        SensitiveDataByType,
        VisibilityDataApiGetSensitiveDataByTypeRequest
      >,
    ) {
      state.sensitiveDataByType = QueryState.next(
        state.sensitiveDataByType,
        action,
      );
    },

    getSensitiveDataByRegion(
      state,
      action: QueryAction<
        SensitiveDataByRegion,
        VisibilityDataApiGetSensitiveDataByRegionRequest
      >,
    ) {
      state.sensitiveDataByRegion = QueryState.next(
        state.sensitiveDataByRegion,
        action,
      );
    },

    getDataSourcesByRegion(
      state,
      action: QueryAction<
        SensitiveDataByRegion,
        VisibilityDataApiGetSensitiveDataByRegionRequest
      >,
    ) {
      state.dataSourcesByRegion = QueryState.next(
        state.dataSourcesByRegion,
        action,
      );
    },

    getSensitiveDataByRegionTrend(
      state,
      action: QueryAction<
        Record<string, TrendRegionsInfo>,
        VisibilityDataApiGetTrendRegionsInfoGraphRequest
      >,
    ) {
      state.sensitiveDataByRegionTrend = QueryState.next(
        state.sensitiveDataByRegionTrend,
        action,
      );
    },

    getSensitiveDataByTypeTrend(
      state,
      action: QueryAction<
        Record<string, TrendTypesInfo>,
        VisibilityDataApiGetTrendTypesInfoGraphRequest
      >,
    ) {
      state.sensitiveDataByTypeTrend = QueryState.next(
        state.sensitiveDataByTypeTrend,
        action,
      );
    },

    getSourcesByAccounts(
      state,
      action: QueryAction<
        SensitiveDataByAccount,
        VisibilityDataApiGetSensitiveDataByAccountRequest
      >,
    ) {
      state.sourcesByAccount = QueryState.next(state.sourcesByAccount, action);
    },

    getDataSourcesAnalyzedInfo(
      state,
      action: QueryAction<
        TrendSourcesInfo,
        VisibilityDataApiGetTrendSourcesInfoRequest
      >,
    ) {
      state.dataSourcesAnalyzed = QueryState.next(
        state.dataSourcesAnalyzed,
        action,
      );
    },

    getDataAnalyzedInfo(
      state,
      action: QueryAction<
        TrendTotalSizeInfo,
        VisibilityDataApiGetTrendDataAnalyzedRequest
      >,
    ) {
      state.dataAnalyzed = QueryState.next(state.dataAnalyzed, action);
    },

    getDataFindings(
      state,
      action: QueryAction<
        AlertHistoryResponse,
        AlertsApiGetAlertHistoryRequest
      >,
    ) {
      state.dataFindings = QueryState.next(state.dataFindings, action);
    },

    getTotalDataFindings(
      state,
      action: QueryAction<
        AggregatedAlertsSummaryResponse,
        AlertsApiGetAggregatedAlertsSummaryRequest
      >,
    ) {
      state.totalDataFindings = QueryState.next(
        state.totalDataFindings,
        action,
      );
    },

    updateSelectedTab(state, action: PayloadAction<SideNavKey>) {
      state.selectedTab = action.payload;
    },

    getAnalyzedSources(
      state,
      action: QueryAction<
        DataFootprintAnalyzed,
        ResourcesApiGetResourcesCategoriesCountRequest
      >,
    ) {
      state.analyzedSources = QueryState.next(state.analyzedSources, action);
    },

    getSensitiveDataSourceCount(
      state,
      action: QueryAction<
        number,
        ResourcesApiGetSensitiveDataSourcesCountRequest
      >,
    ) {
      state.sensitiveDataSourceCount = QueryState.next(
        state.sensitiveDataSourceCount,
        action,
      );
    },

    getSensitiveDataLabelCount(
      state,
      action: QueryAction<
        Record<string, number>,
        SensitiveDataApiGetSensitiveDataLabelsCountRequest
      >,
    ) {
      state.labelCount = QueryState.next(state.labelCount, action);
    },

    getPubliclyExposedDataSources(
      state,
      action: QueryAction<
        PublicResourceSummary[],
        ResourceSummaryApiGetPublicExposedResSummaryRequest
      >,
    ) {
      state.publiclyExposedDataSources = QueryState.next(
        state.publiclyExposedDataSources,
        action,
      );
    },

    getSummaryOfAllDataSources(
      state,
      action: QueryAction<
        DataSourcesSummary,
        ResourceApiGetDataSourcesSummaryRequest
      >,
    ) {
      state.summaryOfAllDataSources = QueryState.next(
        state.summaryOfAllDataSources,
        action,
      );
    },

    getFilesByDataType(
      state,
      action: QueryAction<
        PageSourceBySensitiveData,
        SensitiveDataApiGetFilesBySensitiveDataRequest
      >,
    ) {
      state.filesByDataType = PagedQueryState.next(
        state.filesByDataType,
        action,
        { mapData: r => r.items, mapTotalCount: r => r.total ?? 0 },
      );
    },

    getDataTypeSources(
      state,
      action: QueryAction<
        PageResourcesRead,
        ResourcesApiGetDataTypeSensitiveSourcesRequest
      >,
    ) {
      state.dataTypeSensitiveSources = PagedQueryState.next(
        state.dataTypeSensitiveSources,
        action,
        { mapData: r => r.items, mapTotalCount: r => r.total ?? 0 },
      );
    },

    getAlertingCount(
      state,
      action: QueryAction<number, AlertsApiGetAlertingResourcesCountRequest>,
    ) {
      state.alertingCount = QueryState.next(state.alertingCount, action);
    },

    updateDocumentSubTypesPagination(state, action: PayloadAction<PageInfo>) {
      state.documentSubTypes.page.info = action.payload;
    },

    getSensitiveDataByAgnosticSources(
      state,
      action: QueryAction<
        ResourceDistributionCount,
        ResourcesApiGetSourcesDistributionCountRequest
      >,
    ) {
      state.sensitiveDataByAgnosticSources = QueryState.next(
        state.sensitiveDataByAgnosticSources,
        action,
      );
    },

    getHighRiskDataSources(
      state,
      action: QueryAction<
        PageResourcesRead,
        ResourcesApiGetDataTypeSensitiveSourcesRequest
      >,
    ) {
      state.highRiskDataSources = QueryState.next(
        state.highRiskDataSources,
        action,
        { mapData: r => r.items },
      );
    },

    getRuntimeInsights(
      state,
      action: QueryAction<
        RuntimeInsightBySources[],
        CloudInsightApiGetRuntimeInsightsForDataSourcesRequest
      >,
    ) {
      state.runtimeInsights = QueryState.next(state.runtimeInsights, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = dataDashboardSlice;
