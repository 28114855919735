import React from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';

export const pieChartLegendFormatter = (value, entry, index) => {
  return (
    <span style={{ fontSize: '14px', color: '#666' }}>
      {value + ' (' + formatNumber(entry?.payload?.value) + ')'}
    </span>
  );
};

export const renderPieChartLegend = props => {
  return (
    <Center w="full" h="full" justifyContent="flex-start" fontSize="14px">
      <Stack spacing={1}>
        {map(props?.payload, o => (
          <HStack w="full">
            <Box boxSize={2.5} bg={o?.color} borderRadius={3} />
            <Box whiteSpace="nowrap">
              {o?.value} ({formatNumber(o?.payload?.value, 1)})
            </Box>
          </HStack>
        ))}
      </Stack>
    </Center>
  );
};
