import {
  BlueprintApiAddExceptionRequest,
  BlueprintApiRemoveExceptionRequest,
  BlueprintExceptionRead,
} from '@ariksa/compliance-policies/api';
import {
  ContextApiGetRiskContextResRequest,
  InsightV2ApiInsightV2Request,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsPaginatedResponse,
  AlertPaginatedResponse,
  AlertsApiGetAggregatedAlertsRequest,
  AlertsApiGetAlertsRequest,
  AlertsApiGetAlertSummaryRequest,
  AlertSummaryResponse,
  ClientResponse,
  ClientsApiGetClientDetailsRequest,
  ClientsApiGetClientTicketFieldsRequest,
  ClientsApiGetJiraProjectDetailsRequest,
  ClientsApiGetTicketFieldsRequest,
  ClientsApiNotifyClientRequest,
  SnoozedAlertsApiAddSnoozedAlertRequest,
  SnoozedAlertsApiStopSnoozeRequest,
  TicketFieldsResponse,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { forEach } from 'lodash';
import { isDeepEqual } from 'react-use/lib/util';
import { QueryAction, QueryStatus } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import {
  apiCallStateInitialState,
  apiCallStatusInitialValue,
} from 'api/initial_values';
import { Sorted } from 'components/DataDisplay/NewTable/types';
import { publishedAtOptions } from 'components/DataEntry/Utils/SelectHistoricalOptions';
import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Alerts container
export const initialState: ContainerState = {
  activeTab: '',
  activeStatus: '',
  filters: {},
  aggregatedFindings: PagedQueryState.init([], {
    page: { info: { page_size: 100, page_number: 1 }, totalCount: 0 },
  }),
  entityDetails: {},
  alerts: PagedQueryState.init(
    {},
    { page: { info: { page_size: 100, page_number: 1 }, totalCount: 0 } },
  ),
  startDate: publishedAtOptions?.[0],
  alertCategories: QueryState.init([]),
  groupByResource: false,
  sortByField: {
    sortOrder: 'desc',
    sortField: 'entity_name',
  },

  pagination: {
    page_number: 1,
    page_size: 10,
    total_results: 0,
  },
  alertsSummary: {
    loading: false,
    error: null,
    loaded: false,
    data: {
      severity_count: { assets: 0, critical: 0, medium: 0, low: 0, snoozed: 0 },
    },
  },
  assetsAlerts: {
    results: [],
    num_of_pages: 0,
    total_results: 0,
    loading: false,
    error: null,
  },
  criticalAlerts: {
    results: [],
    num_of_pages: 0,
    total_results: 0,
    loading: false,
    error: null,
  },
  mediumAlerts: {
    results: [],
    num_of_pages: 0,
    total_results: 0,
    loading: false,
    error: null,
  },
  lowAlerts: {
    results: [],
    num_of_pages: 0,
    total_results: 0,
    loading: false,
    error: null,
  },
  snoozedAlerts: {
    results: [],
    num_of_pages: 0,
    total_results: 0,
    loading: false,
    error: null,
  },

  childAlerts: {
    results: [],
    num_of_pages: 0,
    total_results: 0,
    loading: false,
    loaded: false,
    error: null,
  },

  snooze: {
    loading: false,
    error: null,
  },
  escalate: {
    loading: false,
    loaded: false,
    error: null,
  },
  forward: {
    loading: false,
    error: null,
  },
  suppress: {
    loading: false,
    error: null,
  },
  resourceTypes: {
    loading: false,
    error: null,
    loaded: false,
    data: [],
  },

  notificationChannels: apiCallStateInitialState([]),

  stopSnooze: QueryState.init({}),
  ticketChannels: apiCallStateInitialState([]),
  resourceDetails: apiCallStateInitialState({}),
  searchTerm: '',
  download: apiCallStatusInitialValue,
  ticketFields: QueryState.init({}),
  ticketInfo: QueryState.init({}),
  alertAction: QueryState.init({}),
  individualRiskContext: QueryState.init({}),
  clients: QueryState.init([]),
  clientTicketFields: QueryState.init({} as TicketFieldsResponse),
};

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    resetTicketDetails(state) {
      state.ticketInfo = initialState.ticketInfo;
      state.ticketFields = initialState.ticketFields;
    },
    addException(
      state,
      action: QueryAction<
        BlueprintExceptionRead,
        BlueprintApiAddExceptionRequest
      >,
    ) {
      state.alertAction = QueryState.next(state.alertAction, action);
    },
    getClients(
      state,
      action: QueryAction<ClientResponse[], ClientsApiGetClientDetailsRequest>,
    ) {
      state.clients = QueryState.next(state.clients, action);
    },
    getTicketInfo(
      state,
      action: QueryAction<any, ClientsApiGetTicketFieldsRequest>,
    ) {
      state.ticketInfo = QueryState.next(state.ticketInfo, action);
    },
    getTicketFields(
      state,
      action: QueryAction<any, ClientsApiGetJiraProjectDetailsRequest>,
    ) {
      state.ticketFields = QueryState.next(state.ticketFields, action);
    },
    createTicket(
      state,
      action: QueryAction<any, ClientsApiNotifyClientRequest>,
    ) {
      state.alertAction = QueryState.next(state.alertAction, action);
    },
    createNotification(
      state,
      action: QueryAction<any, ClientsApiNotifyClientRequest>,
    ) {
      state.alertAction = QueryState.next(state.alertAction, action);
    },
    getAggregatedFindings(
      state,
      action: QueryAction<
        AggregatedAlertsPaginatedResponse,
        AlertsApiGetAggregatedAlertsRequest
      >,
    ) {
      state.aggregatedFindings = PagedQueryState.next(
        state.aggregatedFindings,
        action,
        {
          mapData: r => r.aggregated_alerts,
          mapTotalCount: r => r.total_results,
        },
      );
    },
    updateAggregatedFindingsPagination(state, action: PayloadAction<PageInfo>) {
      state.aggregatedFindings.page.info = action.payload;
    },
    updateStartDate(state, action: PayloadAction<Record<string, any>>) {
      state.startDate = action.payload;
    },
    updateGroupByResource(state, action: PayloadAction<boolean>) {
      state.groupByResource = action.payload;
    },
    updateSortInfo(state, action: PayloadAction<Sorted>) {
      state.sortByField = action.payload;
    },

    getIndividualRiskContext(
      state,
      action: QueryAction<any, ContextApiGetRiskContextResRequest>,
    ) {
      state.individualRiskContext = QueryState.next(
        state.individualRiskContext,
        action,
      );
    },
    getEntityDetails(
      state,
      action: QueryAction<Record<string, any>[], InsightV2ApiInsightV2Request>,
    ) {
      const resourceType = action.payload.q.insightV2Request.resource_type;
      if (!state.entityDetails[resourceType!]) {
        state.entityDetails[resourceType!] = QueryState.init({} as any, {
          status: QueryStatus.pending,
        });
        return;
      } else {
        state.entityDetails[resourceType!] = QueryState.next(
          state.entityDetails[resourceType!],
          action,
          {
            mapData: res => {
              let data: Record<string, any> = {};
              forEach(res, o => (data[o.uuid] = o));
              return data;
            },
          },
        );
      }
    },

    getAlerts(
      state,
      action: QueryAction<AlertPaginatedResponse, AlertsApiGetAlertsRequest>,
    ) {
      state.alerts = PagedQueryState.next(state.alerts, action, {
        mapData: res => res.results,
        mapTotalCount: res => res.total_results,
      });
    },
    updateAlertsPagination(state, action: PayloadAction<PageInfo>) {
      state.alerts.page.info = action.payload;
    },
    snoozeAlert(
      state,
      action: QueryAction<any, SnoozedAlertsApiAddSnoozedAlertRequest>,
    ) {
      state.alertAction = QueryState.next(state.alertAction, action);
    },
    updateSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    stopSnooze(
      state,
      action: QueryAction<any, SnoozedAlertsApiStopSnoozeRequest>,
    ) {
      state.alertAction = QueryState.next(state.alertAction, action);
    },

    removeException(
      state,
      action: QueryAction<any, BlueprintApiRemoveExceptionRequest>,
    ) {
      state.alertAction = QueryState.next(state.alertAction, action);
    },

    getAlertCategories(
      state,
      action: QueryAction<
        AlertSummaryResponse,
        AlertsApiGetAlertSummaryRequest
      >,
    ) {
      state.alertCategories = QueryState.next(state.alertCategories, action, {
        mapData: r => Object.keys(r.categories),
      });
    },

    updateActiveTab(state, action: PayloadAction<any>) {
      state.activeTab = action.payload.tab;
      state.activeStatus = action.payload.status;
      if (!isDeepEqual(state.pagination, initialState.pagination)) {
        state.pagination = initialState.pagination;
      }
    },

    updateFilters(state, action: PayloadAction<Record<string, any>>) {
      state.filters = { ...state.filters, ...action.payload };
      state.resourceDetails = initialState.resourceDetails;
    },
    updateAllFilters(state, action: PayloadAction<Record<string, any>>) {
      state.filters = action.payload;
      state.resourceDetails = initialState.resourceDetails;
    },
    updateFilter(
      state,
      action: PayloadAction<{ field: string; value: string }>,
    ) {
      state.filters[action.payload.field] = action.payload.value;
      //state.resourceDetails = initialState.resourceDetails;
    },

    resetFilters(state) {
      state.filters = initialState.filters;
    },

    getClientTicketFields(
      state,
      action: QueryAction<
        TicketFieldsResponse,
        ClientsApiGetClientTicketFieldsRequest
      >,
    ) {
      state.clientTicketFields = QueryState.next(state.alerts, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = alertsSlice;
