import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';

import { CommonEdge } from 'components/Visualization/PixiGraph/common/Edge';
import {
  EdgeElements,
  END_CIRCLE_RADIUS,
} from 'components/Visualization/PixiGraph/common/EdgeElements';
import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';
import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import { colors } from 'components/Visualization/PixiGraph/style';

import { AGraphNodeId } from './/types';
import { APoint } from './Point';
import { AGraphEdgeId } from './types';

interface AGraphEdgeProps {
  source: AGraphNodeId;
  dest: AGraphNodeId;
}

export class AGraphEdge {
  id: AGraphEdgeId;
  source: AGraphNodeId;
  dest: AGraphNodeId;

  data: Record<string, any> = {};

  start: APoint = APoint.origin();
  end: APoint = APoint.origin();

  static getId(sourceId: AGraphNodeId, destId: AGraphNodeId): AGraphEdgeId {
    return `${sourceId}#${destId}`;
  }

  constructor(props: AGraphEdgeProps) {
    const { source, dest, ...rest } = props;
    // this.id = `${generateString(32)}`;
    this.id = AGraphEdge.getId(source, dest);
    this.source = source;
    this.dest = dest;
    this.data = rest;
  }

  render(g: Graphics): void {
    const { properties = {} } = this.data ?? {};

    const { db_user_name = '', encrypted } = properties;
    let color = 0xaaaaaa;

    if (db_user_name) {
      if (encrypted) {
        color = 0x00ff00;
      } else {
        color = 0xff0000;
      }
    }

    CommonEdge.render(this, g, { color });
    EdgeElements.renderEndCircle(this, g);
    EdgeElements.renderStartCircle(this, g);
    EdgeElements.renderEdgeIcon(this, g);
    EdgeElements.renderEdgeText(this, g);
  }

  renderActive(g: Graphics) {
    CommonEdge.render(this, g, { color: colors.primary });
    EdgeElements.renderEndCircle(this, g, { color: colors.primary });
    EdgeElements.renderStartCircle(this, g, { color: colors.primary });
  }

  addInteractiveElement(graph: AGraph<AGraphNode, AGraphEdge>, index: number) {
    if (this.data.disabled) return;
    const { start, end } = this;
    // add the node hover bound
    graph.addInteractiveElement({
      minX: end.x - END_CIRCLE_RADIUS,
      maxX: end.x + END_CIRCLE_RADIUS,
      minY: end.y - END_CIRCLE_RADIUS,
      maxY: end.y + END_CIRCLE_RADIUS,
      minZ: index,
      maxZ: index,
      id: 'edge:end:' + this.id,
      data: this,
    });

    graph.addInteractiveElement({
      minX: start.x - END_CIRCLE_RADIUS,
      maxX: start.x + END_CIRCLE_RADIUS,
      minY: start.y - END_CIRCLE_RADIUS,
      maxY: start.y + END_CIRCLE_RADIUS,
      minZ: index,
      maxZ: index,
      id: 'edge:start:' + this.id,
      data: this,
    });

    graph.addInteractiveElement({
      ...EdgeElements.renderEdgeIconBoundary(this),
      minZ: index,
      maxZ: index,
      id: 'edge-info:' + this.id,
      data: this,
    });
  }

  renderHover(g: Graphics, id: string) {
    if (id.includes('edge:end:')) {
      EdgeElements.renderEndCircle(this, g, { color: colors.primary });
    }
    if (id.includes('edge:start:')) {
      EdgeElements.renderStartCircle(this, g, { color: colors.primary });
    }
  }
}
