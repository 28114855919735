import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { NotificationFor } from '@ariksa/notification/api';
import { Box, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { H1, PageHeaderWithIcon } from 'components/DataDisplay';
import { PrimaryButton, PrimaryIconButton } from 'components/DataEntry';
import {
  ActionsIcon,
  AutomationIcon,
  DownloadIcon,
  NotificationIcon,
  PolicyExceptionIcon,
  RefreshIcon,
  SnoozeIcon,
  ViewAllIcon,
} from 'components/Icons';
import { SidebarLayout } from 'components/Layout';
import { IMenuItemProps, Menu } from 'components/Overlay';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { AddException } from 'containers/Findings/Alerts/Components/AddException';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { SnoozeAlert } from 'containers/Findings/Alerts/Components/SnoozeAlert';
import { EvidenceGraphDetails } from 'containers/Visibility/SecurityGraphNext/Components/Evidence/EvidenceGraphDetails';
import { useEvidence } from 'containers/Visibility/SecurityGraphNext/Components/Evidence/hooks/useEvidence';
import { SecurityGraphContent } from 'containers/Visibility/SecurityGraphNext/Components/SecurityGraphContent';
import { useGraphComponents } from 'containers/Visibility/SecurityGraphNext/hooks/useGraphComponents';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';

export const Evidence: FC = () => {
  const { evidence } = useSelector(selectSecurityGraphNext);
  const { alertDetails } = evidence;
  const { updateActiveResource } = useActiveResourceActions();
  const { onOpenMetadata } = useActiveResourceContext();
  const navigate = useNavigate();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const snooze = useDisclosure();
  const exceptionModal = useDisclosure();
  const { getAlertStatus, getAlertDetails } = useEvidence();
  const { graphComponent } = useGraphComponents();

  const menuItems: IMenuItemProps[] = [
    {
      key: 'ticket',
      label: 'Create ticket',
      icon: <AutomationIcon />,
      onClick: () => ticketModal.onOpen(),
    },
    {
      key: 'notification',
      label: 'Send notification',
      icon: <NotificationIcon />,
      onClick: () => notificationModal.onOpen(),
    },
    {
      key: 'Snooze alert',
      label: 'Snooze alert',
      icon: <SnoozeIcon />,
      onClick: () => snooze.onOpen(),
    },
    {
      key: 'Add exception',
      label: 'Add exception',
      icon: <PolicyExceptionIcon />,
      onClick: () => exceptionModal.onOpen(),
    },
  ];

  return (
    <SidebarLayout
      sidebar={<EvidenceGraphDetails />}
      content={
        <SecurityGraphContent>
          <ActiveResourceProvider>
            <Stack h={'full'}>
              <HStack justify="space-between">
                <HStack>
                  <PageHeaderWithIcon
                    label={
                      <H1 pt={4} mb={2}>
                        Security Graph: Evidence
                      </H1>
                    }
                  />
                  <PrimaryIconButton
                    aria-label="view details"
                    tooltip="View resource details"
                    isDisabled={alertDetails.isLoading}
                    onClick={() => {
                      updateActiveResource({
                        resourceType: alertDetails.data
                          .resource as NativeResources,
                        uuid: alertDetails.data.entity_uuid!,
                        accountId: alertDetails.data.account_id!,
                        resourceId: alertDetails.data.entity_id!,
                      });
                      onOpenMetadata();
                    }}
                  >
                    <ViewAllIcon />
                  </PrimaryIconButton>
                </HStack>
                <HStack>
                  <PrimaryButton
                    leftIcon={<RefreshIcon />}
                    onClick={() => navigate('/visibility/security-graph')}
                  >
                    Go to search
                  </PrimaryButton>
                  <Menu
                    menuItems={menuItems}
                    buttonLabel={
                      <PrimaryButton
                        leftIcon={<ActionsIcon />}
                        isDisabled={alertDetails.isLoading}
                        iconSize={5}
                      >
                        Actions
                      </PrimaryButton>
                    }
                  />
                  <PrimaryButton
                    leftIcon={<DownloadIcon />}
                    isDisabled
                    //onClick={() => navigate('/visibility/security-graph')}
                  >
                    Save as...
                  </PrimaryButton>
                </HStack>
              </HStack>
              <Text fontSize={'md'} w="full">
                {alertDetails.data?.message}
              </Text>
              <Box flex={1} pos={'relative'}>
                {graphComponent}
              </Box>
            </Stack>
            {exceptionModal.isOpen && (
              <AddException
                onClose={exceptionModal.onClose}
                isOpen={exceptionModal.isOpen}
                alert={alertDetails.data}
              />
            )}

            {ticketModal.isOpen && (
              <CreateTicket
                onClose={ticketModal.onClose}
                isOpen={ticketModal.isOpen}
                alert={alertDetails.data}
                onCreate={() => getAlertStatus()}
                notification_for={NotificationFor.Alerts}
              />
            )}
            {notificationModal.isOpen && (
              <CreateNotification
                onClose={notificationModal.onClose}
                isOpen={notificationModal.isOpen}
                alert={alertDetails.data}
                notification_for={NotificationFor.Alerts}
              />
            )}
            {snooze.isOpen && (
              <SnoozeAlert
                onClose={snooze.onClose}
                isOpen={snooze.isOpen}
                alert={alertDetails.data}
                onSuccess={() => getAlertDetails()}
              />
            )}
          </ActiveResourceProvider>
        </SecurityGraphContent>
      }
    />
  );
};
