import React from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContentType } from 'recharts/types/component/Tooltip';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { BarChart } from 'components/Visualization';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { DashboardOverviewCardProps } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { DeployOutpostForDataMessage } from 'containers/Dashboard/utils/DeployOutpostForDataMessage';
import { selectSharedState } from 'containers/SharedState/selectors';

interface Props extends Omit<DashboardOverviewCardProps, 'content'> {
  chartData: Record<string, any>[];
  isLoadingChart?: boolean;
  yFields: string[];
  xField: string;
  barChartColors: string[];
  placeholder?: string;
  labelListKey?: string;
  customTooltipContent?: ContentType<any, any>;
  onClick?(e: any, key?: string, data?: any);
  totalSensitiveData: number;
  isLoadingSensitiveData: boolean;
}

export const DataSourcesCardWithBarChart: React.FC<Props> = props => {
  const {
    chartData,
    isLoadingChart,
    yFields,
    xField,
    barChartColors,
    placeholder,
    labelListKey,
    onClick,
    customTooltipContent,
    totalSensitiveData,
    isLoadingSensitiveData,
    ...rest
  } = props;

  const { isDataScannerDeployed } = useSelector(selectSharedState);
  const { environmentId } = useAccessBoundary();
  const navigate = useNavigate();

  return (
    <DashboardWidget
      subHeader={
        <HStack>
          <Box>Total Sensitive Data:</Box>
          <Box>
            {isLoadingSensitiveData ? (
              <CustomSpinner />
            ) : (
              formatNumber(totalSensitiveData, 1)
            )}
          </Box>
        </HStack>
      }
      content={
        isDataScannerDeployed?.[environmentId!] ? (
          placeholder && !chartData?.length ? (
            <Center color={customTheme.colors.gray['250']}>
              {placeholder}
            </Center>
          ) : (
            <Stack w="full" h="full">
              <HStack w="full" flex={1} py={3} spacing={0}>
                {/*<Box h="full">
                <Center
                  pb={1}
                  pr={1}
                  color={customTheme.colors.gray['300']}
                  style={{
                    writingMode: 'vertical-rl',
                    transform: 'rotate(-180deg)',
                  }}
                >
                  No. of data sources
                </Center>
              </Box>*/}

                <Box w="full" h="full">
                  <BarChart
                    data={chartData}
                    xField={xField}
                    yField={yFields}
                    isLoading={isLoadingChart}
                    legend={false}
                    colors={barChartColors}
                    labelListKey={labelListKey}
                    onClick={onClick}
                    customTooltipContent={customTooltipContent}
                    styles={{
                      barChart: {
                        margin: { bottom: -10, left: 0, top: 15, right: 0 },
                      },
                      yAxis: {
                        // axisLine: false,
                        tickLine: false,
                        tickCount: 3,
                        style: { fontSize: 14 },
                      },
                      bar: { barSize: 60, maxBarSize: 60 },
                      labelList: { fontSize: 14, dataKey: 'count' },
                      xAxis: {
                        tick: props => {
                          const { x, y, payload } = props;
                          return (
                            <g transform={`translate(${x},${y})`}>
                              <text
                                x={0}
                                y={0}
                                dy={10}
                                textAnchor="middle"
                                fontSize={14}
                              >
                                {formatNumber(payload.value)}
                              </text>
                            </g>
                          );
                        },
                      },
                    }}
                  />
                </Box>
              </HStack>
            </Stack>
          )
        ) : (
          <DeployOutpostForDataMessage />
        )
      }
      {...rest}
    />
  );
};
