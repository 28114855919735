/**
 *
 * Update Account
 *
 */

import React, { memo } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AzureActiveDirectoryIcon, AzureIcon, Form } from 'app/components';
import { actions as setupActions } from 'containers/Setup/slice';

import { selectCloudAccounts } from '../../../selectors';

interface Props {
  provider: CloudProviders;
}

export const EditAzureAccount = memo((props: Props) => {
  const { provider } = props;
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { accountDetails } = useSelector(selectCloudAccounts);
  const { data, isLoading } = accountDetails;
  const { azure = {} } = data;

  const handleSubmit = data => {
    const { account_name } = data;

    dispatch(
      setupActions.updateCloudAccount({
        q: {
          uuid: params.id!,
          cloudAccountUpdate: {
            cloud_type: props.provider,
            name: account_name,
            //azure: {},
          },
        },
        onSuccess: () => {
          navigate('/setup/accounts');
        },
      }),
    );
  };

  const handleCancel = () => {
    navigate('/setup/accounts');
  };
  return (
    <Box w="full">
      <Form
        title={
          'Update ' +
          (provider === CloudProviders.Azure ? 'Microsoft Azure' : 'AzureAD')
        }
        titleIcon={
          provider === CloudProviders.Azure ? (
            <AzureIcon />
          ) : (
            <AzureActiveDirectoryIcon />
          )
        }
        styles={{ form: { title: { iconFilled: false } } }}
        schema={{
          account_name: {
            type: 'text',
            label: 'Account Name',
            placeholder: 'Engineering-US',
            isRequired: true,
            defaultValue: data.name,
          },
        }}
        buttonOptions={{
          submit: {
            name: 'Update',
            isLoading: isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleCancel,
          },
        }}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
});
