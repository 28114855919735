import {
  ContextApiGetContextResourceCountRequest,
  ContextResourceResponse,
  CountSummary,
  ResourceApiGetIdentityInsightsRequest,
  ResourceSummaryApiGetSummaryByTypeRequest,
} from '@ariksa/inventory-core/api';
import { GetSummaryResponse } from '@ariksa/jit/api';
import {
  AggregatedAlertsSummary,
  AlertHistoryResponse,
  AlertsApiGetAlertHistoryRequest,
  AlertsApiGetAlertSummaryRequest,
  AlertsApiGetFindingsCategoriesSummaryRequest,
  AlertsApiGetResolvedAlertSummaryRequest,
  AlertSummaryResponse,
  ResolvedSummaryResponse,
} from '@ariksa/notification/api';
import {
  ChartsApiGetIdentitiesHistoryRequest,
  InventoryApiGetResourceCountRequest,
  ResourceCountResponse,
} from '@ariksa/reporting/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  InventoryService,
  JitService,
  NotificationService,
  ReportingService,
} from 'api/services';
import { doGetResourceCategories } from 'containers/Inventory/CloudInventory/saga';

import { actions } from './slice';

export function* identityOverviewSaga() {
  yield takeLatestAction(
    actions.getIdentityAuthorizationOpenAlerts,
    doGetOpenAlerts,
  );
  yield takeLatestAction(
    actions.getIdentityAuthorizationResolvedAlerts,
    doGetResolvedAlertSummary,
  );
  yield takeLatestAction(
    actions.getIdentitiesWithExcessPermissions,
    doGetIdentitiesWithExcessPermissions,
  );
  yield takeLatestAction(
    actions.getIdentitiesWithHighPrivileges,
    doGetIdentitiesWithHighPrivileges,
  );
  yield takeLatestAction(
    actions.getIdentitiesWithAccessToSensitiveData,
    doGetIdentitiesWithAccessToSensitiveData,
  );
  yield takeLatestAction(actions.getJitSummary, doGetJitSummary);
  yield takeLatestAction(actions.getIdentitiesHistory, doGetIdentitiesHistory);
  yield takeLatestAction(
    actions.getInsightsForNonHumanIdentities,
    doGetInsightsForNonHumanIdentities,
  );
  yield takeLatestAction(
    actions.getIdentityAuthorizationFindingsCategorySummary,
    doGetFindingsCategorySummary,
  );

  yield takeLatestAction(
    actions.getFederatedUsersChange,
    doGetFederatedUsersChange,
  );
  yield takeLatestAction(actions.getNonUsersChange, doGetNonUsersChange);
  yield takeLatestAction(actions.getCloudUsersChange, doGetCloudUsersChange);
  yield takeLatestAction(actions.getSaaSUsersChange, doGetSaaSUsersChange);
  yield takeLatestAction(
    actions.getResourceCategories,
    doGetResourceCategories,
  );
  yield takeLatestAction(
    actions.getIdentityRolesChange,
    doGetIdentityRolesChange,
  );
}

//get state of your cloud summary
export function* doGetInventorySummary(
  ctx: QueryContext<CountSummary, ResourceSummaryApiGetSummaryByTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.ResourceSummary.getSummaryByType(ctx.params),
    {
      errorMsg: 'Failed to get inventory summary!',
      cacheKey: 'inventorySummary',
    },
  );
}

export function* doGetIdentitiesWithAccessToSensitiveData(
  ctx: QueryContext<
    ContextResourceResponse,
    ContextApiGetContextResourceCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Context.getContextResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get inventory summary!',
      cacheKey: 'identitiesWithAccessToSensitiveData',
    },
  );
}
export function* doGetIdentitiesWithExcessPermissions(
  ctx: QueryContext<
    ContextResourceResponse,
    ContextApiGetContextResourceCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Context.getContextResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get inventory summary!',
      cacheKey: 'identitiesWithExcessPermissions',
    },
  );
}
export function* doGetIdentitiesWithHighPrivileges(
  ctx: QueryContext<
    ContextResourceResponse,
    ContextApiGetContextResourceCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Context.getContextResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get inventory summary!',
      cacheKey: 'identitiesWithHighPrivileges',
    },
  );
}

//get open alerts
export function* doGetOpenAlerts(
  ctx: QueryContext<AlertSummaryResponse, AlertsApiGetAlertSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertSummary(ctx.params),
    {
      errorMsg: 'Failed to get open alert count!',
      cacheKey: 'identityOpenAlerts',
    },
  );
}

//get findings summary
export function* doGetResolvedAlertSummary(
  ctx: QueryContext<
    ResolvedSummaryResponse,
    AlertsApiGetResolvedAlertSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getResolvedAlertSummary(ctx.params),
    {
      errorMsg: 'Failed to get resolved findings summary!',
      cacheKey: 'resolvedAlertSummary',
    },
  );
}

//get findings history
export function* doGetOpenFindingHistory(
  ctx: QueryContext<AlertHistoryResponse, AlertsApiGetAlertHistoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertHistory(ctx.params),
    {
      errorMsg: 'Failed to get open finding history!',
      cacheKey: 'openFindingHistory',
    },
  );
}

//get jit summary
export function* doGetJitSummary(ctx: QueryContext<GetSummaryResponse>) {
  yield call(ctx.fetch, () => JitService.Jit.getSummary(ctx.params), {
    errorMsg: 'Failed to get jit summary!',
    cacheKey: 'jitSummary',
  });
}

//get findings category summary
export function* doGetFindingsCategorySummary(
  ctx: QueryContext<
    AggregatedAlertsSummary[],
    AlertsApiGetFindingsCategoriesSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getFindingsCategoriesSummary(ctx.params),
    {
      errorMsg: 'Failed to get findings category summary!',
      cacheKey: 'identityFindingsCategorySummary',
    },
  );
}

//get identities history
export function* doGetIdentitiesHistory(
  ctx: QueryContext<Record<string, any>, ChartsApiGetIdentitiesHistoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getIdentitiesHistory(ctx.params),
    {
      errorMsg: 'Failed to get identities history!',
      cacheKey: 'identitiesHistory',
    },
  );
}

//insights for cloud networking
export function* doGetInsightsForNonHumanIdentities(
  ctx: QueryContext<
    Record<string, number>,
    ResourceApiGetIdentityInsightsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getIdentityInsights(ctx.params),
    {
      errorMsg: 'Failed to get insights for non-human identities!',
      cacheKey: 'insightsForNonHumanIdentities',
    },
  );
}

export function* doGetFederatedUsersChange(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get federated users change!',
      cacheKey: 'federatedUsersChange',
    },
  );
}
export function* doGetNonUsersChange(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get non-users change!',
      cacheKey: 'nonUsersChange',
    },
  );
}
export function* doGetCloudUsersChange(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get cloud users change!',
      cacheKey: 'cloudUsersChange',
    },
  );
}
export function* doGetSaaSUsersChange(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get SaaS users change!',
      cacheKey: 'saasUsersChange',
    },
  );
}
export function* doGetIdentityRolesChange(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get identity roles change!',
      cacheKey: 'identityRolesChange',
    },
  );
}
