import React from 'react';

import { Icon } from '@chakra-ui/react';

export const KubernetesIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        fill="inherit"
        d="M304.5,293c-0.7-0.1-1.4-0.2-2.2-0.2c-1.2,0.1-2.5,0.4-3.6,0.9c-4.5,2.2-6.5,7.6-4.6,12.2l-0.1,0.1l21.5,52
		c20.6-13.1,36.2-32.8,44.4-55.8l-55.5-9.4L304.5,293z M218.2,300.4c-1-4.2-4.7-7.2-9-7.3c-0.6,0-1.2,0-1.9,0.2l-0.1-0.1l-55,9.3
		c8.3,22.8,23.8,42.3,44.3,55.4l21.3-51.5l-0.2-0.2C218.5,304.3,218.6,302.3,218.2,300.4L218.2,300.4z M264.3,320.6
		c-2.5-4.6-8.1-6.3-12.7-3.8c-1.6,0.9-3,2.2-3.8,3.9h0l-27,48.9c19.4,6.6,40.2,7.7,60.1,3.1c3.7-0.8,7.3-1.9,10.8-3l-27.1-49
		L264.3,320.6z M349.9,206.9l-41.6,37.2l0,0.1c-3.9,3.4-4.3,9.4-0.9,13.3c1.2,1.4,2.8,2.4,4.6,2.9l0.1,0.2l53.9,15.5
		C368.3,252,362.6,227.7,349.9,206.9L349.9,206.9z M272.6,211.1c0.2,5.2,4.6,9.2,9.8,9c1.8-0.1,3.6-0.7,5.1-1.8l0.2,0.1l45.5-32.3
		c-17.3-17-39.8-27.8-63.8-30.8L272.6,211.1L272.6,211.1z M224.3,218.5c4.2,3.1,10.1,2.1,13.1-2.1c1.1-1.5,1.7-3.3,1.8-5.1l0.2-0.1
		l3.2-55.9c-3.8,0.5-7.6,1.1-11.4,2c-20,4.5-38.3,14.5-52.9,28.8L224.3,218.5L224.3,218.5z M199.9,260.7c5-1.4,8-6.5,6.6-11.5
		c-0.5-1.8-1.5-3.4-2.9-4.6l0-0.2l-41.8-37.4c-12.8,20.8-18.2,45.2-15.6,69.5l53.6-15.5L199.9,260.7z M240.5,277.2l15.4,7.4
		l15.4-7.4l3.8-16.6l-10.7-13.3h-17.1l-10.7,13.3L240.5,277.2z"
      />
      <path
        fill="inherit"
        d="M511.2,309.9l-44-191.1c-2.4-10-9.2-18.4-18.4-22.9l-178.1-85c-9.3-4.5-20.2-4.5-29.6,0L63.2,96
		c-9.3,4.5-16,12.9-18.4,22.9L0.8,309.9c-1.1,4.8-1.1,9.8,0,14.7c0.3,1.5,0.7,2.9,1.2,4.3c0.9,2.4,2,4.7,3.4,6.9
		c0.6,0.9,1.2,1.8,1.9,2.7l123.2,153.2c0.5,0.7,1.1,1.3,1.7,1.9c1.9,2.1,4.1,3.9,6.5,5.4c3,1.9,6.3,3.3,9.7,4.2
		c2.8,0.8,5.7,1.2,8.7,1.2h1.9l195.7,0c1.2,0,2.4-0.1,3.6-0.2c1.7-0.2,3.4-0.5,5-1c1.2-0.3,2.3-0.7,3.5-1.1c0.9-0.3,1.8-0.7,2.6-1.1
		c1.3-0.6,2.5-1.3,3.7-2.1c3.1-1.9,5.9-4.4,8.2-7.2l3.8-4.7l119.4-148.6c2.3-2.9,4.1-6.1,5.3-9.6c0.5-1.4,0.9-2.9,1.3-4.4
		C512.3,319.7,512.3,314.7,511.2,309.9L511.2,309.9z M326.5,384.2c0.5,1.5,1.1,2.9,1.8,4.3c-1.1,1.9-1.4,4.2-0.8,6.3
		c2.6,5.9,5.8,11.6,9.4,16.9c2.1,2.7,4,5.6,5.7,8.5c0.4,0.8,1,2,1.4,2.9c3,5.1,1.4,11.6-3.7,14.7c-5.1,3-11.6,1.4-14.7-3.7
		c-0.4-0.6-0.7-1.2-0.9-1.9c-0.4-0.8-1-1.9-1.3-2.7c-1.2-3.2-2.3-6.5-3.1-9.8c-1.8-6.2-4.3-12.2-7.3-17.9c-1.3-1.8-3.3-3-5.4-3.3
		c-0.3-0.6-1.6-2.9-2.3-4.1c-31.7,12-66.7,11.9-98.3-0.3l-2.4,4.4c-1.7,0.3-3.3,1-4.6,2.2c-3.6,6-6.4,12.4-8.3,19.1
		c-0.8,3.3-1.9,6.6-3.1,9.8c-0.3,0.8-0.9,1.9-1.3,2.7v0l0,0c-2,5.6-8.1,8.5-13.7,6.5c-5.6-2-8.5-8.1-6.5-13.7
		c0.2-0.7,0.5-1.3,0.9-1.9c0.4-0.8,0.9-2.1,1.4-2.9c1.7-2.9,3.6-5.8,5.7-8.5c3.8-5.5,7-11.3,9.6-17.3c0.3-2.1,0-4.2-0.9-6l1.9-4.6
		c-29.2-17.2-51-44.5-61.5-76.7l-4.6,0.8c-1.8-1.3-3.9-2-6.1-2.2c-6.4,1.2-12.6,3-18.6,5.5c-3.1,1.4-6.3,2.6-9.6,3.7
		c-0.8,0.2-1.9,0.4-2.8,0.6c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-5.6,1.8-11.7-1.2-13.5-6.8c-1.8-5.6,1.2-11.7,6.8-13.5
		c0.6-0.2,1.3-0.4,2-0.4l0.2,0l0.1,0c0.9-0.2,2-0.5,2.8-0.7c3.4-0.5,6.8-0.7,10.2-0.8c6.5-0.4,12.8-1.5,19.1-3.1
		c1.9-1.2,3.4-2.8,4.6-4.6l4.5-1.3c-4.8-33.6,2.9-67.9,21.7-96.1l-3.4-3c-0.1-2.2-0.9-4.3-2.1-6.2c-4.9-4.2-10.2-7.9-15.8-11.1
		c-3-1.6-6-3.3-8.8-5.2c-0.6-0.5-1.5-1.2-2.2-1.8l-0.2-0.1c-5-3.6-6.2-10.6-2.6-15.7c2.1-2.5,5.2-3.9,8.5-3.8
		c2.7,0.1,5.3,1.1,7.4,2.8c0.7,0.6,1.7,1.3,2.4,1.9c2.5,2.3,4.8,4.8,7,7.4c4.4,4.8,9.2,9.1,14.4,13c1.9,1,4.2,1.2,6.3,0.6
		c1.3,0.9,2.6,1.8,3.8,2.7c18.7-19.9,43-33.8,69.6-39.8c6.3-1.4,12.8-2.4,19.3-3l0.3-4.5c1.7-1.5,2.9-3.4,3.5-5.5
		c0.2-6.5-0.2-12.9-1.2-19.3c-0.7-3.3-1.2-6.7-1.5-10.1c0-0.8,0-1.8,0-2.7c0-0.1,0-0.2,0-0.3c-0.6-5.9,3.7-11.1,9.6-11.7
		c5.9-0.6,11.1,3.7,11.7,9.6c0.1,0.7,0.1,1.4,0,2.2c0,0.9,0.1,2.2,0,3c-0.3,3.4-0.8,6.8-1.5,10.1c-1,6.4-1.4,12.9-1.2,19.3
		c0.4,2.2,1.7,4.1,3.5,5.3c0,0.8,0.2,3.3,0.3,4.8c33.7,3.1,65.1,18.3,88.5,42.7l4.1-2.9c2.2,0.4,4.4,0.1,6.5-0.7
		c5.2-3.9,10-8.2,14.4-13c2.2-2.6,4.5-5.1,7-7.4c0.7-0.6,1.6-1.3,2.4-1.9c4.2-4.2,11-4.1,15.1,0.1c4.2,4.2,4.1,11-0.1,15.1
		c-0.5,0.5-1.1,1-1.8,1.4c-0.7,0.6-1.7,1.4-2.3,1.9c-2.8,1.9-5.8,3.6-8.9,5.2c-5.6,3.2-10.9,6.9-15.8,11.1c-1.4,1.7-2.1,3.8-2,6
		c-0.6,0.5-2.7,2.4-3.8,3.4c18.9,28.2,26.8,62.3,22.2,95.9l4.3,1.3c1.1,1.9,2.7,3.5,4.6,4.6c6.3,1.7,12.7,2.7,19.1,3.1
		c3.4,0.1,6.8,0.4,10.2,0.8c0.9,0.2,2.2,0.5,3.1,0.7c5.9,0.8,10,6.1,9.2,12c-0.8,5.9-6.1,10-12,9.2c-0.7-0.1-1.3-0.2-2-0.4l-0.2,0
		c-0.1,0-0.1-0.1-0.2-0.1c-0.9-0.2-2-0.4-2.8-0.6c-3.3-1-6.4-2.3-9.6-3.7c-6-2.4-12.2-4.3-18.6-5.5c-2.2-0.1-4.3,0.7-6,2.2
		c-1.5-0.3-3-0.6-4.6-0.8C377.6,339.4,355.8,366.9,326.5,384.2L326.5,384.2z"
      />
    </g>
  </Icon>
);
