import React, { useEffect, useState } from 'react';

import { DataCategory } from '@ariksa/inventory-core';
import { PanelsEnum, PublicResourceSummary } from '@ariksa/inventory-core/api';
import {
  Box,
  Center,
  Stack,
  ListItem,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { each, isEmpty, map, toArray } from 'lodash';
import { useSelector } from 'react-redux';

import { renderRiskContext, WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import { IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import {
  DashboardWidgetTooltipIcon,
  renderCloudIcons,
} from 'containers/Dashboard/utils/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSharedState } from 'containers/SharedState/selectors';

export const PubliclyAccessibleDataSources: React.FC = () => {
  const { publiclyExposedDataSources } = useSelector(
    selectDataSecurityOverview,
  );
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );
  const { riskContext } = useSelector(selectSharedState);
  const [categories, setCategories] = useState<
    Record<
      string,
      PublicResourceSummary & { iconType: string; clouds: string[] }
    >
  >({});
  const { accountMapping } = useCloudAccountId();

  useEffect(() => {
    const items = {};
    each(publiclyExposedDataSources.data, o => {
      let clouds = new Set();
      each(o.resources, r => {
        const cloud = accountMapping[r?.account]?.cloud_type;
        if (!!cloud) clouds.add(cloud);
      });
      items[o.category] = { ...o, clouds: toArray(clouds) };
    });

    setCategories({
      Buckets: {
        ...items[DataCategory.Buckets],
        iconType: IconTypes.SimpleStorageService,
      },
      Databases: {
        ...items[DataCategory.Databases],
        iconType: IconTypes.Database,
      },
      Others: {
        ...items[DataCategory.Others],
        iconType: IconTypes.BlockStorage,
      },
      SaaS: {
        ...items[DataCategory.SaaS],
        iconType: IconTypes.Cloud,
      },
    });
  }, [publiclyExposedDataSources.data, accountMapping]);

  const getRiskContext = resources => {
    let risk: Record<string, any> = {};
    each(resources, o => {
      each(riskContext.data?.[o.uuid], s => {
        risk[s.type] = s.severity;
      });
    });
    return map(risk, (o, key) => ({ type: key, severity: o }));
  };

  const data = map(
    [
      DataCategory?.Buckets,
      DataCategory?.Databases,
      DataCategory?.Others,
      DataCategory?.SaaS,
    ],
    c => ({
      ...categories?.[c],
      type: c,
      count: categories?.[c]?.total ?? 0,
      clouds: categories?.[c]?.clouds ?? [],
    }),
  );

  const columns = [
    {
      header: <Box pl={9}>RESOURCE</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={categories[row.type]?.iconType}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
          color="primary"
        >
          {row.type === DataCategory?.Others ? 'Disks' : row.type}
        </WithResourceIcon>
      ),
      styles: { w: '30%' },
    },
    {
      header: 'COUNT',
      accessor: 'count',
      styles: {
        cell: { textAlign: 'right', pr: 3 },
        header: { textAlign: 'right' },
        w: '10%',
      },
    },
    {
      header: 'CLOUDS',
      render: ({ row }) => <Center>{renderCloudIcons(row?.clouds)}</Center>,
      styles: { w: '25%', header: { textAlign: 'center' } },
    },
    {
      header: 'CONTEXT',
      render: ({ row }) => renderRiskContext(getRiskContext(row?.resources)),
      styles: { td: { w: '25%' } },
    },
  ];

  return (
    <>
      <DashboardWidget
        label="Publicly Exposed Data Sources"
        isLoading={publiclyExposedDataSources.isLoading}
        noData={isEmpty(publiclyExposedDataSources.data)}
        noDataMessage="No data sources are publicly accessible"
        content={
          <Box w="full" pl={1} h="full">
            <DashboardTable
              columns={columns}
              data={data}
              onRowClick={row => {
                allEntitiesDisclosure.onOpen();
                setDetails({
                  iconType: IconTypes.Globe,
                  panel: PanelsEnum.PubliclyExposedDataSources,
                  total: row?.count || 0,
                  resourceTypeClass: 'agnostic',
                  resourceType: row?.agnostic_class,
                });
              }}
            />
          </Box>
        }
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
        tooltip={{
          tooltip: (
            <Stack>
              <Box>
                Ariksa detects publicly exposed data sources in cloud and SaaS
                and enriches with deeper context to uncover imminent threats.
              </Box>
              <Box>To protect your data:</Box>
              <UnorderedList color="primary" spacing={2}>
                <ListItem pl={2}>Deploy Outpost for Data</ListItem>
                <ListItem pl={2}>Review exposed data</ListItem>
                <ListItem pl={2}>Remediate using Ariksa</ListItem>
              </UnorderedList>
            </Stack>
          ),
          header: 'Publicly Exposed Data Sources',
          button: <DashboardWidgetTooltipIcon />,
          showArrow: false,
        }}
      />
      {allEntitiesDisclosure.isOpen && (
        <AllEntitiesDrawer
          isOpen={allEntitiesDisclosure.isOpen}
          onClose={allEntitiesDisclosure.onClose}
          {...details}
        />
      )}
    </>
  );
};
