import React, { useCallback, useEffect, useState } from 'react';

import { Box, HStack, Flex } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { customTheme } from 'theme';

import { WithResourceIcon } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { SideNav, SideNavMenuItemProps } from 'components/Navigation';
import { useVulnerabilityContext } from 'containers/Visibility/Vulnerabilities/Components/hooks/context';
import { vulnerabilitySideNavItemList } from 'containers/Visibility/Vulnerabilities/Components/utils';
import { VulnerabilityDashboard } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityDashboard';
import { VulnerabilityTabs } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/VulnerabilityTabs';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

import { actions } from '../slice';

export const VulnerabilitySideNav: React.FC = () => {
  const [menuItems, setMenuItems] = useState<SideNavMenuItemProps[]>([]);
  const { getVulnerabilities } = useVulnerabilityContext();
  const { selectedTab, vulnerabilities } = useSelector(selectVulnerabilities);
  const dispatch = useDispatch();

  const params = useParams<{ tab: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectTo = useCallback(
    (tab: string) => {
      navigate(`${location.pathname}?tab=${tab}`);
    },
    [navigate, location.pathname],
  );

  useEffect(() => {
    if (!!params.tab) {
      dispatch(actions.updateSelectedTab(params.tab));
      dispatch(
        actions.updateVulnerabilityPageInfo({
          page_number: 1,
          page_size: vulnerabilities.page.info.page_size,
        }),
      );
    }
  }, [params.tab, dispatch, vulnerabilities.page.info.page_size]);

  useEffect(() => {
    const getMenuItem = (item, type) => {
      return {
        ...item,
        ...(!item.showIcon
          ? {}
          : { icon: <Box color="primary">{getIcon(item.key)}</Box> }),
        ...(!!item.secondary
          ? {
              secondary: map(item.secondary, o => getMenuItem(o, 'secondary')),
            }
          : {}),
      };
    };

    const items: SideNavMenuItemProps[] = [
      {
        title: (
          <Box>
            <WithResourceIcon resourceType={IconTypes.Overview} spacing={2}>
              OVERVIEW
            </WithResourceIcon>
          </Box>
        ),
        key: 'Overview',
        showDivider: true,
      },
      ...map(vulnerabilitySideNavItemList, o => getMenuItem(o, 'primary')),
    ];

    setMenuItems(items);
  }, [selectedTab]);

  useEffect(() => {
    getVulnerabilities();
  }, [getVulnerabilities]);

  return (
    <HStack spacing={customTheme.sizes.interCardSpacing} h="full" w="full">
      <SideNav
        menuItems={menuItems}
        hasContent={false}
        navCompWidth={64}
        height="full"
        current={selectedTab}
        onChange={current => {
          redirectTo(current as string);
          dispatch(actions.updateVulnerabilityPageInfo(INIT_PAGE_INFO));
        }}
        styles={{
          menu: { defaultIndex: [1] },
        }}
      />
      <Flex flex={1} h={'full'} pos={'relative'}>
        {selectedTab !== 'Overview' ? (
          <VulnerabilityTabs />
        ) : (
          <VulnerabilityDashboard />
        )}
      </Flex>
    </HStack>
  );
};
