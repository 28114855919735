import React from 'react';

import { BlockStorageIcon } from 'components/Icons';
import { CDashboard } from 'components/Visualization';
import { dataSecurityDashboardConfig } from 'containers/Dashboard/DataSecurityOverview/config';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';

interface Props {
  isDataClassificationEnabled: boolean;
}

export const DataSecurity: React.FC<Props> = props => {
  return (
    <DashboardOverviewWrapperCard
      label="Data Security"
      content={<CDashboard dashboardWidgets={dataSecurityDashboardConfig} />}
      icon={<BlockStorageIcon />}
      redirectLink="/findings?tab=Data Security"
    />
  );
};
