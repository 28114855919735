/**
 *
 *
 *
 */

import React from 'react';

import { Center, Stack } from '@chakra-ui/react';

export const NoPermissionMessage = () => {
  return (
    <Center fontSize="md" w="full" h="full">
      <Stack>
        <Center>You do not have permission to view this page.</Center>
        <Center>Please contact your domain admin.</Center>
      </Stack>
    </Center>
  );
};
