import React, { useEffect, useState } from 'react';

import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import { each, map, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { getIconColor } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { dashboardWidgetLineStyles } from 'containers/Dashboard/utils/styles';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { toTitleCase } from 'utils/string';

export const VulnerabilitiesByResourceTypes: React.FC = () => {
  const { vulnerabilitiesByResourceTypes } = useSelector(
    selectVulnerabilityOverview,
  );
  const [data, setData] = useState<Record<string, any>[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    let data: Record<string, any>[] = [];
    each(vulnerabilitiesByResourceTypes.data?.items, o =>
      data.push({
        ...o,
        total:
          (o.vulnerability_count.critical ?? 0) +
          (o.vulnerability_count.high ?? 0),
      }),
    );
    setData(orderBy(data, o => o.total, ['desc']));
  }, [vulnerabilitiesByResourceTypes.data]);

  const renderLegend = (category, vulnerability_count, total) => {
    const value = formatNumber(total, 1);
    const color = getIconColor(category);
    return (
      <HStack
        /*onClick={() => {
          navigate(
            '/findings?tab=' +
              '&alertCategory=' +
              category +
              '&severity=' +
              severity?.toUpperCase() +
              '&redirect=true',
          );
        }}
        cursor="pointer"*/
        spacing={6}
        {...dashboardWidgetLineStyles}
      >
        <Box h="full" w={1} bg={color} borderRadius={2} />
        <Stack spacing={0} w="full">
          <HStack justify="space-between" w="full">
            <Box>{toTitleCase(category)}</Box>
            <Box>{value}</Box>
          </HStack>
        </Stack>
      </HStack>
    );
  };

  return (
    <DashboardWidget
      label="Vulnerabilities By Resource Types"
      isLoading={vulnerabilitiesByResourceTypes.isLoading}
      subHeader="Critical or high severity with known exploits"
      content={
        <Stack h="full" w="full" justify="space-between" spacing={0}>
          {map(data, o =>
            renderLegend(o?.agnostic_name, o?.vulnerability_count, o?.total),
          )}
        </Stack>
      }
      styles={{ contentWrapper: { px: 0 } }}
    />
  );
};
