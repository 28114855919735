import React, { memo, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Center,
  HStack,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { cloneDeep, isArray, merge, isEmpty, isString } from 'lodash';
import { findIndex } from 'lodash';
import { Pie, PieChart, Cell, Legend, Tooltip, Label, Text } from 'recharts';
import { ResponsiveContainer } from 'recharts';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';

import { LIGHT_COLORS, DARK_COLORS, customLegends } from '../utils';

import { renderActiveShape } from './renderActiveShape';
import { defaultStyles } from './styles';
import { IPieChart } from './types';

// for reference visit
// https://recharts.org/en-US/examples/CustomActiveShapePieChart
export const CustomPieChart: React.FC<IPieChart> = ({
  data = [],
  dataKey,
  total,
  outerRadius = '90%',
  innerRadius = '82%',
  tooltip = true,
  legend = true,
  overwriteDefaultStyles,
  activeShape = true,
  color,
  styles,
  renderLegend,
  onClick,
  customTooltipContent,
  isLoading = false,
  colorMap,
  activeShapeKey = 'name',
  centerLabel,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [active, setActive] = useState(false);
  const [colors, setColors] = useState(
    useColorModeValue(LIGHT_COLORS, DARK_COLORS),
  );
  const [totalCount, setTotalCount] = useState(0);
  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);

  useEffect(() => {
    if (isString(total)) setTotalCount(parseInt(total));
    else setTotalCount(total);
  }, [total]);

  const onPieEnter = e => {
    const index = findIndex(
      data,
      d => d[activeShapeKey] === e.payload[activeShapeKey],
    );
    setActiveIndex(index);
    setActive(true);
  };
  useEffect(() => {
    if (!isEmpty(color)) {
      if (isArray(color)) {
        setColors(color);
      } else {
        setColors([color, customTheme.colors.gray['100']]);
      }
    }
  }, [color]);

  const onPieLeave = e => setActive(false);

  const CustomCenterLabel = props => {
    const { viewBox, value, fontSize = 24, pb = 10 } = props;
    const { cx, cy } = viewBox;

    return (
      <>
        <Text
          x={cx}
          y={cy - (centerLabel ? pb : 0)}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={fontSize}
          fill="black"
        >
          {formatNumber(value, 1)}
        </Text>

        {centerLabel && (
          <Text
            x={cx}
            y={cy + 15}
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={14}
            fill="#666"
          >
            {centerLabel}
          </Text>
        )}
      </>
    );
  };

  return (
    <Box {...baseStyles?.root}>
      {isLoading ? (
        <CustomSpinner loading={isLoading} />
      ) : (
        <ResponsiveContainer>
          <PieChart {...baseStyles?.pieChart}>
            <Pie
              activeIndex={activeIndex}
              activeShape={activeShape && active ? renderActiveShape : {}}
              data={totalCount > 0 ? data : [{ name: 'No data', value: 1 }]}
              dataKey={dataKey}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              onMouseEnter={onPieEnter}
              onMouseLeave={onPieLeave}
              startAngle={450}
              endAngle={90}
              {...(baseStyles?.pie as any)}
              onClick={e => onClick?.(e)}
            >
              {!isEmpty(data) &&
                data.map((entry, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        totalCount > 0
                          ? colorMap?.[entry?.name] ??
                            colors[index % colors.length]
                          : customTheme.colors.gray['100']
                      }
                    />
                  );
                })}
              {isEmpty(data) && (
                <Cell
                  key={`empty-cell`}
                  fill={customTheme.colors.gray['100']}
                />
              )}
              <Label
                value={total}
                content={CustomCenterLabel}
                {...baseStyles?.label}
              />
            </Pie>
            {/* @ts-ignore */}
            {tooltip && totalCount > 0 && (
              <Tooltip
                {...baseStyles?.tooltip}
                content={customTooltipContent}
              />
            )}
            {/* @ts-ignore */}
            {legend && totalCount > 0 && (
              <Legend
                {...(baseStyles?.legend as any)}
                onMouseEnter={onPieEnter}
                onMouseLeave={onPieLeave}
                content={renderLegend ?? customLegends}
              />
            )}
          </PieChart>

          {/* Percentage Text */}
        </ResponsiveContainer>
      )}
      {/*<Center>
        <Stack></Stack>
      </Center>*/}
    </Box>
  );
};

export const MemoPieChart = memo<IPieChart>(CustomPieChart);
