import React from 'react';

import { Box, Stack, Center, Text } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import {
  CustomTooltip,
  getIconColor,
  renderRiskContext,
  renderTooltipFieldValue,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const RuntimeInsightsForDataSources: React.FC = () => {
  const { runtimeInsights } = useSelector(selectDataDashboard);
  const { getResourceAlias, getCloudNativeName } = useResourceType();
  const { accountMapping } = useCloudAccountId();

  const columns = [
    {
      header: <Box pl={9}>SOURCE</Box>,
      render: ({ row }) => {
        const account = accountMapping?.[row?.account_id];
        return (
          <WithResourceIcon
            resourceType={row.source}
            iconFilled={false}
            iconStyles={{ w: 5, h: 5 }}
            color={getIconColor(row?.source)}
          >
            <CustomTooltip
              label={
                <Stack spacing={1}>
                  {renderTooltipFieldValue(
                    'Resource',
                    row?.name || row?.resource_id,
                  )}
                  {renderTooltipFieldValue(
                    'Type',
                    getCloudNativeName(row?.source),
                  )}
                  {renderTooltipFieldValue('Account Name', account?.name)}
                  {renderTooltipFieldValue(
                    'Account ID',
                    account?.cloud_account_id,
                  )}
                </Stack>
              }
            >
              <StackedCell
                upper={row?.name || row?.resource_id}
                lower={
                  getResourceAlias(row?.source) +
                  ' / ' +
                  (account?.cloud_account_id ?? '-')
                }
                showUpperTooltip={false}
                showLowerTooltip={false}
                styles={{ lower: { fontSize: '13px' } }}
              />
            </CustomTooltip>
          </WithResourceIcon>
        );
      },
      styles: { w: '30%' },
    },
    {
      header: 'CONTEXT',
      render: ({ row }) => <Center>{renderRiskContext(row.context)}</Center>,
      styles: { w: '15%', header: {textAlign: 'center'} },
    },
    {
      header: 'DESCRIPTION',
      align: 'left',
      render: ({ row }) => (
        <Text whiteSpace="wrap" noOfLines={3}>
          {row.description}
        </Text>
      ),
      styles: { w: '55%' },
    },
  ];

  return (
    <DashboardWidget
      label="Runtime Insights For Data Sources"
      subHeader="Last 30 days"
      content={
        <Box w="full" h="full">
          <DashboardTable
            columns={columns}
            data={runtimeInsights.data}
            isLoading={runtimeInsights.isLoading}
          />
        </Box>
      }
    />
  );
};
