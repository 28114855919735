import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { useSelector } from 'react-redux';

import { AriksaIcon, WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';

export const WellKnownPorts: React.FC = () => {
  const { vmPortUsage } = useSelector(selectCloudPostureOverview);
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  const columns = [
    {
      header: <Box pl={9}>PORT</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={IconTypes.InternetGateway}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
          color="critical"
        >
          {row.port}
        </WithResourceIcon>
      ),
    },
    { header: 'ENTITIES', accessor: 'count' },
    {
      header: 'RISKS',
      render: ({ row }) => (
        <HStack spacing={1}>
          {map(row.contexts, c => (
            <CustomTooltip label={c}>
              <AriksaIcon
                type={c}
                color="critical"
                bg="white"
                useCustomColor
                borderColor="gray.200"
                border
                size="small"
              />
            </CustomTooltip>
          ))}
        </HStack>
      ),
    },
  ];

  return (
    <>
      <DashboardWidget
        label="Well-known Ports Permitting 0.0.0.0/0"
        isLoading={vmPortUsage.isLoading}
        noData={isEmpty(vmPortUsage.data)}
        noDataMessage="No insights available"
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
        content={
          <DashboardTable
            columns={columns}
            data={vmPortUsage.data}
            onRowClick={row => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.WellKnownPortsUnrestrictedAccess,
                iconType: IconTypes.Port,
                port: row?.port,
                total: row?.count,
              });
            }}
          />
        }
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
