import React, { useCallback, useState } from 'react';

import { Box, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { map, some } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  renderRiskContext,
  renderSeverityBar,
  renderTableHeaderWithLoader,
  renderTime,
  StackedCell,
  Tag,
  WithResourceIcon,
} from 'components/DataDisplay';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  BellIcon,
  ChatIcon,
  GraphIcon,
  NotificationIcon,
  PolicyExceptionIcon,
  RemediateIcon,
  SnoozeIcon,
  SummaryIcon,
  TicketIcon,
} from 'components/Icons';
import { TableActionsMenu } from 'components/Overlay';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import {
  ResourceTypeIconTooltip,
  useResourceType,
} from 'containers/App/hooks/useResourceType';
import { selectAlerts } from 'containers/Findings/Alerts/selectors';
import {
  renderAlertsStatus,
  renderAlertsStatusHeader,
} from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/getAlertColumns';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSharedState } from 'containers/SharedState/selectors';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';
import { useSearchParams } from 'hooks/useSearchParams';
import { toTitleCase } from 'utils/string';

export const useIndividualAlertsTableColumns = () => {
  const navigate = useNavigate();
  const snooze = useDisclosure();
  const remediate = useDisclosure();
  const exceptionModal = useDisclosure();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const { entityDetails } = useSelector(selectAlerts);
  const { updateActiveResource } = useActiveResourceActions();
  const { onOpenMetadata } = useActiveResourceContext();
  const { getCloudNativeName } = useResourceType();
  const { riskContext, alertListStatus } = useSelector(selectSharedState);
  const { renderStackedCloudAccountWithCloud } = useCloudAccountId();
  const { alertStatus } = useSearchParams();

  const handleRowClick = useCallback(
    (row, tabIndex) => {
      updateActiveResource({
        tabIndex: tabIndex,
        resourceType: row?.resource,
        resourceId: row?.entity_id,
        uuid: row?.entity_uuid,
        accountId: row?.account_id,
      });
      onOpenMetadata();
    },
    [onOpenMetadata, updateActiveResource],
  );

  const renderAction = ({ row }) => {
    const menuItems = [
      {
        label: 'Help me investigate',
        icon: <GraphIcon />,
        onClick: () => {
          const url = securityGraphRedirectUrl({
            alert_id: row.uuid,
            resource_id: row.entity_id,
            source_resource_uuid: row.entity_uuid,
            resource_type: row.resource,
            account_id: row.account_id,
            alert_rule_id: row.alert_rule_id,
          });
          navigate(url);
        },
      },
      {
        label: 'Resource Details',
        icon: <SummaryIcon />,
        onClick: () => {
          handleRowClick(row, 0);
        },
      },
      {
        label: 'Create ticket',
        icon: <TicketIcon />,
        onClick: () => {
          setCurrentRecord(row);
          ticketModal.onOpen();
        },
      },
      {
        label: 'Send notification',
        icon: <NotificationIcon />,
        onClick: () => {
          setCurrentRecord(row);
          notificationModal.onOpen();
        },
      },
      {
        label: row.snooze ? 'Remove snooze' : 'Snooze alert',
        icon: row.snooze ? <BellIcon /> : <SnoozeIcon />,
        onClick: () => {
          setCurrentRecord(row);
          snooze.onOpen();
        },
      },
      {
        label: 'Add exception',
        icon: <PolicyExceptionIcon />,
        onClick: () => {
          setCurrentRecord(row);
          exceptionModal.onOpen();
        },
      },
      {
        label: 'Remediate',
        icon: <RemediateIcon />,
        onClick: () => {
          setCurrentRecord(row);
          remediate.onOpen();
        },
      },
    ];

    return (
      <Box onClick={e => e.stopPropagation()}>
        <TableActionsMenu
          menuItems={menuItems}
          styles={{
            button: {
              isDisabled: row.status === 'RESOLVED',
            },
          }}
        />
      </Box>
    );
  };

  const name = {
    header: <Box pl="thLeftPaddingWithIcon">Entity Name</Box>,
    accessor: 'entity_name',
    render: ({ row }) => (
      <WithResourceIcon
        resourceType={row.resource ?? ''}
        iconSize="sm"
        iconTooltip={
          <ResourceTypeIconTooltip resourceType={row.resource ?? ''} />
        }
      >
        <StackedCell
          upper={row?.entity_name ?? row?.entity_id}
          lower={getCloudNativeName(row?.resource)}
        />
      </WithResourceIcon>
    ),
    styles: { width: '250px', cell: { maxWidth: '250px' } },
    sortKey: 'entity_name',
  };

  const finding = {
    header: 'Description',
    align: 'left',
    render: ({ row }) => (
      <CustomTooltip label={row.message}>
        <Text whiteSpace="wrap" noOfLines={2}>
          {row.message}
        </Text>
      </CustomTooltip>
    ),
    //styles: { width: '400px', cell: { maxWidth: '400px' } },
  };

  const account = {
    header: 'Account',
    render: ({ row }) => renderStackedCloudAccountWithCloud(row.account_id),
    align: 'left',
    styles: { width: '160px', cell: { maxWidth: '160px' } },
  };

  const context = {
    header: renderTableHeaderWithLoader('Risk Context', riskContext.isLoading),
    align: 'left',
    render: ({ row }) =>
      renderRiskContext(riskContext?.data?.[row?.entity_uuid]),
    styles: { width: '100px', cell: { maxWidth: '100px' } },
  };

  const blueprint = {
    header: 'Blueprints',
    align: 'left',
    accessor: 'blueprints',
    render: ({ value }) => {
      const renderLink = item => (
        <Box
          _hover={{ cursor: 'pointer' }}
          overflow="hidden"
          textOverflow="ellipsis"
          onClick={() => {
            navigate(`/policy-hub/blueprint/${item.blueprint_id}`);
          }}
        >
          {item?.blueprint_name}
        </Box>
      );
      return (
        <CustomTooltip
          label={
            <Stack>
              <Box borderBottom="1px solid" borderColor="orange" color="orange">
                POLICY BLUEPRINTS
              </Box>
              {map(value, o => (
                <Box>{o?.blueprint_name}</Box>
              ))}
            </Stack>
          }
        >
          <Stack spacing={0} color="primary">
            <HStack spacing={0}>
              {value[0] && renderLink(value[0])}
              <Box>{value[1] && ','}</Box>
            </HStack>
            <HStack spacing={0}>
              {value[1] && renderLink(value[1])}
              {value?.length > 2 && (
                <Box>
                  ,{' '}
                  <Tag
                    label={'+' + (value?.length - 2)}
                    styles={{
                      tag: { bg: 'primary' },
                      label: { color: 'white' },
                    }}
                  />
                </Box>
              )}
            </HStack>
          </Stack>
        </CustomTooltip>
      );
    },
    styles: { width: '200px', cell: { maxWidth: '200px' } },
  };

  const severity = {
    header: 'Severity',
    accessor: 'severity',
    render: ({ value }) => renderSeverityBar({ value, isInline: false }),
    align: 'left',
    styles: { width: '100px', cell: { maxWidth: '100px' } },
  };

  const state = {
    header: 'State',
    accessor: 'status',
    render: ({ value, row }) =>
      row?.snooze ? (
        <HStack spacing={1}>
          <Box>Snoozed</Box>
          <Box boxSize={4} color="primary">
            <ChatIcon />
          </Box>
        </HStack>
      ) : (
        toTitleCase(value)
      ),
    align: 'left',
    styles: { width: '100px', cell: { maxWidth: '100px' } },
  };

  const workflowStatus = {
    header: renderTableHeaderWithLoader(
      renderAlertsStatusHeader(),
      alertListStatus.isLoading,
    ),
    accessor: 'workflow_status',
    align: 'left',
    render: ({ row }) => {
      const tickets = alertListStatus?.data?.[row?.uuid];
      return renderAlertsStatus(tickets);
    },
    styles: { width: '120px', cell: { maxWidth: '120px' } },
  };

  const deployedIn = {
    header: renderTableHeaderWithLoader(
      'Deployed In',
      some(entityDetails, { isLoading: true }),
    ),
    align: 'left',
    render: ({ row }) => {
      const details = entityDetails?.[row?.resource];
      const data = details?.data?.[row?.entity_uuid];
      const vpc = data?.vpc || data?.vpc_id;
      return !details || details?.isLoading ? (
        '-'
      ) : (
        <StackedCell
          upper={vpc}
          lower={row?.region}
          lowerTooltip={
            <Stack spacing={1}>
              <Box color="orange">Region</Box>
              <Box>{row?.region}</Box>
            </Stack>
          }
          upperTooltip={
            <Stack spacing={1}>
              <Box color="orange">VPC</Box>
              <Box>{vpc}</Box>
            </Stack>
          }
        />
      );
    },
    styles: { width: '200px', cell: { maxWidth: '200px' } },
  };

  const commonColumns = [
    name,
    finding,
    account,
    /* {
      header: 'Category',
      accessor: 'alert_category',
      render: ({ value }) => toTitleCase(value),
      align: 'left',
    },*/
    deployedIn,
    severity,
  ];

  const resolved = [
    ...commonColumns,
    {
      header: 'Resolved at',
      accessor: 'resolved_at',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Reason',
      accessor: 'resolved_by',
      align: 'left',
    },
  ];

  const open = [
    ...commonColumns,
    state,
    context,
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  const snoozed = [
    ...commonColumns,
    state,
    {
      header: 'Snoozed Until',
      accessor: 'unsnooze_at',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  const exceptions = [
    ...commonColumns,
    {
      header: 'State',
      align: 'left',
      render: () => 'Exception',
    },
    blueprint,
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  let columns: TableColumnProps[];

  switch (alertStatus) {
    case 'Resolved':
      columns = resolved;
      break;
    case 'Snoozed':
      columns = snoozed;
      break;
    case 'Exception':
      columns = exceptions;
      break;
    default:
      columns = open;
  }

  return {
    columns,
    handleRowClick,
    snooze,
    exceptionModal,
    ticketModal,
    notificationModal,
    currentRecord,
    blueprint,
    remediate,
    account,
    deployedIn,
    context,
  };
};
