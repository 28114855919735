import {
  CloudAccountApiDeleteCloudAccountByUuidRequest,
  CloudAccountApiDiscoverInventoryRequest,
  CloudAccountApiGetCloudAccountByUuidRequest,
  CloudAccountApiUpdateCloudAccountRequest,
  CloudAccountGetResponse,
  CodeRepositoryApiOnboardCodeRepositoryRequest,
} from '@ariksa/cloud-account/api';
import {
  AlertWorkflowApiGetWorkflowsByClientRequest,
  AlertWorkflowResponse,
  ClientConfig,
  ClientResponse,
  ClientsApiAddClientRequest,
  ClientsApiDeleteClientConfigRequest,
  ClientsApiEditClientRequest,
  ClientsApiGetClientByIdRequest,
  ClientsApiGetClientDetailsRequest,
  ClientsApiGetJiraProjectsRequest,
  ClientsApiValidateClientConfigRequest,
  JiraProjects,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import forEach from 'lodash/forEach';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import {
  IdentityProviderNameServiceListResponse,
  IdentityProviderServiceCreateResponse,
  IdentityProviderServiceListResponse,
} from 'api/auth/api.pb';
import { onApiCall, onApiCallError, onApiCallSuccess } from 'api/call_status';
import {
  apiCallStateInitialState,
  apiCallStatusInitialValue,
} from 'api/initial_values';
import {
  CreateProviderProps,
  DeleteProviderProps,
} from 'containers/Setup/Integrations/types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { randomString } from 'utils/string';

import { ContainerState, IRepositoryInfo } from './types';

function devToolRepoInitialValue(): IRepositoryInfo {
  return {
    id: randomString(),
    account: {
      id: '',
      username: '',
    },
    repository: {
      id: '',
      name: '',
    },
    branch: {
      name: '',
    },
    policy: {
      id: '',
      name: '',
    },
    editing: true,
    deleted: false,
  };
}

// The initial state of the Integrations container
export const initialState: ContainerState = {
  activeClient: QueryState.init({} as any),
  client: QueryState.init({}),
  clients: {
    loading: false,
    error: false,
    slack: [],
    jira: [],
    servicenow: [],
    teams: [],
    email: [],
    sns: [],
    elasticsearch: [],
  },
  github: {
    authId: '',
    account: {
      data: {
        id: '',
        username: '',
        totalRepos: 0,
      },
      ...apiCallStatusInitialValue,
    },
    repos: {
      data: [],
      ...apiCallStatusInitialValue,
    },
    selectedRepos: [
      {
        id: randomString(),
        account: {
          id: '1324',
          username: 'ariksa',
        },
        repository: {
          id: 'make',
          name: 'notifications',
        },
        branch: {
          name: 'dev',
        },
        policy: {
          id: '',
          name: '',
        },
        editing: false,
        deleted: false,
      },
      {
        id: randomString(),
        account: {
          id: '1324',
          username: 'maitysubhasis',
        },
        repository: {
          id: 'make',
          name: 'webui',
        },
        branch: {
          name: 'master',
        },
        policy: {
          id: '',
          name: '',
        },
        editing: false,
        deleted: false,
      },
    ],
  },
  gitlab: {
    authId: '',
    account: {
      data: {
        id: '',
        username: '',
        totalRepos: 0,
      },
      ...apiCallStatusInitialValue,
    },
    repos: {
      data: [],
      ...apiCallStatusInitialValue,
    },
    selectedRepos: [],
  },
  bitbucket: {
    authId: '',
    account: {
      data: {
        id: '',
        username: '',
        totalRepos: 0,
      },
      ...apiCallStatusInitialValue,
    },
    repos: {
      data: [],
      ...apiCallStatusInitialValue,
    },
    selectedRepos: [],
  },
  client_name: '',
  currentTab: 'identityAccess',
  jiraProjectDetails: QueryState.init({} as JiraProjects),
  workflows: QueryState.init([]),
  validateClient: QueryState.init({}),

  codeRepositories: QueryState.init([]),

  sso: {
    providers: apiCallStateInitialState([]),
    providerNames: apiCallStateInitialState([]),
    providerForm: {
      formData: {
        providerConfig: {},
      },
      createProvider: apiCallStatusInitialValue,
    },
    deleteProvider: apiCallStatusInitialValue,
  },

  account: QueryState.init({}),
  rediscoverAccount: QueryState.init({}),
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    updateAccount(
      state,
      action: QueryAction<any, CloudAccountApiUpdateCloudAccountRequest>,
    ) {
      state.account = QueryState.next(state.account, action);
    },
    deleteAccount(
      state,
      action: QueryAction<any, CloudAccountApiDeleteCloudAccountByUuidRequest>,
    ) {
      state.account = QueryState.next(state.account, action);
    },
    rediscoverInventory(
      state,
      action: QueryAction<any, CloudAccountApiDiscoverInventoryRequest>,
    ) {
      state.rediscoverAccount = QueryState.next(
        state.rediscoverAccount,
        action,
      );
    },

    getAccountByID(
      state,
      action: QueryAction<
        CloudAccountGetResponse,
        CloudAccountApiGetCloudAccountByUuidRequest
      >,
    ) {
      state.account = QueryState.next(state.account, action);
    },

    updateProviderForm(state, action: PayloadAction<any>) {
      state.sso.providerForm.formData = {
        ...state.sso.providerForm.formData,
        ...action.payload,
      };
    },
    getProviderNames(state) {
      onApiCall(state.sso.providerNames);
    },
    getProviderNamesSuccess(
      state,
      action: PayloadAction<IdentityProviderNameServiceListResponse>,
    ) {
      state.sso.providerNames.data = action.payload.providers ?? [];
      onApiCallSuccess(state.sso.providerNames);
    },
    getProviderNamesError(state, action: PayloadAction<any>) {
      onApiCallError(state.sso.providerNames, action.payload);
    },

    getSSOProviders(state) {
      onApiCall(state.sso.providers);
    },
    getSSOProvidersSuccess(
      state,
      action: PayloadAction<IdentityProviderServiceListResponse>,
    ) {
      state.sso.providers.data = action.payload.identity_providers ?? [];
      onApiCallSuccess(state.sso.providers);
    },
    getSSOProvidersError(state, action: PayloadAction<any>) {
      onApiCallError(state.sso.providers, action.payload);
    },

    createSSOProvider(state, action: PayloadAction<CreateProviderProps>) {
      onApiCall(state.sso.providerForm.createProvider);
    },
    createSSOProviderSuccess(
      state,
      action: PayloadAction<IdentityProviderServiceCreateResponse>,
    ) {
      onApiCallSuccess(state.sso.providerForm.createProvider);
    },
    createSSOProviderError(state, action: PayloadAction<any>) {
      onApiCallError(state.sso.providerForm.createProvider, action.payload);
    },

    deleteSSOProvider(state, action: PayloadAction<DeleteProviderProps>) {
      onApiCall(state.sso.deleteProvider);
    },
    deleteSSOProviderSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.sso.deleteProvider);
    },
    deleteSSOProviderError(state, action: PayloadAction<any>) {
      onApiCallError(state.sso.deleteProvider, action.payload);
    },

    // Update Client Details
    updateClientById(
      state,
      action: QueryAction<ClientResponse, ClientsApiEditClientRequest>,
    ) {
      state.client = QueryState.next(state.client, action);
    },
    //create client
    createClient(state, action: QueryAction<any, ClientsApiAddClientRequest>) {
      state.client = QueryState.next(state.client, action);
    },
    //delete client
    deleteClient(
      state,
      action: QueryAction<any, ClientsApiDeleteClientConfigRequest>,
    ) {
      state.client = QueryState.next(state.client, action);
    },
    //validate client
    validateClient(
      state,
      action: QueryAction<ClientConfig, ClientsApiValidateClientConfigRequest>,
    ) {
      state.validateClient = QueryState.next(state.validateClient, action);
    },

    getAlertWorkflowAttachedToClient(
      state,
      action: QueryAction<
        AlertWorkflowResponse[],
        AlertWorkflowApiGetWorkflowsByClientRequest
      >,
    ) {
      state.workflows = QueryState.next(state.workflows, action);
    },

    // Get Client Details by ID
    getClientDetailsById(
      state,
      action: QueryAction<ClientResponse, ClientsApiGetClientByIdRequest>,
    ) {
      state.activeClient = QueryState.next(state.activeClient, action);
    },

    //update active client (needed in slack and jira)
    updateActiveClient(state, action: PayloadAction<any>) {
      state.activeClient.data = action.payload;
    },

    //load projects and users for jira
    loadJiraProjectDetails(
      state,
      action: QueryAction<JiraProjects, ClientsApiGetJiraProjectsRequest>,
    ) {
      state.jiraProjectDetails = QueryState.next(
        state.jiraProjectDetails,
        action,
      );
    },
    resetClientInfo(state) {
      state.jiraProjectDetails = initialState.jiraProjectDetails;
    },

    resetActiveClient(state) {
      state.activeClient = initialState.activeClient;
    },

    updateClientName(state, action: PayloadAction<string>) {
      state.client_name = action.payload;
    },
    updateCurrentTab(state, action: PayloadAction<any>) {
      state.currentTab = action.payload;
    },

    //get clients
    loadClients(
      state,
      action: PayloadAction<ClientsApiGetClientDetailsRequest>,
    ) {
      const { clientName } = action.payload;
      if (clientName) {
        state.clients[clientName] = [];
        state.client_name = clientName;
      }
      onApiCall(state.clients);
    },
    clientsLoaded(
      state,
      action: PayloadAction<{ client_name: string; data: any }>,
    ) {
      if (action.payload.client_name)
        state.clients[state.client_name] = action.payload.data;
      else {
        state.clients.slack = [];
        state.clients.email = [];
        state.clients.servicenow = [];
        state.clients.teams = [];
        state.clients.jira = [];
        state.clients.sns = [];
        state.clients.elasticsearch = [];
        forEach(action.payload.data, o => state.clients[o.client_name].push(o));
      }
      onApiCallSuccess(state.clients);
    },
    clientsLoadingError(state, action: PayloadAction<any>) {
      state.clients[action.payload.client_name] = [];
      onApiCallError(state.clients, action.payload);
    },

    //code repository
    onboardCodeRepository(
      state,
      action: QueryAction<any, CodeRepositoryApiOnboardCodeRepositoryRequest>,
    ) {
      state.account = QueryState.next(state.account, action);
    },

    addNewGithubAccount(state, action: PayloadAction<any>) {
      state.github.authId = action.payload.authId;
      onApiCall(state.github.account);
    },
    addNewGithubAccountSuccess(state, action: PayloadAction<any>) {
      const { authId, ...rest } = action.payload;
      state.github.authId = authId;
      state.github.account.data = rest;
      onApiCallSuccess(state.github.account);
    },
    addNewGithubAccountError(state, action: PayloadAction<any>) {
      onApiCallError(state.github.account, action.payload);
    },

    loadGithubRepos(state, action: PayloadAction<any>) {
      onApiCall(state.github.repos);
    },
    loadGithubReposSuccess(state, action: PayloadAction<any>) {
      // console.log(state.github.repos.data);
      state.github.repos.data = action.payload.map(
        ({ id, name, clone_url }) => ({
          id: id.toString(),
          name,
          clone_url,
          branches: {
            data: [],
            ...apiCallStatusInitialValue,
          },
        }),
      );
      onApiCallSuccess(state.github.repos);
    },
    loadGithubReposError(state, action: PayloadAction<any>) {
      onApiCallError(state.github.repos, action.payload);
    },

    addNewGithubRepo(state, action: PayloadAction<any>) {
      const newRepo = devToolRepoInitialValue();
      newRepo.account.id = state.github.account.data.id.toString();
      newRepo.account.username = state.github.account.data.username;
      state.github.selectedRepos.push(newRepo);
    },
    addNewGithubRepoSuccess(state, action: PayloadAction<any>) {},
    addNewGithubRepoError(state, action: PayloadAction<any>) {},

    removeGitHubRepo(state, action: PayloadAction<any>) {
      const { rowId } = action.payload;
      const repo = state.github.selectedRepos.find(r => r.id === rowId);
      if (repo) {
        repo.deleted = true;
      }
    },

    updateGithubRepoName(state, action: PayloadAction<any>) {
      const { rowId, repoId } = action.payload;

      const selectedRepo = state.github.selectedRepos.find(r => r.id === rowId);
      const repo = state.github.repos.data.find(r => r.id === repoId);
      if (selectedRepo && repo) {
        selectedRepo.repository.id = repoId;
        selectedRepo.repository.name = repo.name;
      }
    },
    updateGithubRepoNameSuccess(state, action: PayloadAction<any>) {},
    updateGithubRepoNameError(state, action: PayloadAction<any>) {},

    loadGithubRepoBranches(state, action: PayloadAction<any>) {
      const { repoId } = action.payload;
      const repo = state.github.repos.data.find(r => r.id === repoId);
      if (repo) {
        onApiCall(repo.branches);
      }
    },
    loadGithubRepoBranchesSuccess(state, action: PayloadAction<any>) {
      const { repoId, branches } = action.payload;
      const repo = state.github.repos.data.find(r => r.id === repoId);
      if (repo) {
        repo.branches.data = branches;
        onApiCallSuccess(repo.branches);
      }
    },
    loadGithubRepoBranchesError(state, action: PayloadAction<any>) {
      const { repoId, error } = action.payload;
      const repo = state.github.repos.data.find(r => r.id === repoId);
      if (repo) {
        onApiCallError(repo.branches, error);
      }
    },

    updateGithubRepoBranchName(state, action: PayloadAction<any>) {
      const { rowId, branchName } = action.payload;
      const selectedRepo = state.github.selectedRepos.find(r => r.id === rowId);
      if (selectedRepo) {
        selectedRepo.branch.name = branchName;
      }
    },

    //  GITLAB integrations

    addNewGitLabAccount(state, action: PayloadAction<any>) {
      state.gitlab.authId = action.payload.authId;
      onApiCall(state.gitlab.account);
    },
    addNewGitLabAccountSuccess(state, action: PayloadAction<any>) {
      const { authId, ...rest } = action.payload;
      state.gitlab.authId = authId;
      state.gitlab.account.data = rest;
      onApiCallSuccess(state.gitlab.account);
    },
    addNewGitLabAccountError(state, action: PayloadAction<any>) {
      onApiCallError(state.gitlab.account, action.payload);
    },

    loadGitLabRepos(state, action: PayloadAction<any>) {
      onApiCall(state.gitlab.repos);
    },
    loadGitLabReposSuccess(state, action: PayloadAction<any>) {
      state.gitlab.repos.data = action.payload.map(({ id, name }) => ({
        id: id.toString(),
        name,
        branches: {
          data: [],
          ...apiCallStatusInitialValue,
        },
      }));
      onApiCallSuccess(state.gitlab.repos);
    },
    loadGitLabReposError(state, action: PayloadAction<any>) {
      onApiCallError(state.gitlab.repos, action.payload);
    },

    addNewGitLabRepo(state, action: PayloadAction<any>) {
      const newRepo = devToolRepoInitialValue();
      newRepo.account.id = state.gitlab.account.data.id.toString();
      newRepo.account.username = state.gitlab.account.data.username;
      state.gitlab.selectedRepos.push(newRepo);
    },
    addNewGitLabRepoSuccess(state, action: PayloadAction<any>) {},
    addNewGitLabRepoError(state, action: PayloadAction<any>) {},

    removeGitLabRepo(state, action: PayloadAction<any>) {
      const { rowId } = action.payload;
      const repo = state.gitlab.selectedRepos.find(r => r.id === rowId);
      if (repo) {
        repo.deleted = true;
      }
    },

    updateGitLabRepoName(state, action: PayloadAction<any>) {
      const { rowId, repoId } = action.payload;

      const selectedRepo = state.gitlab.selectedRepos.find(r => r.id === rowId);
      const repo = state.gitlab.repos.data.find(r => r.id === repoId);
      if (selectedRepo && repo) {
        selectedRepo.repository.id = repoId;
        selectedRepo.repository.name = repo.name;
      }
    },
    updateGitLabRepoNameSuccess(state, action: PayloadAction<any>) {},
    updateGitLabRepoNameError(state, action: PayloadAction<any>) {},

    updateGitLabRepoBranches(state, action: PayloadAction<any>) {
      const { repoId } = action.payload;
      const repo = state.gitlab.repos.data.find(r => r.id === repoId);
      if (repo) {
        onApiCall(repo.branches);
      }
    },
    loadGitLabRepoBranchesSuccess(state, action: PayloadAction<any>) {
      const { repoId, branches } = action.payload;
      const repo = state.gitlab.repos.data.find(r => r.id === repoId);
      if (repo) {
        repo.branches.data = branches;
        onApiCallSuccess(repo.branches);
      }
    },
    loadGitLabRepoBranchesError(state, action: PayloadAction<any>) {
      const { repoId, error } = action.payload;
      const repo = state.gitlab.repos.data.find(r => r.id === repoId);
      if (repo) {
        onApiCallError(repo.branches, error);
      }
    },

    updateGitLabRepoBranchName(state, action: PayloadAction<any>) {
      const { rowId, branchName } = action.payload;
      const selectedRepo = state.gitlab.selectedRepos.find(r => r.id === rowId);
      if (selectedRepo) {
        selectedRepo.branch.name = branchName;
      }
    },

    //  Bitbucket integrations

    addNewBitbucketAccount(state, action: PayloadAction<any>) {
      state.bitbucket.authId = action.payload.authId;
      onApiCall(state.bitbucket.account);
    },
    addNewBitbucketAccountSuccess(state, action: PayloadAction<any>) {
      const { authId, ...rest } = action.payload;
      state.bitbucket.authId = authId;
      state.bitbucket.account.data = rest;
      onApiCallSuccess(state.bitbucket.account);
    },
    addNewBitbucketAccountError(state, action: PayloadAction<any>) {
      onApiCallError(state.bitbucket.account, action.payload);
    },

    loadBitbucketRepos(state, action: PayloadAction<any>) {
      onApiCall(state.github.repos);
    },
    loadBitbucketReposSuccess(state, action: PayloadAction<any>) {
      state.bitbucket.repos.data = action.payload.map(({ uuid, name }) => ({
        id: uuid,
        name,
        branches: {
          data: [],
          ...apiCallStatusInitialValue,
        },
      }));
      onApiCallSuccess(state.bitbucket.repos);
    },
    loadBitbucketReposError(state, action: PayloadAction<any>) {
      onApiCallError(state.bitbucket.repos, action.payload);
    },

    addNewBitbucketRepo(state, action: PayloadAction<any>) {
      const newRepo = devToolRepoInitialValue();
      newRepo.account.id = state.bitbucket.account.data.id;
      newRepo.account.username = state.bitbucket.account.data.username;
      state.bitbucket.selectedRepos.push(newRepo);
    },
    removeBitbucketRepo(state, action: PayloadAction<any>) {
      const { rowId } = action.payload;
      const repo = state.bitbucket.selectedRepos.find(r => r.id === rowId);
      if (repo) {
        repo.deleted = true;
      }
    },
    addNewBitbucketRepoSuccess(state, action: PayloadAction<any>) {},
    addNewBitbucketRepoError(state, action: PayloadAction<any>) {},

    loadBitbucketRepoBranches(state, action: PayloadAction<any>) {
      const { repoId } = action.payload;
      const repo = state.bitbucket.repos.data.find(r => r.id === repoId);
      if (repo) {
        onApiCall(repo.branches);
      }
    },
    loadBitbucketRepoBranchesSuccess(state, action: PayloadAction<any>) {
      const { repoId, branches } = action.payload;
      const repo = state.bitbucket.repos.data.find(r => r.id === repoId);
      if (repo) {
        repo.branches.data = branches;
        onApiCallSuccess(repo.branches);
      }
    },
    loadBitbucketRepoBranchesError(state, action: PayloadAction<any>) {
      const { repoId, error } = action.payload;
      const repo = state.bitbucket.repos.data.find(r => r.id === repoId);
      if (repo) {
        onApiCallError(repo.branches, error);
      }
    },

    updateBitbucketRepoName(state, action: PayloadAction<any>) {
      const { rowId, repoId } = action.payload;

      const selectedRepo = state.bitbucket.selectedRepos.find(
        r => r.id === rowId,
      );
      const repo = state.bitbucket.repos.data.find(r => r.id === repoId);
      if (selectedRepo && repo) {
        selectedRepo.repository.id = repoId;
        selectedRepo.repository.name = repo.name;
      }
    },
    updateBitbucketRepoNameSuccess(state, action: PayloadAction<any>) {},
    updateBitbucketRepoNameError(state, action: PayloadAction<any>) {},

    updateBitbucketRepoBranchName(state, action: PayloadAction<any>) {
      const { rowId, branchName } = action.payload;
      const selectedRepo = state.bitbucket.selectedRepos.find(
        r => r.id === rowId,
      );
      if (selectedRepo) {
        selectedRepo.branch.name = branchName;
      }
    },
    updateBitbucketRepoBranchNameSuccess(state, action: PayloadAction<any>) {},
    updateBitbucketRepoBranchNameError(state, action: PayloadAction<any>) {},
  },
});

function findAccountById(accounts, uuid: string) {
  return accounts.find(a => a.data.uuid === uuid);
}

export const { actions, reducer, name: sliceKey } = integrationsSlice;
