import React, { FC, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Accordion, Box } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { usePageContentContext } from 'components/Layout';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { InstanceSummaryByID } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/ContainerVulnerability/InstanceSummaryByID';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

interface Props {
  vulnerability?: boolean;
}

export const ContainerVulnerability: FC<Props> = props => {
  const { vulnerability } = props;
  const {
    containerVulnerability,
    resourceInsight,
    resourceType,
    resourceDetails,
    resourceId,
  } = useSelector(selectActiveResource);

  const { contentHeight } = usePageContentContext();

  const dispatch = useDispatch();

  useEffect(() => {
    let instance_ids: string[] = [resourceId];
    if (
      resourceType === NativeResources.ElasticKubernetesService ||
      resourceType === NativeResources.Gcpgke ||
      resourceType === NativeResources.Aks
    ) {
      instance_ids = map(
        resourceInsight.data?.instances,
        o => o.split(',')?.[0]?.split('instance-id: ')[1],
      );
    } else if (
      resourceType === NativeResources.ElasticContainerService ||
      resourceType === NativeResources.EcsCluster
    ) {
      instance_ids = map(
        resourceDetails.data?.config?.containerInstances,
        o => o?.ec2InstanceId,
      );
    }
    dispatch(actions.resetContainerVulnerability());

    if (!!instance_ids?.length) {
      dispatch(
        actions.getContainerVulnerability({
          q: {
            getContainersRequest: { instance_ids, vulnerable: vulnerability },
          },
        }),
      );
    }
  }, [
    resourceType,
    resourceInsight.data,
    dispatch,
    resourceDetails.data,
    vulnerability,
    resourceId,
  ]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetContainerVulnerability());
    };
  }, [dispatch]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Container ID</Box>,
      accessor: 'container_id',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={NativeResources.Container}
          tooltip={value}
        >
          {value}
        </WithResourceIcon>
      ),
    },
    {
      header: 'Container Image',
      align: 'left',
      accessor: 'image',
    },
    {
      header: 'POD Name',
      align: 'left',
      accessor: 'pod',
    },
    { header: 'Namespace', align: 'left', accessor: 'namespace' },
  ];
  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      <MetaAccordionItem
        height={contentHeight - 250 + 'px'}
        iconType={NativeResources.Container}
        label={
          (vulnerability ? 'VULNERABILITIES DETECTED IN ' : '') +
          'RUNNING CONTAINERS'
        }
        onClickDownload={() => {}}
        totalCount={containerVulnerability.data?.length ?? 0}
        panel={
          <Table
            columns={columns}
            data={containerVulnerability.data ?? []}
            /*onRowClick={row =>
              dispatch(
                actions.getInstanceSummary({
                  q: {
                    instanceId: row?.instance_id,
                    containerId: row.container_id,
                  },
                }),
              )
            }*/
            cursor="pointer"
            isLoading={containerVulnerability.isLoading}
            subComponent={({ row, expanded }) => (
              <InstanceSummaryByID row={row} isExpanded={expanded} />
            )}
          />
        }
        iconBGColor="primary"
        isLoading={
          resourceInsight.isLoading || containerVulnerability.isLoading
        }
      />
    </Accordion>
  );
};
