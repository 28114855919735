import React, { FC, useEffect, useReducer } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import camelcase from 'camelcase';
import {
  filter,
  forEach,
  includes,
  isEmpty,
  map,
  split,
  toArray,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect, useLocation } from 'react-use';
import { customTheme } from 'theme';

import { severityOptions } from 'components/DataDisplay/Utils/utils';
import { Select } from 'components/index';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { useSearchParamFunctions } from 'containers/App/hooks/useSearchParamFunctions';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useSearchParams } from 'hooks/useSearchParams';
import { toTitleCase } from 'utils/string';

export const AlertTableFilter: FC = () => {
  const dispatch = useDispatch();
  const { currentCategory, alertCategories } = useSelector(
    selectFindingsOverview,
  );
  const { alertResourceTypes, aiParamExtraction } = useSelector(
    selectSharedState,
  );
  const { getAlertResourceTypeOptionsWithAll } = useResourceType();
  const { updateValue } = useSearchParamFunctions();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useSearchParams();
  const key = 'findings_overview_';

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      alertCategoryOptions: [],
      resourceTypeOptions: [],
      resourceType: [],
      severity: [],
      alertCategory: [],
    },
  );

  //set parameters from localstorage to url
  useDeepCompareEffect(() => {
    if (!params?.redirect) {
      const storageKey = key + camelcase(currentCategory as string);
      const alertCategory = localStorage.getItem(
        storageKey + '_alert_category',
      );
      const severity = localStorage.getItem(storageKey + '_severity');
      const resourceType = localStorage.getItem(storageKey + '_resource_type');

      let searchParams = '';
      const setValue = (f, v) => {
        searchParams = searchParams + (!!searchParams ? '&' : '') + f + '=' + v;
      };

      if (!!alertCategory) setValue('alertCategory', alertCategory);
      if (!!severity) setValue('severity', severity);
      if (!!resourceType) setValue('resourceType', resourceType);
      if (!!searchParams)
        navigate(
          location.pathname + '?tab=' + currentCategory + '&' + searchParams,
        );
    }
  }, [navigate, location.pathname, currentCategory]);

  useDeepCompareEffect(() => {
    if (!isEmpty(aiParamExtraction.data)) {
      const data = aiParamExtraction.data;
      const alertCategory = !!data?.alert_category
        ? [data?.alert_category]
        : !!data?.alertCategories
        ? data?.alertCategories
        : undefined;
      const severity = !!data?.severity
        ? [data?.severity]
        : !!data?.severities
        ? split(data?.severities, ',')
        : undefined;
      const resourceType = !!data?.resource_type
        ? [data?.resource_type]
        : !!data?.resourceTypes
        ? split(data?.resourceTypes, ',')
        : undefined;
      //const categoryClass = data?.category_class || undefined;
      let searchParams = '';
      const setValue = (f, v) => {
        searchParams = searchParams + (!!searchParams ? '&' : '') + f + '=' + v;
      };

      if (!!alertCategory) setValue('alertCategory', alertCategory);
      if (!!severity) setValue('severity', severity);
      if (!!resourceType) setValue('resourceType', resourceType);
      if (!!searchParams)
        navigate(
          location.pathname + '?tab=' + currentCategory + '&' + searchParams,
        );
    }
  }, [aiParamExtraction.data, currentCategory]);

  //set resource type options
  useEffect(() => {
    const options = getAlertResourceTypeOptionsWithAll(alertResourceTypes.data);
    updateState({
      resourceTypeOptions: options,
    });
  }, [alertResourceTypes.data, getAlertResourceTypeOptionsWithAll]);

  //set category options
  useEffect(() => {
    const categories = new Set();
    if (currentCategory === 'AllFindings')
      forEach(alertCategories.data, categoryClass => {
        forEach(categoryClass, (value, key) => categories.add(key));
      });
    else
      forEach(alertCategories.data?.[currentCategory], (o, key) =>
        categories.add(key),
      );

    updateState({
      alertCategoryOptions: [
        ...map(toArray(categories), o => ({
          label: toTitleCase(o as string),
          value: o,
        })),
      ],
    });
  }, [alertCategories.data, currentCategory]);

  //set resource types
  useEffect(() => {
    const resourceTypes = !!params.resourceType
      ? split(params.resourceType, ',')
      : [];
    const selected =
      filter(state.resourceTypeOptions, o =>
        includes(resourceTypes, o.value),
      ) || [];
    updateState({
      resourceType: selected,
    });
  }, [params.resourceType, state.resourceTypeOptions]);

  //set severities
  useEffect(() => {
    const severity = !!params.severity ? split(params.severity, ',') : [];
    updateState({
      severity:
        filter(severityOptions, o =>
          includes(severity, o.value?.toUpperCase()),
        ) || [],
    });
  }, [state.severityOptions, params.severity]);

  //set alert category
  useEffect(() => {
    const categories = params.alertCategory;
    const alertCategories = !!categories
      ? map(split(categories, ','), o => ({
          label: toTitleCase(o),
          value: o,
        }))
      : [];
    updateState({
      alertCategory: alertCategories,
    });
  }, [dispatch, params.alertCategory, state.alertCategoryOptions]);

  const styles = {
    menu: provided => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      right: 0,
    }),
    placeholder: st => ({
      ...st,
      color: customTheme.colors.gray['400'],
    }),
  };

  return (
    <HStack>
      <Box w={48} zIndex={900}>
        <Select
          options={severityOptions}
          value={state.severity}
          onChange={s =>
            updateValue(
              'severity',
              map(s, o => o?.value?.toUpperCase()),
              key + camelcase(currentCategory as string) + '_severity',
            )
          }
          showIconInValueContainer
          isMulti
          showTotalSelected
          isAFilter
          styles={styles}
          placeholder="All severities"
          selectedMessage={
            state.severity?.length === 1 ? 'severity' : 'severities'
          }
        />
      </Box>

      <Box w={60} zIndex={900}>
        <Select
          options={state.alertCategoryOptions}
          value={state.alertCategory}
          isLoading={alertCategories.isLoading}
          onChange={s =>
            updateValue(
              'alertCategory',
              map(s, o => o?.value),
              key + camelcase(currentCategory as string) + '_alert_category',
            )
          }
          showIconInValueContainer
          isMulti
          showTotalSelected
          isAFilter
          styles={styles}
          placeholder="All alert categories"
          selectedMessage={
            'alert ' +
            (state.alertCategory?.length === 1 ? 'category' : 'categories')
          }
        />
      </Box>

      <Box w={56} zIndex={900}>
        <Select
          options={state.resourceTypeOptions}
          value={state.resourceType}
          onChange={s =>
            updateValue(
              'resourceType',
              map(s, o => o.value),
              key + camelcase(currentCategory as string) + '_resource_type',
            )
          }
          isLoading={alertResourceTypes.isLoading}
          showIconInValueContainer
          //menuPortalTarget={document.body}
          menuShouldScrollIntoView
          isMulti
          showTotalSelected
          isAFilter
          styles={styles}
          placeholder="All resource types"
          selectedMessage={
            'resource type' + (state.resourceType?.length === 1 ? '' : 's')
          }
        />
      </Box>
    </HStack>
  );
};
