import React, { FC, useEffect, useState } from 'react';

import { DataSourcesWithData } from '@ariksa/data-scanning/api';
import { Box } from '@chakra-ui/react';
import { map } from 'lodash';

import { colorMapForCharts } from 'components/DataDisplay';
import { PieChart } from 'components/Visualization';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { renderPieChartLegend } from 'containers/Visibility/Data/Components/Dashboard/utils';

interface Props {
  dataSources: DataSourcesWithData;
  isLoading?: boolean;
}

export const DataFootprintBySourceTypesComponent: FC<Props> = props => {
  const { dataSources, isLoading } = props;
  const { service_sources, total = 0 } = dataSources;
  const { getResourceAlias } = useResourceType();
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!!service_sources) {
      setData(
        map(service_sources, o => ({
          name: getResourceAlias(o.service),
          value: o.source_count,
        })),
      );
    }
  }, [service_sources, getResourceAlias]);

  return (
    <DashboardWidget
      label="Data Footprint By Source Types"
      subHeader={
        data?.length +
        ' source type' +
        (data?.length === 1 ? ', ' : 's, ') +
        total +
        ' source' +
        (total === 1 ? '' : 's')
      }
      content={
        <Box w="full" h="full" overflow="hidden">
          <PieChart
            isLoading={isLoading}
            data={data}
            dataKey="value"
            total={data?.length}
            outerRadius="85%"
            innerRadius="76%"
            activeShape={false}
            colorMap={colorMapForCharts}
            styles={{
              pie: { cx: '45%' },
              legend: {
                wrapperStyle: {
                  width: '200px',
                },
              },
            }}
            renderLegend={renderPieChartLegend}
            centerLabel={'source type' + (data?.length === 1 ? '' : 's')}
          />
          {/**/}
        </Box>
      }
    />
  );
};
