import React, { useState } from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { StackedCell, WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { ViewAllIcon } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { DashboardWidgetRightIcon } from 'containers/Dashboard/utils/utils';
import { AllEOLSoftwareDrawer } from 'containers/Dashboard/VulnerabilityOverview/Components/EOLSoftware/AllEOLSoftwareDrawer';
import { IndividualEOLSoftwareDrawer } from 'containers/Dashboard/VulnerabilityOverview/Components/EOLSoftware/IndividualEOLSoftwareDrawer';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { formatDate } from 'utils/date';

export const EndOfLifeSoftware: React.FC = () => {
  const { eolSoftware } = useSelector(selectVulnerabilityOverview);
  const allEolSoftwareDisclosure = useDisclosure();
  const eolSoftwareDisclosure = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});

  const columns = [
    {
      header: <Box pl={9}>SOFTWARE</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row.name}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
        >
          <StackedCell upper={row.name} lower={row.version} />
        </WithResourceIcon>
      ),
    },
    {
      header: 'EOL',
      render: ({ row }) => formatDate(row.end_of_life, 'DD-MMM-YYYY'),
    },
    {
      header: 'COUNT',
      render: ({ row }) => formatNumber(row.occurrences, 1),
      styles: {
        td: { w: '20%' },
        cell: { textAlign: 'right', w: '53%' },
        header: { textAlign: 'center' },
      },
    },
  ];

  return (
    <ActiveResourceProvider>
      <DashboardWidget
        label="End-of-life Software (OS and Apps)"
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
        rightHeader={
          <DashboardWidgetRightIcon
            onClick={() => allEolSoftwareDisclosure.onOpen()}
            icon={<ViewAllIcon p={1} />}
            iconColor="primary"
            iconTooltip="View all"
          />
        }
        content={
          <>
            <DashboardTable
              columns={columns}
              data={eolSoftware.data?.items}
              styles={{ td: { py: 2 } }}
              onRowClick={row => {
                setCurrentRecord(row);
                eolSoftwareDisclosure.onOpen();
              }}
              isLoading={eolSoftware.isLoading}
            />
            {allEolSoftwareDisclosure.isOpen && (
              <AllEOLSoftwareDrawer
                isOpen={allEolSoftwareDisclosure.isOpen}
                onClose={allEolSoftwareDisclosure.onClose}
              />
            )}
            {eolSoftwareDisclosure.isOpen && (
              <IndividualEOLSoftwareDrawer
                isOpen={eolSoftwareDisclosure.isOpen}
                onClose={eolSoftwareDisclosure.onClose}
                currentRecord={currentRecord}
              />
            )}
          </>
        }
      />
    </ActiveResourceProvider>
  );
};
