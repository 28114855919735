import React from 'react';

import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { ComputeEntities } from 'containers/Dashboard/CloudPostureOverview/Components/ComputeEntities';
import { Credentials } from 'containers/Dashboard/CloudPostureOverview/Components/Credentials';
import { DataSources } from 'containers/Dashboard/CloudPostureOverview/Components/DataSources';
import { Domains } from 'containers/Dashboard/CloudPostureOverview/Components/Domains';
import { HighlyPrivilegedMachineIdentities } from 'containers/Dashboard/CloudPostureOverview/Components/HighlyPrivilegedMachineIdentities';
import { InfrastructureFindingsToPrioritize } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructureFindingsToPrioritize';
import { InfrastructurePostureFindings } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructurePostureFindings';
import { InfrastructurePostureSummary } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructurePostureSummary';
import { InfrastructureThatLacksEncryption } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructureThatLacksEncryption';
import { InsightsForCloudNetworking } from 'containers/Dashboard/CloudPostureOverview/Components/InsightsForCloudNetworking';
import { NetworkEntities } from 'containers/Dashboard/CloudPostureOverview/Components/NetworkEntities';
import { TopPubliclyAccessibleMachinesAndDataSources } from 'containers/Dashboard/CloudPostureOverview/Components/TopPubliclyAccessibleMachinesAndDataSources';
import { WellKnownPorts } from 'containers/Dashboard/CloudPostureOverview/Components/WellKnownPorts';
import { VulnerablePubliclyExposedEntities } from 'containers/Dashboard/utils/VulnerablePubliclyExposedEntities';

export const overviewDashboardWidgets: Record<string, WidgetProps> = {
  summary: {
    flex: true,
    components: {
      content: <InfrastructurePostureSummary />,
    },
  },
  computeEntities: {
    flex: true,
    components: {
      content: <ComputeEntities />,
    },
  },
  dataSources: {
    flex: true,
    components: {
      content: <DataSources />,
    },
  },
  networkEntities: {
    flex: true,
    components: {
      content: <NetworkEntities />,
    },
  },
  domains: {
    flex: true,
    components: {
      content: <Domains />,
    },
  },
  credentials: {
    flex: true,
    components: {
      content: <Credentials />,
    },
  },
  infrastructureFindingsToPrioritize: {
    flex: true,
    components: {
      content: <InfrastructureFindingsToPrioritize />,
    },
  },
  infrastructurePostureFindings: {
    flex: true,
    components: {
      content: <InfrastructurePostureFindings />,
    },
  },
  publiclyAccessibleEntities: {
    flex: true,
    components: {
      content: <TopPubliclyAccessibleMachinesAndDataSources />,
    },
  },
  vulnerablePubliclyExposedEntities: {
    flex: true,
    components: {
      content: (
        <VulnerablePubliclyExposedEntities label="Vulnerable Publicly Exposed Entities" />
      ),
    },
  },
  infrastructureThatLacksEncryption: {
    flex: true,
    components: {
      content: <InfrastructureThatLacksEncryption />,
    },
  },
  highlyPrivilegedMachineIdentities: {
    flex: true,
    components: {
      content: <HighlyPrivilegedMachineIdentities />,
    },
  },
  wellKnownPorts: {
    flex: true,
    components: {
      content: <WellKnownPorts />,
    },
  },
  insightsForCloudNetworking: {
    flex: true,
    components: {
      content: <InsightsForCloudNetworking />,
    },
  },
};
