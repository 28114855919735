import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  HStack,
  ListItem,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import { map, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { CloudInsightDrawer } from 'containers/App/CloudInsight';
import { selectApp } from 'containers/App/selectors';
import { actions } from 'containers/App/slice';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { dashboardWidgetLineStyles } from 'containers/Dashboard/utils/styles';
import { DashboardWidgetTooltipIcon } from 'containers/Dashboard/utils/utils';
import { toTitleCase } from 'utils/string';

export const RuntimeInsights: React.FC = () => {
  const { runtimeInsights } = useSelector(selectDashboard);
  const { isCloudInsightDrawerOpen } = useSelector(selectApp);
  const [data, setData] = useState<Record<string, any>[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(orderBy(runtimeInsights.data, ['count'], ['desc']));
  }, [runtimeInsights.data]);

  const renderItem = item => {
    return (
      <HStack
        /*onClick={() => {
        navigate(
          '/findings?tab=' +
          (!!categoryClass ? categoryClass : 'AllFindings') +
          '&alertCategory=' +
          category +
          '&severity=' +
          severity?.toUpperCase() +
          '&redirect=true',
        );
      }}*/
        cursor="pointer"
        spacing={6}
        onClick={() => {
          dispatch(
            actions.updateRuntimeInsightsCategories([
              {
                label: item.category,
                value: item.category,
              },
            ]),
          );
          dispatch(actions.updateIsCloudInsightDrawerOpen(true));
        }}
        {...dashboardWidgetLineStyles}
      >
        <Box h="full" w={1} bg={item.severity.toLowerCase()} borderRadius={2} />
        <Stack spacing={0} w="full" py={1}>
          <HStack justify="space-between" w="full">
            <Box>{toTitleCase(item.category)}</Box>
            <Box>{item.count}</Box>
          </HStack>

          <Box color={customTheme.colors.gray['250']} w="full">
            {toTitleCase(item.severity)} severity
          </Box>
        </Stack>
      </HStack>
    );
  };

  return (
    <>
      <DashboardWidget
        label="Runtime Insights"
        subHeader="Last 30 days"
        isLoading={runtimeInsights.isLoading}
        content={
          <Flex w="full" h="full">
            <Stack spacing={0} w="full" h="full">
              {map(data, o => renderItem(o))}
            </Stack>
          </Flex>
        }
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
        tooltip={{
          tooltip: (
            <Stack spacing={3}>
              <Box>
                Ariksa correlates runtime info, logs, activity and configuration
                data to detect insights that proactively assess risks and
                imminent threats.
              </Box>
              <Box>For deeper insights:</Box>
              <UnorderedList color="primary" spacing={2}>
                <ListItem pl={2}>Retain user activity logs</ListItem>
                <ListItem pl={2}>Retain network activity logs</ListItem>
                <ListItem pl={2}>Deploy runtime sensors</ListItem>
              </UnorderedList>
            </Stack>
          ),
          header: 'Runtime Insights for Threats',
          showArrow: false,
          button: <DashboardWidgetTooltipIcon />,
        }}
      />
      {isCloudInsightDrawerOpen && (
        <CloudInsightDrawer
          isOpen={isCloudInsightDrawerOpen}
          onClose={() =>
            dispatch(actions.updateIsCloudInsightDrawerOpen(false))
          }
        />
      )}
    </>
  );
};
