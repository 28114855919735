import { NativeResources } from '@ariksa/inventory-core/api';
import { Severity } from '@ariksa/notification';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth/lib/SmoothGraphics';
import { Container } from 'pixi.js';

import { IconTypes } from 'components/Icons';
import { NodeElements } from 'components/Visualization/PixiGraph/common/NodeElements';
import { NodeType } from 'components/Visualization/PixiGraph/common/NodeType';
import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import {
  colors,
  SQUARE_NODE_SIZE,
} from 'components/Visualization/PixiGraph/style';

import { NodeRenderOptions } from '../core/types';

import { nodeColor } from './utils/nodeColor';

export class CommonNode {
  static isInternet(node: AGraphNode) {
    return node.data?.native_name === 'Internet';
  }

  static isSeverity(node: AGraphNode) {
    return node.data?.native_name === 'Severity';
  }

  static isOs(node: AGraphNode) {
    return node.data?.native_name === 'OS';
  }

  static isTech(node: AGraphNode) {
    return node.data?.native_name === 'Technology';
  }

  static render(
    node: AGraphNode,
    g: Graphics,
    container: Container,
    options: NodeRenderOptions = {},
  ): void {
    if (!node.isVisible) return;

    const { x, y, w, h, data = {} } = node;

    const isProcess = node.data.native_name === 'Process';
    const isHostedDatabase =
      //node.data.native_name === 'Hosted Technology' ||
      node.data.native_name === 'Managed Database';

    const isContext = NodeType.isContext(node);
    const isInternet = NodeType.isInternet(node);
    const isPermission = NodeType.isPermission(node);
    const isSeverity = NodeType.isSeverity(node);
    const isOs = NodeType.isOs(node);
    const isTech = NodeType.isTech(node);
    const severityType = NodeType.severityType(node);
    const isEndpoint = NodeType.isEndpoint(node);
    const isCredential = NodeType.isCredentialWithCount(node);
    const isResource = w === h;
    const isAccount =
      data?.native_name === NativeResources.AwsAccount ||
      data?.header === 'Account';
    const isBlocker =
      NodeType.isBlockPublicPolicy(node) ||
      NodeType.isBlockPublicAcl(node) ||
      NodeType.isIgnorePolicyAcls(node) ||
      NodeType.isRestrictPublicBuckets(node);

    let radius = 6;
    let lineColor = 0xaaaaaa;
    let fillColor = 0xffffff;
    let fillOpacity = 1;
    if (isAccount && data.account_type === 'Cross') {
      lineColor = nodeColor('medium');
    } else if (isAccount && data.account_type === 'Federated') {
      lineColor = nodeColor('high');
    }

    // fill node
    if (isProcess) {
      fillColor = 0xe0d8fa;
    } else if (isHostedDatabase) {
      fillOpacity = 0;
    } else if (isEndpoint) {
      fillColor = nodeColor(IconTypes.Endpoint);
      radius = w / 2;
    } else if (isSeverity) {
      if (severityType === Severity.Critical) {
        fillColor = nodeColor('critical');
      }
      if (severityType === Severity.High) {
        fillColor = nodeColor('high');
      }
      if (severityType === Severity.Medium) {
        fillColor = nodeColor('medium');
      }
      if (severityType === Severity.Low) {
        fillColor = nodeColor('low');
      }
      radius = w / 2;
    } else if (isOs || isTech) {
      fillColor = 0xffffff;
      radius = w / 2;
    } else if (isInternet) {
      fillColor = 0x000000;
      fillOpacity = 0;
    } else if (isResource) {
      if (isContext) {
        fillColor = colors.primary;
      } else if (isBlocker) {
        fillColor = 0xffffff;
      } else {
        fillColor = nodeColor(data.native_name);
      }
    }

    g.beginFill(fillColor, fillOpacity);

    // draw border
    if (!isInternet) {
      g.lineStyle(1, lineColor, 1);
    } else {
      g.lineStyle(1, 0x000000, 0);
    }

    g.drawRoundedRect(x, y, w, h, radius);
    g.endFill();

    NodeElements.renderIcon(
      node,
      g,
      container,
      isBlocker ? 'default' : isResource ? 'white' : 'purple',
    );

    if (isBlocker) {
      NodeElements.renderTitleOutside(node, g, container);
    } else if (isSeverity || isCredential) {
      NodeElements.renderTitleOutside(node, g, container);
      NodeElements.renderInfoOutside(node, g, container);
    } else if (isOs || isTech) {
      NodeElements.renderTitleOutside(node, g, container);
      NodeElements.renderInfoOutside(node, g, container);
    } else if (isPermission) {
      NodeElements.renderTextInside(node, g, container, {
        x: x + 40,
        y: y + SQUARE_NODE_SIZE / 2 - 8,
      });
    } else if (isResource) {
      NodeElements.renderTitleOutside(node, g, container);
      if (!isInternet) {
        NodeElements.renderInfoOutside(node, g, container);
      }
    } else {
      NodeElements.renderTitleInside(node, g, container);
      if (!isInternet) {
        NodeElements.renderInfoInside(node, g, container);
      }
    }

    if (isSeverity || isCredential) {
      NodeElements.renderCountInside(node, g, container);
    }

    // if (isProcess) {
    //   NodeElements.renderTraffic(node, g, container);
    // }
  }

  static renderActive(node: AGraphNode, g: Graphics): void {
    if (!node.isVisible) return;
    const { x, y, w, h, data } = node;
    const isAccount = data?.native_name === NativeResources.AwsAccount;
    const isResource = w === h;

    let line_color = colors.primary;
    if (isAccount && data.account_type === 'Cross')
      line_color = nodeColor('medium');
    else if (isAccount && data.account_type === 'Federated')
      line_color = nodeColor('high');

    g.lineStyle(1, line_color, 1);

    //fill node
    if (isAccount && data.account_type === 'Cross')
      g.beginFill(nodeColor('medium'), 0.1);
    else if (isAccount && data.account_type === 'Federated')
      g.beginFill(nodeColor('high'), 0.1);
    else if (!isResource) g.beginFill(colors.primary, 0.1);

    if (NodeType.isCircular(node)) {
      g.drawRoundedRect(x, y, w, h, w / 2);
    } else {
      g.drawRoundedRect(x, y, w, h, 5);
    }

    g.endFill();
  }

  static renderHover(node: AGraphNode, g: Graphics): void {
    if (!node.isVisible) return;
    const { x, y, w, h, data } = node;

    const isAccount = data?.native_name === NativeResources.AwsAccount;

    let line_color = 0xaaaaaa;
    if (isAccount && data.account_type === 'Cross')
      line_color = nodeColor('medium');
    else if (isAccount && data.account_type === 'Federated')
      line_color = nodeColor('high');

    g.lineStyle(1, line_color, 1);

    if (NodeType.isCircular(node)) {
      g.drawRoundedRect(x, y, w, h, w / 2);
    } else {
      g.drawRoundedRect(x, y, w, h, 5);
    }
    g.endFill();
  }
}
