/**
 *
 * Slack Form
 *
 */

import React, { useEffect, useReducer } from 'react';

import { Clients } from '@ariksa/notification';
import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { each, includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import {
  Form,
  FormSchema,
  PageHeaderWithIcon,
  QuestionCircleIcon,
} from 'app/components';
import { GetChannelID } from 'containers/Setup/Integrations/Components/Slack/Components/Help/GetChannelID';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { actions } from 'containers/Setup/Integrations/slice';

import { formStyles } from '../../../styles';

import { CreateOAuthToken } from './Help/CreateOAuthToken';

interface Props {
  handleSubmit: (date: any) => void;
}

export const SlackForm = (props: Props) => {
  const { handleSubmit } = props;
  const { client, clients, validateClient } = useSelector(selectIntegrations);
  const { actionType, onCancel, currentRecord } = useIntegrationsContext();
  const dispatch = useDispatch();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      name: '',
      channel: '',
      validateToken: false,
      validateChannel: false,
      isEdit: false,
      isHelpOpen: false,
      help: 'channel',
      channelName: '',
      showValidatedMessage: false,
      names: [],
    },
  );

  //set isEdit true if opened in edit
  useEffect(() => {
    updateState({ isEdit: actionType === 'Update' });
  }, [actionType]);

  useEffect(() => {
    let names: string[] = [];
    each(clients.slack, o => names.push(o.name));
    updateState({ names });
  }, [clients.slack]);

  //set selected channel in update mode
  useEffect(() => {
    if (state.isEdit) {
      updateState({
        channel: currentRecord?.slack?.channel_id,
        name: currentRecord?.name,
        validateToken: true,
        validateChannel: true,
        nameError: '',
        showValidatedMessage: false,
      });
    }
  }, [currentRecord, state.isEdit]);

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ ]*$/i,
        'Only alphanumeric, hyphen, underscore and space is allowed!',
      ),
  });

  const tokenField: FormSchema = state.isEdit
    ? {}
    : {
        token: {
          htmlInputType: 'password',
          passwordShow: true,
          type: 'text',
          label: 'OAuth Token',
          isRequired: true,
          helpComponent: <CreateOAuthToken />,
          onChange: val =>
            updateState({
              validateToken: !!val,
              validateChannel: false,
              showValidatedMessage: false,
            }),
          helpIcon: (
            <Box
              onClick={() => updateState({ isHelpOpen: true, help: 'OAuth' })}
              _hover={{ cursor: 'pointer' }}
              color="primary"
            >
              <QuestionCircleIcon />
            </Box>
          ),
          /*error:
        clientInfo.isError && !validate
          ? 'Please enter valid OAuth token!'
          : '',*/
        },
      };

  const validateChannel = data => {
    dispatch(
      actions.validateClient({
        q: {
          clientConfig: {
            name: data.name,
            status: true,
            client_name: Clients.Slack,
            slack: { channel_id: data?.channel_id, token: data?.token },
          },
        },
        onSuccess: res =>
          updateState({
            validateChannel: true,
            showValidatedMessage: true,
            channelName: res?.slack?.channel_name,
          }),
        onError: res =>
          updateState({ validateChannel: false, showValidatedMessage: true }),
      }),
    );
  };

  return (
    <Form
      formValidation={schemaForm}
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          value: state.name,
          onChange: value => {
            const flag = includes(state.names, value);
            let err = '';
            if (!value.match(/^[a-zA-Z0-9-]*$/i))
              err = 'Only Alphanumeric, hyphen is allowed!';
            else if (flag) err = 'Name already exists';
            updateState({
              name: value,
              nameError: err,
            });
          },
          error: state.nameError,
          isDisabled: client.isLoading,
          tooltip: 'Only alphanumeric, hyphen, underscore and space is allowed',
        },
        ...tokenField,
        channel_id: {
          type: 'text',
          label: 'Channel ID',
          isRequired: true,
          isDisabled: state.isEdit,
          value: state.channel,
          onChange: v =>
            updateState({
              channel: v,
              validateChannel: false,
              showValidatedMessage: false,
            }),
          isHelpOpen: state.channelHelp,
          helpIcon: (
            <Box
              onClick={() => updateState({ isHelpOpen: true, help: 'channel' })}
              _hover={{ cursor: 'pointer' }}
              color="primary"
            >
              <QuestionCircleIcon />
            </Box>
          ),
        },
        ...(state.showValidatedMessage
          ? {
              validatedMessage: {
                type: 'custom',
                component: () => {
                  const err = validateClient.error?.title;
                  const success = !err;
                  return (
                    <Box w="full">
                      <Alert
                        status={success ? 'success' : 'error'}
                        borderRadius={6}
                        title={success ? `` : validateClient.error?.title}
                      >
                        <AlertIcon />
                        {success
                          ? `All fields are valid. Please click on "Add Channel" to proceed.`
                          : validateClient.error?.description}
                      </Alert>
                    </Box>
                  );
                },
              },
            }
          : {}),
      }}
      helpDrawer={{
        isOpen: state.isHelpOpen,
        onClose: () => updateState({ isHelpOpen: false }),
        header: (
          <PageHeaderWithIcon
            label={
              state.help === 'channel' ? 'Get Channel ID' : 'Get OAuth Token'
            }
          />
        ),
        content:
          state.help === 'channel' ? <GetChannelID /> : <CreateOAuthToken />,
      }}
      styles={formStyles}
      buttonOptions={{
        submit: {
          name: !state.validateChannel
            ? 'Validate'
            : state.isEdit
            ? 'Update Channel'
            : 'Add Channel',
          isLoading: client.isLoading || validateClient.isLoading,
          isDisabled: !state.name || !state.channel || !state.validateToken,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: client.isLoading,
          onClick: onCancel,
        },
      }}
      handleSubmit={data =>
        state.validateChannel
          ? handleSubmit({ ...data, channelName: state.channelName })
          : validateChannel(data)
      }
    />
  );
};
