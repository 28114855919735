import { CloudProviders } from '@ariksa/cloud-account/api';
import { SupportedServices } from '@ariksa/data-scanning/api';
import {
  Categories,
  NativeResources,
  Resources,
} from '@ariksa/inventory-core/api';
import { colorOpacity, customTheme } from 'theme';

import { CustomIconTypes, IconTypes } from 'components/Icons';

export const getIconColor = (type: string, useCustomColor?: boolean) => {
  if (useCustomColor) return type;
  let color = type;

  switch (type) {
    case Resources.Analytics:
    case NativeResources.ElasticMapReduce:
      color = '#F01B29'; //rgb: 240,27,41
      break;
    case NativeResources.ApiGatewayRestApi:
    case NativeResources.ApiGatewayDomain:
    case Resources.ApiGateway:
    case NativeResources.GcpapiGateway:
      color = '#09C2FF'; //rgb: 9,194,255
      break;
    case NativeResources.EcsService:
    case NativeResources.ElasticContainerService:
    case NativeResources.AcmCertificate:
    case NativeResources.IamUserAccessKey:
    case NativeResources.KeyManagementService:
    case NativeResources.RdsCertificate:
    case NativeResources.Secret:
    case NativeResources.AzureSecrets:
    case NativeResources.SshKeyPair:
    case NativeResources.GcpSecretManager:
    case Resources.Key:
    case Resources.Secrets:
    case Resources.Certificate:
    case NativeResources.GcpCloudKms:
    case NativeResources.GcpsqlsslCerts:
    case 'EcsTask':
      color = '#E02947'; //rgb: 224,41,71
      break;
    case NativeResources.ElastiCache:
    case NativeResources.ElastiCacheReplicationGroup:
    case NativeResources.ElasticSearch:
    case NativeResources.GcpMemorystoreRedis:
      color = '#056147'; //rgb: 5,97,71
      break;
    case NativeResources.EcsCluster:
    case NativeResources.ElasticKubernetesService:
    case NativeResources.RdsCluster:
    case NativeResources.Container:
    case CustomIconTypes.IdentityAuthorization:
    case NativeResources.Gcpgke:
    case NativeResources.GcpContainer:
      color = '#623CE4'; //rgb:98, 60, 228
      break;
    case NativeResources.RedshiftCluster:
    case NativeResources.BackupPlan:
    case NativeResources.DocumentDb:
    case NativeResources.DynamoDb:
    case NativeResources.DocumentDbCluster:
    case NativeResources.EbsSnapshot:
    case NativeResources.ElasticFileSystem:
    case NativeResources.Neptune:
    case NativeResources.NeptuneCluster:
    case NativeResources.ElasticBlockStorage:
    case NativeResources.SimpleStorageService:
    case NativeResources.GcpBucket:
    case Resources.ObjectStorage:
    case Resources.BlockStorage:
    case NativeResources.RelationalDatabaseService:
    case NativeResources.RdsSnapshot:
    case NativeResources.RdsEventSubscription:
    case CustomIconTypes.DataSecurity:
    case NativeResources.GcpBigQuery:
    case NativeResources.GcpFilestore:
    case NativeResources.GcpFirestore:
    case NativeResources.GcpBigtableInstances:
    case NativeResources.GcpCloudSql:
    case NativeResources.GcpPersistentDisk:
    case NativeResources.GcpFilestoreBackup:
    case NativeResources.GcpsqlInstances:
      color = '#07BC8A'; //rgb: 7, 188, 138
      break;
    case Resources.ServerlessCompute:
    case Resources.VirtualMachine:
    case NativeResources.LambdaFunction:
    case NativeResources.Ec2Instance:
    case NativeResources.Ec2AutoScalingGroup:
    case NativeResources.Ec2LaunchTemplate:
    case NativeResources.EcsContainerInstance:
    case CustomIconTypes.CloudPosture:
    case NativeResources.GcpComputeEngine:
    case NativeResources.GcpCloudFunctions:
    case NativeResources.GcpComputeEngineAutoscaler:
    case NativeResources.GcpCloudTpus:
      color = '#1862FF'; //rgb: 24, 98, 255
      break;
    case NativeResources.SystemsManager:
    case NativeResources.SystemsManagerDocument:
    case NativeResources.AmazonMachineImage:
    case NativeResources.ElasticContainerRegistry:
    case NativeResources.GcpImage:
      color = '#0DACFF'; //rgb: 13, 172, 255
      break;
    case NativeResources.Route53HealthCheck:
    case NativeResources.Route53HostedZone:
    case NativeResources.GcpCloudDnsZone:
    case NativeResources.GcpCloudDomainsRegistration:
    case 'Route53RecordSet':
      color = '#EDC543'; //rgb: 237,197,676
      break;
    case 'Route53TrafficPolicy':
      color = '#31B616'; //rgb: 49, 182, 22
      break;
    case NativeResources.AccountRoot:
    case IconTypes.Permission:
      color = '#D6221F'; //rgb: 214,34,31
      break;
    case NativeResources.IamGroup:
    case NativeResources.IamUser:
    case NativeResources.IamRole:
    case NativeResources.GcpUser:
    case NativeResources.GcpRole:
    case NativeResources.GcpGroup:
    case Resources.Group:
    case Resources.User:
    case Resources.Role:
      color = '#6D04EB'; //rgb: 109,4,235
      break;
    case NativeResources.CloudTrail:
    case NativeResources.GcpCloudLogMetrics:
      color = '#B72A4D'; //rgb: 183, 42, 77
      break;
    case NativeResources.CloudWatchAlarms:
    case NativeResources.CloudWatchLogs:
    case NativeResources.CloudWatchLogGroup:
    case NativeResources.CloudWatchMetrics:
    case NativeResources.CloudWatchMetricAlarms:
      color = '#04724d'; //rgb: 4, 114, 77
      break;
    case NativeResources.SimpleNotificationService:
    case NativeResources.GcpPubSubLite:
      color = '#9083AF'; //rgb: 144, 131, 175
      break;
    case NativeResources.SimpleQueueService:
    case NativeResources.GcpCloudTasksQueue:
      color = '#E5B710'; //rgb: 229, 183, 16
      break;
    case NativeResources.SagemakerEndpoints:
    case NativeResources.SagemakerNotebookInstances:
      color = '#9D63F7'; //rgb: 157, 99, 247
      break;
    case NativeResources.CloudFront:
    case NativeResources.SecurityGroup:
    case NativeResources.DhcpOptions:
    case NativeResources.ElasticIp:
    case NativeResources.ElasticLoadBalancer:
    case NativeResources.ElasticLoadBalancerv2:
    case NativeResources.InternetGateway:
    case NativeResources.NatGateway:
    case NativeResources.NetworkAcl:
    case NativeResources.ElasticNetworkInterface:
    case NativeResources.PrefixList:
    case NativeResources.DatabaseSecurityGroup:
    case NativeResources.Subnet:
    case NativeResources.SubnetRouteTable:
    case NativeResources.TransitGateway:
    case NativeResources.TransitGatewayAttachment:
    case NativeResources.TransitGatewayRouteTable:
    case NativeResources.VirtualPrivateCloud:
    case NativeResources.VpcFlowLogs:
    case NativeResources.VpcPeeringConnection:
    case NativeResources.AwsVirtualPrivateNetwork:
    case NativeResources.VpnGateway:
    case NativeResources.WafRegional:
    case NativeResources.Wafv1:
    case NativeResources.Wafv2:
    case NativeResources.GcpVirtualPrivateCloud:
    case NativeResources.GcpSubnetwork:
    case NativeResources.GcpRoute:
    case NativeResources.GcpurlMaps:
    case NativeResources.GcpNetworkInterface:
    case NativeResources.GcpCloudNat:
    case NativeResources.GcpvpnGateway:
    case IconTypes.Route:
    case Resources.P2P:
    case Categories.Network:
      color = '#ED7D32'; //rgb: 237, 125, 50
      break;
    case Resources.Account:
    case NativeResources.AwsAccount:
    case NativeResources.AwsOrganization:
    case NativeResources.OrganizationUnit:
    case NativeResources.GcpOrganization:
    case NativeResources.GcpFolder:
    case NativeResources.GcpServiceAccount:
      color = '#012966'; //rgb: 1, 41, 102
      break;
    case NativeResources.ElasticBeanstalk:
      color = '#0A5EFF'; //rgb: 10, 194, 255
      break;
    case NativeResources.AccountPasswordIdentityPolicy:
    case NativeResources.AsgResourcePolicy:
    case NativeResources.IamPolicy:
    case NativeResources.ResourcePolicy:
    case NativeResources.IamInlinePolicy:
    case NativeResources.OrganizationPolicy:
    case NativeResources.GcpOrgPolicyService:
    case NativeResources.GcpiamPolicy:
    case NativeResources.GcpResourcePolicy:
      color = '#31B616'; //rgb: 49, 182, 22
      break;
    case NativeResources.SnowflakeTables:
    case NativeResources.SnowflakeUsers:
    case NativeResources.SnowflakeDatabases:
    case NativeResources.SnowflakeRoles:
    case CloudProviders.Snowflake:
      color = '#29b5e8';
      break;
    case 'aws':
    case CustomIconTypes.AWS:
      color = '#FF9900';
      break;
    case 'azure':
      color = '#008CDB';
      break;
    case NativeResources.AzureAdGroup:
    case NativeResources.AzureAdOrganization:
    case NativeResources.AzureAdUser:
    case CloudProviders.AzureAd:
    case 'azureAd':
      color = '#00bef2';
      break;
    case 'gcp':
    case CloudProviders.Gcp:
      color = customTheme.colors.green['300'];
      break;
    case 'okta':
    case NativeResources.OktaUser:
    case NativeResources.OktaGroup:
    case NativeResources.OktaOrganization:
    case CloudProviders.Okta:
    case CustomIconTypes.Okta:
      color = '#00297a';
      break;
    case NativeResources.GitLabUser:
    case NativeResources.GitLabGroup:
    case NativeResources.GitLabProject:
    case SupportedServices.GitLabBranch:
    case CloudProviders.Gitlab:
    case CloudProviders.GitlabIac:
    case CustomIconTypes.GitLab:
      color = '#d14f36';
      break;
    case NativeResources.GitHubRepository:
    case NativeResources.GitHubUser:
    case NativeResources.GitHubGroup:
    case SupportedServices.GitHubBranch:
    case CloudProviders.Github:
    case CloudProviders.GithubIac:
    case CustomIconTypes.GitHub:
      color = '#25292f';
      break;
    case NativeResources.BitBucketRepository:
    case SupportedServices.BitBucketBranch:
    case CloudProviders.BitbucketIac:
    case CloudProviders.Bitbucket:
    case CustomIconTypes.BitBucket:
      color = '#4089f7';
      break;
    case CustomIconTypes.PII:
    case 'pii':
      color = colorOpacity(customTheme.colors.primary, 0.2);
      break;
    case CustomIconTypes.PHI:
    case 'phi':
      color = colorOpacity(customTheme.colors.skyBlue['300'], 0.2);
      break;
    case CustomIconTypes.PCI:
    case 'pci':
      color = colorOpacity(customTheme.colors.blue['300'], 0.2);
      break;
    case CustomIconTypes.SENSITIVE:
    case CustomIconTypes.Sensitive:
      color = '#da8b31';
      break;
    case 'critical':
    case 'Critical':
    case 'CRITICAL':
      color = customTheme.colors?.critical;
      break;
    case 'medium':
    case 'Medium':
    case 'MEDIUM':
      color = customTheme.colors?.medium;
      break;
    case 'low':
    case 'Low':
    case 'LOW':
      color = customTheme.colors?.low;
      break;
    case 'high':
    case 'High':
    case 'HIGH':
      color = customTheme.colors?.high;
      break;
    case 'green':
      color = 'green';
      break;
    case 'orange':
      color = 'orange';
      break;
    case 'red':
      color = 'red';
      break;
    case 'passed':
      color = 'passed';
      break;
    default:
      color = customTheme.colors.primary;
  }
  return color;
};
