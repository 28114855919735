import React, { ReactNode } from 'react';

import { Box, Center, CenterProps, HStack, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { IdeaGlitterIcon } from 'components/Icons';
import {
  DashboardOverviewTooltip,
  DashboardOverviewTooltipProps,
} from 'containers/Dashboard/utils/OverviewDashboardTooltip';

export interface DashboardOverviewCardProps {
  content: ReactNode;
  label?: ReactNode;
  subHeader?: ReactNode;
  rightHeader?: ReactNode;
  tooltip?: DashboardOverviewTooltipProps;
  isLoading?: boolean;
  styles?: { contentWrapper?: CenterProps };
  noDataMessage?: ReactNode;
  noData?: boolean;
}

export const DashboardWidget: React.FC<DashboardOverviewCardProps> = props => {
  const {
    label,
    subHeader,
    content,
    tooltip,
    rightHeader,
    isLoading = false,
    styles,
    noData,
    noDataMessage,
  } = props;

  return (
    <Box py={5} px={2} w="full" h="full">
      <Stack w="full" h="full" spacing={subHeader ? 3 : 6}>
        <Stack>
          <HStack w="full" justify="space-between">
            <HStack px={5} w="full">
              <Stack spacing={1} w="full">
                <PageHeaderWithIcon
                  label={label}
                  fontSize="lg"
                  reversed
                  labelStyles={{ fontWeight: 600 }}
                />
                <Box
                  color={customTheme.colors.gray['270']}
                  pb={!!subHeader ? 0 : 2.5}
                  w="full"
                >
                  {subHeader}
                </Box>
              </Stack>
            </HStack>
            <HStack justify="flex-end">
              {tooltip && <DashboardOverviewTooltip {...tooltip} />}
              <Box>{rightHeader}</Box>
            </HStack>
          </HStack>
        </Stack>
        <Center
          w="full"
          h="full"
          py={2}
          overflowY="auto"
          px={5}
          {...styles?.contentWrapper}
        >
          {isLoading ? (
            <CustomSpinner />
          ) : noData ? (
            <Box pb={8} color={customTheme.colors.gray['300']}>
              {noDataMessage || 'No Data Available'}
            </Box>
          ) : (
            content
          )}
        </Center>
      </Stack>
    </Box>
  );
};
