import React, { FC } from 'react';

import { NoSetup } from 'components/DataDisplay/Utils/NoSetup';

export const NoJITSetup: FC = () => {
  return (
    <NoSetup
      header="Just In Time (JIT) Not Setup"
      headerMessage="Just In Time (JIT) permissioning provides time-based access and custom privileges to developers and users to cloud
and SaaS environments. This minimizes risks due to long-standing excess privileges"
      steps={[
        'Enable JIT permissions for cloud accounts',
        'Set up JIT approvers and select roles made available for JIT',
        'Install bot in Slack to allow anyone to make JIT requests',
      ]}
      footer="ENABLE JIT"
      footerLink="/setup/jit/add"
    />
  );
};
