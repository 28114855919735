import React, { useEffect, useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { each } from 'lodash';
import { useSelector } from 'react-redux';

import { formatTooltip } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes } from 'components/Icons';
import { getIconColor } from 'components/Icons/Components/getIconColor';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';

export const IdentitiesWithHighPrivileges: React.FC = () => {
  const { identitiesWithHighPrivileges } = useSelector(selectIdentityOverview);
  const [items, setItems] = useState<Record<string, any>>({});
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  useEffect(() => {
    let items = {};
    each(
      identitiesWithHighPrivileges.data?.items,
      o => (items[o.provider_or_source_type + ' ' + o.agnostic] = o.count),
    );
    setItems(items);
  }, [identitiesWithHighPrivileges.data]);

  return (
    <>
      <DashboardWidget
        label="Identities with High Privileges"
        content={renderResourcesWithIconAndCount([
          {
            label: 'Cloud Users',
            count: formatNumber(items?.['Cloud User'] || 0),
            isLoading: identitiesWithHighPrivileges.isLoading,
            iconType: IconTypes.IamUser,
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.HighPrivilegesCloudUsers,
                iconType: IconTypes.IamUser,
                total: items?.['Cloud User'] || 0,
              });
              /*navigate(
              `/visibility/security-graph?query=${getSearchQuery(
                SearchQueryMapping.Show_resource_type_with_high_privileges_and_resource_type_that_can_access_any,
                ['User', 'User'],
              )}&redirect_url=${history.location.pathname}`,
            );*/
            },
            iconTooltip: formatTooltip({
              header: 'Cloud users',
              content: 'Cloud-native users in public clouds',
              width: 32,
            }),
          },
          {
            label: 'Roles',
            count: formatNumber(items?.['Cloud Role'] || 0),
            isLoading: identitiesWithHighPrivileges.isLoading,
            iconType: IconTypes.Role,
            iconColor: getIconColor(IconTypes.Role),
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.HighPrivilegesRoles,
                iconType: IconTypes.Role,
                total: items?.['Cloud Role'] || 0,
              });
              /*navigate(
              `/visibility/security-graph?query=${getSearchQuery(
                SearchQueryMapping.Show_resource_type_with_high_privileges_and_resource_type_that_can_access_any,
                ['Role', 'Role'],
              )}&redirect_url=${history.location.pathname}`,
            );*/
            },
            iconTooltip: formatTooltip({
              header: 'Roles',
              content: 'Cloud-native roles for public clouds, SaaS',
              width: 32,
            }),
          },
          {
            label: 'Non-users',
            count: formatNumber(items?.['Cloud Non-users'] || 0),
            isLoading: identitiesWithHighPrivileges.isLoading,
            iconType: IconTypes.VirtualMachine,
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.HighPrivilegesNonUsers,
                iconType: IconTypes.VirtualMachine,
                total: items?.['Cloud Non-users'] || 0,
              });
              /*const user = 'Compute,Credential,ContainerImages,App';
          navigate(
            `/visibility/security-graph?query=${getSearchQuery(
              SearchQueryMapping.Show_resource_type_with_high_privileges_and_resource_type_that_can_access_any,
              [user, user],
            )}&redirect_url=${history.location.pathname}`,
          );*/
            },
            iconTooltip: formatTooltip({
              header: 'Non-users',
              content:
                'Non—user identities such as VMs, Containers, Container Services, Serverless etc.',
              width: 48,
            }),
          },
          {
            label: 'Federated users',
            count: formatNumber(
              items?.high_privileges?.['Federated User'] || 0,
            ),
            isLoading: identitiesWithHighPrivileges.isLoading,
            iconType: IconTypes.User,
            iconColor: 'orange',
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.HighPrivilegesFederatedUsers,
                iconType: IconTypes.User,
                total: items?.high_privileges?.['Federated User'] || 0,
              });
              /*const user =
            NativeResources.OktaUser +
            ',' +
            NativeResources.AzureAdUser +
            ',' +
            NativeResources.GoogleWorkspaceUser;
          navigate(
            `/visibility/security-graph?query=${getSearchQuery(
              SearchQueryMapping.Show_resource_type_with_high_privileges_and_resource_type_that_can_access_any,
              [user, user],
            )}&redirect_url=${history.location.pathname}`,
          );*/
            },
            iconTooltip: formatTooltip({
              header: 'Federated users',
              content:
                'Includes users in IdP such as Okta, Azure Entra etc. with access to public clouds, SaaS',
              width: 48,
            }),
          },
        ])}
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
