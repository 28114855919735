import React, { useEffect, useState } from 'react';

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import {
  Box,
  HStack,
  Stack,
  Center,
  useDisclosure,
  Text,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import { PieChart } from 'components/Visualization';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { DashboardWidgetTooltipIcon } from 'containers/Dashboard/utils/utils';
import { GovernanceChecklist } from 'containers/Setup/CloudAccounts/Components/GovernanceChecklist';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

export const GovernanceScore: React.FC = () => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);
  const { governanceScore } = stateOfYourCloud;
  const { accountMapping } = useCloudAccountId();
  const navigate = useNavigate();
  const governanceChecklist = useDisclosure();
  const [currentAccount, setCurrentAccount] = useState({});
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const [total, setTotal] = useState(0);

  const getRiskScore = score => score * 10;

  const getHealthColor = score => {
    const percent = getRiskScore(score);
    if (percent < 25) return '#f0bcba';
    if (percent >= 25 && percent < 50) return '#f8e2a7';
    if (percent >= 50 && percent < 75) return '#d2f7e9';
    else return '#a1cd63';
  };

  const getHealth = score => {
    const percent = getRiskScore(score);
    if (percent < 25) return 'Poor';
    if (percent >= 25 && percent < 50) return 'Fair';
    if (percent >= 50 && percent < 75) return 'Good';
    else return 'Excellent';
  };

  const renderHealthBox = (score, size = 5) => (
    <Box
      boxSize={size}
      border="1px solid"
      borderColor={customTheme.colors.gray['100']}
      bg={getHealthColor(score)}
      borderRadius={3}
    />
  );

  useEffect(() => {
    const score = getRiskScore(governanceScore.data.score);
    setChartData([
      {
        name: 'score',
        value: score,
      },
      {
        name: 'grayScale',
        value: 100 - score,
      },
    ]);
    setTotal(score ?? 0);
  }, [governanceScore.data]);

  const columns = [
    {
      header: <Box pl={10}>Account</Box>,
      render: ({ row }) => {
        const account = accountMapping[row?.account_id];
        return (
          <WithResourceIcon
            resourceType={account?.cloud_type ?? ''}
            iconFilled={false}
            iconStyles={{ w: 5, h: 5 }}
          >
            {account?.name ?? account?.cloud_account_id}
          </WithResourceIcon>
        );
      },
    },
    {
      header: 'SCORE',
      render: ({ row }) => getRiskScore(row.score) + '%',
      styles: { cell: { textAlign: 'center' } },
    },
    {
      header: 'HEALTH',
      render: ({ row }) => renderHealthBox(row.score),
      styles: { cell: { justifyContent: 'center', display: 'flex' } },
    },
  ];

  const ratings = [
    { value: '<25', label: 'Poor', score: 2 },
    { value: '25 - 50', label: 'Fair', score: 4 },
    { value: '50 - 75', label: 'Good', score: 6 },
    { value: '>75', label: 'Excellent', score: 8 },
  ];

  const renderRating = ({ value, label, score }) => (
    <Stack spacing={0} color={customTheme.colors.gray['270']}>
      <Center h={3}>{value}</Center>
      <Center>{label}</Center>
      <Center>{renderHealthBox(score, 3.5)}</Center>
    </Stack>
  );

  return (
    <DashboardWidget
      label="Account Governance"
      isLoading={governanceScore.isLoading}
      content={
        <HStack w="full" h="full" justify="space-between" spacing={2}>
          <Stack w="40%" h="full">
            <Box w="full" h="75%">
              <PieChart
                data={chartData}
                colorMap={{
                  score: getHealthColor(governanceScore.data.score),
                  grayScale: customTheme.colors.gray['100'],
                }}
                dataKey={'value'}
                total={total + '%'}
                activeShape={false}
                tooltip={false}
                styles={{
                  pie: {
                    endAngle: -5,
                    startAngle: 185,
                    paddingAngle: 1,
                    cy: '70%',
                  },
                  label: { fontSize: 48, pb: 20 },
                  //root: { position: 'absolute', pb: 0, top: 0 },
                }}
                outerRadius="140%"
                innerRadius="127%"
                legend={false}
                centerLabel={`Overall: ${getHealth(
                  governanceScore.data.score,
                )}`}
              />
            </Box>
            <HStack
              w="full"
              h="25%"
              justify="space-between"
              pl={4}
              fontSize="sm"
            >
              {map(ratings, o => renderRating(o))}
            </HStack>
          </Stack>
          <Stack h="full" w="60%">
            <DashboardTable
              columns={columns}
              data={governanceScore.data?.accounts_score}
              styles={{ cell: { py: 1.5 } }}
              onRowClick={row => {
                setCurrentAccount(accountMapping[row.account_id]);
                governanceChecklist.onOpen();
              }}
            />
          </Stack>
          {governanceChecklist.isOpen && (
            <GovernanceChecklist
              isOpen={governanceChecklist.isOpen}
              onClose={governanceChecklist.onClose}
              row={currentAccount as CloudAccountGetResponse}
            />
          )}
        </HStack>
      }
      styles={{ contentWrapper: { pl: 0 } }}
      tooltip={{
        tooltip: (
          <Stack spacing={3}>
            <Box>
              Ariksa scores and prioritizes proactive account controls to
              mitigate future risks and threats due to poor governance.
            </Box>
            <Box>For robust account governance:</Box>
            <UnorderedList color="primary" spacing={2}>
              <ListItem pl={2}>Focus on high impact changes</ListItem>
              <ListItem pl={2}>Pick low effort changes for wins</ListItem>
              <ListItem pl={2}>Assess drift using historical info</ListItem>
            </UnorderedList>
          </Stack>
        ),
        button: <DashboardWidgetTooltipIcon />,
        header: 'Proactive Account Governance',
        showArrow: false,
        onClickFooter: () => navigate('/setup/accounts'),
      }}
    />
  );
};
