import React from 'react';

import { useSelector } from 'react-redux';

import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { ResourceActions } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/ResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const ResourceActionsPatternAccordion = () => {
  const { resourceActionsMonthly } = useSelector(selectActiveResource);

  return (
    <MetaAccordionItem
      iconType={IconTypes.DataScan}
      label={'ACTIONS PERFORMED ON THIS RESOURCE DURING LAST 30 DAYS'}
      onClickDownload={() => {}}
      panel={<ResourceActions />}
      iconBGColor="primary"
      //height={'400px'}
      isLoading={resourceActionsMonthly.isLoading}
    />
  );
};
