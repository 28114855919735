import React from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import round from 'lodash/round';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { BarProgress } from 'components/Visualization/Progress/BarProgress';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';

export const CloudCompliance: React.FC = () => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);
  const navigate = useNavigate();
  const { complianceSummary } = stateOfYourCloud;

  const onClick = row => navigate('/compliance?blueprint_id=' + row?.id);

  const columns = [
    {
      header: <Box pl={9}>STANDARD</Box>,
      accessor: '',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row.standard}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
          color={
            row.standard === IconTypes.AriksaBestPractices ? 'black' : 'inherit'
          }
          bgColor={
            row.standard === IconTypes.AriksaBestPractices ? 'white' : 'inherit'
          }
        >
          {row.standard}
        </WithResourceIcon>
      ),
    },
    {
      header: <Box pr={6}>ENTITIES</Box>,
      accessor: 'attached_entities',
      render: ({ value }) => formatNumber(value, 1),
      styles: {
        cell: { textAlign: 'right', pr: 8 },
        header: { textAlign: 'right' },
        w: '60px',
      },
    },
    {
      header: 'SCORE',
      accessor: 'score',
      render: ({ value }) => (
        <HStack spacing={3}>
          <Box w={10} textAlign="right">
            {round(value) + '%'}
          </Box>
          <Box w="170px">
            <BarProgress total={100} value={round(value)} width="full" />
          </Box>
        </HStack>
      ),
    },
  ];

  return (
    <DashboardWidget
      label="Cloud Compliance"
      isLoading={complianceSummary.isLoading}
      content={
        <DashboardTable
          columns={columns}
          data={orderBy(complianceSummary.data, ['score'], ['desc'])}
          onRowClick={onClick}
        />
      }
    />
  );
};
