import {
  SensitiveDataByRegion,
  SensitiveDataByType,
  SensitivityCategory,
  SensitivityLevel,
} from '@ariksa/data-scanning';
import {
  DataFootprintAnalyzed,
  DataSourcesWithData,
  ResourceDistributionCount,
  ResourceGroup,
  ResourcesRead,
  SensitiveDataByAccount,
  SensitiveDataBySource,
  SourceBySensitiveData,
  TrendRegionsInfo,
  TrendSourcesInfo,
  TrendTotalSizeInfo,
  TrendTypesInfo,
} from '@ariksa/data-scanning/api';
import { TrendDataInfo } from '@ariksa/data-scanning/dist/api';
import {
  DataSourcesSummary,
  PublicResourceSummary,
  RiskContextResponse, RuntimeInsightBySources,
} from '@ariksa/inventory-core/api';
import { AggregatedAlertsSummaryResponse } from '@ariksa/notification/api';
import { IPagedQueryState } from 'services/utils/PagedQueryState';
import { IQueryState } from 'services/utils/QueryState';

import { SideNavKey } from 'components/Navigation';

export interface DataDashboardState {
  selectedTab: SideNavKey;
  dataSources: IPagedQueryState<any[]>;
  allDataResourceTypes: IQueryState<ResourceGroup[]>;
  dataTypeSensitiveSources: IPagedQueryState<any[]>;
  filesByDataType: IPagedQueryState<SourceBySensitiveData[]>;
  historicalTrend: IQueryState<TrendDataInfo>;
  dataSourcesPieChart: IQueryState<DataSourcesWithData>;
  dataTypesRiskContext: IQueryState<Record<string, RiskContextResponse[]>>;
  resourceCount: IQueryState<any>;
  highRiskDataSources: IQueryState<ResourcesRead[]>;

  sensitiveDataByAgnosticSources: IQueryState<ResourceDistributionCount>;
  sensitiveDataBySources: IQueryState<SensitiveDataBySource>;
  sensitiveDataByType: IQueryState<SensitiveDataByType>;
  sensitiveDataByRegion: IQueryState<SensitiveDataByRegion>;
  dataSourcesByRegion: IQueryState<SensitiveDataByRegion>;
  sensitiveDataByRegionTrend: IQueryState<Record<string, TrendRegionsInfo>>;
  sensitiveDataByTypeTrend: IQueryState<Record<string, TrendTypesInfo>>;
  sourcesByAccount: IQueryState<SensitiveDataByAccount>;
  dataSourcesAnalyzed: IQueryState<TrendSourcesInfo>;
  dataAnalyzed: IQueryState<TrendTotalSizeInfo>;
  dataFindings: IQueryState<any>;
  totalDataFindings: IQueryState<AggregatedAlertsSummaryResponse>;

  analyzedSources: IQueryState<DataFootprintAnalyzed>;
  sensitiveDataSourceCount: IQueryState<number>;
  labelCount: IQueryState<Record<string, number>>;
  publiclyExposedDataSources: IQueryState<PublicResourceSummary[]>;
  summaryOfAllDataSources: IQueryState<DataSourcesSummary>;
  alertingCount: IQueryState<number>;
  runtimeInsights: IQueryState<RuntimeInsightBySources[]>;

  documentSubTypes: IPagedQueryState<any>;
  documentSubTypeDataSources: IPagedQueryState<any>;
  dataSourcesByType: IPagedQueryState<any>;
  documentTypeDetails: IQueryState<any>;
}

export const SENSITIVITY_CATEGORIES = {
  [SensitivityCategory.Restricted]: SensitivityLevel.Critical,
  [SensitivityCategory.Confidential]: SensitivityLevel.High,
  [SensitivityCategory.Public]: SensitivityLevel.Medium,
  [SensitivityCategory.Internal]: SensitivityLevel.Low,
};

export type ContainerState = DataDashboardState;
