import {
  AuditTrailServiceApiAuditTrailServiceAccountStatusRequest,
  V1AccountStatusResponse,
} from '@ariksa/audittrail/api';
import {
  BlueprintApiGetAttachedBlueprintSummaryRequest,
  BlueprintApiGetBlueprintComplianceSummaryRequest,
  BlueprintAttachedSummary,
  BlueprintComplianceSummary,
} from '@ariksa/compliance-policies/api';
import {
  DataFootprintAnalyzed,
  ResourcesApiGetResourcesCategoriesCountRequest,
} from '@ariksa/data-scanning/api';
import {
  AppSchemasInventoryResourceCountDeploymentSummary,
  CountSummary,
  ResourceApiGetDeploymentSummaryRequest,
  ResourceSummaryApiGetSummaryByTypeRequest,
} from '@ariksa/inventory-core/api';
import {
  AlertLogsApiGetTicketHistoryRequest,
  AlertLogsApiGetTicketSummaryRequest,
  AlertWorkflowApiGetWorkflowRequest,
  AlertWorkflowResponse,
  TicketSummaryResponse,
} from '@ariksa/notification/api';
import {
  ChartsApiGetDashboardResourcesHistoryRequest,
  ChartsApiGetDashboardResourcesRequest,
  ChartsApiGetGovernanceScoresRequest,
  ChartsApiGetTotalAlertsRequest,
  InventoryApiGetResourceCountRequest,
  OrgGovernanceReport,
  ResourceCount,
  ResourceCountResponse,
  TotalAlertsResponse,
} from '@ariksa/reporting/api';
import { map } from 'lodash';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';
import { formatDate } from 'utils/date';

import { ContainerState } from './types';

// The initial state of the Tag container
export const initialState: ContainerState = {
  //state of your cloud
  stateOfYourCloud: {
    cloudSummary: QueryState.init({} as CountSummary),
    resourcesCount: QueryState.init({} as ResourceCount),
    complianceSummary: QueryState.init([]),

    dataCount: QueryState.init({} as ResourceCountResponse),
    infrastructureCount: QueryState.init({} as ResourceCountResponse),

    entitiesHistory: QueryState.init({}),
    alertsHistory: QueryState.init({} as TotalAlertsResponse),
    analyzedDataSourcesCount: QueryState.init({}),

    governanceScore: QueryState.init({} as OrgGovernanceReport),
  },
  policyManagement: {
    workflows: QueryState.init([]),
    policies: QueryState.init({} as BlueprintAttachedSummary),
  },
  ticketSummary: QueryState.init({} as TicketSummaryResponse),
  ticketHistory: QueryState.init([]),
  accountStatus: QueryState.init({}),
  vpcStatus: QueryState.init({} as any),
  deploymentSummary: QueryState.init([]),
};

const dashboardOverviewSlice = createSlice({
  name: 'dashboardOverview',
  initialState,
  reducers: {
    getCloudSummary(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.stateOfYourCloud.cloudSummary = QueryState.next(
        state.stateOfYourCloud.cloudSummary,
        action,
      );
    },

    getResourcesCount(
      state,
      action: QueryAction<ResourceCount, ChartsApiGetDashboardResourcesRequest>,
    ) {
      state.stateOfYourCloud.resourcesCount = QueryState.next(
        state.stateOfYourCloud.resourcesCount,
        action,
      );
    },

    getPolicyManagementSummary(
      state,
      action: QueryAction<
        BlueprintAttachedSummary,
        BlueprintApiGetAttachedBlueprintSummaryRequest
      >,
    ) {
      state.policyManagement.policies = QueryState.next(
        state.policyManagement.policies,
        action,
      );
    },
    getAlertWorkflows(
      state,
      action: QueryAction<
        AlertWorkflowResponse[],
        AlertWorkflowApiGetWorkflowRequest
      >,
    ) {
      state.policyManagement.workflows = QueryState.next(
        state.policyManagement.workflows,
        action,
      );
    },

    /*--------------------------State of your cloud------------------------*/
    getEntitiesHistory(
      state,
      action: QueryAction<
        Record<string, number>,
        ChartsApiGetDashboardResourcesHistoryRequest
      >,
    ) {
      state.stateOfYourCloud.entitiesHistory = QueryState.next(
        state.stateOfYourCloud.entitiesHistory,
        action,
      );
    },

    getDataCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.stateOfYourCloud.dataCount = QueryState.next(
        state.stateOfYourCloud.dataCount,
        action,
      );
    },
    getInfrastructureCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.stateOfYourCloud.infrastructureCount = QueryState.next(
        state.stateOfYourCloud.infrastructureCount,
        action,
      );
    },

    getAlertsHistory(
      state,
      action: QueryAction<TotalAlertsResponse, ChartsApiGetTotalAlertsRequest>,
    ) {
      state.stateOfYourCloud.alertsHistory = QueryState.next(
        state.stateOfYourCloud.alertsHistory,
        action,
      );
    },

    getComplianceSummary(
      state,
      action: QueryAction<
        BlueprintComplianceSummary[],
        BlueprintApiGetBlueprintComplianceSummaryRequest
      >,
    ) {
      state.stateOfYourCloud.complianceSummary = QueryState.next(
        state.stateOfYourCloud.complianceSummary,
        action,
      );
    },

    getAnalyzedDataSourcesCount(
      state,
      action: QueryAction<
        DataFootprintAnalyzed,
        ResourcesApiGetResourcesCategoriesCountRequest
      >,
    ) {
      state.stateOfYourCloud.analyzedDataSourcesCount = QueryState.next(
        state.stateOfYourCloud.analyzedDataSourcesCount,
        action,
      );
    },

    getTicketSummary(
      state,
      action: QueryAction<
        TicketSummaryResponse,
        AlertLogsApiGetTicketSummaryRequest
      >,
    ) {
      state.ticketSummary = QueryState.next(state.ticketSummary, action);
    },

    getTicketHistory(
      state,
      action: QueryAction<
        Record<string, number>,
        AlertLogsApiGetTicketHistoryRequest
      >,
    ) {
      state.ticketHistory = QueryState.next(state.ticketHistory, action, {
        mapData: r => {
          return map(r, (count, date) => ({
            Date: formatDate(date, 'D-MMM'),
            Tickets: count,
          }));
        },
      });
    },

    getGovernanceScore(
      state,
      action: QueryAction<
        OrgGovernanceReport,
        ChartsApiGetGovernanceScoresRequest
      >,
    ) {
      state.stateOfYourCloud.governanceScore = QueryState.next(
        state.stateOfYourCloud.governanceScore,
        action,
      );
    },

    getAccountStatus(
      state,
      action: QueryAction<
        V1AccountStatusResponse,
        AuditTrailServiceApiAuditTrailServiceAccountStatusRequest
      >,
    ) {
      state.accountStatus = QueryState.next(state.accountStatus, action);
    },

    getVpcStatus(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.vpcStatus = QueryState.next(state.vpcStatus, action);
    },
    getDeploymentSummary(
      state,
      action: QueryAction<
        AppSchemasInventoryResourceCountDeploymentSummary[],
        ResourceApiGetDeploymentSummaryRequest
      >,
    ) {
      state.deploymentSummary = QueryState.next(
        state.deploymentSummary,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = dashboardOverviewSlice;
