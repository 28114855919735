import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Assets, Sprite, Text } from 'pixi.js';

import { IconTypes } from 'components/Icons';
import { getBlackMapIcon } from 'components/Icons/MapIcons/getBlackMapIcon';
import { getColoredIcons } from 'components/Icons/MapIcons/getColoredIcons';
import { getPurpleMapIcon } from 'components/Icons/MapIcons/getPurpleMapIcon';
import { nodeInfo } from 'components/Visualization/PixiGraph/common/utils/nodeInfo';
import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import {
  infoTextStyle,
  ipTextStyle,
  LIMIT_INNER_NAME_LEN,
  LIMIT_OUTER_INFO_LEN,
  nameTextStyle,
} from 'components/Visualization/PixiGraph/style';

import { limitedString } from '../../../../../utils/string';

export const END_CIRCLE_RADIUS = 5;

// Bézier curve function (cubic)
function getBezierPoint(t, p0, p1, p2, p3) {
  const u = 1 - t;
  const tt = t * t;
  const uu = u * u;
  const uuu = uu * u;
  const ttt = tt * t;

  let p: Record<string, any> = {};
  p.x = uuu * p0.x + 3 * uu * t * p1.x + 3 * u * tt * p2.x + ttt * p3.x;
  p.y = uuu * p0.y + 3 * uu * t * p1.y + 3 * u * tt * p2.y + ttt * p3.y;
  return p;
}

// Derivative of the Bézier curve for tangent calculation
function getBezierTangent(t, p0, p1, p2, p3) {
  const u = 1 - t;
  const tt = t * t;
  const uu = u * u;

  return {
    x:
      -3 * uu * p0.x +
      3 * (uu - 2 * u * t) * p1.x +
      3 * (2 * t - tt) * p2.x +
      3 * tt * p3.x,
    y:
      -3 * uu * p0.y +
      3 * (uu - 2 * u * t) * p1.y +
      3 * (2 * t - tt) * p2.y +
      3 * tt * p3.y,
  };
}

// Calculate angle from tangent
function getAngle(tangent) {
  return Math.atan2(tangent.y, tangent.x);
}

export class EdgeElements {
  static renderEndCircle(
    edge: AGraphEdge,
    g: Graphics,
    opts: Record<string, any> = {},
  ) {
    const { color = 0xaaaaaa } = opts;
    const { start, end } = edge;
    g.lineStyle(1, color, 1);
    g.beginFill(0xffffff, 1);
    g.drawCircle(end.x - END_CIRCLE_RADIUS, end.y, END_CIRCLE_RADIUS);
    g.endFill();
  }

  static renderStartCircle(
    edge: AGraphEdge,
    g: Graphics,
    opts: Record<string, any> = {},
  ) {
    const { color = 0xaaaaaa } = opts;
    const { start, end } = edge;
    g.lineStyle(1, color, 1);
    g.beginFill(0xffffff, 1);
    g.drawCircle(
      start.x + 2 * END_CIRCLE_RADIUS - END_CIRCLE_RADIUS,
      start.y,
      END_CIRCLE_RADIUS,
    );
    g.endFill();
  }

  static renderEdgeText(edge: AGraphEdge, g: Graphics) {
    const { start, end, data } = edge;
    const { properties = {} } = data ?? {};
    const {
      db_user_name = '',
      public_ip = '',
      private_ip_address = '',
    } = properties;

    const startX = start.x;
    const startY = start.y;
    const cpX1 = (end.x + start.x) / 2;
    const cpY1 = start.y;
    const cpX2 = start.x + (end.x - start.x) / 3;
    const cpY2 = end.y;
    const endX = end.x;
    const endY = end.y;

    if (db_user_name) {
      const title = `Login: \n${db_user_name}`;
      const text = new Text(title, infoTextStyle);

      text.x = (start.x + end.x) / 2 - text.width / 2;
      text.y = (start.y + end.y) / 2 - text.height / 2;
      g.addChild(text);
    } else if (public_ip || private_ip_address) {
      const public_ip_text = new Text(public_ip, ipTextStyle);
      const private_ip_text = new Text(private_ip_address, ipTextStyle);

      const pos = getBezierPoint(
        0.5,
        { x: startX, y: startY },
        { x: cpX1, y: cpY1 },
        { x: cpX2, y: cpY2 },
        { x: endX, y: endY },
      );

      const tangent = getBezierTangent(
        0.5,
        { x: startX, y: startY },
        { x: cpX1, y: cpY1 },
        { x: cpX2, y: cpY2 },
        { x: endX, y: endY },
      );

      const angle = getAngle(tangent);

      public_ip_text.x = pos.x;
      public_ip_text.y = pos.y; // Offset to place above
      public_ip_text.anchor.set(0.5);
      //text.rotation = angle; // Align text with curve's direction

      private_ip_text.x = pos.x;
      private_ip_text.y = pos.y + 20; // Offset to place below
      private_ip_text.anchor.set(0.5);
      g.addChild(public_ip_text);
      g.addChild(private_ip_text);
    }
  }

  static renderEdgeIcon(edge: AGraphEdge, g: Graphics) {
    const { start, end, data } = edge;
    const { properties = {} } = data ?? {};
    const { db_user_name = '', public_ip = '' } = properties;

    const startX = start.x;
    const startY = start.y;
    const cpX1 = (end.x + start.x) / 2;
    const cpY1 = start.y;
    const cpX2 = start.x + (end.x - start.x) / 3;
    const cpY2 = end.y;
    const endX = end.x;
    const endY = end.y;

    if (db_user_name || public_ip) {
      const x = (start.x + end.x) / 2 - 5;
      const y = (start.y + end.y) / 2;
      const iconPath = db_user_name
        ? getPurpleMapIcon(IconTypes.View)
        : getColoredIcons(IconTypes.InternetAccess);
      Assets.loader.load(iconPath).then(texture => {
        if (!texture) return;
        const icon = Sprite.from(texture);

        const scale = (db_user_name ? 20 : 15) / icon.width;
        icon.scale.set(scale);

        const pos = getBezierPoint(
          0.5,
          { x: startX, y: startY },
          { x: cpX1, y: cpY1 },
          { x: cpX2, y: cpY2 },
          { x: endX, y: endY },
        );

        icon.x = db_user_name
          ? x - icon.width / 2
          : pos.x - public_ip?.length * 5;
        icon.y = db_user_name ? y - icon.height / 2 : pos.y - 7;

        g.addChild(icon);
      });
    }
  }

  static renderEdgeIconBoundary(
    edge: AGraphEdge,
  ): {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
  } {
    const { start, end, data } = edge;
    const { properties = {} } = data ?? {};
    const { db_user_name = '' } = properties;

    if (db_user_name) {
      const x = (start.x + end.x) / 2;
      const y = (start.y + end.y) / 2;

      return {
        minX: x - 10,
        maxX: x + 10,
        minY: y - 10,
        maxY: y + 10,
      };
    }

    return {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    };
  }
}
