import React, { useCallback, useEffect, useRef } from 'react';

import { Box, Center, Flex, Stack } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { Select } from 'components/DataEntry';
import { CloudInsightItem } from 'containers/App/CloudInsight/CloudInsightItem';
import { selectApp } from 'containers/App/selectors';
import { actions } from 'containers/App/slice';
import { runtimeInsightsCategoryOptions } from 'containers/App/utils';

interface CloudInsightListProps {}

export const CloudInsightList = (props: CloudInsightListProps) => {
  const {
    cloudInsights,
    cloudInsightPage,
    runtimeInsightsCategories,
  } = useSelector(selectApp);
  const dispatch = useDispatch();
  const isLoading = useRef(false);

  useEffect(() => {
    dispatch(
      actions.getCloudInsights({
        q: {
          accountId: [],
          page: cloudInsightPage,
          pageSize: 20,
          categories: map(runtimeInsightsCategories, o => o.value),
        },
        onSuccess: () => {
          isLoading.current = false;
        },
        onError: () => {
          isLoading.current = false;
        },
      }),
    );
  }, [cloudInsightPage, dispatch, runtimeInsightsCategories]);

  const handleScroll = useCallback(
    e => {
      const pullNext =
        e.target.scrollTop >
        e.target.scrollHeight - e.target.clientHeight - 100;
      if (isLoading.current) {
        return;
      }

      if (pullNext && !cloudInsights.isLoading) {
        isLoading.current = true;
        dispatch(actions.updateCloudInsightPage(cloudInsightPage + 1));
      }
    },
    [cloudInsightPage, cloudInsights.isLoading, dispatch],
  );

  return (
    <Stack h="full">
      <Flex justify="flex-end" w="full">
        <Box w="60%" zIndex={900} pr={10}>
          <Select
            options={runtimeInsightsCategoryOptions}
            value={runtimeInsightsCategories}
            onChange={s => {
              dispatch(actions.updateRuntimeInsightsCategories(s));
            }}
            showIconInValueContainer
            isMulti
            isDisabled={cloudInsights.isLoading}
          />
        </Box>
      </Flex>
      <WithSpinner
        loadStatus={{
          loading: isEmpty(cloudInsights.data) && cloudInsights.isLoading,
        }}
      >
        <Stack
          spacing={0}
          py={6}
          onScroll={handleScroll}
          h={'full'}
          overflow={'auto'}
        >
          {cloudInsights.data.map((insight, index) => {
            return (
              <CloudInsightItem
                item={insight}
                key={
                  insight.event_time +
                  '/' +
                  insight.insight_id +
                  '/' +
                  insight.account_id
                }
                isLast={index === cloudInsights.data.length - 1}
              />
            );
          })}
          {!isEmpty(cloudInsights.data) && cloudInsights.isLoading && (
            <Center pos={'absolute'} left={0} bottom={0} w={'full'} h="100px">
              <CustomSpinner size="lg" />
            </Center>
          )}
        </Stack>
      </WithSpinner>
    </Stack>
  );
};
