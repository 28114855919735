import React, { memo, useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { find, isEmpty, map } from 'lodash';
import filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { WithResourceIcon } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { usePageContentContext } from 'components/Layout';
import { SideNav, SideNavMenuItemProps } from 'components/Navigation';
import { selectApp } from 'containers/App/selectors';
import { ComplianceAssessment } from 'containers/Compliance/Components/ComplianceAssessment';
import { ComplianceDashboard } from 'containers/Compliance/Components/ComplianceDashboard';
import { selectCompliance } from 'containers/Compliance/selectors';
import { useSearchParams } from 'hooks/useSearchParams';

import { actions } from '../slice';

export const ComplianceSummary = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useSearchParams<{ blueprint_id?: string }>();
  const { selectedBlueprint, blueprints } = useSelector(selectCompliance);
  const { cloudAccounts } = useSelector(selectApp);
  const [menuItems, setMenuItems] = useState<SideNavMenuItemProps[]>([]);
  const { contentHeight } = usePageContentContext();

  useEffect(() => {
    dispatch(actions.getBlueprints({}));
  }, [dispatch]);

  useEffect(() => {
    const id = params?.blueprint_id;
    if (id === selectedBlueprint?.id) return;
    if (id && blueprints.isSuccess && blueprints.data?.[id]) {
      dispatch(actions.updateSelectedBlueprint(blueprints.data[id]));
    }
  }, [dispatch, blueprints, params?.blueprint_id, selectedBlueprint?.id]);

  useEffect(() => {
    const data = map(blueprints.data, o => o);
    const custom = filter(data, o => o.created_by !== 'system');
    const standards = filter(
      data,
      o => o.created_by === 'system' && o?.name !== 'Ariksa Best Practices',
    );
    const bestPractices =
      find(
        data,
        o => o.created_by === 'system' && o?.name === 'Ariksa Best Practices',
      ) ?? {};
    if (!params.blueprint_id) {
      !isEmpty(data) &&
        dispatch(
          actions.updateSelectedBlueprint(
            custom?.length ? custom[0] : standards[0],
          ),
        );
    }

    const getMenuItem = item => ({
      title: item?.name,
      key: item?.id,
      icon: (
        <Box
          color={
            item?.compliance || item?.name === IconTypes.AriksaBestPractices
              ? 'black'
              : 'primary'
          }
        >
          {getIcon(
            item?.compliance ||
              (item?.name === IconTypes.AriksaBestPractices
                ? item?.name
                : IconTypes.Policy),
          )}
        </Box>
      ),
    });

    let items: SideNavMenuItemProps[] = [];
    if (custom.length)
      items.push({
        title: 'CUSTOM',
        key: 'custom',
        secondary: map(custom, o => getMenuItem(o) as SideNavMenuItemProps),
        showDivider: true,
      });
    items.push({
      title: 'STANDARDS',
      key: 'standard',
      secondary: map(standards, o => getMenuItem(o) as SideNavMenuItemProps),
      showDivider: true,
    });
    items.push({
      title: 'BEST PRACTICES',
      key: 'bestPractices',
      secondary: [getMenuItem(bestPractices) as SideNavMenuItemProps],
    });

    setMenuItems(items);
  }, [blueprints.data, dispatch, params?.blueprint_id]);

  return isEmpty(cloudAccounts.data) ? (
    <ComplianceAssessment />
  ) : (
    <HStack
      w="full"
      h="full"
      align="start"
      spacing={customTheme.sizes.interCardSpacing}
    >
      <SideNav
        menuItems={menuItems}
        hasContent={false}
        navCompWidth={64}
        showNavHeaderDivider
        navHeader={
          <Box pb={2}>
            <WithResourceIcon
              resourceType={IconTypes.Overview}
              bgColor="primary"
              iconSize="small"
              iconStyles={{ p: 2 }}
            >
              <Box>Dashboard</Box>
            </WithResourceIcon>
          </Box>
        }
        leftAlignSecondaryItems
        current={selectedBlueprint?.id}
        onChange={id => {
          navigate(`/compliance`);
          dispatch(actions.updateSelectedBlueprint(blueprints.data?.[id]));
        }}
        styles={{ menu: { defaultIndex: [0, 1, 2] } }}
        isLoading={blueprints.isLoading}
      />
      <Box flex={1} w="full" h={contentHeight + 'px'}>
        <ComplianceDashboard />
      </Box>
    </HStack>
  );
});
