import React from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { StackedCell } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import {
  CheckmarkCircleOutlineIcon,
  CloseCircleOutlineIcon,
} from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { formatDate } from 'utils/date';

export const TopNewVulnerabilities: React.FC = () => {
  const { topNewVulnerabilities } = useSelector(selectVulnerabilityOverview);
  const navigate = useNavigate();

  const columns = [
    {
      header: 'CVE-ID',
      render: ({ row }) => (
        <StackedCell
          upper={row.vulnerability_id}
          lower={formatDate(row.last_seen, 'DD-MMM-YY')}
          styles={{ upper: { fontWeight: 400 }, lower: { fontSize: 'sm' } }}
        />
      ),
      styles: { w: '30%' },
    },
    {
      header: 'CVSSV3',
      render: ({ row }) => row.cvss_score ?? '-',
      styles: {
        td: { w: '20%' },
        cell: { textAlign: 'right', w: '60%' },
        header: { textAlign: 'center' },
      },
    },
    {
      header: 'ENTITIES',
      render: ({ row }) => formatNumber(row.occurrences, 1),
      styles: {
        td: { w: '20%' },
        cell: { textAlign: 'right', w: '75%' },
        header: { textAlign: 'center' },
      },
    },
    {
      header: 'CONTEXT',
      styles: { w: '20%' },
      render: ({ row }) => (
        <HStack spacing={1} justify="center">
          <CustomTooltip
            label={
              row.patch_available ? 'Patch available' : 'Patch not available'
            }
            width={20}
            textAlign="center"
          >
            <Box
              boxSize={5}
              color={
                row.patch_available
                  ? customTheme.colors.green['300']
                  : 'critical'
              }
            >
              {row.patch_available ? (
                <CheckmarkCircleOutlineIcon />
              ) : (
                <CloseCircleOutlineIcon />
              )}
            </Box>
          </CustomTooltip>
        </HStack>
      ),
    },
  ];

  return (
    <DashboardWidget
      label="New & Updated Vulnerabilities"
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
      content={
        <DashboardTable
          columns={columns}
          data={topNewVulnerabilities.data}
          styles={{ td: { py: 2 } }}
          onRowClick={row =>
            navigate(
              '/visibility/vulnerability?tab=cve&search_term=' +
                row.vulnerability_id,
            )
          }
          isLoading={topNewVulnerabilities.isLoading}
        />
      }
    />
  );
};
