import { VulnerabilitiesApiGetEolDashboardDetailsRequest } from '@ariksa/inventory-core';
import {
  ContextApiGetRiskContextResRequest,
  CountSummary,
  EOLDashboard,
  InsightV2Pagination,
  ResourceSummaryApiGetSummaryByTypeRequest,
  RiskContextResponse,
  VulnerabilitiesApiGetEolEntitiesDashboardDetailsRequest,
} from '@ariksa/inventory-core/api';
import { ChartsApiGetVulnerabilitiesDetailsRequest } from '@ariksa/reporting/api';
import {
  VulnerabilitiesApiGetVulnerabilityCountRequest,
  VulnerabilityCountResponse,
} from '@ariksa/reporting/dist/api';
import {
  BySourcesDashboard,
  CriticalAndHighSeverityVulnerabilities,
  DefaultApiGetBySourcesDashboardDataRequest,
  DefaultApiGetCriticalAndHighSeveritySummaryRequest,
  DefaultApiGetHighestOccurringVulnerabilitiesRequest,
  DefaultApiGetMostWidelyDeployedLibVulnerabilitiesRequest,
  DefaultApiGetTopNewVulnerabilitiesRequest,
  HightestOccurringVulnerabilities,
  MostWidelyDeployedLibVulnerabilities,
  TopVulnerabilities,
} from '@ariksa/scan-analysis/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { InventoryService, ReportingService, ScanAnalysis } from 'api/services';
import { doGetVulnerabilityPrioritization } from 'containers/Visibility/Vulnerabilities/saga';

import { actions } from './slice';

export function* vulnerabilityOverviewSaga() {
  //vulnerabilities
  yield takeLatestAction(
    actions.getTopNewVulnerabilities,
    doGetTopNewVulnerabilities,
  );
  yield takeLatestAction(
    actions.getHighestOccurringVulnerabilities,
    doGetHighestOccurringVulnerabilities,
  );
  yield takeLatestAction(
    actions.getMostWidelyDeployedLibraries,
    doGetMostWidelyDeployedLibraries,
  );
  yield takeLatestAction(
    actions.getVulnerableEntitiesWithSensitiveDataAccess,
    doGetVulnerableEntitiesWithSensitiveDataAccess,
  );

  yield takeLatestAction(
    actions.getCriticalAndHighVulnerabilitiesSummary,
    doGetVulnerabilitiesBySeveritySummary,
  );
  yield takeLatestAction(actions.getEOLSoftware, doGetEOLSoftware);
  yield takeLatestAction(actions.getAllEOLSoftware, doGetAllEOLSoftware);
  yield takeLatestAction(actions.getRiskContext, doGetRiskContext);
  yield takeLatestAction(
    actions.getEOLSoftwareEntities,
    doGetEOLSoftwareEntities,
  );
  yield takeLatestAction(
    actions.getVmVulnerabilitiesCount,
    doGetVMVulnerabilitiesCount,
  );
  yield takeLatestAction(
    actions.getVulnerabilitiesCount,
    doGetVulnerabilitiesCount,
  );
  yield takeLatestAction(
    actions.getVmImageVulnerabilitiesCount,
    doGetVMImageVulnerabilitiesCount,
  );
  yield takeLatestAction(
    actions.getContainerServiceVulnerabilitiesCount,
    doGetContainerServiceVulnerabilitiesCount,
  );
  yield takeLatestAction(
    actions.getContainerVulnerabilitiesCount,
    doGetContainerVulnerabilitiesCount,
  );
  yield takeLatestAction(
    actions.getServerlessVulnerabilitiesCount,
    doGetServerlessVulnerabilitiesCount,
  );
  yield takeLatestAction(
    actions.getVulnerabilityPrioritizationData,
    doGetVulnerabilityPrioritization,
  );
  yield takeLatestAction(
    actions.getVulnerabilitiesByResourceTypes,
    doGetVulnerabilitiesByResourceTypes,
  );
  yield takeLatestAction(
    actions.getExploitableEntitiesToPatch,
    doGetExploitableEntitiesToPatch,
  );
}

/*--------------------------vulnerabilities---------------------------------*/

/*get top new vulnerabilities*/
export function* doGetTopNewVulnerabilities(
  ctx: QueryContext<
    TopVulnerabilities[],
    DefaultApiGetTopNewVulnerabilitiesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getTopNewVulnerabilities(ctx.params),
    {
      errorMsg: 'Failed to get top new vulnerabilities!',
      cacheKey: 'topNewVulnerabilities',
    },
  );
}

/*get highest occurring vulnerabilities*/
export function* doGetHighestOccurringVulnerabilities(
  ctx: QueryContext<
    HightestOccurringVulnerabilities[],
    DefaultApiGetHighestOccurringVulnerabilitiesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getHighestOccurringVulnerabilities(ctx.params),
    {
      errorMsg: 'Failed to get highest occurring vulnerabilities!',
      cacheKey: 'highestOccurringVulnerabilities',
    },
  );
}

/*get most widely deployed libraries*/
export function* doGetMostWidelyDeployedLibraries(
  ctx: QueryContext<
    MostWidelyDeployedLibVulnerabilities[],
    DefaultApiGetMostWidelyDeployedLibVulnerabilitiesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      ScanAnalysis.Default.getMostWidelyDeployedLibVulnerabilities(ctx.params),
    {
      errorMsg: 'Failed to get most widely deployed libraries!',
      cacheKey: 'mostWidelyDeployedLibraries',
    },
  );
}

/*get vulnerable entities with privileged sensitive data access*/
export function* doGetVulnerableEntitiesWithSensitiveDataAccess(
  ctx: QueryContext<CountSummary, ResourceSummaryApiGetSummaryByTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.ResourceSummary.getSummaryByType(ctx.params),
    {
      errorMsg:
        'Failed to get vulnerable entities with privileged sensitive data access!',
      cacheKey: 'vulnerableEntitiesWithSensitiveDataAccess',
    },
  );
}

/*get critical and high severity for OS and technology*/
export function* doGetVulnerabilitiesBySeveritySummary(
  ctx: QueryContext<
    CriticalAndHighSeverityVulnerabilities,
    DefaultApiGetCriticalAndHighSeveritySummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getCriticalAndHighSeveritySummary(ctx.params),
    {
      errorMsg: 'Failed to get vulnerabilities by severity!',
      cacheKey: 'vulnerabilitiesBySeveritySummary',
    },
  );
}

export function* doGetEOLSoftware(
  ctx: QueryContext<
    EOLDashboard,
    VulnerabilitiesApiGetEolDashboardDetailsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Vulnerability.getEolDashboardDetails(ctx.params),
    {
      errorMsg: 'Failed to get end-of-life software!',
      cacheKey: 'eolSoftware',
    },
  );
}

export function* doGetAllEOLSoftware(
  ctx: QueryContext<
    EOLDashboard,
    VulnerabilitiesApiGetEolDashboardDetailsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Vulnerability.getEolDashboardDetails(ctx.params),
    {
      errorMsg: 'Failed to get end-of-life software!',
      //cacheKey: 'eolSoftware',
    },
  );
}

export function* doGetEOLSoftwareEntities(
  ctx: QueryContext<
    InsightV2Pagination,
    VulnerabilitiesApiGetEolEntitiesDashboardDetailsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      InventoryService.Vulnerability.getEolEntitiesDashboardDetails(ctx.params),
    {
      errorMsg: 'Failed to get end-of-life software entities!',
      //cacheKey: 'eolSoftware',
    },
  );
}

export function* doGetVulnerabilitiesCount(
  ctx: QueryContext<
    VulnerabilityCountResponse,
    VulnerabilitiesApiGetVulnerabilityCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Vulnerability.getVulnerabilityCount(ctx.params),
    {
      errorMsg: 'Failed to get vulnerabilities count!',
      cacheKey: 'vulnerabilityReportingResourcesCount',
    },
  );
}

export function* doGetVMVulnerabilitiesCount(
  ctx: QueryContext<
    VulnerabilityCountResponse,
    VulnerabilitiesApiGetVulnerabilityCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Vulnerability.getVulnerabilityCount(ctx.params),
    {
      errorMsg: 'Failed to get VM vulnerability count!',
      cacheKey: 'vmVulnerabilityCount',
    },
  );
}

export function* doGetContainerVulnerabilitiesCount(
  ctx: QueryContext<
    VulnerabilityCountResponse,
    VulnerabilitiesApiGetVulnerabilityCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Vulnerability.getVulnerabilityCount(ctx.params),
    {
      errorMsg: 'Failed to get Container vulnerability count!',
      cacheKey: 'containerVulnerabilityCount',
    },
  );
}

export function* doGetContainerServiceVulnerabilitiesCount(
  ctx: QueryContext<
    VulnerabilityCountResponse,
    VulnerabilitiesApiGetVulnerabilityCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Vulnerability.getVulnerabilityCount(ctx.params),
    {
      errorMsg: 'Failed to get Container Service vulnerability count!',
      cacheKey: 'containerServiceVulnerabilityCount',
    },
  );
}

export function* doGetVMImageVulnerabilitiesCount(
  ctx: QueryContext<
    VulnerabilityCountResponse,
    VulnerabilitiesApiGetVulnerabilityCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Vulnerability.getVulnerabilityCount(ctx.params),
    {
      errorMsg: 'Failed to get VM Image vulnerability count!',
      cacheKey: 'vmImageVulnerabilityCount',
    },
  );
}

export function* doGetServerlessVulnerabilitiesCount(
  ctx: QueryContext<
    VulnerabilityCountResponse,
    VulnerabilitiesApiGetVulnerabilityCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Vulnerability.getVulnerabilityCount(ctx.params),
    {
      errorMsg: 'Failed to get Serverless vulnerability count!',
      cacheKey: 'serverlessVulnerabilityCount',
    },
  );
}

export function* doGetRiskContext(
  ctx: QueryContext<
    Record<string, RiskContextResponse[]>,
    ContextApiGetRiskContextResRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Context.getRiskContextRes(ctx.params),
    {
      errorMsg: 'Failed to get risk context!',
    },
  );
}

export function* doGetVulnerabilitiesByResourceTypes(
  ctx: QueryContext<
    BySourcesDashboard,
    DefaultApiGetBySourcesDashboardDataRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getBySourcesDashboardData(ctx.params),
    {
      errorMsg: 'Failed to get vulnerabilities by resource types!',
      cacheKey: 'vulnerabilitiesByResourceTypes',
    },
  );
}

export function* doGetExploitableEntitiesToPatch(
  ctx: QueryContext<any, ChartsApiGetVulnerabilitiesDetailsRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getVulnerabilitiesDetails(ctx.params),
    {
      errorMsg: 'Failed to get exploitable entities to patch!',
      cacheKey: 'exploitableEntitiesToPatch',
    },
  );
}
