import React, { ReactNode } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { PrimaryIconButton } from 'components/DataEntry';
import { ThirdPartyIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { CDashboard } from 'components/Visualization';
import { IDashboardLayout } from 'components/Visualization/CDashboard/types';

interface Props {
  content?: ReactNode;
  label: ReactNode;
  icon: ReactNode;
  height?: string | number;
  titleBgColor?: string;
  iconBgColor?: string;
  titleFontColor?: string;
  dashboardWidgets?: IDashboardLayout;
  redirectLink: string;
}

export const DashboardOverviewWrapperCard: React.FC<Props> = props => {
  const {
    label,
    content,
    icon,
    height,
    iconBgColor = 'primary',
    titleFontColor = 'black',
    dashboardWidgets,
    redirectLink,
  } = props;
  const { contentHeight } = usePageContentContext();
  const navigate = useNavigate();
  return (
    <Stack h={height || contentHeight + 'px'} pb={2}>
      <HStack
        color="primary"
        bg="white"
        py={2}
        px={3}
        borderRadius={6}
        border="1px solid"
        borderColor={customTheme.colors.gray['100']}
        justify="space-between"
      >
        <PageHeaderWithIcon
          label={label}
          icon={icon}
          fontSize="xl"
          reversed
          border
          iconSize="md"
          borderColor={customTheme.colors.gray['250']}
          iconBgColor={iconBgColor}
          iconStyles={{ borderRadius: 12 }}
          fontColor={titleFontColor}
          labelStyles={{ fontWeight: 600 }}
        />
        <Box>
          <PrimaryIconButton
            aria-label="redirection"
            bg="white"
            color="primary"
            icon={<ThirdPartyIcon />}
            p={1}
            onClick={() => navigate(redirectLink)}
          />
        </Box>
      </HStack>
      <Box flex={1} h="full" py={2} px={0}>
        {dashboardWidgets ? (
          <CDashboard dashboardWidgets={dashboardWidgets} />
        ) : (
          content
        )}
      </Box>
    </Stack>
  );
};
