import {
  AuditTrailServiceApiAuditTrailServiceAccountStatusRequest,
  V1AccountStatusResponse,
} from '@ariksa/audittrail/api';
import {
  BlueprintApiGetAttachedBlueprintSummaryRequest,
  BlueprintApiGetBlueprintComplianceSummaryRequest,
  BlueprintAttachedSummary,
  BlueprintComplianceSummary,
} from '@ariksa/compliance-policies/api';
import {
  AppSchemasInventoryResourceCountDeploymentSummary,
  CountSummary,
  DataSourcesSummary,
  PublicResourceSummary,
  ResourceApiGetDataSourcesSummaryRequest,
  ResourceApiGetDeploymentSummaryRequest,
  ResourceSummaryApiGetPublicExposedResSummaryRequest,
  ResourceSummaryApiGetSummaryByTypeRequest,
} from '@ariksa/inventory-core/api';
import {
  AlertLogsApiGetTicketHistoryRequest,
  AlertLogsApiGetTicketSummaryRequest,
  TicketSummaryResponse,
} from '@ariksa/notification/api';
import {
  ChartsApiGetDashboardResourcesHistoryRequest,
  ChartsApiGetDashboardResourcesRequest,
  ChartsApiGetGovernanceScoresRequest,
  ChartsApiGetTotalAlertsRequest,
  InventoryApiGetResourceCountRequest,
  OrgGovernanceReport,
  ResourceCount,
  ResourceCountResponse,
  TotalAlertsResponse,
} from '@ariksa/reporting/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  AuditTrailApiService,
  authTokenHeader,
  ComplianceService,
  InventoryService,
  NotificationService,
  ReportingService,
} from 'api/services';
import { doGetAlertWorkflows } from 'containers/PolicyHub/AlertWorkflow/saga';
import { doGetAnalyzedSources } from 'containers/Visibility/Data/saga';

import { actions } from './slice';

export function* dashboardOverviewSaga() {
  yield takeLatestAction(actions.getCloudSummary, doGetCloudSummary);
  yield takeLatestAction(actions.getResourcesCount, doGetResourcesCount);
  yield takeLatestAction(actions.getTicketSummary, doGetTicketSummary);

  yield takeLatestAction(actions.getEntitiesHistory, doGetEntitiesHistory);
  yield takeLatestAction(
    actions.getPolicyManagementSummary,
    doGetPolicyManagementSummary,
  );
  yield takeLatestAction(actions.getAlertWorkflows, doGetAlertWorkflows);

  yield takeLatestAction(actions.getTicketHistory, doGetTicketHistory);
  yield takeLatestAction(actions.getGovernanceScore, doGetGovernanceScore);
  yield takeLatestAction(actions.getDataCount, doGetDataCount);
  yield takeLatestAction(
    actions.getInfrastructureCount,
    doGetInfrastructureCount,
  );
  yield takeLatestAction(actions.getComplianceSummary, doGetComplianceSummary);
  yield takeLatestAction(actions.getAlertsHistory, doGetAlertsHistory);
  yield takeLatestAction(
    actions.getAnalyzedDataSourcesCount,
    doGetAnalyzedSources,
  );
  yield takeLatestAction(actions.getAccountStatus, doGetAccountStatus);
  yield takeLatestAction(actions.getVpcStatus, doGetVpcStatus);
  yield takeLatestAction(actions.getDeploymentSummary, doGetDeploymentSummary);
}

//get state of your cloud summary
export function* doGetCloudSummary(
  ctx: QueryContext<CountSummary, ResourceSummaryApiGetSummaryByTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.ResourceSummary.getSummaryByType(ctx.params),
    { errorMsg: 'Failed to get cloud summary!', cacheKey: 'cloudSummary' },
  );
}

//get entities history
export function* doGetEntitiesHistory(
  ctx: QueryContext<
    Record<string, number>,
    ChartsApiGetDashboardResourcesHistoryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getDashboardResourcesHistory(ctx.params),
    {
      errorMsg: 'Failed to get entities history!',
      cacheKey: 'entitiesHistory',
    },
  );
}

//get count and change
export function* doGetPolicyManagementSummary(
  ctx: QueryContext<
    BlueprintAttachedSummary,
    BlueprintApiGetAttachedBlueprintSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.getAttachedBlueprintSummary(ctx.params),
    {
      errorMsg: 'Failed to get policy management summary!',
      cacheKey: 'policyManagementSummary',
    },
  );
}

//get compliance summary
export function* doGetComplianceSummary(
  ctx: QueryContext<
    BlueprintComplianceSummary[],
    BlueprintApiGetBlueprintComplianceSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.getBlueprintComplianceSummary(ctx.params),
    {
      errorMsg: 'Failed to get compliance summary!',
      cacheKey: 'policyManagementSummary',
    },
  );
}

//get count and change
export function* doGetDataCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get resource count!',
      cacheKey: 'dataReportingResourcesCount',
    },
  );
}

export function* doGetInfrastructureCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get resource count!',
      cacheKey: 'infrastructureReportingResourcesCount',
    },
  );
}

//get resources count
export function* doGetResourcesCount(
  ctx: QueryContext<ResourceCount, ChartsApiGetDashboardResourcesRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getDashboardResources(ctx.params),
    { errorMsg: 'Failed to get resources count!', cacheKey: 'resourcesCount' },
  );
}

//get ticket summary
export function* doGetTicketSummary(
  ctx: QueryContext<TicketSummaryResponse, AlertLogsApiGetTicketSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertsLogs.getTicketSummary(ctx.params),
    { errorMsg: 'Failed to get ticket summary!', cacheKey: 'ticketSummary' },
  );
}

//get ticket history
export function* doGetTicketHistory(
  ctx: QueryContext<
    Record<string, number>,
    AlertLogsApiGetTicketHistoryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertsLogs.getTicketHistory(ctx.params),
    { errorMsg: 'Failed to get ticket history!', cacheKey: 'ticketHistory' },
  );
}

//get data sources summary (for data security)
export function* doGetSummaryOfAllDataSources(
  ctx: QueryContext<
    DataSourcesSummary,
    ResourceApiGetDataSourcesSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getDataSourcesSummary(ctx.params),
    {
      errorMsg: 'Failed to get data sources summary!',
      cacheKey: 'summaryOfAllDataSources',
    },
  );
}

//get governance scores
export function* doGetGovernanceScore(
  ctx: QueryContext<OrgGovernanceReport, ChartsApiGetGovernanceScoresRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getGovernanceScores(ctx.params),
    {
      errorMsg: 'Failed to get governance scores!',
      cacheKey: 'governanceScore',
    },
  );
}

//get governance scores
export function* doGetAlertsHistory(
  ctx: QueryContext<TotalAlertsResponse, ChartsApiGetTotalAlertsRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getTotalAlerts(ctx.params),
    {
      errorMsg: 'Failed to get alerts history!',
      cacheKey: 'alertsHistory',
    },
  );
}

/*get publicly exposed data sources*/
export function* doGetPubliclyExposedDataSources(
  ctx: QueryContext<
    PublicResourceSummary[],
    ResourceSummaryApiGetPublicExposedResSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      InventoryService.ResourceSummary.getPublicExposedResSummary(ctx.params),
    {
      errorMsg: 'Failed to get publicly exposed data sources!',
      cacheKey: 'publiclyExposedDataSources',
    },
  );
}

function* doGetAccountStatus(
  ctx: QueryContext<
    V1AccountStatusResponse,
    AuditTrailServiceApiAuditTrailServiceAccountStatusRequest
  >,
) {
  yield call(ctx.fetch, async () =>
    AuditTrailApiService.AuditTrail.auditTrailServiceAccountStatus(
      ctx.params,
      authTokenHeader(),
    ),
  );
}

function* doGetVpcStatus(
  ctx: QueryContext<CountSummary, ResourceSummaryApiGetSummaryByTypeRequest>,
) {
  yield call(ctx.fetch, async () =>
    InventoryService.ResourceSummary.getSummaryByType(ctx.params),
  );
}

export function* doGetDeploymentSummary(
  ctx: QueryContext<
    AppSchemasInventoryResourceCountDeploymentSummary[],
    ResourceApiGetDeploymentSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getDeploymentSummary(ctx.params),
    {
      errorMsg: 'Failed to get deployment summary!',
    },
  );
}
