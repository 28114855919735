import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';

export const InfrastructureThatLacksEncryption: React.FC = () => {
  const { infrastructureThatLacksEncryption } = useSelector(
    selectCloudPostureOverview,
  );
  const navigate = useNavigate();
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  return (
    <>
      <DashboardWidget
        isLoading={infrastructureThatLacksEncryption.isLoading}
        label="Entities Lacking Encryption"
        noData={isEmpty(infrastructureThatLacksEncryption.data)}
        noDataMessage="No insights available"
        content={renderResourcesWithIconAndCount(
          map(infrastructureThatLacksEncryption.data.slice(0, 4), o => ({
            label: o.agnostic_name!,
            iconType: o.agnostic_class!,
            count: formatNumber(o?.resource_count),
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.UnencryptedInfraEntities,
                iconType: o.agnostic_class!,
                resourceType: o.agnostic_class,
                resourceTypeClass: 'agnostic',
                total: o?.resource_count ?? 0,
              });
            },
          })),
        )}
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
