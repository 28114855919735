import React from 'react';

import { Categories } from '@ariksa/inventory-core/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { KeyIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const Credentials: React.FC = () => {
  const { infrastructurePostureSummary } = useSelector(selectDashboard);
  const { data, isLoading } = infrastructurePostureSummary;
  const navigate = useNavigate();
  const { credentialCount } = useSelector(selectDashboard);

  return (
    <SummaryCard
      label="Credentials"
      icon={<KeyIcon />}
      change={{
        isLoading: false,
        first: credentialCount.data.previous_count ?? 0,
        last: credentialCount.data.current_count ?? 0,
        percentChange: credentialCount.data.percentage_change ?? 0,
      }}
      cloudProviders={data?.items?.['ProvidersByLabel']?.['Credential']}
      count={credentialCount.data.current_count ?? 0}
      isLoading={isLoading}
      onClick={() =>
        navigate('/inventory/summary?tab=' + Categories.Credential)
      }
      showPercentChange={true}
      labelTooltip={formatTooltip({
        header: 'Credentials',
        content:
          'Access keys, SSH keys, certificates and other credentials used to authenticate or access resources',
        width: 48,
      })}
    />
  );
};
