import React from 'react';

import {
  Box,
  Center,
  HStack,
  ListItem,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { PrimaryButton } from 'components/DataEntry';
import { CustomerJourneyBulbIcon, IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { DashboardWidgetTooltipIcon } from 'containers/Dashboard/utils/utils';
import { VerticalCountCard } from 'containers/Dashboard/utils/VerticalCountCard';

export const JITAccessRequests: React.FC = () => {
  const { jitSummary } = useSelector(selectIdentityOverview);
  const navigate = useNavigate();

  const items = [
    {
      label: 'JIT Accounts',
      count: jitSummary.data?.jit_enabled_count,
      iconColor: customTheme.colors.gray['300'],
      iconType: IconTypes.Account,
      onClick: () => navigate('/setup/jit?tab=jit-accounts'),
      message: jitSummary.data?.jit_enabled_count + ' Enabled',
      messageBgColor: customTheme.colors.green['100'],
    },
    {
      label: 'JIT Requests',
      count: jitSummary.data?.requests_count,
      iconColor: 'orange',
      iconType: IconTypes.Clock,
      onClick: () => navigate('/setup/jit?tab=jit-access-request'),
      message:
        formatNumber(jitSummary?.data?.approved_requests_count) +
        ' Approved, ' +
        formatNumber(jitSummary.data?.denied_requests_count) +
        ' Denied',
      messageBgColor: colorOpacity(customTheme.colors.primary, 0.2),
    },
    /*{
      label: 'Approved',
      count: jitSummary.data?.approved_requests_count,
      borderColor: 'green.300',
      iconBgColor: colorOpacity(customTheme.colors.green['300'], 0.1),
      iconType: IconTypes.CheckmarkCircle,
      onClick: () => navigate('/setup/jit?tab=jit-access-request'),
    },*/
  ];

  return (
    <DashboardWidget
      label="Just-in-Time (JiT) Access Management"
      //subHeader="Last 30 Days - Daily"
      //jitEnabled={!!jitSummary.data?.jit_enabled_count}
      /*rightHeader={
        <Box w="full" pr={2}>
          {renderCloudIcons(jitSummary.data?.cloud_providers)}
        </Box>
      }*/
      content={
        !jitSummary.data?.jit_enabled_count ? (
          <HStack
            spacing={4}
            align="flex-start"
            fontSize="md"
            justify="center"
            pb={6}
            maxW="55%"
          >
            <Box boxSize={10} color="orange">
              <Center>
                <CustomerJourneyBulbIcon />
              </Center>
            </Box>
            <Stack spacing={4} flex={1}>
              <Box color="primary" cursor="pointer">
                JIT management not enabled for Cloud or SaaS accounts
              </Box>
              <Box>
                <PrimaryButton onClick={() => navigate('/setup/jit/add')}>
                  ENABLE
                </PrimaryButton>
              </Box>
            </Stack>
          </HStack>
        ) : (
          <HStack w="full" h="full" spacing={10} p={2}>
            {map(items, o => (
              <VerticalCountCard {...o} />
            ))}
          </HStack>
        )
      }
      isLoading={jitSummary.isLoading}
      tooltip={{
        tooltip: (
          <Stack spacing={3}>
            <Box>
              JIT permissions allows you to provide just-in-time access to cloud
              and SaaS. This eliminates the timebound risk due to excess
              permissions given to users.
            </Box>
            <Box>You can:</Box>
            <UnorderedList color="primary" spacing={2}>
              <ListItem pl={2}>Enable JIT for an account</ListItem>
              <ListItem pl={2}>Provide fine-grained privileges</ListItem>
              <ListItem pl={2}>Approve using messaging apps</ListItem>
            </UnorderedList>
          </Stack>
        ),
        header: 'JIT Permission Management',
        showArrow: false,
        button: <DashboardWidgetTooltipIcon />,
      }}
    />
  );
};
