import React, { useMemo } from 'react';

import { CloudInsight } from '@ariksa/inventory-core/api';
import { Box, Circle, HStack, Stack, Text } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { renderTime } from 'components/DataDisplay';

interface CloudInsightItemProps {
  item: CloudInsight;
  isLast?: boolean;
}

export const CloudInsightItem = (props: CloudInsightItemProps) => {
  const { item, isLast } = props;

  const circleColor = useMemo(() => {
    switch (item.category) {
      case 'Informational':
        return customTheme.colors.yellow['400'];
      case 'Elevated Risk':
        return customTheme.colors.red['500'];
      default:
        return customTheme.colors.red['500'];
    }
  }, [item.category]);

  return (
    <HStack spacing={5} alignItems={'flex-start'} w="full" pr={10} h="full">
      <Box pos="relative" pt={1} h="full">
        <Circle
          size="20px"
          bg={circleColor}
          border="1px solid"
          borderColor={customTheme.colors.gray['200']}
        />
        {!isLast && (
          <Box
            w={'1px'}
            h="full"
            pos={'absolute'}
            bg={customTheme.colors.gray['100']}
            left={'50%'}
            zIndex={-1}
          />
        )}
      </Box>
      <Stack w="full" pb={10}>
        <HStack spacing={6} justify="space-between">
          <Box fontWeight={600} fontSize="lg">
            {item.category ?? '-'}
          </Box>
          <Text color={customTheme.colors.gray['250']}>
            {renderTime({ value: item.event_time })}
          </Text>
        </HStack>
        <Text>
          <Text
            color="primary"
            whiteSpace="nowrap"
            pr={1}
            display="inline-block"
          >
            {item.account_id + ':'}
          </Text>
          {item.description ?? '-'}
        </Text>
      </Stack>
    </HStack>
  );
};
