import {
  SensitiveDataByRegion,
  SensitiveDataByType,
  VisibilityDataApiGetSensitiveDataByRegionRequest,
} from '@ariksa/data-scanning';
import {
  DataFootprintAnalyzed,
  PageDocumentClassificationAggregateInfo,
  PageDocumentClassificationInfo,
  PageResourcesRead,
  PageSensitiveDataInfo,
  PageSourceBySensitiveData,
  ResourceDistributionCount,
  ResourceGroup,
  ResourcesApiGetDataTypeSensitiveSourcesRequest,
  ResourcesApiGetResourceGroupsRequest,
  ResourcesApiGetResourcesByGroupRequest,
  ResourcesApiGetResourcesCategoriesCountRequest,
  ResourcesApiGetSensitiveDataSourcesCountRequest,
  ResourcesApiGetSourcesDistributionCountRequest,
  SensitiveDataApiGetFilesBySensitiveDataRequest,
  SensitiveDataApiGetSensitiveDataLabelsCountRequest,
  SensitiveDataByAccount,
  SensitiveDataBySource,
  TrendRegionsInfo,
  TrendSourcesInfo,
  TrendTotalSizeInfo,
  TrendTypesInfo,
  VisibilityDataApiGetDocumentClassificationBySubtypeAndSourceRequest,
  VisibilityDataApiGetDocumentClassificationBySubtypesRequest,
  VisibilityDataApiGetDocumentClassificationByTypeAndSourceRequest,
  VisibilityDataApiGetDocumentClassificationByTypesRequest,
  VisibilityDataApiGetSensitiveDataByAccountRequest,
  VisibilityDataApiGetSensitiveDataBySourcesRequest,
  VisibilityDataApiGetSensitiveDataInfoTableRequest,
  VisibilityDataApiGetTrendDataAnalyzedRequest,
  VisibilityDataApiGetTrendDataInfoGraphsRequest,
  VisibilityDataApiGetTrendRegionsInfoGraphRequest,
  VisibilityDataApiGetTrendSourcesInfoRequest,
  VisibilityDataApiGetTrendTypesInfoGraphRequest,
} from '@ariksa/data-scanning/api';
import {
  TrendDataInfo,
  VisibilityDataApiGetSensitiveDataByTypeRequest,
} from '@ariksa/data-scanning/dist/api';
import {
  CloudInsightApiGetRuntimeInsightsForDataSourcesRequest,
  ContextApiGetRiskContextResDataScanRequest,
  RiskContextResponse,
  RuntimeInsightBySources,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummaryResponse,
  AlertHistoryResponse,
  AlertsApiGetAggregatedAlertsSummaryRequest,
  AlertsApiGetAlertHistoryRequest,
  AlertsApiGetAlertingResourcesCountRequest,
} from '@ariksa/notification/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  DataScanningService,
  InventoryService,
  NotificationService,
} from 'api/services';
import { doGetDataSourcesPieChartData } from 'containers/Dashboard/DataSecurityOverview/saga';
import {
  doGetPubliclyExposedDataSources,
  doGetSummaryOfAllDataSources,
} from 'containers/Dashboard/Overview/saga';

import { actions } from './slice';

export function* dataDashboardSaga() {
  yield takeLatestAction(actions.getDataSources, doGetDataSources);
  yield takeLatestAction(actions.getDataTypes, doGetDataTypes);
  yield takeLatestAction(actions.getHistoricalTrend, doGetHistoricalTrend);
  yield takeLatestAction(
    actions.getSensitiveDataByRegion,
    doGetSensitiveDataByRegion,
  );
  yield takeLatestAction(
    actions.getDataSourcesByRegion,
    doGetSensitiveDataByRegion,
  );
  yield takeLatestAction(
    actions.getSensitiveDataByType,
    doGetSensitiveDataByType,
  );
  yield takeLatestAction(
    actions.getSensitiveDataBySources,
    doGetSensitiveDataBySources,
  );
  yield takeLatestAction(
    actions.getRiskContextForDataTypes,
    doGetRiskContextForDataTypes,
  );
  yield takeLatestAction(
    actions.getDataSourcesPieChartData,
    doGetDataSourcesPieChartData,
  );
  yield takeLatestAction(
    actions.getSensitiveDataByRegionTrend,
    doGetSensitiveDataByRegionTrend,
  );
  yield takeLatestAction(
    actions.getSensitiveDataByTypeTrend,
    doGetSensitiveDataByTypeTrend,
  );
  yield takeLatestAction(actions.getSourcesByAccounts, doGetSourcesByAccounts);
  yield takeLatestAction(
    actions.getDataSourcesAnalyzedInfo,
    doGetDataSourcesAnalyzedInfo,
  );
  yield takeLatestAction(actions.getDataAnalyzedInfo, doGetDataAnalyzedInfo);
  yield takeLatestAction(actions.getDataFindings, doGetDataFindings);
  yield takeLatestAction(actions.getAnalyzedSources, doGetAnalyzedSources);
  yield takeLatestAction(actions.getAlertingCount, doGetAlertingCount);
  yield takeLatestAction(
    actions.getSensitiveDataLabelCount,
    doGetSensitiveDataLabelCount,
  );
  yield takeLatestAction(
    actions.getSensitiveDataSourceCount,
    doGetSensitiveDataSourceCount,
  );
  yield takeLatestAction(
    actions.getPubliclyExposedDataSources,
    doGetPubliclyExposedDataSources,
  );
  yield takeLatestAction(
    actions.getSummaryOfAllDataSources,
    doGetSummaryOfAllDataSources,
  );
  yield takeLatestAction(actions.getFilesByDataType, doGetFilesByDataType);
  yield takeLatestAction(actions.getDataTypeSources, doGetDataTypeSources);
  yield takeLatestAction(actions.getDocumentTypes, doGetDocumentTypes);
  yield takeLatestAction(actions.getDocumentSubTypes, doGetDocumentSubTypes);
  yield takeLatestAction(
    actions.getSensitiveDataByAgnosticSources,
    doGetSensitiveAgnosticDataSourcesCount,
  );
  yield takeLatestAction(
    actions.getAllDataResourceTypes,
    doGetDataResourceTypes,
  );
  yield takeLatestAction(
    actions.getDataSourcesByDocumentType,
    doGetDataSourcesByDocumentType,
  );
  yield takeLatestAction(
    actions.getDataSourcesByDocumentSubType,
    doGetDataSourcesByDocumentSubType,
  );
  yield takeLatestAction(actions.getTotalDataFindings, doGetTotalDataFindings);
  yield takeLatestAction(
    actions.getDataSourcesByResourceType,
    doGetDataSourcesByResourceType,
  );
  yield takeLatestAction(
    actions.getHighRiskDataSources,
    doGetHighRiskDataSources,
  );
  yield takeLatestAction(actions.getRuntimeInsights, doGetRuntimeInsights);
}

export function* doGetDataSources(
  ctx: QueryContext<
    PageResourcesRead,
    ResourcesApiGetDataTypeSensitiveSourcesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getDataTypeSensitiveSources(ctx.params),
    { errorMsg: 'Failed to get data sources!' },
  );
}

export function* doGetDataTypes(
  ctx: QueryContext<
    PageSensitiveDataInfo,
    VisibilityDataApiGetSensitiveDataInfoTableRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getSensitiveDataInfoTable(ctx.params),
    { errorMsg: 'Failed to get data types!', cacheKey: 'dataTypes' },
  );
}

export function* doGetDataResourceTypes(
  ctx: QueryContext<ResourceGroup[], ResourcesApiGetResourceGroupsRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getResourceGroups(ctx.params),
    { errorMsg: 'Failed to get data resource types!' },
  );
}

export function* doGetDocumentTypes(
  ctx: QueryContext<
    PageDocumentClassificationAggregateInfo,
    VisibilityDataApiGetDocumentClassificationByTypesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      DataScanningService.Visibility.getDocumentClassificationByTypes(
        ctx.params,
      ),
    { errorMsg: 'Failed to get document types!', cacheKey: 'documentTypes' },
  );
}

export function* doGetDocumentSubTypes(
  ctx: QueryContext<
    PageDocumentClassificationAggregateInfo,
    VisibilityDataApiGetDocumentClassificationBySubtypesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      DataScanningService.Visibility.getDocumentClassificationBySubtypes(
        ctx.params,
      ),
    { errorMsg: 'Failed to get document types!' },
  );
}

export function* doGetDataSourcesByDocumentType(
  ctx: QueryContext<
    PageDocumentClassificationInfo,
    VisibilityDataApiGetDocumentClassificationByTypeAndSourceRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      DataScanningService.Visibility.getDocumentClassificationByTypeAndSource(
        ctx.params,
      ),
    { errorMsg: 'Failed to get data sources!' },
  );
}

export function* doGetDataSourcesByResourceType(
  ctx: QueryContext<PageResourcesRead, ResourcesApiGetResourcesByGroupRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getResourcesByGroup(ctx.params),
    { errorMsg: 'Failed to get data sources!' },
  );
}

export function* doGetDataSourcesByDocumentSubType(
  ctx: QueryContext<
    PageDocumentClassificationInfo,
    VisibilityDataApiGetDocumentClassificationBySubtypeAndSourceRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      DataScanningService.Visibility.getDocumentClassificationBySubtypeAndSource(
        ctx.params,
      ),
    { errorMsg: 'Failed to get data sources!' },
  );
}

export function* doGetHistoricalTrend(
  ctx: QueryContext<
    TrendDataInfo,
    VisibilityDataApiGetTrendDataInfoGraphsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getTrendDataInfoGraphs(ctx.params),
    {
      errorMsg: 'Failed to get data historical trend!',
      cacheKey: 'historicalTrend',
    },
  );
}

export function* doGetRiskContextForDataTypes(
  ctx: QueryContext<
    Record<string, RiskContextResponse[]>,
    ContextApiGetRiskContextResDataScanRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Context.getRiskContextResDataScan(ctx.params),
    { errorMsg: 'Failed to get risk context!' },
  );
}

export function* doGetSensitiveDataBySources(
  ctx: QueryContext<
    SensitiveDataBySource,
    VisibilityDataApiGetSensitiveDataBySourcesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getSensitiveDataBySources(ctx.params),
    { errorMsg: 'Failed to get sensitive data by sources!' },
  );
}

export function* doGetSensitiveDataByType(
  ctx: QueryContext<
    SensitiveDataByType,
    VisibilityDataApiGetSensitiveDataByTypeRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getSensitiveDataByType(ctx.params),
    {
      errorMsg: 'Failed to get sensitive data by type!',
      cacheKey: 'sensitiveDataByType',
    },
  );
}

export function* doGetSensitiveDataByRegion(
  ctx: QueryContext<
    SensitiveDataByRegion,
    VisibilityDataApiGetSensitiveDataByRegionRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getSensitiveDataByRegion(ctx.params),
    {
      errorMsg: 'Failed to get sensitive data by region!',
      cacheKey: 'sensitiveDataByRegion',
    },
  );
}

export function* doGetSensitiveDataByRegionTrend(
  ctx: QueryContext<
    Record<string, TrendRegionsInfo>,
    VisibilityDataApiGetTrendRegionsInfoGraphRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getTrendRegionsInfoGraph(ctx.params),
    {
      errorMsg: 'Failed to get sensitive data by region - trend!',
      cacheKey: 'sensitiveDataByRegionTrend',
    },
  );
}

export function* doGetSensitiveDataByTypeTrend(
  ctx: QueryContext<
    Record<string, TrendTypesInfo>,
    VisibilityDataApiGetTrendTypesInfoGraphRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getTrendTypesInfoGraph(ctx.params),
    {
      errorMsg: 'Failed to get sensitive data by type - trend!',
      cacheKey: 'sensitiveDataByTypeTrend',
    },
  );
}

export function* doGetSourcesByAccounts(
  ctx: QueryContext<
    SensitiveDataByAccount,
    VisibilityDataApiGetSensitiveDataByAccountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getSensitiveDataByAccount(ctx.params),
    {
      errorMsg: 'Failed to get sources by accounts!',
      cacheKey: 'sourcesByAccount',
    },
  );
}

export function* doGetDataSourcesAnalyzedInfo(
  ctx: QueryContext<
    TrendSourcesInfo,
    VisibilityDataApiGetTrendSourcesInfoRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getTrendSourcesInfo(ctx.params),
    {
      errorMsg: 'Failed to get data sources analyzed info!',
      cacheKey: 'dataSourcesAnalyzedInfo',
    },
  );
}

export function* doGetDataAnalyzedInfo(
  ctx: QueryContext<
    TrendTotalSizeInfo,
    VisibilityDataApiGetTrendDataAnalyzedRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getTrendDataAnalyzed(ctx.params),
    {
      errorMsg: 'Failed to get data analyzed info!',
      cacheKey: 'dataAnalyzedInfo',
    },
  );
}

export function* doGetDataFindings(
  ctx: QueryContext<AlertHistoryResponse, AlertsApiGetAlertHistoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertHistory(ctx.params),
    { errorMsg: 'Failed to get data findings!', cacheKey: 'dataFindings' },
  );
}

export function* doGetTotalDataFindings(
  ctx: QueryContext<
    AggregatedAlertsSummaryResponse,
    AlertsApiGetAggregatedAlertsSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAggregatedAlertsSummary(ctx.params),
    { errorMsg: 'Failed to get data findings!' },
  );
}

export function* doGetAnalyzedSources(
  ctx: QueryContext<
    DataFootprintAnalyzed,
    ResourcesApiGetResourcesCategoriesCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getResourcesCategoriesCount(ctx.params),
    {
      errorMsg: 'Failed to get analyzed sources!',
      cacheKey: 'analyzedDataSourcesCount',
    },
  );
}

export function* doGetSensitiveDataSourceCount(
  ctx: QueryContext<number, ResourcesApiGetSensitiveDataSourcesCountRequest>,
) {
  yield call(
    ctx.fetch,
    () =>
      DataScanningService.Resources.getSensitiveDataSourcesCount(ctx.params),
    {
      errorMsg: 'Failed to get sensitive data source count!',
      cacheKey: 'sensitiveDataSourceCount',
    },
  );
}

export function* doGetSensitiveDataLabelCount(
  ctx: QueryContext<
    Record<string, number>,
    SensitiveDataApiGetSensitiveDataLabelsCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      DataScanningService.SensitiveData.getSensitiveDataLabelsCount(ctx.params),
    {
      errorMsg: 'Failed to get sensitive data label count!',
      cacheKey: 'sensitiveDataLabelCount',
    },
  );
}

export function* doGetDataTypeSources(
  ctx: QueryContext<
    PageResourcesRead,
    ResourcesApiGetDataTypeSensitiveSourcesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getDataTypeSensitiveSources(ctx.params),
    {
      errorMsg: 'Failed to get sources for data type!',
      cacheKey: 'dataTypeSources',
    },
  );
}

export function* doGetFilesByDataType(
  ctx: QueryContext<
    PageSourceBySensitiveData,
    SensitiveDataApiGetFilesBySensitiveDataRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.SensitiveData.getFilesBySensitiveData(ctx.params),
    { errorMsg: 'Failed to get files by data type!' },
  );
}

export function* doGetAlertingCount(
  ctx: QueryContext<number, AlertsApiGetAlertingResourcesCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertingResourcesCount(ctx.params),
    {
      errorMsg: 'Failed to get alerting count!',
      cacheKey: 'dataSourcesAlertingCount',
    },
  );
}

export function* doGetSensitiveAgnosticDataSourcesCount(
  ctx: QueryContext<
    ResourceDistributionCount,
    ResourcesApiGetSourcesDistributionCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getSourcesDistributionCount(ctx.params),
    {
      errorMsg: 'Failed to get sensitive agnostic data sources count!',
      cacheKey: 'sensitiveAgnosticDataSourcesCount',
    },
  );
}

export function* doGetHighRiskDataSources(
  ctx: QueryContext<
    PageResourcesRead,
    ResourcesApiGetDataTypeSensitiveSourcesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getDataTypeSensitiveSources(ctx.params),
    {
      errorMsg: 'Failed to get high risk data sources!',
      cacheKey: 'highRiskDataSources',
    },
  );
}

export function* doGetRuntimeInsights(
  ctx: QueryContext<
    RuntimeInsightBySources[],
    CloudInsightApiGetRuntimeInsightsForDataSourcesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      InventoryService.CloudInsight.getRuntimeInsightsForDataSources(
        ctx.params,
      ),
    {
      errorMsg: 'Failed to get runtime insights for data sources!',
      cacheKey: 'runtimeInsightsDataSources',
    },
  );
}
