import {
  CloudAccountApiDeleteCloudAccountByUuidRequest,
  CloudAccountApiDiscoverInventoryRequest,
  CloudAccountApiGetCloudAccountByUuidRequest,
  CloudAccountApiUpdateCloudAccountRequest,
  CloudAccountGetResponse,
  CodeRepositoryApiOnboardCodeRepositoryRequest,
} from '@ariksa/cloud-account/api';
import {
  AlertWorkflowApiGetWorkflowsByClientRequest,
  AlertWorkflowResponse,
  ClientConfig,
  ClientResponse,
  ClientsApiAddClientRequest,
  ClientsApiDeleteClientConfigRequest,
  ClientsApiEditClientRequest,
  ClientsApiGetClientByIdRequest,
  ClientsApiGetClientDetailsRequest,
  ClientsApiGetJiraProjectsRequest,
  ClientsApiValidateClientConfigRequest,
  JiraProjects,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  IdentityProviderNameService,
  IdentityProviderService,
} from 'api/auth/api.pb';
import { IAuthID, IRepoID, IRepoName } from 'api/dev_tools_integration/types';
import {
  CloudAccountService,
  NotificationService,
  serviceClient,
} from 'api/services';
import { Api } from 'app/api';
import { selectUser } from 'containers/App/selectors';
import request from 'utils/request';

import { callApiFn } from '../../../../utils/saga';

import {
  selectBitbucketIntegrations,
  selectGithubIntegrations,
  selectGitLabIntegrations,
} from './selectors';
import { actions } from './slice';
import { CreateProviderProps, DeleteProviderProps } from './types';

export function* integrationsSaga() {
  // yield takeLatest(actions.loadGithubRepos.type, doLoadGithubRepos);
  yield takeLatest(actions.addNewGithubAccount.type, doAddNewGithubAccount);
  yield takeLatest(actions.updateGithubRepoName.type, doLoadGithubRepoBranches);

  yield takeLatest(actions.addNewGitLabAccount.type, doAddNewGitLabAccount);
  yield takeLatest(actions.updateGitLabRepoName.type, doLoadGitLabRepoBranches);

  yield takeLatest(
    actions.updateBitbucketRepoName.type,
    doLoadBitbucketRepoBranches,
  );
  yield takeLatest(
    actions.addNewBitbucketAccount.type,
    doAddNewBitbucketAccount,
  );

  yield takeLatest(actions.loadClients.type, doGetClients);
  yield takeLatestAction(actions.deleteClient, doDeleteClient);
  yield takeLatestAction(actions.createClient, doCreateClient);
  yield takeLatestAction(
    actions.loadJiraProjectDetails,
    doGetJiraProjectDetails,
  );
  yield takeLatestAction(actions.getClientDetailsById, doGetClientDetailsById);
  yield takeLatestAction(actions.updateClientById, doUpdateClient);
  yield takeLatestAction(actions.validateClient, doValidateClient);
  yield takeLatestAction(
    actions.getAlertWorkflowAttachedToClient,
    doGetAlertWorkflowsAttachedToClient,
  );

  // SSO
  yield takeLatest(actions.getProviderNames.type, doGetProviderNames);
  yield takeLatest(actions.getSSOProviders.type, doGetSSOProviders);
  yield takeLatest(actions.createSSOProvider.type, doCreateSSOProvider);
  yield takeLatest(actions.deleteSSOProvider.type, doDeleteSSOProvider);

  //Identity And Access Providers and snowflake
  yield takeLatestAction(actions.getAccountByID, doGetAccountByID);
  yield takeLatestAction(actions.updateAccount, doUpdateAccount);
  yield takeLatestAction(actions.deleteAccount, doDeleteAccount);
  yield takeLatestAction(actions.rediscoverInventory, doRediscoverInventory);

  //SaaS
  yield takeLatestAction(
    actions.onboardCodeRepository,
    doOnboardCodeRepository,
  );
}

export function* doDeleteSSOProvider(
  action: PayloadAction<DeleteProviderProps>,
) {
  const { onSuccess, ...rest } = action.payload;

  yield call(callApiFn, {
    fn: IdentityProviderService.Delete,
    data: rest,
    onSuccess: actions.deleteSSOProviderSuccess,
    onError: actions.deleteSSOProviderError,
    errorTitle: 'Failed to create sso providers',
    onResponse: (res, err) => {
      if (!err) {
        onSuccess();
      }
      return err ? err : res;
    },
  });
}

export function* doCreateSSOProvider(
  action: PayloadAction<CreateProviderProps>,
) {
  const { onRedirect, ...rest } = action.payload;

  yield call(callApiFn, {
    fn: IdentityProviderService.Create,
    data: rest,
    onSuccess: actions.createSSOProviderSuccess,
    onError: actions.createSSOProviderError,
    errorTitle: 'Failed to create sso providers',
    onResponse: (res, err) => {
      if (!err) {
        onRedirect();
      }
      return err ? err : res;
    },
  });
}

export function* doGetSSOProviders() {
  const { info } = yield select(selectUser);

  yield call(callApiFn, {
    fn: IdentityProviderService.List,
    data: { organization: info.organization },
    onSuccess: actions.getSSOProvidersSuccess,
    onError: actions.getSSOProvidersError,
    errorTitle: 'Failed to get sso providers',
  });
}

export function* doGetProviderNames() {
  const { info } = yield select(selectUser);

  yield call(callApiFn, {
    fn: IdentityProviderNameService.List,
    data: { organization: info.organization },
    onSuccess: actions.getProviderNamesSuccess,
    onError: actions.getProviderNamesError,
    errorTitle: 'Failed to get sso provider names',
  });
}

export function* doLoadGithubRepoBranches(
  action: PayloadAction<IRepoID & IRepoName>,
) {
  const { repo_id, repo_name } = action.payload;
  const github = yield select(selectGithubIntegrations);
  const requestUrl = Api.getGitHubRepoBranches({
    repo_name,
    user_name: github.account.data.username,
  });

  try {
    const branches = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Pizzly-Auth-Id': github.authId,
      },
    });
    yield put(
      actions.loadGithubRepoBranchesSuccess({ repoId: repo_id, branches }),
    );
  } catch (error) {
    yield put(actions.loadGithubRepoBranchesError({ repoId: repo_id, error }));
  }
}

export function* doLoadGithubRepos(authId: string, totalRepos: number) {
  // FIXME: use totalRepos/perPage instead of hardcoded value
  for (let i = 1; i <= Math.ceil(totalRepos / 100); i++) {
    yield fork(doLoadGithubReposPage, authId, i);
  }
}

export function* doLoadGithubReposPage(authId: string, page: number) {
  const requestUrl = Api.getGitHubRepos({ page, per_page: 100 });
  try {
    const repos = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Pizzly-Auth-Id': authId,
      },
    });
    yield put(actions.loadGithubReposSuccess(repos));
  } catch (err) {
    yield put(actions.loadGithubReposError(err));
  }
}

export function* doAddNewGithubAccount(action: PayloadAction<IAuthID>) {
  const { auth_id } = action.payload;
  const requestUrl = Api.getGitHubUserInfo();
  try {
    const user = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Pizzly-Auth-Id': auth_id,
      },
    });

    const { login: username, id, total_private_repos, public_repos } = user;
    const totalRepos = total_private_repos + public_repos;
    yield put(
      actions.addNewGithubAccountSuccess({ auth_id, username, id, totalRepos }),
    );
    yield call(doLoadGithubRepos, auth_id, totalRepos);
  } catch (err) {
    yield put(actions.addNewGithubAccountError(err));
  }
}

export function* doLoadGitLabRepoBranches(
  action: PayloadAction<IRepoID & IRepoName>,
) {
  const { repo_id } = action.payload;
  const gitlab = yield select(selectGitLabIntegrations);
  const requestUrl = Api.getGitLabRepoBranches({
    repo_id,
  });

  try {
    const branches = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Pizzly-Auth-Id': gitlab.authId,
      },
    });
    yield put(
      actions.loadGitLabRepoBranchesSuccess({ repoId: repo_id, branches }),
    );
  } catch (error) {
    yield put(actions.loadGitLabRepoBranchesError({ repoId: repo_id, error }));
  }
}

export function* doLoadGitLabRepos(authId: string) {
  const requestUrl = Api.getGitLabRepos();
  try {
    const repos = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Pizzly-Auth-Id': authId,
      },
    });
    yield put(actions.loadGitLabReposSuccess(repos));
  } catch (err) {
    yield put(actions.loadGitLabReposError(err));
  }
}

export function* doAddNewGitLabAccount(action: PayloadAction<IAuthID>) {
  const { auth_id } = action.payload;
  const requestUrl = Api.getGitLabUserInfo();
  try {
    const user = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Pizzly-Auth-Id': auth_id,
      },
    });

    const { username, id } = user;
    yield put(
      actions.addNewGitLabAccountSuccess({ authId: auth_id, username, id }),
    );
    yield call(doLoadGitLabRepos, auth_id);
  } catch (err) {
    yield put(actions.addNewGitLabAccountError(err));
  }
}

// Bitbucket

export function* doAddNewBitbucketAccount(action: PayloadAction<IAuthID>) {
  const { auth_id } = action.payload;
  const requestUrl = Api.getBitbucketUserInfo();
  try {
    const user = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Pizzly-Auth-Id': auth_id,
      },
    });

    const { username, account_id: id } = user;
    // const totalRepos = total_private_repos + public_repos;
    yield put(
      actions.addNewBitbucketAccountSuccess({ authId: auth_id, username, id }),
    );
    yield call(doLoadBitbucketRepos, auth_id, username);
  } catch (err) {
    yield put(actions.addNewBitbucketAccountError(err));
  }
}

export function* doLoadBitbucketRepos(authId: string, username: string) {
  const requestUrl = Api.getBitbucketRepos({ username, pagelen: 100 });
  try {
    const repos = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Pizzly-Auth-Id': authId,
      },
    });

    yield put(actions.loadBitbucketReposSuccess(repos.values));
  } catch (err) {
    yield put(actions.loadBitbucketReposError(err));
  }
}

export function* doLoadBitbucketRepoBranches(
  action: PayloadAction<IRepoID & IRepoName>,
) {
  const { repo_id, repo_name } = action.payload;
  const bitbucket = yield select(selectBitbucketIntegrations);
  const requestUrl = Api.getBitbucketRepoBranches({
    repo_name,
    user_name: bitbucket.account.data.username,
  });

  try {
    const branches = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Pizzly-Auth-Id': bitbucket.authId,
      },
    });

    yield put(
      actions.loadBitbucketRepoBranchesSuccess({
        repo_id,
        branches: branches.values,
      }),
    );
  } catch (error) {
    yield put(
      actions.loadBitbucketRepoBranchesError({ repoId: repo_id, error }),
    );
  }
}

export function* doGetClients(
  action: PayloadAction<ClientsApiGetClientDetailsRequest>,
) {
  yield call(serviceClient, {
    fn: async () =>
      NotificationService.Clients.getClientDetails(action.payload),
    onSuccess: actions.clientsLoaded,
    onError: actions.clientsLoadingError,
    mapResponse: res => ({
      clientName: action.payload.clientName,
      data: res?.data,
    }),
    errorTitle: 'Failed to get clients!',
  });
}

/*create client*/
function* doCreateClient(ctx: QueryContext<any, ClientsApiAddClientRequest>) {
  const fetcher = () => NotificationService.Clients.addClient(ctx.params);
  yield call(ctx.fetch, fetcher);
}

/*Update Client Details by ID*/
function* doUpdateClient(
  ctx: QueryContext<ClientResponse, ClientsApiEditClientRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Clients.editClient(ctx.params),
  );
}

/*delete client*/
export function* doDeleteClient(
  ctx: QueryContext<any, ClientsApiDeleteClientConfigRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Clients.deleteClientConfig(ctx.params),
  );
}

/*Get client details by ID*/
function* doGetClientDetailsById(
  ctx: QueryContext<ClientResponse, ClientsApiGetClientByIdRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Clients.getClientById(ctx.params),
  );
}

/*Get jira projects and users*/
function* doGetJiraProjectDetails(
  ctx: QueryContext<JiraProjects, ClientsApiGetJiraProjectsRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Clients.getJiraProjects(ctx.params),
  );
}

/*update account*/
function* doUpdateAccount(
  ctx: QueryContext<any, CloudAccountApiUpdateCloudAccountRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.updateCloudAccount(ctx.params),
    { errorMsg: 'Failed to update account details!' },
  );
}

/*delete account*/
function* doDeleteAccount(
  ctx: QueryContext<any, CloudAccountApiDeleteCloudAccountByUuidRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.deleteCloudAccountByUuid(ctx.params),
    { errorMsg: 'Failed to delete account!' },
  );
}

/*get accounts by id*/
function* doGetAccountByID(
  ctx: QueryContext<
    CloudAccountGetResponse,
    CloudAccountApiGetCloudAccountByUuidRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.getCloudAccountByUuid(ctx.params),
    { errorMsg: 'Failed to get account details!' },
  );
}

export function* doRediscoverInventory(
  ctx: QueryContext<any, CloudAccountApiDiscoverInventoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.discoverInventory(ctx.params),
    {
      errorMsg: 'Request failed to do rediscovery!',
      successMsg: 'Rediscovery is in progress!',
    },
  );
}

export function* doGetAlertWorkflowsAttachedToClient(
  ctx: QueryContext<
    AlertWorkflowResponse[],
    AlertWorkflowApiGetWorkflowsByClientRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertWorkflow.getWorkflowsByClient(ctx.params),
    {
      errorMsg: 'Failed to get alert workflows attached to client!',
    },
  );
}

export function* doOnboardCodeRepository(
  ctx: QueryContext<any, CodeRepositoryApiOnboardCodeRepositoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CodeRepository.onboardCodeRepository(ctx.params),
    {
      errorMsg: 'Failed to onboard code repository!',
      successMsg: 'Code repository onboarded successfully.',
    },
  );
}

export function* doValidateClient(
  ctx: QueryContext<ClientConfig, ClientsApiValidateClientConfigRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.validateClientConfig(ctx.params),
    {
      errorMsg: 'Failed to validate client!',
    },
  );
}
