import React, { useEffect, useState } from 'react';

import { EnvironmentRead } from '@ariksa/compliance-policies/api';
import {
  Box,
  Center,
  Flex,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import {
  Card,
  PageHeaderWithIcon,
  renderTime,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ActionButton, AddButton } from 'components/DataEntry';
import { EditIcon, EnvironmentIcon, TrashIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { selectApp } from 'containers/App/selectors';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { AccountsUnderEnvironment } from 'containers/Setup/Environment/Components/AccountsUnderEnvironment';
import { AttachedBlueprints } from 'containers/Setup/Environment/Components/AttachedBlueprints';
import { NoAccountOnboardedEnvironmentMessage } from 'containers/Setup/Environment/Components/NoAccountOnboardedEnvironmentMessage';
import { renderCriteria } from 'containers/Setup/Environment/Components/utils';
import { selectEnvironment } from 'containers/Setup/Environment/selectors';

import { actions } from '../slice';

export const EnvironmentTable: React.FC = () => {
  const { contentHeight } = usePageContentContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { environments } = useSelector(selectEnvironment);
  const { cloudAccounts } = useSelector(selectApp);
  const { toCloudAccountId } = useCloudAccountId();
  const [showAttachedBlueprints, setShowAttachedBlueprints] = useState(false);
  const [currentRow, setCurrentRow] = useState<EnvironmentRead>(
    {} as EnvironmentRead,
  );
  const [envAccountsMapping, setEnvAccountsMapping] = useState({});
  const { getEnvironmentAccountsMapping } = useEnvironmentOptions();
  const showAccounts = useDisclosure();

  useEffect(() => {
    setEnvAccountsMapping(getEnvironmentAccountsMapping());
  }, [getEnvironmentAccountsMapping]);

  useDeepCompareEffect(() => {
    dispatch(actions.getEnvironments({}));
    dispatch(actions.resetFormData());
  }, [dispatch]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      accessor: 'name',
      align: 'left',
      render: ({ row }) => (
        <WithResourceIcon resourceType={row?.environment_type}>
          {row.name}
        </WithResourceIcon>
      ),
    },
    {
      header: 'Business Critical',
      accessor: 'is_business_critical',
      align: 'center',
      render: ({ value }) => (value ? 'Yes' : 'No'),
    },
    {
      header: 'Accounts',
      accessor: 'account_ids',
      render: ({ value }) =>
        value?.length ? (
          <CustomTooltip
            label={
              <Stack>
                <Box>Accounts</Box>
                {map(value, o => (
                  <Box>{toCloudAccountId(o)}</Box>
                ))}
              </Stack>
            }
          >
            {value?.length}
          </CustomTooltip>
        ) : (
          0
        ),
    },
    {
      header: 'Criteria',
      accessor: 'criteria',
      align: 'left',
      render: renderCriteria,
    },
    {
      header: 'Created By',
      accessor: 'created_by',
      align: 'left',
    },
    {
      header: 'Created At',
      accessor: 'created_at',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: ({ row }) => {
        return (
          <Center>
            <ActionButton
              label={'Edit'}
              icon={<EditIcon />}
              isDisabled={row.created_by?.toString().toLowerCase() === 'system'}
              onClick={() => navigate(`/setup/environment/edit/${row?.id}`)}
            />
            <ActionButton
              label="Delete"
              icon={<TrashIcon />}
              color={'red'}
              isDisabled={row.created_by?.toString().toLowerCase() === 'system'}
              onClick={() => {
                setShowAttachedBlueprints(true);
                setCurrentRow(row);
              }}
            />
          </Center>
        );
      },
    },
  ];

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      {isEmpty(cloudAccounts.data) && cloudAccounts.loaded ? (
        <NoAccountOnboardedEnvironmentMessage />
      ) : (
        <Stack h="full" spacing={5} w="full">
          <HStack w="full" justify="space-between">
            <PageHeaderWithIcon
              label="Environment"
              icon={<EnvironmentIcon w="full" h="full" />}
            />
            <Flex>
              <AddButton
                label="Add Environment"
                onClick={() => {
                  navigate('/setup/environment/add');
                }}
              />
            </Flex>
          </HStack>
          <Box flex={1}>
            <Table
              data={environments.data}
              columns={columns}
              isLoading={environments.isLoading}
              onRowClick={row => {
                showAccounts.onOpen();
                setCurrentRow(row);
              }}
            />
          </Box>
        </Stack>
      )}
      {showAttachedBlueprints && (
        <AttachedBlueprints
          isOpen={showAttachedBlueprints}
          onClose={() => setShowAttachedBlueprints(false)}
          environment={currentRow}
        />
      )}
      {showAccounts.isOpen && (
        <AccountsUnderEnvironment
          accounts={envAccountsMapping[currentRow?.id]}
          isOpen={showAccounts.isOpen}
          onClose={showAccounts.onClose}
          environment={currentRow}
        />
      )}
    </Card>
  );
};
