/**
 *
 * Cloud Account Wizard
 *
 */

import React, { memo, useEffect } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Card } from 'components/DataDisplay';
import { usePageContentContext } from 'components/Layout';
import { EditIACRepository } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/IACRepository/EditIACRepository';
import { OnboardIACRepository } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/IACRepository/OnboardIACRepository';
import { EditAzureAccount } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/MicrosoftAzure/EditAzureAccount';
import { OnboardMicrosoftAzureForm } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/MicrosoftAzure/OnboardMicrosoftAzureForm';
import { EditAWSAccount } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/EditAWSAccount';
import { EditGCPAccount } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardGcp/EditGCPAccount';
import { OnboardGCPForm } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardGcp/OnboardGCPForm';
import { EditSnowflake } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/Snowflake/EditSnowflake';
import { actions } from 'containers/Setup/CloudAccounts/slice';
import { useInjector } from 'utils/inject';

import { OnboardAws } from './Components/OnboardAws';
import { cloudAccountWizardSaga } from './saga';
import { reducer, sliceKey } from './slice';

export const CloudAccountWizard = memo(() => {
  useInjector(sliceKey, reducer, cloudAccountWizardSaga);
  const params = useParams<{
    provider: string;
    id: string;
    accountType: string;
  }>();
  const { contentHeight } = usePageContentContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!params.id) {
      dispatch(
        actions.loadCloudAccountDetails({
          q: {
            uuid: params.id,
          },
        }),
      );

      return function cleanup() {
        dispatch(actions.setIsEdit(false));
      };
    }
  }, [dispatch, params]);

  const getComponent = () => {
    switch (params?.provider) {
      case CloudProviders.Gitlab:
      case CloudProviders.GitlabIac:
      case CloudProviders.Github:
      case CloudProviders.GithubIac:
      case CloudProviders.Bitbucket:
      case CloudProviders.BitbucketIac:
        return !!params.id ? (
          <EditIACRepository />
        ) : (
          <OnboardIACRepository provider={params?.provider} />
        );
      case CloudProviders.Gcp:
        return !!params.id ? <EditGCPAccount /> : <OnboardGCPForm />;
      case CloudProviders.Snowflake:
        return !!params.id && <EditSnowflake />;
      case CloudProviders.Azure:
        return !!params?.id ? (
          <EditAzureAccount provider={CloudProviders.Azure} />
        ) : (
          <OnboardMicrosoftAzureForm />
        );
      case CloudProviders.AzureAd:
        return (
          !!params.id && <EditAzureAccount provider={CloudProviders.AzureAd} />
        );
      default:
        return !!params.id ? <EditAWSAccount /> : <OnboardAws />;
    }
  };

  return (
    <Card
      styles={{ card: { pl: 24, h: contentHeight + 'px', w: 'full', pt: 8 } }}
    >
      <Box pl={12}>{getComponent()}</Box>
    </Card>
  );
});
