import React, { FC } from 'react';

import { NoSetup } from 'components/DataDisplay/Utils/NoSetup';

export const WorkflowManagementMessage: FC = () => {
  return (
    <NoSetup
      header="Workflow Management"
      headerMessage="Ariksa workflows provide policy-based routing for issues and insights. You can dynamically segment issues based on
multiple criteria for notifications (like Slack), ticketing (like Jira), SIEM enrichment or take other custom actions"
      steps={[
        'Add 3rd party integrations for notification (ex: Slack) and ticketing (ex: Jira)',
        'Edit the default workflow to use these integrations to route issues',
        'Create custom workflows if needed for policy-based routing',
      ]}
      footer="ADD INTEGRATIONS"
      footerLink="/setup/integrations"
    />
  );
};
