import { AIBasedAPISelectionParameterExtractionApiExtractParametersRequest } from '@ariksa/ai-engine';
import { AIAgentsApiGetResourceSummaryRequest } from '@ariksa/ai-engine/api';
import { WorkflowApiGetLatestRequest, WorkflowRead } from '@ariksa/awm/api';
import {
  BlueprintApiAddExceptionRequest,
  BlueprintApiGetBlueprintsRequest,
  BlueprintExceptionRead,
  PageBluePrintsRead,
} from '@ariksa/compliance-policies/api';
import { FileDataApiGetScannersRequest } from '@ariksa/data-scanning/api';
import {
  TagsApiGetTagKeysRequest,
  TagsApiGetTagValueRequest,
} from '@ariksa/inventory-core';
import {
  ContextApiGetRiskContextResRequest,
  InsightV2ApiInsightV2AllRequest,
  InsightV2Pagination,
  LabelResponse,
  ResourceApiGetRegionsRequest,
  RiskContextResponse,
  TagsApiGetLabelsRequest,
} from '@ariksa/inventory-core/api';
import {
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
  AlertResourceResponse,
  AlertsApiGetResourceDetailsRequest,
  AlertWorkflowResponse,
  ClientsApiGetClientDetailsRequest,
  ClientsApiNotifyClientRequest,
} from '@ariksa/notification/api';
import {
  FaqApiGetAllQuestionsWithAnswersRequest,
  FaqRead,
  FeatureRead,
  JourneyApiGetFeatureStatusRequest,
} from '@ariksa/profile/api';
import { ReportsApiSendToEsRequest } from '@ariksa/reporting';
import { ScannerInfo } from '@ariksa/scan-analysis/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { each, map, reduce, values } from 'lodash';
import forEach from 'lodash/forEach';
import { QueryAction, QueryStatus } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { onApiCall, onApiCallError, onApiCallSuccess } from 'api/call_status';
import { SharedState, TagValuesResponse } from 'containers/SharedState/types';
import { createSlice } from 'utils/@reduxjs/toolkit';

// The initial state of the SharedState container
export const initialState: SharedState = {
  regions: QueryState.init<string[]>([]),
  tagKeys: QueryState.init<string[]>([]),
  tagValues: QueryState.init({}),
  lastRefreshed: QueryState.init(''),
  notifyClient: QueryState.init({}),
  faq: QueryState.init([]),
  journey: {
    myTasks: QueryState.init({}),
    taskStatus: {},
  },
  labels: QueryState.init([]),
  insightV2All: QueryState.init({} as InsightV2Pagination),
  riskContext: QueryState.init({}),
  alertResourceTypes: QueryState.init([]),
  blueprints: QueryState.init([]),
  workflows: QueryState.init([]),
  exportAlerts: QueryState.init({}),
  aiParamExtraction: QueryState.init({}),
  aiResourceSummary: QueryState.init({}),
  addException: QueryState.init({}),
  snapshotStatus: QueryState.init({}),
  alertListStatus: QueryState.init({}),

  //scanner info
  dataScannerInfo: QueryState.init({}),
  vulnerabilityScannerInfo: QueryState.init({}),
  isDataScannerDeployed: {},
  isVulnerabilityScannerDeployed: {},

  //search term
  searchTerm: '',
  finalSearchTerm: '',

  clients: {
    loading: false,
    error: false,
    slack: [],
    jira: [],
    servicenow: [],
    teams: [],
    email: [],
    sns: [],
    elasticsearch: [],
  },
};

const sharedStateSlice = createSlice({
  name: 'sharedState',
  initialState,
  reducers: {
    /*get regions*/
    getRegions(state, action: QueryAction<any, ResourceApiGetRegionsRequest>) {
      state.regions = QueryState.next(state.regions, action);
    },

    /*get tag keys*/
    getTagKeys(state, action: QueryAction<string[], TagsApiGetTagKeysRequest>) {
      state.tagKeys = QueryState.next(state.tagKeys, action);
    },

    /*get tag values*/
    getTagValues(
      state,
      action: QueryAction<TagValuesResponse, TagsApiGetTagValueRequest>,
    ) {
      const status = QueryState.emptyFromAction(action);
      state.tagValues.error = status.error;
      state.tagValues.isLoading = status.isLoading;
      state.tagValues.isSuccess = status.isSuccess;
      state.tagValues.isError = status.isError;
      if (action.payload.data && action.payload.data?.key)
        state.tagValues.data = {
          ...state.tagValues.data,
          [action.payload.data?.key]: action.payload.data?.values,
        };
    },

    /*Load last discovery time*/
    getLastDiscoveryTime(
      state,
      action: QueryAction<WorkflowRead[], WorkflowApiGetLatestRequest>,
    ) {
      state.lastRefreshed = QueryState.next(state.lastRefreshed, action, {
        mapData: r => r?.[0].created_at || '',
      });
    },

    /*send notification / createTicket*/
    notifyClient(
      state,
      action: QueryAction<any, ClientsApiNotifyClientRequest>,
    ) {
      state.notifyClient = QueryState.next(state.notifyClient, action);
    },

    /*get faq*/
    getFAQ(
      state,
      action: QueryAction<FaqRead[], FaqApiGetAllQuestionsWithAnswersRequest>,
    ) {
      state.faq = QueryState.next(state.faq, action);
    },

    /*get my tasks*/
    getMyTasks(state, action: QueryAction<FeatureRead[]>) {
      state.journey.myTasks = QueryState.next(state.journey.myTasks, action, {
        mapData: r => {
          let items = {};
          each(r, o => (items[o.id] = o));
          return items;
        },
      });
    },

    getTaskStatus(
      state,
      action: QueryAction<FeatureRead, JourneyApiGetFeatureStatusRequest>,
    ) {
      const id = action.payload.q.featureId;
      if (!state.journey.taskStatus[id!]) {
        state.journey.taskStatus[id!] = QueryState.init({} as any, {
          status: QueryStatus.pending,
        });
        return;
      } else {
        state.journey.taskStatus[id!] = QueryState.next(
          state.journey.taskStatus[id!],
          action,
        );
      }
      if (action.payload.status === QueryStatus.fulfilled)
        state.journey.myTasks[id!] = action.payload;
    },

    /*get labels*/
    getLabels(
      state,
      action: QueryAction<LabelResponse[], TagsApiGetLabelsRequest>,
    ) {
      state.labels = QueryState.next(state.labels, action);
    },

    /*get resources*/
    getInsightV2All(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2AllRequest>,
    ) {
      state.insightV2All = QueryState.next(state.insightV2All, action);
    },

    /*add exception*/
    addException(
      state,
      action: QueryAction<
        BlueprintExceptionRead,
        BlueprintApiAddExceptionRequest
      >,
    ) {
      state.addException = QueryState.next(state.addException, action);
    },

    /*get risk context*/
    getRiskContext(
      state,
      action: QueryAction<
        Record<string, RiskContextResponse[]>,
        ContextApiGetRiskContextResRequest
      >,
    ) {
      state.riskContext = QueryState.next(state.riskContext, action);
    },

    /*get resource types for which alerts are enabled*/
    getAlertResourceTypes(
      state,
      action: QueryAction<
        AlertResourceResponse,
        AlertsApiGetResourceDetailsRequest
      >,
    ) {
      state.alertResourceTypes = QueryState.next(
        state.alertResourceTypes,
        action,
        { mapData: res => map(res.resources, o => o?.resource_type) },
      );
    },

    /*get blueprints*/
    getBlueprints(
      state,
      action: QueryAction<PageBluePrintsRead, BlueprintApiGetBlueprintsRequest>,
    ) {
      state.blueprints = QueryState.next(state.blueprints, action, {
        mapData: res => {
          /*const items: Record<string, any> = {};
          forEach(res.items, o => (items[o.id] = o));
          state.blueprintsMapping = items;*/
          return res?.items ?? [];
        },
      });
    },

    /*export alerts*/
    exportAlertsToElasticSearch(
      state,
      action: QueryAction<any, ReportsApiSendToEsRequest>,
    ) {
      state.exportAlerts = QueryState.next(state.exportAlerts, action);
    },

    /*get workflows*/
    getWorkflows(state, action: QueryAction<AlertWorkflowResponse[]>) {
      state.workflows = QueryState.next(state.workflows, action);
    },

    /*get ai resource summary*/
    getAIResourceSummary(
      state,
      action: QueryAction<any, AIAgentsApiGetResourceSummaryRequest>,
    ) {
      state.aiResourceSummary = QueryState.next(
        state.aiResourceSummary,
        action,
      );
    },

    /*extract parameters for ai search*/
    extractParametersForSearch(
      state,
      action: QueryAction<
        any,
        AIBasedAPISelectionParameterExtractionApiExtractParametersRequest
      >,
    ) {
      state.aiParamExtraction = QueryState.next(
        state.aiParamExtraction,
        action,
        {
          mapData: r => {
            const isEmpty = values(r)?.every(o => o === null);
            if (isEmpty) return { search: state.searchTerm };
            else return r;
          },
        },
      );
    },
    resetAIParamExtraction(state) {
      state.aiParamExtraction.data = initialState.aiParamExtraction.data;
    },
    setAIParamExtractionSearchTerm(state, action: PayloadAction<string>) {
      state.aiParamExtraction.data = { search: action.payload };
    },
    setAIParams(state, action: PayloadAction<Record<string, any>>) {
      state.aiParamExtraction.data = action.payload;
    },

    /*get snapshot status*/
    getSnapshotStatus(
      state,
      action: QueryAction<WorkflowRead[], WorkflowApiGetLatestRequest>,
    ) {
      state.snapshotStatus = QueryState.next(state.snapshotStatus, action, {
        mapData: res => {
          let status: Record<string, WorkflowRead> = {};
          forEach(res, o => (status[o.account_id!] = o));
          return status;
        },
      });
    },

    /*updateSearchTerm*/
    updateSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    updateFinalSearchTerm(state, action: PayloadAction<string>) {
      state.finalSearchTerm = action.payload;
    },

    //get alert workflow status (for ex. for slack, jira tickets etc.)
    getAlertListStatus(
      state,
      action: QueryAction<
        AlertLogsResponse[],
        AlertLogsApiGetLogsForIdsRequest
      >,
    ) {
      state.alertListStatus = QueryState.next(state.alertListStatus, action, {
        mapData: r => {
          return reduce(
            r,
            (obj, v) => {
              return {
                ...obj,
                [v.alert_id]: v.logs,
              };
            },
            {},
          );
        },
      });
    },

    /*get scanner info*/
    getVulnerabilityScannerInfo(
      state,
      action: QueryAction<Record<string, ScannerInfo[]>>,
    ) {
      state.vulnerabilityScannerInfo = QueryState.next(
        state.vulnerabilityScannerInfo,
        action,
      );
    },
    getDataScannerInfo(
      state,
      action: QueryAction<Record<string, any>, FileDataApiGetScannersRequest>,
    ) {
      state.dataScannerInfo = QueryState.next(state.dataScannerInfo, action);
    },
    updateIsDataScannerDeployed(
      state,
      action: PayloadAction<Record<string, boolean>>,
    ) {
      state.isDataScannerDeployed = action.payload;
    },
    updateIsVulnerabilityScannerDeployed(
      state,
      action: PayloadAction<Record<string, boolean>>,
    ) {
      state.isVulnerabilityScannerDeployed = action.payload;
    },

    //get clients
    getClients(
      state,
      action: PayloadAction<ClientsApiGetClientDetailsRequest>,
    ) {
      const { clientName } = action.payload;
      if (clientName) {
        state.clients[clientName] = [];
      }
      onApiCall(state.clients);
    },
    clientsLoaded(
      state,
      action: PayloadAction<{ client_name: string; data: any }>,
    ) {
      if (action.payload.client_name)
        state.clients[action.payload.client_name] = action.payload.data;
      else {
        state.clients.slack = [];
        state.clients.email = [];
        state.clients.servicenow = [];
        state.clients.teams = [];
        state.clients.jira = [];
        state.clients.sns = [];
        state.clients.elasticsearch = [];
        forEach(action.payload.data, o => state.clients[o.client_name].push(o));
      }
      onApiCallSuccess(state.clients);
    },
    clientsLoadingError(state, action: PayloadAction<any>) {
      state.clients[action.payload.client_name] = [];
      onApiCallError(state.clients, action.payload);
    },
  },
});

export const { actions, reducer, name: sliceKey } = sharedStateSlice;
