import React, { FC } from 'react';

import { NoSetup } from 'components/DataDisplay/Utils/NoSetup';

export const NoVulnerabilityScannerDeployed: FC = () => {
  return (
    <NoSetup
      header="Outpost for Vulnerability Not Deployed"
      headerMessage="You have no Outposts for Vulnerability in the selected environment.
            Deploy these for automatic prioritization of software patches based
            on deep, contextual analysis, business and environmental"
      steps={[
        'Deploy Outpost for Vulnerability for scanning from a central\n' +
          '              account',
        'Automatically assess containers, VMs, serverless and images',
        'Streamline patch management to automatically prioritized hosts',
      ]}
      footer="OUTPOST FOR VULNERABILITY"
      footerLink="'/setup/outpost/add/vulnerability'"
    />
  );
};
