import { useCallback } from 'react';

import { each, includes, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { selectApp } from 'containers/App/selectors';
import { actions } from 'containers/SharedState/slice';

export const useScannerInfo = () => {
  const dispatch = useDispatch();
  const { environments } = useSelector(selectApp);

  const getScannerDeployedInfo = useCallback(
    scanners => {
      let items: Record<string, boolean> = {};
      const scannerAccounts = Object.keys(scanners ?? {});
      each(environments.data, o => {
        items[o.id] = some(o.account_ids, a => includes(scannerAccounts, a));
      });
      return items;
    },
    [environments.data],
  );

  const onSuccessDataScanner = useCallback(
    (res?: any) => {
      dispatch(
        actions.updateIsDataScannerDeployed(getScannerDeployedInfo(res)),
      );
    },
    [dispatch, getScannerDeployedInfo],
  );

  const onSuccessVulnerabilityScanner = useCallback(
    (res?: any) => {
      dispatch(
        actions.updateIsVulnerabilityScannerDeployed(
          getScannerDeployedInfo(res),
        ),
      );
    },
    [dispatch, getScannerDeployedInfo],
  );

  return {
    onSuccessDataScanner,
    onSuccessVulnerabilityScanner,
  };
};
