import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { Box, useDisclosure } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { useSelector } from 'react-redux';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';

export const InsightsForCloudNetworking: React.FC = () => {
  const { insightsForCloudNetworking } = useSelector(
    selectCloudPostureOverview,
  );
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  const getPanel = key => {
    switch (key) {
      case 'Public IPs':
        return PanelsEnum.ExternallyAccessiblePublicIps;
      case 'Public subnets':
        return PanelsEnum.ExternallyAccessiblePublicSubnets;
      case 'Anomalous traffic':
        return PanelsEnum.AnomalousActivityMachines;
      case IconTypes['Public VMs with SSH open']:
        return PanelsEnum.OpenSshPublicMachines;
      default:
        return PanelsEnum.OpenSshMachines;
    }
  };

  return (
    <>
      <DashboardWidget
        isLoading={insightsForCloudNetworking.isLoading}
        label="Insights for Cloud Networking"
        noData={isEmpty(insightsForCloudNetworking.data)}
        noDataMessage="No insights available"
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
        content={
          <Box w="full" h="full" overflow="auto">
            {renderResourcesWithIconAndCount(
              map(insightsForCloudNetworking.data, (count, key) => ({
                label: key!,
                icon: getIcon(key),
                count: formatNumber(count),
                iconColor: 'critical',
                onClick: () => {
                  allEntitiesDisclosure.onOpen();
                  setDetails({
                    panel: getPanel(key),
                    iconType: key,
                    total: count,
                  });
                },
              })),
            )}
          </Box>
        }
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
