import React from 'react';

import { Box, Text } from '@chakra-ui/react';

import step1 from 'app/images/slack/OAuthToken/Step-1.svg';
import step2 from 'app/images/slack/OAuthToken/Step-2.svg';
import step3 from 'app/images/slack/OAuthToken/Step-3.svg';
import step4 from 'app/images/slack/OAuthToken/Step-4.svg';
import step5 from 'app/images/slack/OAuthToken/Step-5.svg';
import step6 from 'app/images/slack/OAuthToken/Step-6.svg';
import step7 from 'app/images/slack/OAuthToken/Step-7.svg';
import step8a from 'app/images/slack/OAuthToken/Step-8-1.svg';
import step8b from 'app/images/slack/OAuthToken/Step-8-2.svg';

export const steps = [
  {
    description: (
      <Text>
        Log in to your workspace in Slack via a web browser and navigate to the{' '}
        <Box
          as="a"
          href="https://api.slack.com/apps"
          target="_blank"
          color="primary"
          borderBottom="1px"
          borderColor="primary"
        >
          API Control Panel
        </Box>
        . Now click on the <Text as="b">‘Create an App’</Text> button
      </Text>
    ),
    image: step1,
    alt: 'Get Started to Create an App',
  },
  {
    description: (
      <Text>
        Provide a name of your app and select a workspace you have admin access
        to. Click on <Text as="b">‘Create App’</Text>.
      </Text>
    ),
    image: step2,
    alt: 'Create a Slack App',
  },
  {
    description: (
      <Text>
        Once your app is created, for this app to be able to post messages to a
        channel you need to grant permissions to send messages. To do this,
        select <Text as="b">’OAuth & Permissions’</Text> from the left
        navigation.
      </Text>
    ),
    image: step3,
    alt: 'Generate OAuth Token',
  },
  {
    description: (
      <Text>
        Scroll to <Text as="b">’Bot Token Scopes’</Text> to provide permissions
        required for Ariksa to post message to your public channel. Refer to the
        pic for token scopes needed.
      </Text>
    ),
    pt: 0,
    image: step4,
    alt: 'Define Scope for App',
  },
  {
    description: (
      <Text>
        Scroll back up on the OAuth & Permissions page and click the{' '}
        <Text as="b">‘Install App to Workspace’</Text>
        button at the top.
      </Text>
    ),
    image: step5,
    alt: 'Install App to Workspace',
  },
  {
    description: (
      <Text>
        Review the actions that the app can undertake and click{' '}
        <Text as="b">‘Allow’</Text>.
      </Text>
    ),
    pt: 0,
    image: step6,
    alt: 'Approve App Access to Workspace',
  },
  {
    description: (
      <Text>
        Copy the ‘Bot User OAuth Token’. You will need to paste this as OAuth
        Token in Ariksa
      </Text>
    ),
    image: step7,
    alt: 'Copy OAuth Token to Ariksa',
  },
  {
    description: (
      <Text>
        As a final step, invite the newly create app from the public channel
        that you intend to use
      </Text>
    ),
    image: step8a,
    image2: step8b,
    alt: 'Copy OAuth Token to Ariksa',
    alt2: 'Copy OAuth Token to Ariksa2',
  },
];
