import React from 'react';

import { Categories } from '@ariksa/inventory-core/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorOpacity, customTheme } from 'theme';

import { formatTooltip } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { NatGatewayIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { getSumOfValues } from 'containers/Dashboard/utils/utils';

export const NetworkEntities: React.FC = () => {
  const { infrastructurePostureSummary } = useSelector(selectDashboard);
  const { data, isLoading } = infrastructurePostureSummary;
  const navigate = useNavigate();
  const { networkCount } = useSelector(selectDashboard);

  return (
    <SummaryCard
      label="Network Entities"
      icon={<NatGatewayIcon />}
      change={{
        isLoading: false,
        first: networkCount.data.previous_count ?? 0,
        last: networkCount.data.current_count ?? 0,
        percentChange: networkCount.data.percentage_change ?? 0,
      }}
      cloudProviders={data?.items?.['ProvidersByLabel']?.['Network']}
      count={getSumOfValues(data?.items?.['Network'])}
      isLoading={isLoading}
      onClick={() => navigate('/inventory/summary?tab=' + Categories.Network)}
      info={{
        message:
          formatNumber(data?.items?.['Network']?.VPC || 0) +
          ' VPCs, ' +
          formatNumber(data?.items?.['Network']?.['Subnets'] || 0) +
          ' Subnets',
        bg: colorOpacity(customTheme.colors.primary, 0.3),
      }}
      showPercentChange={true}
      labelTooltip={formatTooltip({
        header: 'Network entities',
        content:
          'Networking elements that help manage ingress and egress traffic to your cloud',
        width: 48,
      })}
    />
  );
};
