import React, { useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { map, orderBy, sum } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { getSensitiveLabelColor } from 'containers/Visibility/Data/Components/hooks/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const SensitiveDataByType: React.FC = () => {
  const { labelCount } = useSelector(selectDataDashboard);
  const [sortedLabels, setSortedLabels] = useState<Record<string, any>[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getTitle = type => {
      switch (type) {
        case 'pii':
          return 'PII';
        case 'phi':
          return 'PHI';
        case 'pci':
          return 'PCI';
        case 'credential':
          return 'Credential';
        default:
          return type;
      }
    };
    setSortedLabels(
      orderBy(
        map(labelCount.data, (count, key) => ({
          label: getTitle(key),
          count,
          bg: getSensitiveLabelColor(key),
        })),
        ['count'],
        ['desc'],
      ),
    );
    setTotal(sum(Object.values(labelCount.data)));
  }, [labelCount.data]);

  const columns = [
    {
      header: <Box pl={9}>LABEL</Box>,
      accessor: 'region',
      render: ({ row }) => (
        <HStack spacing={4}>
          <Box
            boxSize={5}
            border="1px solid"
            borderColor={customTheme.colors.gray['200']}
            bg={row.bg}
            borderRadius={6}
          />
          <Box>{row.label}</Box>
        </HStack>
      ),
      styles: { td: { w: '70%' } },
    },
    {
      header: 'COUNT',
      render: ({ row }) => formatNumber(row.count, 1),
      styles: {
        cell: { textAlign: 'right', pr: 12 },
        header: { textAlign: 'right', pr: 14 },
      },
    },
  ];

  return (
    <DashboardWidget
      label="Sensitive Data By Labels"
      subHeader={formatNumber(total, 1) + ' record' + (total === 1 ? '' : 's')}
      content={
        <DashboardTable
          columns={columns}
          data={sortedLabels}
          isLoading={labelCount.isLoading}
        />
      }
    />
  );
};
