import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IconTypes } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';

export const PolicyManagement: React.FC = () => {
  const { policyManagement } = useSelector(selectDashboardOverview);
  const { environmentMapping } = useEnvironmentOptions();
  const { environmentId, accountId } = useAccessBoundary();
  const { policies, workflows } = policyManagement;
  const navigate = useNavigate();

  const items = useMemo(
    () => [
      {
        label: 'Cloud Accounts with Policies',
        count: !!accountId
          ? 1
          : environmentMapping?.[environmentId!]?.account_ids?.length || 0,
        //isLoading: .isLoading,
        iconType: IconTypes.Account,
        onClick: () => navigate('/policy-hub/blueprint'),
      },
      {
        label: 'Policies Enforced',
        count: policies.data?.policies || 0,
        isLoading: policies.isLoading,
        iconType: IconTypes.List,
        onClick: () => navigate('/policy-hub/policy'),
      },
      {
        label: 'Policy Exceptions',
        count: policies.data?.exceptions || 0,
        isLoading: policies.isLoading,
        iconType: IconTypes.Exception,
        onClick: () => navigate('/policy-hub/blueprint'),
      },
      {
        label: 'Active Workflows',
        count: workflows.data?.length || 0,
        isLoading: workflows.isLoading,
        iconType: IconTypes.Documents,
        onClick: () => navigate('/policy-hub/workflow'),
      },
    ],
    [
      policies.data,
      policies.isLoading,
      workflows.data,
      workflows.isLoading,
      environmentId,
      accountId,
      environmentMapping,
      navigate,
    ],
  );

  return (
    <DashboardWidget
      label="Policy Management"
      content={renderResourcesWithIconAndCount(items)}
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
    />
  );
};
