/**
 *
 * Onboard Google Cloud
 *
 */

import React, { memo, useEffect, useState } from 'react';

import { CloudProviders } from '@ariksa/cloud-account';
import { Screens } from '@ariksa/inventory-core';
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { Form } from 'components/DataEntry/Form';
import {
  CheckmarkCircleIcon,
  CopyIcon,
  GoogleCloudIcon,
  QuestionCircleIcon,
  RoleIcon,
  ThirdPartyIcon,
} from 'components/Icons';
import { errorToast } from 'components/Toast';
import { accordionStyles } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardGcp/styles';
import {
  gcpPermissionList,
  renderAccordionButton,
  renderCopyIcon,
  renderRequiredSteps,
} from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardGcp/utils';
import { ReadOnlyTag } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/utils/ReadOnlyTag';
import { selectCloudAccountWizard } from 'containers/Setup/CloudAccounts/CloudAccountWizard/selectors';
import { actions } from 'containers/Setup/CloudAccounts/CloudAccountWizard/slice';
import { selectSetup } from 'containers/Setup/selectors';
import { actions as setupActions } from 'containers/Setup/slice';

export const OnboardGCPForm = memo(() => {
  const navigate = useNavigate();
  const helpDrawer = useDisclosure();
  const { onboardingAccount } = useSelector(selectCloudAccountWizard);
  const { configCommands } = useSelector(selectSetup);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState<Record<string, any>>({});
  const [accountNameUsedError, setAccountNameUsedError] = useState('');
  const [name, setName] = useState('');
  const [serviceAccountEmailID, setServiceAccountEmailID] = useState('');
  const [copied, setCopied] = useState('');

  // get account config
  useEffect(() => {
    dispatch(
      setupActions.getConfigCommands({
        q: {
          screen: Screens.Onboarding,
        },
      }),
    );
  }, [dispatch]);

  const onClickAccordionButton = item => {
    helpDrawer.onOpen();
    setSelectedItem(item);
  };

  const steps = [
    {
      label: 'Enable Google APIs',
      helpHeader: 'Enable Google APIs',
      headerIcon: <CheckmarkCircleIcon />,
      iconBgColor: 'green.300',
      helpContent: (
        <Stack spacing={4}>
          <Box fontWeight={600} fontSize="md">
            Execute the following command in CloudShell from Google console
          </Box>
          <Box h={32} bg={customTheme.colors.gray['50']} p={4} borderRadius={6}>
            {
              configCommands.data?.cloud_commands_map?.gcp?.onboarding
                ?.enable_google_api
            }
          </Box>
        </Stack>
      ),
      secondary: [
        {
          label: 'Enable Google APIs',
          icon: <CopyIcon p={0.5} />,
          tooltip: 'Copy command',
          copyText:
            configCommands.data?.cloud_commands_map?.gcp?.onboarding
              ?.enable_google_api,
        },
        {
          label: 'Redirect to Google Cloud console',
          icon: <ThirdPartyIcon />,
          url: 'https://console.cloud.google.com',
          tooltip: 'Redirect to Google console. Manually execute command',
        },
      ],
    },
    {
      label: 'Create a service account for Ariksa',
      helpHeader: 'Create Service Account for Ariksa',
      headerIcon: <RoleIcon />,
      iconBgColor: 'primary',
      helpContent: (
        <Stack spacing={4}>
          <Stack>
            <Box fontWeight={600} fontSize="md">
              Service account must include the following roles:
            </Box>
            <Accordion allowMultiple>
              {map(gcpPermissionList, o => (
                <AccordionItem {...accordionStyles.item}>
                  {({ isExpanded }) => (
                    <>
                      {renderAccordionButton(
                        o,
                        isExpanded,
                        onClickAccordionButton,
                        false,
                      )}
                      <AccordionPanel {...accordionStyles.panel}>
                        <Box px={6} py={3}>
                          {o.description}
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Stack>
          <Box borderBottom="1px solid" borderColor="gray.200" />
          <Box fontWeight={600} pt={2} fontSize="md">
            Copy and paste the following script in Cloudshell
          </Box>
          <Box h={32} bg={customTheme.colors.gray['50']} p={4} borderRadius={6}>
            {
              configCommands.data?.cloud_commands_map?.gcp?.onboarding
                ?.create_service_account
            }
          </Box>
        </Stack>
      ),
      secondary: [
        {
          label: 'Use Cloud Deployment to create Service Account',
          icon: <CopyIcon p={0.5} />,
          tooltip: 'Copy command',
          copyText:
            configCommands.data?.cloud_commands_map?.gcp?.onboarding
              ?.create_service_account,
        },
        {
          label: 'Redirect to Google Cloud console',
          icon: <ThirdPartyIcon />,
          url: 'https://console.cloud.google.com',
          tooltip: 'Redirect to Google console. Manually execute command',
        },
      ],
    },
  ];

  const handleSubmit = data => {
    const { accountName, serviceAccountEmailID } = data;

    const onError = err => {
      if (err?.code === 409) {
        setAccountNameUsedError(err.description ?? '-');
      } else {
        errorToast({ title: err.title, description: err.description });
      }
    };

    dispatch(
      actions.onboardCloudAccount({
        q: {
          cloudAccountCreateRequest: {
            name: accountName,
            cloud_type: CloudProviders.Gcp,
            gcp: {
              service_account_email: serviceAccountEmailID,
            },
          },
        },
        onSuccess: () => navigate('/setup/accounts'),
        onError,
      }),
    );
  };
  const handleReset = () => {
    navigate('/setup/accounts');
  };

  return (
    <>
      <Form
        title="Add Google Cloud"
        titleIcon={<GoogleCloudIcon />}
        isTitleIconFilled={false}
        schema={{
          accountName: {
            type: 'text',
            label: 'Enter account name',
            //tooltip: 'Only Alphanumeric, hyphen is allowed.',
            error: accountNameUsedError,
            onChange: value => {
              setName(value);
              if (value.match(/^[a-zA-Z0-9-]*$/i)) setAccountNameUsedError('');
              else
                setAccountNameUsedError(
                  'Only Alphanumeric, hyphen is allowed!',
                );
            },
          },
          onboardingMode: {
            type: 'custom-with-form-control',
            label: 'Onboarding mode',
            component: <ReadOnlyTag />,
          },
          steps: {
            type: 'custom-with-form-control',
            label: 'Before proceeding, perform the following steps:',
            component: renderRequiredSteps(
              steps,
              onClickAccordionButton,
              copied,
              setCopied,
            ),
          },
          serviceAccountEmailID: {
            type: 'text',
            label: 'Service Account - Email ID',
            onChange: value => setServiceAccountEmailID(value),
            helpIcon: <QuestionCircleIcon />,
            helpTooltip: 'Get Service Account Email ID',
            onClickHelpIcon: () => {
              helpDrawer.onOpen();
              setSelectedItem({
                helpHeader: 'Get Service Account Email ID',
                headerIcon: <RoleIcon />,
                helpContent: (
                  <Stack spacing={4}>
                    <Box fontWeight={600} pt={2} fontSize="md">
                      Copy and paste the following script in Cloudshell
                    </Box>
                    <Box
                      h={32}
                      bg={customTheme.colors.gray['50']}
                      p={4}
                      borderRadius={6}
                    >
                      <HStack
                        align="flex-start"
                        justify="space-between"
                        w="full"
                      >
                        <Box flex={1}>
                          {configCommands.isLoading ? (
                            <Center>
                              <CustomSpinner />
                            </Center>
                          ) : (
                            configCommands.data?.cloud_commands_map?.gcp
                              ?.onboarding?.fetch_service_account
                          )}
                        </Box>
                        {renderCopyIcon(
                          {
                            copyText:
                              configCommands.data?.cloud_commands_map?.gcp
                                ?.onboarding?.fetch_service_account,
                            icon: <CopyIcon p={0.5} color="primary" />,
                            tooltip: 'Copy command',
                          },
                          copied,
                          setCopied,
                        )}
                      </HStack>
                    </Box>
                  </Stack>
                ),
              });
            },
          },
        }}
        isLoading={configCommands.isLoading}
        buttonOptions={{
          submit: {
            name: 'Connect',
            isLoading: configCommands.isLoading || onboardingAccount.isLoading,
            isDisabled:
              configCommands.isLoading ||
              onboardingAccount.isLoading ||
              !!accountNameUsedError ||
              !name ||
              !serviceAccountEmailID,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        helpDrawer={{
          isOpen: helpDrawer.isOpen,
          onClose: helpDrawer.onClose,
          content: selectedItem?.helpContent,
          header: (
            <PageHeaderWithIcon
              label={selectedItem?.helpHeader}
              icon={selectedItem?.headerIcon}
              iconBgColor={selectedItem?.iconBgColor}
              useCustomColor
              reversed
            />
          ),
          styles: {
            body: { pt: 6 },
          },
        }}
        handleSubmit={handleSubmit}
      />
    </>
  );
});
