import React, { FC, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { map, sum } from 'lodash';
import { useSelector } from 'react-redux';

import { colorMapForCharts } from 'components/DataDisplay';
import { PieChart } from 'components/Visualization';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { renderPieChartLegend } from 'containers/Visibility/Data/Components/Dashboard/utils';

export const DataFootprintBySourceTypes: FC = () => {
  const { summaryOfAllDataSources } = useSelector(selectDashboard);
  const [data, setData] = useState<any>();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const items = summaryOfAllDataSources.data?.resource_type_counts ?? {};
    setData(
      map(items, (count, key) => ({
        name: key,
        value: count,
      })),
    );
    setTotal(sum(Object.values(items)));
  }, [summaryOfAllDataSources.data]);

  return (
    <DashboardWidget
      label="Data Footprint By Source Types"
      subHeader={
        data?.length +
        ' source type' +
        (data?.length === 1 ? ', ' : 's, ') +
        total +
        ' source' +
        (total === 1 ? '' : 's')
      }
      content={
        <Box w="full" h="full" overflow="hidden">
          <PieChart
            isLoading={summaryOfAllDataSources.isLoading}
            data={data}
            dataKey="value"
            total={data?.length}
            outerRadius="85%"
            innerRadius="76%"
            activeShape={false}
            colorMap={colorMapForCharts}
            styles={{
              pie: { cx: '45%' },
              legend: {
                wrapperStyle: {
                  width: '200px',
                },
              },
            }}
            renderLegend={renderPieChartLegend}
            centerLabel={'source type' + (data?.length === 1 ? '' : 's')}
          />
        </Box>
      }
    />
  );
};
