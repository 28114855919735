import React, { useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Stack, Text } from '@chakra-ui/react';
import { map, sum, values } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { getIconColor } from 'components/Icons/Components/getIconColor';
import { CustomTooltip } from 'components/Visualization/Charts/CustomTooltip';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { DataSourcesCardWithBarChart } from 'containers/Dashboard/utils/DataSourcesCardWithBarChart';

export const DataSourcesAndSensitiveData: React.FC = () => {
  const { sensitiveDataBySources } = useSelector(selectDataSecurityOverview);
  const { getResourceAlias } = useResourceType();
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const sensitiveData = sensitiveDataBySources.data;
    setChartData([
      ...(!sensitiveData?.Buckets
        ? []
        : [
            {
              name: 'Buckets',
              color: customTheme.colors.primary,
              'Sensitive data count': sensitiveData?.Buckets ?? 0,
              count: formatNumber(sensitiveData?.Buckets ?? 0),
            },
          ]),
      ...(!sensitiveData?.DB
        ? []
        : [
            {
              name: 'Databases',
              color: getIconColor(NativeResources.RelationalDatabaseService),
              'Sensitive data count': sensitiveData?.DB ?? 0,
              count: formatNumber(sensitiveData?.DB ?? 0),
            },
          ]),
      ...(!sensitiveData?.Others
        ? []
        : [
            {
              name: 'Disks',
              color: customTheme.colors.skyBlue['300'],
              'Sensitive data count': sensitiveData?.Others ?? 0,
              count: formatNumber(sensitiveData?.Others ?? 0),
            },
          ]),
      ...(!sensitiveData?.SaaS
        ? []
        : [
            {
              name: 'SaaS',
              color: customTheme.colors.orange['400'],
              'Sensitive data count': sensitiveData?.SaaS ?? 0,
              count: formatNumber(sensitiveData?.SaaS ?? 0),
            },
          ]),
    ]);
  }, [sensitiveDataBySources.data, getResourceAlias]);

  const customTooltipContent = props => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const { payload: obj } = payload[0] ?? {};

      return (
        <>
          <CustomTooltip>
            <Stack fontSize={'sm'}>
              <Text as="b">{obj.name}</Text>
              <Text>
                Sensitive data count:{' '}
                <Text as="b">{formatNumber(obj['Sensitive data count'])}</Text>
              </Text>
            </Stack>
          </CustomTooltip>
        </>
      );
    }

    return null;
  };

  return (
    <DataSourcesCardWithBarChart
      label="Sensitive Data By Source Types"
      chartData={chartData}
      xField="name"
      yFields={['Sensitive data count']}
      barChartColors={map(chartData, o => o.color)}
      isLoadingChart={sensitiveDataBySources.isLoading}
      labelListKey="Sensitive data count"
      onClick={(e, k, v) => {
        navigate('/visibility/data?tab=DataSources');
      }}
      customTooltipContent={customTooltipContent}
      isLoadingSensitiveData={sensitiveDataBySources.isLoading}
      totalSensitiveData={sum(values(sensitiveDataBySources.data))}
      /*tooltip={{
          tooltip: (
            <Stack>
              <Box>
                Ariksa Outpost for Data discovers and classifies sensitive data
                in your environment continuously.
              </Box>
              <Box>
                Deploy Outpost for Data in the region of your data sources and
                add data sources to scan
              </Box>
            </Stack>
          ),
          header: 'Discover your sensitive data…',
          footerText: 'Take me there',
          onClickFooter: () => navigate('/setup/outpost'),
        }}*/
      iconBgColor={getIconColor(NativeResources.ElasticBlockStorage)}
    />
  );
};
