/**
 *
 * Setup Users
 *
 */

import React, { memo, useEffect } from 'react';

import { Box, HStack, Flex, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  AddButton,
  Card,
  FilterComponent,
  IconTypes,
  PageHeaderWithIcon,
  renderNameWithResourceIcon,
  StackedCell,
  usePageContentContext,
  UserIcon,
} from 'app/components';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { errorToast } from 'app/components/Toast';
import { useApp } from 'containers/App/hooks/useApp';
import { selectUser } from 'containers/App/selectors';
import { NoPermissionMessage } from 'containers/Setup/NoPermissionMessage';
import { useTrackApiCall } from 'hooks/useTrackApiCall';
import { formatDate } from 'utils/date';

import { selectSetupUsers } from './selectors';
import { actions } from './slice';
import { renderActions } from './utils';

export const SetupUsers = memo(() => {
  //useInjector(sliceKey, reducer, setupUsersSaga);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contentHeight } = usePageContentContext();

  const { users, deleteUser } = useSelector(selectSetupUsers);
  const deleteUserCall = useTrackApiCall(deleteUser);
  const user = useSelector(selectUser);
  const loggedInUserId = user?.info?.id;
  const { hasPermissionForPage } = useApp();

  useEffect(() => {
    if (hasPermissionForPage) {
      dispatch(actions.loadUsers());
      dispatch(actions.resetUserForm({}));
    }
  }, [dispatch, hasPermissionForPage]);

  useEffect(() => {
    if (deleteUserCall.success) {
      deleteUserCall.done();
    }
  }, [dispatch, deleteUserCall]);

  const onEdit = row => {
    navigate(`/setup/users/edit/${row.id}`);
  };

  const onDelete = row => {
    const { id, organization } = row;
    if (loggedInUserId !== id) {
      dispatch(
        actions.deleteUser({
          id,
          organization,
        }),
      );
    } else {
      errorToast({
        title: `Failed to delete the user`,
        description: 'Cannot delete own account',
      });
    }
  };

  const handlePaginationUpdate = pageInfo => {
    dispatch(actions.updateUserPageInfo(pageInfo));
  };

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Username</Box>,
      accessor: 'username',
      align: 'left',
      render: ({ value, row }) => {
        return renderNameWithResourceIcon(IconTypes.User, value);
      },
    },
    {
      header: 'Roles',
      accessor: 'roles',
      render: ({ value }) => value.length,
      align: 'center',
    },
    {
      header: 'Groups',
      accessor: 'user_groups',
      render: ({ value }) => value.length,
      align: 'center',
    },
    {
      header: 'Accounts',
      accessor: 'users',
      render: ({ value, row }) => {
        return (
          <StackedCell
            upper={row?.account_ids.length ?? 0}
            lower={`Auto addition: ${row.auto_add_accounts ? 'On' : 'Off'}`}
          />
        );
      },
      align: 'left',
    },
    {
      header: 'Created',
      accessor: 'creator.username',
      align: 'left',
      render: ({ value, row }) => {
        return (
          <StackedCell
            upper={row.creator?.username ?? '-'}
            lower={formatDate(row.creation_time)}
          />
        );
      },
    },
    {
      header: 'Updated',
      accessor: 'update_time',
      align: 'left',
      render: ({ value, row }) => {
        return <>{formatDate(row.creation_time)}</>;
      },
    },
    {
      header: 'Actions',
      accessor: 'id',
      render: ({ row }) =>
        renderActions({ row, onEdit, onDelete, userCount: users.totalCount }),
      styles: { cell: { overflow: 'visbile' } },
    },
  ];

  if (deleteUser.loading) {
    return (
      <Box>Deleting</Box> // need to replace with spinner
    );
  }

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      {hasPermissionForPage ? (
        <Stack h="full" spacing={5} w="full">
          <HStack w="full" justify="space-between" dir="row">
            <PageHeaderWithIcon label="Users" icon={<UserIcon />} />
            <Flex>
              <FilterComponent filterPath={'/setup/users/add'}>
                <AddButton
                  label="Add User"
                  onClick={() => navigate('/setup/users/add')}
                />
              </FilterComponent>
            </Flex>
          </HStack>
          <Box flex={1}>
            <Box w={'full'} h={'full'}>
              <Table
                data={users.payload}
                columns={columns}
                isLoading={users.loading}
                pagination={{
                  totalCount: 20,
                  pageInfo: users.pageInfo,
                  onChange: handlePaginationUpdate,
                }}
              />
            </Box>
          </Box>
        </Stack>
      ) : (
        <NoPermissionMessage />
      )}
    </Card>
  );
});
