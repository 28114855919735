import React, { FC } from 'react';

import { NoSetup } from 'components/DataDisplay/Utils/NoSetup';

export const NoAccountOnboardedEnvironmentMessage: FC = () => {
  return (
    <NoSetup
      header="Environment"
      headerMessage="Environments simplify policy enforcement, prioritization, and remediation. You can organize cloud accounts (multi-cloud)
that reflect their context such as for production, development, QA, staging etc. to apply differentiated policies."
      steps={[
        'Onboard one or more cloud accounts',
        'Group cloud accounts by production, development, QA etc.',
        'Apply policy blueprints to each environment for security and GRC',
      ]}
      footer="ONBOARD ACCOUNT"
      footerLink="'/setup/accounts'"
    />
  );
};
