import React, { useState } from 'react';

import { useDisclosure, Box, Center } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { ActionButton } from 'components/DataEntry';
import {
  ComplianceCheckExceptionIcon,
  ExclamationTriangleIcon,
  HistoricalTimelineIcon,
  IconTypes,
} from 'components/Icons';
import { NoAlarmIcon } from 'components/Icons/SvgResourcesIcon';
import { TableActionsMenu } from 'components/Overlay';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';

export const useComplianceHook = () => {
  const { selectedBlueprint } = useSelector(selectCompliance);

  const navigate = useNavigate();
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const {
    isOpen: isOpenSeverityModal,
    onClose: onCloseSeverityModal,
    onOpen: onOpenSeverityModal,
  } = useDisclosure();
  const suppress = useDisclosure();

  const [rulesRowIndex, setRulesRowIndex] = useState(-1);
  const [resourceTypeRowIndex, setResourceTypeRowIndex] = useState(-1);

  const [selectedResource, setSelectedResource] = useState<any>({});
  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();

  const renderRulesActions = ({ row }) => {
    return (
      <Center>
        <ActionButton
          icon={<HistoricalTimelineIcon />}
          label="Show historical data"
          onClick={e => {
            e.stopPropagation();
            setSelectedResource(row);
            setIsOpenHistory(true);
          }}
        />
        <ActionButton
          icon={<ExclamationTriangleIcon />}
          label="Show all violations by severity"
          onClick={e => onClickSeverity(e, row)}
        />
        {!selectedBlueprint?.compliance && (
          <ActionButton
            icon={<ComplianceCheckExceptionIcon />}
            label="Apply exception for all entities"
            onClick={e => onClickSuppress(e, row)}
            isDisabled={selectedBlueprint?.created_by === 'system'}
          />
        )}
      </Center>
    );
  };

  const onClickSearch = (e, row) => {
    e.stopPropagation();
    setSelectedResource(row);
    navigate('/compliance/resources', {
      state: {
        ...row,
        selectedBlueprint,
        selectedResource,
        filter: false,
      },
    });
  };

  const onClickSeverity = (e, row) => {
    e.stopPropagation();
    setSelectedResource(row);
    onOpenSeverityModal();
  };
  const onClickSuppress = (e, row) => {
    e.stopPropagation();
    setSelectedResource(row);
    suppress.onOpen();
  };

  const renderResourceActions = ({ row, value }) => {
    return (
      <Center>
        <ActionButton
          iconType={IconTypes.BarChart}
          label="Severity"
          onClick={e => onClickSeverity(e, row)}
        />
        <ActionButton
          iconType={IconTypes.Exception}
          label="Add Exception"
          onClick={e => onClickSuppress(e, row)}
        />
      </Center>
    );
  };

  const renderResourceSubComponentActions = ({ row }) => {
    const menuItems = [
      {
        label: 'Search',
        iconType: IconTypes.Search,
        onClick: e => onClickSearch(e, row),
      },
      {
        label: 'Severity',
        iconType: IconTypes.BarChart,
        onClick: e => onClickSeverity(e, row),
      },
      {
        label: 'Suppress',
        icon: <NoAlarmIcon />,
        onClick: e => onClickSuppress(e, row),
      },
      {
        label: 'Details',
        iconType: IconTypes.Details,
        onClick: () => {
          updateActiveResource({
            resourceType: row.type,
            uuid: row.resource_id,
            accountId: row.account_id,
          });
          onOpenMetadata();
        },
      },
      {
        label: 'Remediate',
        iconType: IconTypes.Remediate,
      },
    ];

    return (
      <Box onClick={e => e.stopPropagation()}>
        <TableActionsMenu menuItems={menuItems} styles={{ item: { h: 8 } }} />
      </Box>
    );
  };

  const toggleShowResource = (row, index) => {
    setSelectedResource(row);
    setResourceTypeRowIndex(index);
  };

  const toggleShowRules = (row, index) => {
    setSelectedResource(row);
    setRulesRowIndex(index);
  };

  return {
    toggleShowRules,
    toggleShowResource,
    renderResourceSubComponentActions,
    renderResourceActions,
    renderRulesActions,
    isOpenSuppress: suppress.isOpen,
    isOpenHistory,
    onCloseHistory: () => setIsOpenHistory(false),
    isOpenSeverityModal,
    onOpenSeverityModal,
    onCloseSeverityModal,
    onCloseSuppress: suppress.onClose,
    onOpenSuppress: suppress.onOpen,
    rulesRowIndex,
    resourceTypeRowIndex,
    selectedResource,
    setSelectedResource,
    onClickSeverity,
    onClickSuppress,
  };
};
