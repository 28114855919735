import React, { useMemo } from 'react';

import { AlertSeverityInitializer } from '@ariksa/notification/api';

import {
  IDashboardLayout,
  WidgetProps,
} from 'components/Visualization/CDashboard/types';
import { AlertCountForEachCategory } from 'containers/Findings/FindingsOverview/Components/Cards/AlertCount/AlertCountForEachCategory';
import { TopOpenAlertsChart } from 'containers/Findings/FindingsOverview/Components/Widgets/TopOpenAlertsChart';
import { TopAggregatedAlertsTable } from 'containers/Findings/FindingsOverview/Overview/TopAggregatedAlertsTable';
import { renderHeader } from 'containers/Findings/FindingsOverview/utils';
import { toTitleCase } from 'utils/string';

interface Props {
  alertCategory: string;
  severity: AlertSeverityInitializer;
}
export const useCategoryOverview = (props: Props) => {
  const { alertCategory, severity } = props;

  const availableWidgets: Record<string, WidgetProps> = useMemo(
    () => ({
      AlertSummary: {
        name: renderHeader(toTitleCase(alertCategory)),
        styles: {
          componentWrapper: { pt: 4, pb: 0 },
          title: { fontSize: 'md', w: 'full' },
          root: { width: 'full' },
        },
        components: {
          content: (
            <AlertCountForEachCategory
              severity={severity}
              category={alertCategory}
            />
          ),
        },
        className: 'alert_summary',
        w: '20%',
      },
      top_open_issues: {
        name: 'Open Findings Trend',
        styles: { title: { fontSize: 'md' } },
        components: {
          content: <TopOpenAlertsChart alertCategory={alertCategory} />,
        },
        className: 'top_open_issues',
        w: '25.5%',
      },
      top_aggregated_alerts: {
        name: 'Top Findings (By Severity)',
        styles: { title: { fontSize: 'lg' } },
        components: {
          content: <TopAggregatedAlertsTable alertCategory={alertCategory} />,
        },
        className: 'top_aggregated_alerts',
        w: '54.5%',
      },
    }),
    [alertCategory, severity],
  );

  const dashboardWidgets: IDashboardLayout = useMemo(
    () => ({
      id: 'dashboard_1',
      title: 'Dashboard Title',
      widgets: [
        {
          type: 'card',
          i: 'AlertSummary',
          x: 0,
          y: 0,
          w: 2.5,
          h: 6.5,
          minH: 2,
          minW: 2,
          isResizable: false,
          static: true,
        },

        {
          type: 'line-chart',
          i: 'top_open_issues',
          x: 2.5,
          y: 0,
          w: 3.16,
          h: 6.5,
          minH: 2,
          minW: 2,
          isResizable: false,
          static: true,
        },
        {
          type: 'table',
          i: 'top_aggregated_alerts',
          x: 5.66,
          y: 0,
          w: 6.32,
          h: 6.5,
          minH: 4,
          minW: 2,
          isResizable: false,
          static: true,
        },
      ],
      available: availableWidgets,
    }),
    [availableWidgets],
  );

  return { dashboardWidgets, availableWidgets };
};
