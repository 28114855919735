import React from 'react';

import { Box, HStack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { WithResourceIcon } from 'components/DataDisplay';
import { DashboardTable } from 'components/DataDisplay/NewTable/DashboardTable';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import {
  CheckmarkCircleOutlineIcon,
  CloseCircleOutlineIcon,
  IconTypes,
} from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { actions } from 'containers/SharedState/slice';

export const MostDeployedExploitablePackages: React.FC = () => {
  const { mostWidelyDeployedLibraries } = useSelector(
    selectVulnerabilityOverview,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      header: <Box pl={9}>PACKAGES</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={IconTypes.Library}
          iconFilled={false}
          iconStyles={{ w: 5, h: 5 }}
          color="primary"
        >
          {row.package_name}
        </WithResourceIcon>
      ),
      styles: { w: '40%' },
    },
    {
      header: 'COUNT',
      render: ({ row }) => formatNumber(row.occurrences, 1),
      styles: {
        td: { w: '20%' },
        cell: { textAlign: 'right', w: '65%' },
        header: { textAlign: 'center' },
      },
    },
    {
      header: 'PATCH',
      styles: { w: '40%' },
      render: ({ row }) => (
        <HStack spacing={1}>
          <CustomTooltip
            label={
              row.patch_available ? 'Patch available' : 'Patch not available'
            }
            width={20}
            textAlign="center"
          >
            <Box
              boxSize={5}
              color={
                row.patch_available
                  ? customTheme.colors.green['300']
                  : 'critical'
              }
            >
              {row.patch_available ? (
                <CheckmarkCircleOutlineIcon />
              ) : (
                <CloseCircleOutlineIcon />
              )}
            </Box>
          </CustomTooltip>
          <Box flex={1}>
            {row.patch_available ? (
              <CustomTooltip label={row.patch_version}>
                <Text whiteSpace="wrap" noOfLines={2}>
                  {row.patch_version}
                </Text>
              </CustomTooltip>
            ) : (
              <Box>Unknown</Box>
            )}
          </Box>
        </HStack>
      ),
    },
  ];

  return (
    <DashboardWidget
      label="Most Deployed Exploitable Packages"
      styles={{ contentWrapper: { px: 0, pb: 0 } }}
      content={
        <>
          <DashboardTable
            columns={columns}
            data={mostWidelyDeployedLibraries.data}
            styles={{ td: { py: 2 } }}
            isLoading={mostWidelyDeployedLibraries.isLoading}
            onRowClick={row => {
              dispatch(actions.updateFinalSearchTerm(row.package_name || ''));
              dispatch(actions.updateSearchTerm(row.package_name || ''));
              navigate('/visibility/vulnerability?tab=' + IconTypes.Packages);
            }}
          />
        </>
      }
    />
  );
};
