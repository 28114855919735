import React, { useEffect, useMemo } from 'react';

import { BoxProps, HStack, Stack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { isDeepEqual } from 'react-use/lib/util';

import { H1 } from 'components/DataDisplay';
import { DownloadIconButton, PrimaryIconButton } from 'components/DataEntry';
import { TableIcon, TreeIcon } from 'components/Icons';
import { SecurityGraphSearch } from 'containers/Visibility/SecurityGraphNext/Components/SecurityGraphSearch';
import { useRedirectInfo } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectInfo';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import {
  SecurityGraphMapType,
  ViewType,
} from 'containers/Visibility/SecurityGraphNext/types';
import { useBus } from 'hooks/useBus';
import { toTitleCase } from 'utils/string';

interface SecurityGraphContentControlsProps extends BoxProps {}

export const SecurityGraphContentControls = (
  props: SecurityGraphContentControlsProps,
) => {
  const { searchResult } = useSearchResult();
  const { viewType, mapType } = useSelector(selectSecurityGraphNext);
  const bus = useBus();

  return (
    <Stack spacing={6} pt={4}>
      <H1>
        Security Graph:{' '}
        {mapType === SecurityGraphMapType.Unknown ? '-' : toTitleCase(mapType)}
      </H1>
      <HStack>
        <SecurityGraphSearch />

        {/*<PrimaryIconButton
          aria-label={showGraph ? 'show-table' : 'show-graph'}
          tooltip={showGraph ? 'Show Table' : 'Show Graph'}
          icon={showGraph ? <TableIcon /> : <TreeIcon />}
          onClick={() => {
            dispatch(actions.toggleViewType());
          }}
          bg="white"
        />*/}
        <DownloadIconButton
          aria-label="download-access-map"
          tooltip="Download Map"
          onClick={async () => {
            bus.emit('download:security-graph');
          }}
          isDisabled={searchResult.isLoading || isEmpty(searchResult.data)}
        />
      </HStack>
    </Stack>
  );
};
