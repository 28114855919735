import {
  AlertCountForWorkflow,
  AlertWorkflowApiAddWorkflowRequest,
  AlertWorkflowApiDeleteWorkflowRequest,
  AlertWorkflowApiEditWorkflowRequest,
  AlertWorkflowApiGetAlertCountForWorkflowRequest,
  AlertWorkflowApiGetWorkflowRequest,
  AlertWorkflowResponse,
  ClientResponse,
  ClientsApiGetClientDetailsRequest,
} from '@ariksa/notification/api';
import { ReportsApiSendToEsRequest } from '@ariksa/reporting/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { NotificationService, ReportingService } from 'api/services';

import { actions } from './slice';

export function* alertWorkflowSaga() {
  yield takeLatestAction(actions.createAlertWorkflow, doCreateAlertWorkflow);
  yield takeLatestAction(actions.updateAlertWorkflow, doUpdateAlertWorkflow);
  yield takeLatestAction(actions.deleteAlertWorkflow, doDeleteAlertWorkflow);
  yield takeLatestAction(actions.getAlertWorkflow, doGetAlertWorkflow);
  yield takeLatestAction(actions.getAlertWorkflows, doGetAlertWorkflows);
  yield takeLatestAction(actions.exportAlert, doExportAlert);
  yield takeLatestAction(actions.getClients, doGetClients);
  yield takeLatestAction(
    actions.getWorkflowAlertCounts,
    doGetWorkflowAlertCounts,
  );
}

/*create alert workflow*/
function* doCreateAlertWorkflow(
  ctx: QueryContext<any, AlertWorkflowApiAddWorkflowRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.AlertWorkflow.addWorkflow(ctx.params),
  );
}

/*update alert workflow*/
function* doUpdateAlertWorkflow(
  ctx: QueryContext<any, AlertWorkflowApiEditWorkflowRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.AlertWorkflow.editWorkflow(ctx.params),
  );
}

/*delete alert workflow*/
function* doDeleteAlertWorkflow(
  ctx: QueryContext<any, AlertWorkflowApiDeleteWorkflowRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.AlertWorkflow.deleteWorkflow(ctx.params),
  );
}

/*get alert workflow*/
function* doGetAlertWorkflow(
  ctx: QueryContext<
    AlertWorkflowResponse[],
    AlertWorkflowApiGetWorkflowRequest
  >,
) {
  yield call(ctx.fetch, () =>
    NotificationService.AlertWorkflow.getWorkflow(ctx.params),
  );
}

/*get alert workflows*/
export function* doGetAlertWorkflows(
  ctx: QueryContext<
    AlertWorkflowResponse[],
    AlertWorkflowApiGetWorkflowRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertWorkflow.getWorkflow(ctx.params),
    { cacheKey: 'workflows' },
  );
}

/*get alert workflows*/
function* doExportAlert(ctx: QueryContext<any, ReportsApiSendToEsRequest>) {
  yield call(ctx.fetch, () => ReportingService.Reports.sendToEs(ctx.params));
}

/*get alert workflows*/
function* doGetWorkflowAlertCounts(
  ctx: QueryContext<
    AlertCountForWorkflow,
    AlertWorkflowApiGetAlertCountForWorkflowRequest
  >,
) {
  yield call(ctx.fetch, () =>
    NotificationService.AlertWorkflow.getAlertCountForWorkflow(ctx.params),
  );
}

/*get clients*/
function* doGetClients(
  ctx: QueryContext<ClientResponse[], ClientsApiGetClientDetailsRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Clients.getClientDetails(ctx.params),
  );
}
