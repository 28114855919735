import React from 'react';

import { ClientsApiEditClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { CreateJira } from 'containers/Setup/Integrations/Components/Jira/Components/CreateJira';
import { EditJira } from 'containers/Setup/Integrations/Components/Jira/Components/EditJira';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions } from '../../../slice';

export const JiraWizard = () => {
  const dispatch = useDispatch();
  const { onCancel, actionType, currentRecord } = useIntegrationsContext();

  const onUpdate = data => {
    const { name } = data;
    const payload: ClientsApiEditClientRequest = {
      uuid: currentRecord?.uuid,
      editClientConfig: {
        name,
      },
    };
    dispatch(
      actions.updateClientById({ q: payload, onSuccess: () => onCancel() }),
    );
  };

  return actionType === 'Add' ? (
    <CreateJira />
  ) : (
    <EditJira onSubmit={onUpdate} />
  );
};
