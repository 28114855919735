import { colorMap, colorOpacity, customTheme } from 'theme';

const fontSize = customTheme.fontSizes.sm;
export const defaultSelectStyles = (
  height?: string | number,
  showTotalSelected = false,
  isAFilter = false,
) => ({
  menu: provided => {
    return {
      ...provided,
      //width: state.selectProps.width,
      zIndex: 999,
      fontSize: fontSize,
      paddingTop: 0,
    };
  },
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),

  option: (provided, { isSelected, isDisabled, isMulti }) => {
    return {
      ...provided,
      '&:active': {
        backgroundColor: colorMap.primary(100),
      },

      '&:hover': {
        backgroundColor: isSelected ? colorMap.primary(300) : 'hover',
        color: isSelected ? '#fff' : '#000',
      },

      color: isSelected && !isMulti ? '#fff' : '#000',
      cursor: isDisabled ? 'not-allowed' : 'default',
      backgroundColor: isSelected && !isMulti ? colorMap.primary(300) : '#fff',
      marginBottom: '1px',
    };
  },
  control: (styles, props) => ({
    ...styles,
    borderColor: props.isFocused
      ? customTheme.colors.primary
      : customTheme.colors.formFieldBorder,
    borderRadius: customTheme.radii.fieldBorderRadius,
    minHeight: '0px',
    height: '100%',
    padding: 0,
    backgroundColor: 'inherit',
    opacity: props.isDisabled ? 0.9 : 'none',
    cursor: props.isDisabled ? 'not-allowed' : 'default',
    fontSize: fontSize,
    boxShadow: props.isFocused
      ? 'none' // `0 0 0 1px ${customTheme.colors.primary}`
      : 'none',

    '&:hover': {
      borderColor: `${customTheme.colors.primary}`,
      cursor: props.isDisabled ? 'not-allowed' : 'default',
    },
  }),
  container: (styles, { isMulti, hasValue }) => ({
    ...styles,
    border: 'none',
    minHeight: '32px',
    height: height
      ? height
      : isMulti && hasValue && !showTotalSelected
      ? 'auto'
      : '32px',
    padding: 0,
    borderRadius: '3px',
    backgroundColor:
      hasValue && isAFilter
        ? colorOpacity(customTheme.colors.primary, 0.4)
        : 'inherit',
    color: hasValue && isAFilter ? 'white' : 'inherit',
    fontSize: fontSize,
  }),
  valueContainer: (styles, props) => ({
    ...styles,
    height: '100%',
    padding: '0px 0px 0px 8px',
    backgroundColor: 'inherit',
    cursor: props.isDisabled ? 'not-allowed' : 'auto',
    alignItems: 'center',
    borderRadius: '3px',
    //color: 'black',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: 0,
    height: '100%',
    backgroundColor: 'inherit',
    color:
      provided.hasValue && isAFilter ? 'white' : customTheme.colors.gray['200'],
    borderRadius: customTheme.radii.fieldBorderRadius,
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  placeholder: styles => ({
    ...styles,
    color: customTheme.colors.placeholder,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color:
      provided.hasValue && isAFilter ? 'white' : customTheme.colors.gray['250'],
  }),
  multiValue: styles => {
    if (showTotalSelected)
      return {
        ...styles,
        fontSize: fontSize,
        backgroundColor: 'transparent',
      };
    return {
      ...styles,
      fontSize: fontSize,
      backgroundColor: customTheme.colors.gray['50'],
      border: `1px solid ${customTheme.colors.gray['200']}`,
      borderRadius: '12px',
    };
  },
  multiValueContainer: styles => ({
    ...styles,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    'white-space': 'pre-wrap',
    color: data.color || customTheme.colors.primary,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color || customTheme.colors.primary,
    ':hover': {
      backgroundColor: data.color,
    },
  }),
  loadingIndicator: styles => {
    return {
      ...styles,
      // top: '50%',
    };
  },
});

export const defaultCheckBoxStyles = {
  size: 'sm',
  //colorScheme: 'indigo',
  _focus: {
    outline: 'none',
    boxShadow: `0 0 0 1px ${customTheme.colors.primary}`,
  },
};
