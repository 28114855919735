import React, { useEffect, useState } from 'react';

import { Categories, Resources } from '@ariksa/inventory-core/api';
import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { CloudtrailIcon, DatabaseIcon } from 'components/Icons';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const Infrastructure: React.FC = () => {
  const { stateOfYourCloud, vpcStatus } = useSelector(selectDashboardOverview);
  const { infrastructureCount } = stateOfYourCloud;
  const { infrastructurePostureSummary } = useSelector(selectDashboard);
  const navigate = useNavigate();
  const [networkLogEnabledVPCs, setNetworkLogEnabledVPCs] = useState(false);

  useEffect(() => {
    setNetworkLogEnabledVPCs(
      (vpcStatus.data.items as any)?.infrastructure?.vpc_with_logs_enabled,
    );
  }, [vpcStatus.data]);

  return (
    <SummaryCard
      label="Infrastructure"
      icon={<DatabaseIcon />}
      change={{
        isLoading: infrastructureCount.isLoading,
        first: infrastructureCount.data.previous_count,
        last: infrastructureCount.data.current_count,
        percentChange: infrastructureCount.data.percentage_change,
      }}
      onClick={() => navigate('/inventory/summary?tab=' + Categories.Code)}
      cloudProviders={infrastructurePostureSummary.data.cloud_providers}
      count={infrastructureCount.data.current_count ?? 0}
      isLoading={infrastructureCount.isLoading}
      info={{
        isLoading: vpcStatus.isLoading,
        message: networkLogEnabledVPCs
          ? `Logs enabled for ${networkLogEnabledVPCs} VPCs`
          : 'No Network Logs',
        enabled: networkLogEnabledVPCs,
        onClick: () =>
          navigate(
            '/inventory/summary?tab=' +
              Categories.Logging +
              '_' +
              Resources.NetworkLogs,
          ),
      }}
      tooltip={{
        header: 'Insights with network logs…',
        icon: <CloudtrailIcon />,
        tooltip: (
          <Stack spacing={8}>
            <Stack>
              <Box>
                If network logs are enabled, Ariksa can help right-size network
                access and provide insights regarding active network traffic
              </Box>
              <Box>
                To keep costs minimal, you can delete logs older than a week.
                Ariksa automatically extends insights well beyond that.
              </Box>
            </Stack>
            <Box
              color="primary"
              onClick={() =>
                navigate(
                  'https://docs.aws.amazon.com/vpc/latest/userguide/flow-logs-cwl.html#flow-logs-cwl-create-flow-log',
                )
              }
            >
              Enabling VPC Flow Logs
            </Box>
          </Stack>
        ),
        footerText: 'Ok',
      }}
      labelTooltip={formatTooltip({
        header: 'Infrastructure',
        content: 'Infrastructure-as-a Service (iaaS) entities',
        width: 48,
      })}
    />
  );
};
