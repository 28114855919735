import React from 'react';

import {
  Box,
  Link,
  Stack,
  Image,
  Center,
  HStack,
  Text,
} from '@chakra-ui/react';

import jira_token from 'app/images/jira_token.png';
import { HelpCount } from 'components/DataDisplay/Utils/HelpCount';

export const JIRAHelp = () => {
  return (
    <Stack px={4} spacing={6} pt={4} h="full">
      <HStack align={'start'}>
        <HelpCount count={1} />
        <Box>
          Log in to{' '}
          <Link
            color="primary"
            href="https://id.atlassian.com/manage/api-tokens"
            isExternal
          >
            Atlassian JIRA
          </Link>
          .
        </Box>
      </HStack>
      <HStack align={'start'}>
        <HelpCount count={2} />
        <Text>
          From the dialog that appears, enter a short, memorable, and easy to
          remember <Text as="b">Label</Text> for your token and click{' '}
          <Text as="b">Create</Text>.
        </Text>
      </HStack>
      <HStack align={'start'}>
        <HelpCount count={3} />
        <Box>
          Make sure you copy and save your new API token. You won’t be able to
          see this token again.
          <Center>
            <Box
              border="1px solid"
              borderRadius={0}
              borderColor="gray.200"
              my={4}
            >
              <Image src={jira_token} h={56} fit="cover" />
            </Box>
          </Center>
        </Box>
      </HStack>
    </Stack>
  );
};
