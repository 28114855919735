/**
 *
 * Aws On-Boarding
 *
 */

import assert from 'assert';

import React, { memo, useCallback, useEffect, useState } from 'react';

import { CloudAccountCreate, CloudProviders } from '@ariksa/cloud-account';
import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { Api } from 'api/index';
import {
  AwsIcon,
  errorToast,
  GearsIcon,
  PageHeaderWithIcon,
  QuestionCircleIcon,
  RadioWithIcon,
} from 'app/components';
import { Form, FormSchema } from 'app/components/DataEntry/Form';
import { Modal } from 'app/components/Overlay/Modal';
import { DoubleTapIcon } from 'components/Icons/ReactCustomIcons/DoubleTapIcon';
import { helpHeaderMapping } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/Help/utils';
import { OnboardingHelp } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/types';
import { ReadOnlyTag } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/utils/ReadOnlyTag';
import { cloudAccountWizardSaga } from 'containers/Setup/CloudAccounts/CloudAccountWizard/saga';
import { TemplateType } from 'containers/Setup/CloudAccounts/CloudAccountWizard/types';
import { useSearchParams } from 'hooks/useSearchParams';
import { useInjector } from 'utils/inject';

import { selectCloudAccountWizard } from '../../selectors';
import { actions, reducer, sliceKey } from '../../slice';
import { accountInformation } from '../../utils';

interface Props {
  onOpenProgress: () => void;
}

export const OnboardAWSForm = memo((props: Props) => {
  useInjector(sliceKey, reducer, cloudAccountWizardSaga);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isOpen, onClose } = useDisclosure();

  const { config, onboardingAccount, templateType } = useSelector(
    selectCloudAccountWizard,
  );

  const [defaultName, setDefaultName] = useState('');

  const location = useLocation();
  const memberAccount = location.state?.state;
  const { member_account: isMemberAccount } = useSearchParams();

  const [showHelp, setShowHelp] = useState<OnboardingHelp>(OnboardingHelp.None);
  const [isAutomatic, setIsAutomatic] = useState(true);
  const [accountNameUsedError, setAccountNameUsedError] = useState('');

  const [buckets, setBuckets] = useState<string[]>([]);

  useEffect(() => {
    dispatch(actions.resetOnboardingState());
  }, [dispatch]);

  //1. get templates
  //2. get account config
  //3. onboard using external id (along with other params) from config
  //4. redirect to aws page

  const toggleHelp = useCallback(
    (helpType: OnboardingHelp) => {
      if (showHelp === helpType) {
        setShowHelp(OnboardingHelp.None);
      } else {
        setShowHelp(helpType);
      }
    },
    [showHelp],
  );

  // get account config
  useEffect(() => {
    dispatch(
      actions.getAccountConfig({
        q: {
          cloudType: CloudProviders.Aws,
        },
      }),
    );
  }, [templateType, dispatch, isMemberAccount]);

  //set default name
  useEffect(() => {
    if (isMemberAccount) {
      const name = memberAccount?.name ?? '';
      setDefaultName(name);
      if (!name?.match(/^[a-zA-Z0-9-]*$/i))
        setAccountNameUsedError('Only Alphanumeric, hyphen is allowed!');
    }
  }, [isMemberAccount, memberAccount?.name]);

  const handleSubmit = data => {
    const { account_name, role_arn, external_id } = data;

    assert(
      !!config.data?.aws_config?.external_id,
      'External id is not available',
    );

    const onSuccess = createAccount => {
      if (isAutomatic) tryOnboard(createAccount!, account_name);
      else navigate('/setup/accounts');
    };

    const onError = err => {
      if (err?.code === 409) {
        setAccountNameUsedError(err.description ?? '-');
      } else {
        errorToast({ title: err.title, description: err.description });
      }
    };

    const awsConfig = {
      external_id: isAutomatic
        ? config.data?.aws_config?.external_id!
        : external_id,
      onboard_member_account: false,
      role_arn: isAutomatic ? '' : role_arn,
    };

    if (isMemberAccount) {
      dispatch(
        actions.onboardCloudMemberAccount({
          q: {
            uuid: memberAccount.uuid,
            memberAccountOnboardRequest: {
              name: account_name,
              aws: awsConfig,
            },
          },
          onSuccess,
          onError,
        }),
      );
    } else {
      dispatch(
        actions.onboardCloudAccount({
          q: {
            cloudAccountCreateRequest: {
              name: account_name,
              cloud_type: CloudProviders.Aws,
              aws: awsConfig,
            },
          },
          onSuccess,
          onError,
        }),
      );
    }
  };

  const tryOnboard = (createAccount: CloudAccountCreate, accountName) => {
    props.onOpenProgress();
    const params_S3BucketForCloudtrail = buckets.length
      ? buckets?.map(b => `arn:aws:s3:::${b},arn:aws:s3:::${b}/*`).join(',')
      : '*';
    const params: any = {
      stackName: `Ariksa-Onboarding-${uuidv4()}`,
      param_ExternalID: createAccount.aws?.external_id,
      templateURL: config.data.aws_config?.cfs_template_url,
      param_AriksaAdminAccountId: config.data.aws_config?.account_id,
      param_S3GetObjectBuckets:
        templateType === TemplateType.AnalyzeS3
          ? params_S3BucketForCloudtrail
          : '',
    };

    const redirectUrl = Api.awsOnboardRedirectURL(params);
    window.open(redirectUrl, '_blank');
  };

  const handleReset = () => {
    navigate('/setup/accounts');
  };

  const errorMessage: FormSchema = config.isError
    ? {
        errorMessage: {
          type: 'custom',
          component: () => (
            <Flex color="red.300" fontSize="md">
              We can not process this request at this time. Please contact
              admin!
            </Flex>
          ),
        },
      }
    : {};

  const schemaForm = yup.object().shape({
    account_name: yup
      .string()
      .matches(/^[a-zA-Z0-9-_]*$/i, 'Only Alphanumeric, hyphen is allowed!'),
  });

  const roleArn: FormSchema = isAutomatic
    ? {}
    : {
        role_arn: {
          label:
            'Enter the ARN for the IAM role that Ariksa will use to discover your account',
          type: 'text',
          placeholder: 'Enter role ARN',
          isDisabled: isAutomatic,
          isRequired: true,
          helpTooltip:
            'AWS Resource Name (ARN) for the IAM role that Ariksa needs to gather information regarding your cloud account',
          helpIcon: <QuestionCircleIcon />,
        },
        external_id: {
          label: 'Enter External ID associated with the role that you created',
          type: 'text',
          placeholder: 'Enter external ID',
          isDisabled: isAutomatic,
          isRequired: true,
        },
      };

  return (
    <Stack>
      <Form
        title="Add Amazon Web Services"
        titleIcon={<AwsIcon />}
        isTitleIconFilled={false}
        //isLoading={templates.isLoading}
        formValidation={schemaForm}
        schema={{
          account_name: {
            type: 'text',
            label:
              'Enter account name (can be individual or management account)',
            placeholder: 'Enter account name',
            isRequired: true,
            tooltip: 'Only Alphanumeric, hyphen is allowed.',
            error: accountNameUsedError,
            /*onKeyDown: value => {
              setAccountNameUsedError('');
            },*/
            defaultValue: defaultName,
            onChange: value => {
              if (value.match(/^[a-zA-Z0-9-]*$/i)) setAccountNameUsedError('');
              else
                setAccountNameUsedError(
                  'Only Alphanumeric, hyphen is allowed!',
                );
            },
          },
          cfs_template_type_info: {
            type: 'custom-with-form-control',
            label:
              'Onboarding mode. By default, Ariksa will only read from your cloud account and not make any changes?',
            helpIcon: <QuestionCircleIcon />,
            helpTooltip: 'View Permissions',
            onClickHelpIcon: () => toggleHelp(OnboardingHelp.Permission),
            /*helpComponent: (
              <Permissions
                onClose={() => toggleHelp(OnboardingHelp.Permission)}
              />
            ),
            isHelpOpen: isHelpOpen(OnboardingHelp.Permission),*/
            component: <ReadOnlyTag />,
          },
          role_arn_creation: {
            type: 'custom-with-form-control',

            label:
              'Do you want Ariksa to automatically create an IAM role to discover your cloud account?',
            helpIcon: <QuestionCircleIcon />,
            helpTooltip: 'View Automation Help',
            onClickHelpIcon: () => {
              toggleHelp(OnboardingHelp.Automation);
            },
            component: (
              <RadioWithIcon
                options={[
                  {
                    value: 'Automatic',
                    label: 'Yes. If applicable, onboard member accounts',
                    icon: <GearsIcon />,
                    showHelp: true,
                    onClick(e) {
                      toggleHelp(OnboardingHelp.AutomatedOnboarding);
                    },
                  },
                  {
                    value: 'Manual',
                    label:
                      'No, I’ll manually add details for account onboarding',
                    icon: <DoubleTapIcon />,
                    showHelp: true,
                    onClick(e) {
                      toggleHelp(OnboardingHelp.ManualOnboarding);
                    },
                  },
                ]}
                name="role_arn_creation"
                defaultValue="Automatic"
                value={isAutomatic ? 'Automatic' : 'Manual'}
                onChange={value => setIsAutomatic(value === 'Automatic')}
                cardWidth={64}
              />
            ),
          },
          ...roleArn,
          ...errorMessage,
        }}
        helpDrawer={{
          isOpen: showHelp !== OnboardingHelp.None,
          onClose: () => setShowHelp(OnboardingHelp.None),
          content: helpHeaderMapping(showHelp)?.content,
          header: (
            <PageHeaderWithIcon
              label={helpHeaderMapping(showHelp)?.header}
              icon={helpHeaderMapping(showHelp)?.icon}
              reversed
            />
          ),
          styles: { body: { pt: 6 } },
        }}
        formOptions={{ defaultValues: { onboard_member_account: true } }}
        buttonOptions={{
          submit: {
            name: 'Connect',
            //<Tooltip label="We can not process this request at this time. Please contact admin!">
            isDisabled:
              config.isLoading ||
              onboardingAccount.isLoading ||
              !!accountNameUsedError,
            isLoading: onboardingAccount.isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        styles={{ form: { formWidth: 'full' } }}
        handleSubmit={handleSubmit}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        body={
          <Stack spacing={6}>
            {accountInformation(
              'Account ID',
              config.data.aws_config?.account_id,
            )}
            {accountInformation(
              'External ID',
              config.data.aws_config?.external_id,
            )}
          </Stack>
        }
      />
    </Stack>
  );
});
