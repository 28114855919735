import React, { FC } from 'react';

import { NoSetup } from 'components/DataDisplay/Utils/NoSetup';

export const ComplianceAssessment: FC = () => {
  return (
    <NoSetup
      header="Compliance Assessment"
      headerMessage="Ariksa provides turnkey and automatic assessment of your cloud controls against compliance standards such as
SOC2, NIST etc. Cloud compliance augments your broader compliance efforts."
      steps={[
        'Onboard one or more cloud accounts',
        'Automatically assess health of cloud compliance to standards',
        'Customize policies for compliance standards to meet organizational needs',
      ]}
      footer="ONBOARD ACCOUNT"
      footerLink="/setup/accounts"
    />
  );
};
