import React from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ContainerRepositoryIcon } from 'components/Icons';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { getVulnerabilityCountInfo } from 'containers/Dashboard/VulnerabilityOverview/Components/utils';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';

export const ContainerImages: React.FC = () => {
  const { vulnerabilitiesCount } = useSelector(selectVulnerabilityOverview);
  const { data, isLoading } = vulnerabilitiesCount.containerService;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Container Images"
      icon={<ContainerRepositoryIcon />}
      change={{
        isLoading: isLoading,
        first: data.previous_count,
        last: data.current_count,
        percentChange: data.percentage_change,
      }}
      cloudProviders={[]}
      count={data.current_count ?? 0}
      isLoading={isLoading}
      onClick={() =>
        navigate(
          '/visibility/vulnerability?tab=' +
            NativeResources.ElasticContainerRegistry,
        )
      }
      info={{
        message: getVulnerabilityCountInfo(data.vulnerability_count),
        enabled: false,
      }}
      /*labelTooltip={formatTooltip({
        header: 'Container Images',
        content:
          'Vulnerabilities found in container images hosted on cloud registeries',
        width: 48,
      })}*/
    />
  );
};
