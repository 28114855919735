import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorOpacity, customTheme } from 'theme';

import { formatTooltip } from 'components/DataDisplay';
import { DatabaseIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { getSumOfValues } from 'containers/Dashboard/utils/utils';

export const DataSources: React.FC = () => {
  const {
    infrastructurePostureSummary,
    dataCount,
    summaryOfAllDataSources,
  } = useSelector(selectDashboard);
  const { data, isLoading } = infrastructurePostureSummary;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Data Sources"
      icon={<DatabaseIcon />}
      change={{
        isLoading: false,
        first: dataCount.data.previous_count ?? 0,
        last: dataCount.data.current_count ?? 0,
        percentChange: dataCount.data.percentage_change ?? 0,
      }}
      info={{
        message:
          summaryOfAllDataSources.data?.data_region_summary?.length +
          ' region' +
          (summaryOfAllDataSources.data?.data_region_summary?.length === 1
            ? ''
            : 's'),
        isLoading: summaryOfAllDataSources.isLoading,
        bg: colorOpacity(customTheme.colors.primary, 0.3),
      }}
      cloudProviders={data?.items?.['ProvidersByLabel']?.['Data']}
      count={getSumOfValues(data?.items?.['Data'])}
      isLoading={isLoading}
      onClick={() => navigate('/inventory/summary?tab=Data')}
      labelTooltip={formatTooltip({
        header: 'Data sources',
        content:
          'Buckets, Disks, Databases, File stores and Data warehouses in public clouds and SaaS',
        width: 48,
      })}
    />
  );
};
