import { ContextType, NativeResources } from '@ariksa/inventory-core';

import { IconType, IconTypes } from 'components/Icons';

import AccountIcon from './blackIcons/Account-Black-128x128.png';
import AccountRootIcon from './blackIcons/Account-Root-Black-128x128.png';
import AmazonLinuxIcon from './blackIcons/amazon-linux.png';
import AmazonMachineImageIcon from './blackIcons/Ami-Black-128x128.png';
import ApacheHttpIcon from './blackIcons/apache-tomcat.png';
import APIGatewayRestAPIIcon from './blackIcons/api.png';
import AwsIcon from './blackIcons/aws.png';
import AzureADGroupIcon from './blackIcons/azure-ad-group.png';
import AzureADUserIcon from './blackIcons/azure-ad-user.png';
import AzureADOrganizationIcon from './blackIcons/azure-ad.png';
import BackupIcon from './blackIcons/backup.png';
import CassandraIcon from './blackIcons/cassandra.png';
import CentosIcon from './blackIcons/centos.png';
import ACMCertificateIcon from './blackIcons/Certificate-Black-128x128.png';
import RDSCertificateIcon from './blackIcons/Certificate-Black-128x128.png';
import CheckIcon from './blackIcons/CheckIcon.png';
import CloudFrontIcon from './blackIcons/Cloudfront-Distribution-Black-128x128.png';
import CloudTrailIcon from './blackIcons/Cloudtrail-Black-128x128.png';
import CloudWatchLogGroupIcon from './blackIcons/Cloudwatch-Monitoring-Black-128x128.png';
import ConfigurationIcon from './blackIcons/Configuration-Black-512x512.png';
import CrossIcon from './blackIcons/CrossIcon.png';
import DebianLinuxIcon from './blackIcons/debian.png';
import DHCPOptionsIcon from './blackIcons/Dhcp-Options-Black-128x128.png';
import DocumentDBClusterIcon from './blackIcons/Documentdb-Cluster-Black-128x128.png';
import DocumentDBIcon from './blackIcons/Documentdb-Mongodb-Black-128x128.png';
import DynamoDBIcon from './blackIcons/Dynamodb-Black-128x128.png';
import EBSIcon from './blackIcons/ebs-data.png';
import EBSSnapshotIcon from './blackIcons/Ebs-Snapshot-Black-128x128.png';
import EC2AutoScalingGroupIcon from './blackIcons/Ec2-Asg-Black-128x128.png';
import SystemsManagerDocumentIcon from './blackIcons/ec2-ssm-document.png';
import EC2LaunchTemplateIcon from './blackIcons/Ec2-Template-Black-128x128.png';
import ECSClusterIcon from './blackIcons/Ecs-Cluster-Black-128x128.png';
import ElasticContainerServiceIcon from './blackIcons/Ecs-Service-Black-128x128.png';
import ECSContainerIcon from './blackIcons/Ecs-Service-Black-128x128.png';
import ECSServiceIcon from './blackIcons/Ecs-Service-Black-128x128.png';
import ElasticKubernetesServiceIcon from './blackIcons/Eks-Black-128x128.png';
import ElasticBeanstalkIcon from './blackIcons/Elastic-Beanstalk-Application-Orchestration-Black-128x128.png';
import ElasticLoadBalancerIcon from './blackIcons/Elastic-Load-Balancer-Black-128x128.png';
import ElasticMapReduceIcon from './blackIcons/Elastic-Map-Reduce-Black-128x128.png';
import ElastiCacheIcon from './blackIcons/Elasticache-Black-128x128.png';
import ElasticCacheReplicationGroupIcon from './blackIcons/Elasticache-Replication-Group-Black-128x128.png';
import ElasticSearchIcon from './blackIcons/Elasticsearch-Black-128x128.png';
import ExploitableIcon from './blackIcons/exploitable.png';
import ElasticFileSystemIcon from './blackIcons/Folder-Black-128x128.png';
import IAMGroupIcon from './blackIcons/Group-Black-128x128.png';
import HAProxyIcon from './blackIcons/haproxy.png';
import CloudFormationIcon from './blackIcons/Infrastructure-As-Code-Cloudformation-Black-128x128.png';
import InternetGatewayIcon from './blackIcons/Internet-Gateway-Black-128x128.png';
import InternetIcon from './blackIcons/internet.png';
import ElasticIPIcon from './blackIcons/Ip-Address-Black-128x128.png';
import KMSIcon from './blackIcons/Kms-Key-Black-128x128.png';
import MariaDBIcon from './blackIcons/mariadb.png';
import MySQLIcon from './blackIcons/mysql.png';
import NATGatewayIcon from './blackIcons/NAT-Gateway-Black-128x128.png';
import NeptuneIcon from './blackIcons/Neptune-Black-128x128.png';
import NeptuneClusterIcon from './blackIcons/Neptune-Cluster-Black-128x128.png';
import NetworkAccessControlIcon from './blackIcons/Network-ACL-Black-128x128.png';
import NetworkInterfaceIcon from './blackIcons/Network-Interface-Black-128x128.png';
import NginxIcon from './blackIcons/nginx.png';
import OktaGroupIcon from './blackIcons/Okta-Black-128x128.png';
import OktaUserIcon from './blackIcons/Okta-Black-128x128.png';
import OktaOrganizationIcon from './blackIcons/Okta-Black-128x128.png';
import OpenAIIcon from './blackIcons/openai-logomark.png';
import AWSOrganizationIcon from './blackIcons/Organization-Black-128x128.png';
import OrganizationUnitIcon from './blackIcons/Organization-Unit-Black-128x128.png';
import VPCPeeringConnectionIcon from './blackIcons/P2p-Black-128x128.png';
import PermissionsIcon from './blackIcons/Permissions-Actions-Black-128x128.png';
import PolicyIcon from './blackIcons/policy.png';
import PostgresIcon from './blackIcons/postgres-sql.png';
import PrefixListIcon from './blackIcons/Prefix-List-Black-128x128.png';
import RDSIcon from './blackIcons/Rds-Black-128x128.png';
import RDSClusterIcon from './blackIcons/Rds-Cluster-Black-128x128.png';
import RDSSnapshotIcon from './blackIcons/Rds-Snapshot-Black-128x128.png';
import RedHatEnterpriseLinuxIcon from './blackIcons/redhat.png';
import RedisIcon from './blackIcons/redis.png';
import RedshiftClusterIcon from './blackIcons/redshift-cluster.png';
import RegionIcon from './blackIcons/region.png';
import IAMRoleIcon from './blackIcons/Role-Black-128x128.png';
import SubnetRouteTableIcon from './blackIcons/Route-Table-Black-128x128.png';
import TransitGatewayRouteTableIcon from './blackIcons/Route-Table-Black-128x128.png';
import Route53HostedZoneIcon from './blackIcons/route53-hosted-zone.png';
import Route53RecordSet from './blackIcons/route53-record-set.png';
import S3Icon from './blackIcons/S3-Object-Storage-Black-128x128.png';
import SagemakerEndpointsIcon from './blackIcons/Sagemaker-Machine-Learning-Black-128x128.png';
import SagemakerNotebookInstancesIcon from './blackIcons/Sagemaker-Machine-Learning-Black-128x128.png';
import SecretIcon from './blackIcons/Secrets-Password-Black-128x128.png';
import SecurityGroupIcon from './blackIcons/Security-Group-Black-128x128.png';
import DatabaseSecurityGroupIcon from './blackIcons/Security-Group-Black-128x128.png';
import AWSLambdaIcon from './blackIcons/Serverless-Black-128x128.png';
import SeverityCriticalIcon from './blackIcons/SeverityCritical.png';
import SeverityMediumIcon from './blackIcons/SeverityMedium.png';
import SeverityLowIcon from './blackIcons/SeverityMedium.png';
import SnowflakeIcon from './blackIcons/snowflake.png';
import SNSIcon from './blackIcons/Sns-Black-128x128.png';
import SQLDatabaseIcon from './blackIcons/sql-database.png';
import SQSIcon from './blackIcons/Sqs-Black-128x128.png';
import SSHKeyPairIcon from './blackIcons/Ssh-Key-Pair-Black-128x128.png';
import SubnetIcon from './blackIcons/Subnet-Black-128x128.png';
import TransitGatewayAttachmentIcon from './blackIcons/Transit-Gateway-Attachment-Black-128x128.png';
import UbuntuIcon from './blackIcons/ubuntu.png';
import UnencryptedDiskIcon from './blackIcons/unencrypted-disk-black.png';
import IAMUserIcon from './blackIcons/User-Black-128x128.png';
import EC2Icon from './blackIcons/Virtual-Machine-Black-128x128.png';
import VirtualPrivateCloudIcon from './blackIcons/Vpc-Black-128x128.png';
import VpnIcon from './blackIcons/Vpn-Black-128x128.png';
import VpnGatewayIcon from './blackIcons/Vpn-Gateway-Black-128x128.png';
import WAFIcon from './blackIcons/Web-Application-Firewall-(WAF)-Black-128x128.png';
import WAFv2Icon from './blackIcons/Web-Application-Firewall-(WAF)-Black-128x128.png';

export const getBlackMapIcon = (type: IconType) => {
  switch (type) {
    case ContextType.Exploitable:
      return ExploitableIcon;
    case ContextType.UnencryptedData:
    case ContextType.UnencryptedDisk:
      return UnencryptedDiskIcon;
    case IconTypes.Region:
      return RegionIcon;
    case IconTypes.RestrictPublicBuckets:
    case IconTypes.BlockPublicAcl:
    case IconTypes.BlockPublicPolicy:
      return CheckIcon;
    case IconTypes.IgnorePublicAcls:
    case IconTypes.BlockPublicAcls:
    case IconTypes.DataProtection:
    case IconTypes.ObjectOwnership:
      return CrossIcon;
    case IconTypes.Redis:
      return RedisIcon;
    case IconTypes.Cassandra:
      return CassandraIcon;
    case NativeResources.RelationalDatabaseService:
    case NativeResources.GcpCloudSql:
      return RDSIcon;
    case ContextType.CredentialFound:
    case IconTypes.KeyManagementService:
    case NativeResources.IamUserAccessKey:
    case IconTypes.AccessKeys:
    case NativeResources.GcpCloudKms:
      return KMSIcon;
    case NativeResources.BackupPlan:
    case NativeResources.GcpFilestoreBackup:
      return BackupIcon;
    case IconTypes.ApacheHttp:
      return ApacheHttpIcon;
    case IconTypes.Snowflake:
    case IconTypes.SnowflakeTables:
    case IconTypes.SnowflakeRoles:
    case IconTypes.SnowflakePrivileges:
    case IconTypes.SnowflakeUsers:
    case IconTypes.SnowflakeDatabases:
    case IconTypes.SnowflakeSchemas:
      return SnowflakeIcon;
    case IconTypes.PostgreSql:
      return PostgresIcon;
    case IconTypes.MySql:
      return MySQLIcon;
    case IconTypes.Nginx:
      return NginxIcon;
    case IconTypes.HaProxy:
      return HAProxyIcon;
    case IconTypes.RedHatEnterpriseLinux:
    case IconTypes.RedHatEnterpriseLinuxServer:
      return RedHatEnterpriseLinuxIcon;
    case IconTypes.DebianLinux:
      return DebianLinuxIcon;
    case IconTypes.MariaDb:
      return MariaDBIcon;
    case IconTypes.Ubuntu:
      return UbuntuIcon;
    case IconTypes.AmazonLinux:
      return AmazonLinuxIcon;
    case NativeResources.AsgResourcePolicy:
    case NativeResources.IamPolicy:
    case NativeResources.IamInlinePolicy:
    case NativeResources.OrganizationPolicy:
    case IconTypes.Policy:
    case NativeResources.AccountPasswordIdentityPolicy:
    case NativeResources.ResourcePolicy:
    case NativeResources.GcpiamPolicy:
    case NativeResources.GcpResourcePolicy:
    case NativeResources.GcpOrgPolicyService:
      return PolicyIcon;
    case NativeResources.AwsConfigRule:
      return ConfigurationIcon;
    case IconTypes.Account:
    case IconTypes.GcpServiceAccount:
      return AccountIcon;
    case NativeResources.AccountRoot:
      return AccountRootIcon;
    case NativeResources.AcmCertificate:
    case NativeResources.GcpsqlsslCerts:
      return ACMCertificateIcon;
    case NativeResources.AmazonMachineImage:
    case NativeResources.ElasticContainerRegistry:
    case NativeResources.GcpImage:
      return AmazonMachineImageIcon;
    case NativeResources.ApiGatewayRestApi:
    case NativeResources.GcpapiGateway:
      return APIGatewayRestAPIIcon;
    case NativeResources.AwsAccount:
      return AwsIcon;
    case NativeResources.LambdaFunction:
    case NativeResources.GcpCloudFunctions:
      return AWSLambdaIcon;
    case NativeResources.AwsOrganization:
    case NativeResources.GcpOrganization:
      return AWSOrganizationIcon;
    case NativeResources.GcpFolder:
    case NativeResources.OrganizationUnit:
      return OrganizationUnitIcon;
    case NativeResources.AzureAdGroup:
      return AzureADGroupIcon;
    case NativeResources.AzureAdOrganization:
      return AzureADOrganizationIcon;
    case NativeResources.AzureAdUser:
      return AzureADUserIcon;
    case NativeResources.CloudFormation:
    case NativeResources.GcpCloudDeploymentManager:
      return CloudFormationIcon;
    case NativeResources.CloudFront:
      return CloudFrontIcon;
    case NativeResources.CloudTrail:
    case NativeResources.GcpCloudLogMetrics:
      return CloudTrailIcon;
    case NativeResources.CloudWatchLogGroup:
      return CloudWatchLogGroupIcon;
    case NativeResources.DatabaseSecurityGroup:
      return DatabaseSecurityGroupIcon;
    case NativeResources.DhcpOptions:
      return DHCPOptionsIcon;
    case NativeResources.DocumentDb:
    case NativeResources.GcpFirestore:
      return DocumentDBIcon;
    case NativeResources.DocumentDbCluster:
      return DocumentDBClusterIcon;
    case NativeResources.DynamoDb:
      return DynamoDBIcon;
    case NativeResources.ElasticBlockStorage:
    case NativeResources.GcpPersistentDisk:
      return EBSIcon;
    case NativeResources.EbsSnapshot:
      return EBSSnapshotIcon;
    case NativeResources.Ec2Instance:
    case NativeResources.GcpComputeEngine:
    case NativeResources.GcpCloudTpus:
    case NativeResources.AzureVirtualMachine:
      return EC2Icon;
    case NativeResources.SnowflakeUsers:
      return SnowflakeIcon;
    case NativeResources.SnowflakeRoles:
      return SnowflakeIcon;
    case NativeResources.SnowflakePrivileges:
      return SnowflakeIcon;
    case NativeResources.Ec2AutoScalingGroup:
    case NativeResources.GcpComputeEngineAutoscaler:
      return EC2AutoScalingGroupIcon;
    case NativeResources.Ec2LaunchTemplate:
      return EC2LaunchTemplateIcon;
    case NativeResources.EcsCluster:
      return ECSClusterIcon;
    case NativeResources.EcsContainerInstance:
    case NativeResources.Container:
    case NativeResources.GcpContainer:
    case NativeResources.AzureContainer:
      return ECSContainerIcon;
    case NativeResources.EcsService:
      return ECSServiceIcon;
    case NativeResources.ElasticBeanstalk:
      return ElasticBeanstalkIcon;
    case NativeResources.ElasticContainerService:
      return ElasticContainerServiceIcon;
    case NativeResources.ElasticFileSystem:
    case NativeResources.GcpFilestore:
      return ElasticFileSystemIcon;
    case NativeResources.ElasticIp:
      return ElasticIPIcon;
    case NativeResources.ElasticKubernetesService:
    case NativeResources.Gcpgke:
      return ElasticKubernetesServiceIcon;
    case NativeResources.ElasticLoadBalancer:
    case NativeResources.GcpurlMaps:
    case NativeResources.ElasticLoadBalancerv2:
      return ElasticLoadBalancerIcon;
    case NativeResources.ElasticMapReduce:
      return ElasticMapReduceIcon;
    case NativeResources.ElastiCache:
    case NativeResources.GcpMemorystoreRedis:
      return ElastiCacheIcon;
    case NativeResources.ElastiCacheReplicationGroup:
      return ElasticCacheReplicationGroupIcon;
    case NativeResources.ElasticSearch:
      return ElasticSearchIcon;
    case NativeResources.TransitGatewayAttachment:
      return TransitGatewayAttachmentIcon;
    case NativeResources.IamGroup:
    case NativeResources.GcpGroup:
      return IAMGroupIcon;
    case NativeResources.IamRole:
    case NativeResources.GcpRole:
      return IAMRoleIcon;
    case NativeResources.IamUser:
    case NativeResources.GcpUser:
      return IAMUserIcon;
    case NativeResources.InternetGateway:
      return InternetGatewayIcon;
    case NativeResources.NatGateway:
    case NativeResources.GcpCloudNat:
      return NATGatewayIcon;
    case NativeResources.Neptune:
      return NeptuneIcon;
    case NativeResources.NeptuneCluster:
      return NeptuneClusterIcon;
    case NativeResources.NetworkAcl:
      return NetworkAccessControlIcon;
    case NativeResources.ElasticNetworkInterface:
    case NativeResources.VirtualInterface:
    case NativeResources.GcpNetworkInterface:
      return NetworkInterfaceIcon;
    case NativeResources.OktaGroup:
      return OktaGroupIcon;
    case NativeResources.OktaOrganization:
      return OktaOrganizationIcon;
    case NativeResources.OktaUser:
      return OktaUserIcon;
    case NativeResources.PrefixList:
      return PrefixListIcon;
    case IconTypes.Permission:
      return PermissionsIcon;
    case NativeResources.RdsCertificate:
      return RDSCertificateIcon;
    case NativeResources.RdsCluster:
      return RDSClusterIcon;
    case NativeResources.RdsSnapshot:
      return RDSSnapshotIcon;
    case NativeResources.RedshiftCluster:
    case NativeResources.GcpBigQuery:
      return RedshiftClusterIcon;
    case NativeResources.Route53HostedZone:
    case IconTypes.Route53SubDomain:
    case NativeResources.GcpCloudDnsZone:
    case NativeResources.GcpCloudDomainsRegistration:
      return Route53HostedZoneIcon;
    case NativeResources.SimpleStorageService:
    case NativeResources.GcpBucket:
      return S3Icon;
    case NativeResources.SagemakerEndpoints:
      return SagemakerEndpointsIcon;
    case NativeResources.SagemakerNotebookInstances:
      return SagemakerNotebookInstancesIcon;
    case NativeResources.Secret:
    case NativeResources.GcpSecretManager:
    case NativeResources.AzureSecrets:
      return SecretIcon;
    case NativeResources.SecurityGroup:
    case NativeResources.GcpCloudFirewall:
      return SecurityGroupIcon;
    case NativeResources.SimpleNotificationService:
    case NativeResources.GcpPubSubLite:
      return SNSIcon;
    case NativeResources.SimpleQueueService:
    case NativeResources.GcpCloudTasksQueue:
      return SQSIcon;
    case NativeResources.SshKeyPair:
      return SSHKeyPairIcon;
    case NativeResources.Subnet:
    case NativeResources.GcpSubnetwork:
      return SubnetIcon;
    case NativeResources.SubnetRouteTable:
      return SubnetRouteTableIcon;
    case NativeResources.SystemsManagerDocument:
      return SystemsManagerDocumentIcon;
    case NativeResources.TransitGatewayRouteTable:
      return TransitGatewayRouteTableIcon;
    case NativeResources.VirtualPrivateCloud:
    case NativeResources.GcpVirtualPrivateCloud:
      return VirtualPrivateCloudIcon;
    case NativeResources.VpcPeeringConnection:
      return VPCPeeringConnectionIcon;
    case NativeResources.AwsVirtualPrivateNetwork:
      return VpnIcon;
    case NativeResources.VpnGateway:
    case NativeResources.GcpvpnGateway:
      return VpnGatewayIcon;
    case NativeResources.Wafv1:
      return WAFIcon;
    case NativeResources.Wafv2:
      return WAFv2Icon;
    case IconTypes.Internet:
      return InternetIcon;
    case IconTypes.SeverityCritical:
      return SeverityCriticalIcon;
    case IconTypes.SeverityMedium:
      return SeverityMediumIcon;
    case IconTypes.SeverityLow:
      return SeverityLowIcon;
    case IconTypes.Route53RecordSet:
      return Route53RecordSet;
    case NativeResources.GcpsqlInstances:
      return SQLDatabaseIcon;
    case IconTypes.OpenAI:
      return OpenAIIcon;
    case IconTypes.Centos:
    case IconTypes.CentOS:
    case IconTypes.CentOSLinux:
      return CentosIcon;
    default:
      return '';
  }
};
