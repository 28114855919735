import React, { ReactNode } from 'react';

import { Center } from '@chakra-ui/react';
import { customTheme } from 'theme';

interface NoDataAvailableProps {
  text?: ReactNode | string;
}

export const NoDataAvailable = (props: NoDataAvailableProps) => {
  const { text = 'No data available' } = props;
  return (
    <Center h="full" w="full" color={customTheme.colors.gray['300']}>
      {text}
    </Center>
  );
};
